// alert services
// import getAlertActionOptionsService from '../../services/getAlertActionOptions.service.workflow';
import getExcludeCompaniesScopeOptionsService from '../../services/getExcludeCompaniesScopeOptions.service.workflow';
import getScopeOptionsService from '../../services/getScopeOptions.service.workflow';
import getTriggerOptionsService from '../../services/getTriggerOptions.service.workflow';

// ant components
import {Input, Modal, Select, Space, TreeSelect, Checkbox} from 'antd';

// layout colors
import blue500Color from '../../../layout/colors/blue500.color.layout';
import brightRed300Color from '../../../layout/colors/brightRed300.color.layout';
import brightYellow300Color from '../../../layout/colors/brightYellow300.color.layout';

// layout components
import Card from '../../../layout/components/Card/Card';
import Column from '../../../layout/components/Grid/Column/Column';
import Row from '../../../layout/components/Grid/Row/Row';

// local components
import Box from './components/Box';
import Label from './components/Label';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// workflow lib
import isPeriodRequired from '../../lib/isPeriodRequired.lib.workflow';
import isThresholdRequired from '../../lib/isThresholdRequired.lib.workflow';

const WorkflowEditorModal = ({
  actions,
  actionsPerCompany,
  canEditRecipients,
  canEditWorkflow = true,
  comment,
  excludeCompanies,
  name,
  onChange,
  onChangeSelection,
  onClearSelection,
  onClose,
  onReset,
  onSave,
  period,
  recipients,
  saving,
  scope,
  tenantWide,
  threshold,
  trigger,
  visible,
}) => (
  <Modal
    title="Workflow Editor"
    centered
    open={visible}
    confirmLoading={saving}
    onOk={canEditWorkflow ? onSave : null}
    okText="Save"
    onCancel={onClose}
    width="1440px"
  >
    <Row>
      <Column size={1 / 3}>
        <Card title="Step #1: Name & Scope" underline={brightYellow300Color}>
          <Space direction="vertical" style={{width: '100%'}} size={[16, 16]}>
            <Box>
              <Label>Name</Label>
              <Input
                value={name}
                onChange={onChange('name')}
                disabled={!canEditWorkflow}
              />
            </Box>
            {!excludeCompanies.length && (
              <Box>
                <Label>Scope</Label>
                <Select
                  mode="multiple"
                  value={scope}
                  allowClear
                  onSelect={onChangeSelection('scope')}
                  onDeselect={onChangeSelection('scope')}
                  onClear={onClearSelection('scope')}
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Entire Portfolio"
                  options={getScopeOptionsService({excludeCompanies})}
                  disabled={!canEditWorkflow}
                  optionFilterProp="label"
                />
              </Box>
            )}
            {!scope.length && (
              <Box>
                <Label>Exc. Companies</Label>
                <Select
                  mode="multiple"
                  value={excludeCompanies}
                  allowClear
                  onSelect={onChangeSelection('excludeCompanies')}
                  onDeselect={onChangeSelection('excludeCompanies')}
                  onClear={onClearSelection('excludeCompanies')}
                  showSearch
                  style={{width: '100%'}}
                  options={getExcludeCompaniesScopeOptionsService({scope})}
                  disabled={!canEditWorkflow}
                  optionFilterProp="label"
                />
              </Box>
            )}
          </Space>
        </Card>
      </Column>
      <Column size={1 / 3}>
        <Card title="Step #2: Trigger & Filters" underline={blue500Color}>
          <Space direction="vertical" style={{width: '100%'}} size={[16, 16]}>
            <Box>
              <Label>Trigger</Label>
              <TreeSelect
                value={trigger}
                dropdownStyle={{
                  maxHeight: 600,
                  overflow: 'auto',
                }}
                allowClear
                multiple
                onChange={onChange('trigger')}
                placement="topLeft"
                showSearch
                style={{width: '100%'}}
                treeCheckable
                treeData={getTriggerOptionsService()}
                maxTagCount={6}
                disabled={!canEditWorkflow}
              />
            </Box>
            {isPeriodRequired({trigger}) && (
              <Box>
                <Label>Period</Label>
                <Select
                  value={period}
                  onSelect={onChange('period')}
                  style={{width: '100%'}}
                  options={[
                    {
                      value: '1',
                      label: 'Last day',
                    },
                    {
                      value: '7',
                      label: 'Last 7 days',
                    },
                    {
                      value: '30',
                      label: 'Last 30 days',
                    },
                  ]}
                  disabled={!canEditWorkflow}
                />
              </Box>
            )}
            {isThresholdRequired({trigger}) && (
              <Box>
                <Label>Percentage Change</Label>
                <Select
                  value={threshold}
                  onSelect={onChange('threshold')}
                  style={{width: '100%'}}
                  options={[
                    {
                      value: '2',
                      label: '2%',
                    },
                    {
                      value: '5',
                      label: '5%',
                    },
                    {
                      value: '10',
                      label: '10%',
                    },
                  ]}
                  disabled={!canEditWorkflow}
                />
              </Box>
            )}
          </Space>
        </Card>
      </Column>
      <Column size={1 / 3}>
        <Card title="Step #3: Action" underline={brightRed300Color}>
          <Space direction="vertical" style={{width: '100%'}} size={[16, 16]}>
            <Box>
              <Label>Recipients</Label>
              <Space
                direction="vertical"
                style={{width: '100%'}}
                size={[10, 10]}
              >
                <Checkbox
                  checked={tenantWide}
                  onChange={(e) => onChange('tenantWide')(e.target.checked)}
                  disabled={!canEditRecipients || !canEditWorkflow}
                >
                  All Users
                </Checkbox>
                {!tenantWide && (
                  <Select
                    mode="tags"
                    value={recipients}
                    tokenSeparators={[',']}
                    onChange={onChange('recipients')}
                    allowClear
                    style={{width: '100%'}}
                    options={[]}
                    dropdownStyle={{display: 'none'}}
                    disabled={!canEditRecipients || !canEditWorkflow}
                  />
                )}
              </Space>
            </Box>
            {/* <Box>
              <Label>Actions</Label>
              <Select
                mode="multiple"
                value={actions}
                allowClear
                onSelect={onChangeSelection('actions')}
                onDeselect={onChangeSelection('actions')}
                onClear={onClearSelection('actions')}
                showSearch
                style={{width: '100%'}}
                options={getAlertActionOptionsService()}
              />
            </Box> */}
            {/* <Box>
              <Label>Frequency of Actions per Company</Label>
              <Select
                value={actionsPerCompany}
                onSelect={onChange('actionsPerCompany')}
                style={{width: '100%'}}
                options={[
                  'Once a Day',
                  'Once a Week',
                  'Once a Month',
                  'Once every 3 Months',
                  'Once a Year',
                  'Never',
                ].map((option) => ({value: option, label: option}))}
              />
            </Box> */}
            <Box>
              <Label>Comment</Label>
              <Input
                value={comment}
                onChange={onChange('comment')}
                disabled={!canEditWorkflow}
              />
            </Box>
          </Space>
        </Card>
      </Column>
    </Row>
  </Modal>
);

WorkflowEditorModal.propTypes = {
  canEditRecipients: PropTypes.bool,
  canEditWorkflow: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeSelection: PropTypes.func,
  onClearSelection: PropTypes.func,
  onReset: PropTypes.func,
  onSave: PropTypes.func,
  recipients: PropTypes.array,
  saving: PropTypes.bool,
};

export default WorkflowEditorModal;
