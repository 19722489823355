// financial containers
import CompanyFinancialsContainer from '../../containers/CompanyFinancialsContainer/CompanyFinancialsContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const FinancialsPage = () => (
  <SubPage page={page}>
    <CompanyFinancialsContainer />
  </SubPage>
);

export default FinancialsPage;
