// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// countries
import {countryToAlpha2} from 'country-to-iso';

// geopolitical components
import CountrySuppliersView from '../../components/CountrySuppliersView/CountrySuppliersView';

// geopolitical events
import showCountrySuppliersViewEvent from '../../events/showCountrySuppliersModal.event.geopolitical';

// map api
import getCountryMapApi from '../../../map/api/getCountry.api.map';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class CountrySuppliersContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    companyName: null,
    country: null,
    loading: false,
    map: null,
    suppliers: [],
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showCountrySuppliersViewEvent.subscribe(this.show));
  }

  show = async ({companyName, country} = {}) => {
    this.setState({loading: true});

    const map = await this.loadMap(country);

    this.setState({companyName, country, map, visible: true, loading: false});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  loadMap = async (country) => {
    try {
      const countryIso = countryToAlpha2(country.name)?.toLowerCase?.();
      const map = await getCountryMapApi({countryIso});
      return map;
    } catch (error) {
      return null;
    }
  };

  render() {
    const {companyName, country, loading, map, visible} = this.state;
    return (
      visible && (
        <CountrySuppliersView
          companyName={companyName}
          countryName={country?.name || ''}
          displayState={country?.stateBreakdown}
          globalResilienceIndex={country?.globalResilienceIndex}
          loading={loading}
          map={map}
          // onClose={this.hide}
          states={country?.states || []}
          suppliers={country?.data || []}
          // visible={visible}
        />
      )
    );
  }
}

export default subscriptionHOC(CountrySuppliersContainer);
