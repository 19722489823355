// local columns
// import diversityColumn from '../columns/diversity.column';
import haloEsgFactorRatingColumn from '../columns/haloEsgFactorRating.column';
import haloGovernanceFactorRatingColumn from '../columns/haloGovernanceFactorRating.column';
import overallEnvironmentalImpactColumn from '../columns/overallEnvironmentalImpact.column';
import overallGovernanceImpactColumn from '../columns/overallGovernanceImpact.column';
import overallSocialImpactColumn from '../columns/overallSocialImpact.column';

// local lib
import generateColumnGroup from '../lib/generateColumnGroup.lib';

const esgCsrColumnGroup = generateColumnGroup({
  name: 'ESG & CSR',
  columns: [
    // diversityColumn,
    haloEsgFactorRatingColumn,
    overallEnvironmentalImpactColumn,
    overallSocialImpactColumn,
    overallGovernanceImpactColumn,
    haloGovernanceFactorRatingColumn,
  ],
});

export default esgCsrColumnGroup;
