// local columns
import activeInvestorsColumn from '../columns/activeInvestors.column';
import ebitdaColumn from '../columns/ebitda.column';
import enterpriseValueColumn from '../columns/enterpriseValue.column';
import financialStatusColumn from '../columns/financialStatus.column';
import fiscalPeriodColumn from '../columns/fiscalPeriod.column';
import haloFinancialFactorRatingColumn from '../columns/haloFinancialFactorRating.column';
import netDebtColumn from '../columns/netDebt.column';
import numberOfEmployeesColumn from '../columns/numberOfEmployees.column';
import revenueGrowthColumn from '../columns/revenueGrowth.column';
import ultimateParentCompanyColumn from '../columns/ultimateParentCompany.column';

// local lib
import generateColumnGroup from '../lib/generateColumnGroup.lib';

const financialsColumnGroup = generateColumnGroup({
  name: 'Financials',
  size: {x: 2400},
  columns: [
    haloFinancialFactorRatingColumn,
    numberOfEmployeesColumn,
    financialStatusColumn,
    enterpriseValueColumn,
    ebitdaColumn,
    netDebtColumn,
    revenueGrowthColumn,
    fiscalPeriodColumn,
    activeInvestorsColumn,
    ultimateParentCompanyColumn,
  ],
});

export default financialsColumnGroup;
