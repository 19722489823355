import styled from 'styled-components';

const SubValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  font-size: 16px;
  text-align: ${({right}) => (right ? 'right' : 'left')};
`;

export default SubValue;
