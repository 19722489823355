// ant
import {Dropdown, Button, Space} from 'antd';

// company routes
import routeForFinancialsPage from '../../../../../company/pages/FinancialsPage/route';

// esg routes
import routeForEsgPage from '../../../../../esg/pages/EsgPage/route';

// geopolitical routes
import routeForGeopoliticalPage from '../../../../../geopolitical/pages/GeopoliticalPage/route';

// news routes
import routeForNewsPage from '../../../../../news/pages/NewsPage/route';

// layout components
// import FullIntegrationTag from '../../../../../layout/components/FullIntegrationTag/FullIntegrationTag';

// layout events
import notImplementedEvent from '../../../../../layout/events/notImplemented.event.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// report events
import generateReportEvent from '../../../../../report/events/generate.event.report';

// router
import {useNavigate} from 'react-router-dom';

// sanction routes
import routeForSanctionsPage from '../../../../../sanction/pages/SanctionsPage/route';

// tag events
import showCreateTagModalEvent from '../../../../../tag/events/showCreateModal.event.tag';

const CompanyActionsColumn = ({company}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Dropdown
        trigger={['click']}
        menu={{
          onClick: (option) => {
            const isReport =
              option?.keyPath?.includes?.('generateReports') || false;
            const redirectToPage = option?.keyPath?.includes?.('goTo') || false;
            const isTagAction = option?.keyPath?.includes?.('tags') || false;
            if (isTagAction) {
              return showCreateTagModalEvent.publish({supplier: company});
            }
            if (isReport)
              return generateReportEvent.publish({
                reportType: option?.key,
                company,
              });
            if (redirectToPage) return navigate(option?.key);
            notImplementedEvent.publish();
          },
          items: [
            {
              label: 'Go To',
              key: 'goTo',
              children: [
                {
                  key: routeForFinancialsPage(company.CompanyId),
                  label: 'Financials',
                },
                {
                  key: routeForSanctionsPage(company.CompanyId),
                  label: 'Sanctions',
                },
                {
                  key: routeForNewsPage(company.CompanyId),
                  label: 'News',
                },
                {
                  key: routeForEsgPage(company.CompanyId),
                  label: 'ESG',
                },
                {
                  key: routeForGeopoliticalPage(company.CompanyId),
                  label: 'Geopolitical',
                },
              ],
            },
            {
              label: 'Tags',
              key: 'tags',
              children: [
                {
                  key: 'add_for_supplier',
                  label: `Add Tag for ${company.CompanyName}`,
                },
              ],
            },
          ],
        }}
      >
        <Button>
          <Space>Actions</Space>
        </Button>
      </Dropdown>
    </div>
  );
};

CompanyActionsColumn.propTypes = {
  company: PropTypes.object,
};

export default CompanyActionsColumn;
