// currency
import getSymbolFromCurrency from 'currency-symbol-map';

// numeral
import numeral from 'numeral';

const formattedCurrency = ({
  currency = null,
  currencySymbol = null,
  displayCurrency = false,
  displayCurrencySymbol = true,
  number,
  removeZeroDecimals = true,
  withDecimals = true,
}) => {
  const formattedCurrencySymbol =
    currencySymbol || getSymbolFromCurrency(currency);

  return [
    displayCurrencySymbol && formattedCurrencySymbol,
    numeral(number).format(
      withDecimals ? (removeZeroDecimals ? '0,0[.]0[0]' : '0,0.00') : '0,0'
    ),
    (displayCurrency && !!currency) ||
    (displayCurrencySymbol && !formattedCurrencySymbol && !!currency)
      ? ` ${currency}`
      : null,
  ]
    .filter((value) => !!value)
    .join('')
    ?.toUpperCase?.();
};

export default formattedCurrency;
