// local lib
import generateColumn from './generateColumn.lib';

const columns = () => [
  generateColumn({
    title: 'Source',
    field: 'dataSource',
  }),
  generateColumn({
    title: 'Element',
    field: 'name',
  }),
  generateColumn({
    title: 'Min value',
    field: 'min',
  }),
  generateColumn({
    title: 'Max value',
    field: 'max',
  }),
  generateColumn({
    title: 'Description',
    field: 'description',
  }),
];

export default columns;
