// company lib
import getFullDiversity from '../../lib/getFullDiversity.lib.company';

// propTypes
import PropTypes from 'prop-types';

const Diversity = ({diversity, empty}) =>
  (diversity || '')
    .split('|')
    .map((singleDiversity) =>
      getFullDiversity({diversity: singleDiversity, empty})
    )
    .join(', ');

Diversity.propTypes = {
  diversity: PropTypes.string,
  empty: PropTypes.node,
};

export default Diversity;
