// ant components
import {Col as Column, Row, Table} from 'antd';

// charts
import Highcharts from 'highcharts';

// layout components
import Card from '../../../layout/components/Card/Card';
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local constants
import LINE_CHART_CATEGORIES from './constants/lineChartCategories.constant';

// local lib
import getColumns from './lib/getColumns.lib';
import parseNumber from './lib/parseNumber.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const CreditSafeYearlyCharts = ({
  companyResults = [],
  industryQuartiles = [],
  info,
  subtitle,
  title,
}) => {
  const columns = getColumns();
  const id = v4();

  useEffect(() => {
    const years = [...companyResults].map(({year}) => year);
    const data = [
      {
        name: 'Company Results',
        type: 'column',
        yAxis: 1,
        // color,
        data: [...companyResults].map(({value}) => parseNumber(value)),
      },
      ...LINE_CHART_CATEGORIES.map((category) => ({
        name: category.label,
        type: 'spline',
        data: [...industryQuartiles].map(({[category.key]: value}) =>
          parseNumber(value)
        ),
        // color,
      })),
    ];
    Highcharts.chart(id, {
      chart: {
        height: '744px',
        zoomType: 'xy',
      },
      title: {
        text: title,
      },
      xAxis: [
        {
          categories: years,
          crosshair: true,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          title: {
            text: '',
          },
          opposite: true,
        },
        {
          // Secondary yAxis
          gridLineWidth: 0,
          title: {
            text: '',
          },
        },
      ],
      tooltip: {
        shared: true,
      },
      series: data,
    });
    // eslint-disable-next-line
  }, [companyResults, industryQuartiles]);

  return (
    <StatementBlock title={title}>
      <Row gutter={[20, 20]}>
        <Column span={12}>
          <Card noPadding>
            <div id={id} />
          </Card>
        </Column>
        <Column span={12}>
          <Row gutter={[20, 20]}>
            <Column span={24}>
              <Card title={subtitle || title}>{info}</Card>
            </Column>
            <Column span={24}>
              <Card title="Company Results" noPadding indentTitle>
                <Table
                  columns={columns.companyResults}
                  dataSource={companyResults}
                  pagination={false}
                  rowKey="section"
                  size="small"
                />
              </Card>
            </Column>
            <Column span={24}>
              <Card title="Industry Quartiles" noPadding indentTitle>
                <Table
                  columns={columns.industryQuartiles}
                  dataSource={industryQuartiles}
                  pagination={false}
                  rowKey="section"
                  size="small"
                />
              </Card>
            </Column>
          </Row>
        </Column>
      </Row>
    </StatementBlock>
  );
};

CreditSafeYearlyCharts.propTypes = {
  data: PropTypes.array,
  info: PropTypes.node,
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

export default CreditSafeYearlyCharts;
