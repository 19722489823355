// ant
import {Tooltip} from 'antd';

// company components
import HighlightedScore from '../../../components/HighlightedScore/HighlightedScore';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// local tooltips
import iso27001Tooltip from './tooltips/iso27001.tooltip.company';

export default generateColumn({
  key: 'iso27001',
  title: <Tooltip title={iso27001Tooltip}>ISO 27001</Tooltip>,
  componentProps: ({company}) => ({
    score: Number(company?.Compliance?.ISO27001Rating),
    suffix: '%',
  }),
  Component: HighlightedScore,
});
