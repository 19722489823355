const isRisingOrFalling = ({
  lastValue,
  penultimateValue,
  risingLabel = 'Rising',
  fallingLabel = 'Falling',
  sameValueLabel = '-',
} = {}) =>
  lastValue > penultimateValue
    ? risingLabel
    : lastValue < penultimateValue
    ? fallingLabel
    : sameValueLabel;

export default isRisingOrFalling;
