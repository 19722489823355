// local components
import ColorColumn from '../components/ColorColumn';
import ScoreColumn from '../components/ScoreColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const columns = [
  generateGeneralColumn({
    category: 'color',
    title: 'International Score',
    Component: ColorColumn,
  }),
  generateGeneralColumn({category: 'label', title: 'Score Description'}),
  generateGeneralColumn({
    category: 'min',
    title: 'Score',
    Component: ScoreColumn,
  }),
];

export default columns;
