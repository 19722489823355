// company components
import GeopoliticalLayout from '../../components/GeopoliticalLayout/GeopoliticalLayout';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const GeopoliticalPage = () => (
  <SubPage page={page}>
    <GeopoliticalLayout />
  </SubPage>
);

export default GeopoliticalPage;
