// esg lib
import getGeneralPercentileValue from '../../../lib/getGeneralPercentileValue.lib.esg';

const getCategoriesRating = ({company, percentileView, rating}) =>
  (percentileView
    ? getGeneralPercentileValue(
        company?.esgInfo?.percentileRatings?.categories?.[rating]
      )
    : company?.esgInfo?.ratings?.categories?.[rating]) || 0;

export default getCategoriesRating;
