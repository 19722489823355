import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';

const Highlight = styled.div`
  display: inline-block;
  color: ${({color}) => color || blackColor};
  font-weight: 700;
`;

export default Highlight;
