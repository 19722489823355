// ant components
import {Col as Column, Row, Table} from 'antd';

// financial components
import PaydexScoreChartCard from '../PaydexScoreChartCard/PaydexScoreChartCard';
import ViabilityChartCard from '../ViabilityChartCard/ViabilityChartCard';

// layout components
import Card from '../../../layout/components/Card/Card';
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import extractPaydexScoreChartData from './lib/extractPaydexScoreChartData.lib';
import extractViabilityChartData from './lib/extractViabilityChartData.lib';
import getColumns from './lib/getColumns.lib';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const TestFinancialSection = () => {
  const {company} = useSelector((state) => state.company);

  const columns = getColumns();

  return (
    <Fragment>
      <Row gutter={[20, 20]}>
        <Column span={12}>
          <StatementBlock title="Credit Risk">
            <Card noPadding>
              <Table
                columns={columns.creditRisk}
                dataSource={company.testFinancials.creditRisk}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={12}>
          <StatementBlock title="Financial Strength">
            <Card noPadding>
              <Table
                columns={columns.financialStrength}
                dataSource={company.testFinancials.financialStrength}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={12}>
          <StatementBlock title="Legal Filings & Collection">
            <Card noPadding>
              <Table
                columns={columns.legalFilings}
                dataSource={company.testFinancials.legalFilings}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={12}>
          <StatementBlock title="Business Strength">
            <Card noPadding>
              <Table
                columns={columns.businessStrength}
                dataSource={company.testFinancials.businessStrength}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock title="Paydex">
            <Row gutter={[20, 20]}>
              <Column span={24}>
                <Card noPadding>
                  <Table
                    columns={columns.supplierViability}
                    dataSource={company.testFinancials.supplierViability}
                    pagination={false}
                    rowKey="section"
                    size="small"
                  />
                </Card>
              </Column>
              <Column span={24}>
                <PaydexScoreChartCard
                  {...extractPaydexScoreChartData({company})}
                />
              </Column>
            </Row>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock title="Viability">
            <Row gutter={[20, 20]}>
              <Column span={24}>
                <Card noPadding>
                  <Table
                    columns={columns.viabilityScoreRanking}
                    dataSource={company.testFinancials.viabilityScoreRanking}
                    pagination={false}
                    rowKey="section"
                    size="small"
                  />
                </Card>
              </Column>
              <Column span={24}>
                <ViabilityChartCard {...extractViabilityChartData({company})} />
              </Column>
            </Row>
          </StatementBlock>
        </Column>
      </Row>
    </Fragment>
  );
};

export default TestFinancialSection;
