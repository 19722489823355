// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Section = ({children, section}) => (
  <Container data-scroll-section={section} id={section}>
    {children}
  </Container>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  section: PropTypes.string.isRequired,
};

export default Section;
