// local lib
import generateColumn from './generateColumn.lib';

const getColumns = () => ({
  companyResults: [
    generateColumn({category: 'year', title: 'Year'}),
    generateColumn({category: 'value', title: 'Company Results'}),
  ],
  industryQuartiles: [
    generateColumn({category: 'year', title: 'Year'}),
    generateColumn({category: 'quartile25', title: '25% Quartile'}),
    generateColumn({category: 'median', title: 'Median'}),
    generateColumn({category: 'quartile75', title: '75% Quartile'}),
  ],
});

export default getColumns;
