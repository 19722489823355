class Arrangement {
  constructor(
    company,
    name,
    answers,
    uploads,
    confirmations,
    created,
    updated,
    closed
  ) {
    this.id = company ? Object.keys(company.arrangements).length : -1;
    this.company = company;
    this.name = name;
    this.answers = answers || {};
    this.uploads = uploads || {};
    this.confirmations = confirmations || {};
    this.created = created;
    this.updated = updated;
    this.closed = closed;
  }

  static initArrangement(
    company,
    name,
    answers,
    uploads,
    confirmations,
    created,
    updated,
    closed,
    isSecurityReport
  ) {
    const arrangement = new Arrangement(
      company,
      name,
      answers,
      uploads,
      confirmations,
      created,
      updated,
      closed
    );
    if (isSecurityReport) company.securityReport = arrangement;
    else company.arrangements[arrangement.id] = arrangement;
    return arrangement;
  }

  static createArrangement(company, isSecurityReport) {
    const currentDate = new Date();
    const name = company.isOnboarded()
      ? company.getNewArrangementName()
      : 'Onboard Company';
    const arrangement = Arrangement.initArrangement(
      company,
      name,
      null,
      null,
      null,
      currentDate,
      currentDate,
      null,
      isSecurityReport
    );
    return arrangement;
  }

  renameArrangement(name) {
    this.name = name;
  }

  closeArrangement() {
    const currentDate = new Date();
    this.updated = currentDate;
    this.closed = currentDate;
  }

  save() {
    this.updated = new Date();
    this.company.save();
  }

  isDraft() {
    return !this.confirmations.initial && !this.confirmations.risk;
  }

  isConfirmed(section) {
    const isIRQ = ['initial', 'risk', null].includes(section);
    return (
      (isIRQ && !this.isDraft()) || (!isIRQ && this.confirmations[section])
    );
  }

  toJSON() {
    const json = {};
    if (this.name) json.name = this.name;
    if (this.answers) json.answers = this.answers;
    if (this.uploads) json.uploads = this.uploads;
    if (this.confirmations) json.confirmations = this.confirmations;
    if (this.created) json.created = this.created;
    if (this.updated) json.updated = this.updated;
    if (this.closed) json.closed = this.closed;
    return json;
  }
}

export default Arrangement;
