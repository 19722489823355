// company components
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

class DashboardContainer extends Component {
  static propTypes = {
    collectionScores: PropTypes.object,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
  };

  render() {
    const {collectionScores, loading, loadingCollection} = this.props;
    return loading || !collectionScores || loadingCollection ? (
      <FullScreenLoader />
    ) : (
      <DashboardLayout />
    );
  }
}

export default connect((state) => ({
  collectionScores: state.dashboard.collectionScores,
  loading: state.dashboard.loading,
  loadingCollection: state.collection.loadingCollection,
}))(DashboardContainer);
