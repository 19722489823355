const extractTableData = ({company}) =>
  !!company?.extendedPrivateFinancials
    ? [{...company.extendedPrivateFinancials}]
    : !!company?.PrivateFinancialData
    ? [
        {
          yearFounded: company?.PrivateFinancialData?.YearFounded,
          employeeGrowth: !!company?.PrivateFinancialData
            ?.EmployeeGrowthPercentage
            ? `${company?.PrivateFinancialData?.EmployeeGrowthPercentage}%`
            : null,
          ownershipStatus: company?.PrivateFinancialData?.OwnershipStatus,
          totalRaised: !!company?.PrivateFinancialData?.TotalRaised
            ? `$${company?.PrivateFinancialData?.TotalRaised}M`
            : null,
          revenue: !!company?.PrivateFinancialData?.Revenue
            ? `$${company?.PrivateFinancialData?.Revenue}M`
            : null,
          revenueGrowthPercentage: !!company?.PrivateFinancialData
            ?.RevenueGrowthPercentage
            ? `${company?.PrivateFinancialData?.RevenueGrowthPercentage}%`
            : null,
          growthRatePercentile:
            company?.PrivateFinancialData?.GrowthRatePercentile,
          lastKnownValuation: !!company?.PrivateFinancialData
            ?.LastKnownValuation
            ? `$${company?.PrivateFinancialData?.LastKnownValuation}M`
            : null,
          lastKnownValuationDate:
            company?.PrivateFinancialData?.LastKnownValuationDate,
          lastKnownValuationDealType:
            company?.PrivateFinancialData?.LastKnownValuationDealType,
          predictedExitClass: company?.PrivateFinancialData?.PredictedExitClass,
          totalPatentDocuments:
            company?.PrivateFinancialData?.TotalPatentDocuments,
        },
      ]
    : [];

export default extractTableData;
