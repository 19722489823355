// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralSupplierDataAction from './actions/setGeneralData.action.redux.supplier';

// style redux constants
import DEFAULT_SUPPLIER_REDUX_STATE from './constants/defaultState.constant.redux.supplier';

const supplierSlice = createSlice({
  name: 'supplier',
  initialState: DEFAULT_SUPPLIER_REDUX_STATE,
  reducers: {
    setGeneralSupplierData: setGeneralSupplierDataAction,
  },
});

export const {setGeneralSupplierData} = supplierSlice.actions;

export default supplierSlice.reducer;
