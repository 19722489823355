import styled, {css} from 'styled-components';

// colors
import blackColor from '../../../../layout/colors/black.color.layout';

const Title = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  color: ${blackColor};
  position: relative;

  ${({indent}) =>
    indent
      ? css`
          padding-left: 40px;
          margin-top: 10px;
          margin-bottom: 10px;
        `
      : ''}
`;

export default Title;
