const generateQuery = ({message, questionFlow}) => ({
  message,
  queries: [
    ...questionFlow.systemQueries,
    ...((questionFlow?.messages || []).find(
      (question) => question.message === message
    )?.queries || []),
  ],
});

export default generateQuery;
