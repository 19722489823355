const profitabilityRatiosHaloGptPrompt = ({ data }) => ({
  message: 'Explain Profitability Ratios',
  queries: [
    {
      role: 'system',
      content: `You are analyzing a company's financial performance over multiple years. Convert all financial figures to appropriate units (Million, Billion, Trillion, Quadrillion) using the provided rule - amounts < $1 Million stay as is, $1 Million-$999 Million will use Million, $1 Billion - $999 Billion will use Billion, $1 Trillion- $999 Trillion will use Trillion, and $1 Quadrillion will use Quadrillion, rounding to two decimal places.
      Ensure clarity, succinctness, and adherence to the specified format.

      Instructions:

      Summarize the trend in a company's profitability ratios over the past three years, including Return on Assets and Return on Net Assets. Highlight any consistent patterns or changes observed in these ratios and their potential impact on the company's overall profitability and operational efficiency without showing exact date.
      Do not show specific dates. Restrict the response to not more than 5 sentences and upto 100 words.
      Perform the analysis chronologically. When comparing multiple years, accurately mention increase and decrease by comparing data across consecutive years only. Do not compare data if the years are not consecutive.
      You are assessing company's financial performance and identifying risk. Risk indicates the potential areas of weakness, signaling heightened uncertainty or exposure to adverse events.
      Double check that increase and decrese mentioned in the response are only based on comparing data across consecutive years.
      Double-check all analyses for accuracy. Pay close attention to whether the numbers being compared are positive or negative.
      Double check if formatting is correct including proper spacing between the words.
      Double check the metric names and their values.
      Double check that the numbers are associated with corresponding years.
      Double check there are no specific dates and provide data only in four digit years. For example, instead of saying '12/31/21' say '2021'.
      Double check that the conversion to units like millions, billions and trillions is done correctly.

      Note:
      Only if the profitability is blank or no values are present, provide a response stating 'Profitability ratios are not available.'
      `,
    },
    {
      role: 'user',
      content: `Analyze and summarize profitability ratios in a concise manner. Data: ${JSON.stringify(
        data?.financials?.profitabilityRatios || {}
      )}.`,
    },
  ],
});

export default profitabilityRatiosHaloGptPrompt;
