import React from 'react';
import styled from 'styled-components';
import {Button, Row, Col, Typography} from 'antd';
import DOMPurify from 'dompurify';
import {Dial} from './Dial';
import theme from '../constants/theme';

const {Text: AntText} = Typography;

const ComparisonTableContainer = styled.div`
  background-color: ${theme.compareTable.containerBackground};
  .paddingTop {
    padding-top: 1px;
  }
  .paddingBottom {
    padding-bottom: 1px;
  }
`;

const ColInnerContainer = styled.div`
  background-color: ${theme.compareTable.rowBackground};
  height: 100%;
  width: 100%;
`;

const DialContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DownloadPDFSectionContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const BoldH4 = styled.h4`
  font-weight: bold;
  margin: 0;
`;

const Text = styled(AntText)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  width: 2em;
`;

const TextBold = styled(Text)`
  font-weight: bold;
  color: ${theme.compareTable.titleColor};
`;

const ArrangementComparisonTable = () => {
  const getDial = (value, title) => (
    <DialContainer>
      <h3>{title}</h3>
      <Dial
        value={value}
        label="Overall Residual Risk"
        height={135}
        width={215}
      />
    </DialContainer>
  );

  const questionResponsesPersonnel = [
    {
      label:
        'Do you employ designated personnel who supervise all security and privacy related issues and business operations?',
      responses: ['No', 'Yes', 'Yes'],
    },
    {
      label: 'Do you have a designated Privacy Officer?',
      responses: ['No', 'Yes', 'No'],
    },
  ];

  const questionResponsesDataPrivacy = [
    {
      label:
        'What is the review and approval frequency of your Privacy Policy?',
      responses: ['Never', 'Every six months', 'Every year'],
    },
    {
      label: 'What are your privacy standards?',
      responses: [
        [],
        ['GDPR', 'CCPA'],
        ['GDPR', 'CCPA', 'Australian Privacy Act'],
      ],
    },
    {
      label: 'What are your privacy certifications?',
      responses: [
        ['Privacy Shield'],
        ['GDPR', 'ISACA', 'CIPP', 'Privacy Shield'],
        ['ISACA', 'Privacy Shield'],
      ],
    },
    {
      label: 'How do you handle Personally Identifiable Information (PII)?',
      responses: [
        ['Employees have access on a "need-to-know" basis'],
        [
          'Employees have access on a "need-to-know" basis',
          'Individuals have the right to erase their information',
          'All employees are required to take security and privacy training',
        ],
        [
          'Employees have access on a "need-to-know" basis',
          'Individuals have the right to erase their information',
        ],
      ],
    },
    {
      label: 'How do you handle customer complaints?',
      responses: [
        ['Privacy complaints procedure clearly communicated to customers'],
        [
          'Privacy complaints procedure clearly communicated to customers',
          'Have a tracking system to document and resolve compliants',
        ],
        [
          'Privacy complaints procedure clearly communicated to customers',
          'Documented policies and procedures',
          'Have a tracking system to document and resolve compliants',
        ],
      ],
    },
    {
      label:
        'Which Personal Data will be transfered or exchanged between the parties under an agreement?',
      responses: [
        [
          'First Name',
          'Last Name',
          'User’s location address',
          'User’s phone number',
        ],
        ['First Name', 'Last Name'],
        ['First Name', 'Last Name', 'User’s phone number'],
      ],
    },
    {
      label:
        'What company policies will govern data exchanges between parties?',
      responses: [
        ['Privacy Policy'],
        ['Privacy Policy', 'Security Policy'],
        ['Privacy Policy', 'Data Security Documentation'],
      ],
    },
  ];

  const questionResponsesDataSecurity = [
    {
      label:
        'What are your Security Policies related to the use of information, including its receipt, transmission, processing, storage, control, distribution, retrieval, access and presentation?',
      responses: [
        ['Production data backups are conducted periodically'],
        [
          'Data is encypted in transit, Data is encrypted at rest',
          'Production data backups are conducted periodically',
          'Segmentation implemented at the data layer',
          'Segmentation implemented at the network layer',
        ],
        [
          'Data is encypted in transit',
          'Data is encrypted at rest',
          'Production data backups are conducted periodically',
        ],
      ],
    },
    {
      label: 'What are your Data Retention policies?',
      responses: [
        ['Data is retained for duration of customer life'],
        [
          'Data is retained for duration of customer life',
          'Data is retained per regulatory requirements',
          'Data is deleted further per customer request subject to regulatory requirements',
        ],
        [
          'Data is retained for duration of customer life',
          'Data is retained per regulatory requirements',
        ],
      ],
    },
    {
      label:
        'Which data classifications do you have the required controls within your platform to handle?',
      responses: [
        ['Public data', 'Private data', 'Internal data'],
        [
          'Public data',
          'Private data',
          'Internal data',
          'Confidential data',
          'Restricted data',
        ],
        ['Public data', 'Private data', 'Internal data', 'Restricted data'],
      ],
    },
  ];

  function formatResponse(response) {
    let formattedString = '';
    response.forEach((key, index) => {
      const formattedKey = index + 1 + ') ' + key;
      formattedString = !formattedString
        ? formattedKey
        : formattedString + '</br>' + formattedKey;
    });
    return formattedString || '-';
  }

  const QuestionRow = ({label, responses, isLast}) => (
    <Row className={`paddingTop${isLast ? ' paddingBottom' : ''}`}>
      <Col span={6}>
        <ColInnerContainer style={{padding: '10px 0px 10px 10px'}}>
          <Text>{label}</Text>
        </ColInnerContainer>
      </Col>
      {responses.map((response, index) => {
        const isString = typeof response === 'string';
        return (
          <Col span={6} key={index}>
            <ColInnerContainer>
              <div style={{padding: '10px 0px', marginLeft: '20%'}}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      isString ? response : formatResponse(response)
                    ),
                  }}
                />
              </div>
            </ColInnerContainer>
          </Col>
        );
      })}
    </Row>
  );

  const DownloadPDFSection = ({companies}) => (
    <DownloadPDFSectionContainer>
      <BoldH4>{companies} Companies</BoldH4>
      <Button type="primary">Download PDF</Button>
    </DownloadPDFSectionContainer>
  );

  return (
    <ComparisonTableContainer>
      <Row>
        <Col span={6}>
          <ColInnerContainer>
            <DownloadPDFSection companies={3} />
          </ColInnerContainer>
        </Col>
        <Col span={6}>
          <ColInnerContainer>{getDial(77, 'Rocket HR')}</ColInnerContainer>
        </Col>
        <Col span={6}>
          <ColInnerContainer>{getDial(34, 'HireUp')}</ColInnerContainer>
        </Col>
        <Col span={6}>
          <ColInnerContainer>{getDial(59, 'Talenta')}</ColInnerContainer>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <ColInnerContainer style={{paddingBottom: '10px'}}>
            <TextBold style={{fontSize: '14px'}}>Security Report</TextBold>
          </ColInnerContainer>
        </Col>
        <Col span={6}>
          <ColInnerContainer>
            <div
              style={{
                color: theme.dueDilligenceProgress.done,
                textAlign: 'center',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize('Reduced risk by 4'),
                }}
              />
            </div>
          </ColInnerContainer>
        </Col>
        <Col span={6}>
          <ColInnerContainer>
            <div
              style={{
                color: theme.dueDilligenceProgress.done,
                textAlign: 'center',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize('Reduced risk by 9'),
                }}
              />
            </div>
          </ColInnerContainer>
        </Col>
        <Col span={6}>
          <ColInnerContainer>
            <div
              style={{
                color: theme.dueDilligenceProgress.done,
                textAlign: 'center',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize('Reduced risk by 6'),
                }}
              />
            </div>
          </ColInnerContainer>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <ColInnerContainer
            style={{paddingTop: '10px', paddingBottom: '10px'}}
          >
            <TextBold>Security and Privacy Personnel</TextBold>
          </ColInnerContainer>
        </Col>
        <Col span={6}>
          <ColInnerContainer />
        </Col>
        <Col span={6}>
          <ColInnerContainer />
        </Col>
        <Col span={6}>
          <ColInnerContainer />
        </Col>
      </Row>
      {questionResponsesPersonnel.map(({label, responses}, index) => (
        <QuestionRow
          label={label}
          responses={responses}
          isLast={index === questionResponsesPersonnel.length - 1}
          key={label}
        />
      ))}
      <Row>
        <Col span={6}>
          <ColInnerContainer
            style={{paddingTop: '20px', paddingBottom: '10px'}}
          >
            <TextBold>Data Privacy and Privacy Administration</TextBold>
          </ColInnerContainer>
        </Col>
        <Col span={6}>
          <ColInnerContainer />
        </Col>
        <Col span={6}>
          <ColInnerContainer />
        </Col>
        <Col span={6}>
          <ColInnerContainer />
        </Col>
      </Row>
      {questionResponsesDataPrivacy.map(({label, responses}, index) => (
        <QuestionRow
          label={label}
          responses={responses}
          isLast={index === questionResponsesDataPrivacy.length - 1}
          key={label}
        />
      ))}
      <Row>
        <Col span={6}>
          <ColInnerContainer
            style={{paddingTop: '20px', paddingBottom: '10px'}}
          >
            <TextBold>Data Security</TextBold>
          </ColInnerContainer>
        </Col>
        <Col span={6}>
          <ColInnerContainer />
        </Col>
        <Col span={6}>
          <ColInnerContainer />
        </Col>
        <Col span={6}>
          <ColInnerContainer />
        </Col>
      </Row>
      {questionResponsesDataSecurity.map(({label, responses}, index) => (
        <QuestionRow
          label={label}
          responses={responses}
          isLast={index === questionResponsesDataSecurity.length - 1}
          key={label}
        />
      ))}
    </ComparisonTableContainer>
  );
};

export default ArrangementComparisonTable;
