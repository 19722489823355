const GRADES = [
  [60, 'F'],
  [63, 'D-'],
  [67, 'D'],
  [70, 'D+'],
  [73, 'C-'],
  [77, 'C'],
  [80, 'C+'],
  [83, 'B-'],
  [87, 'B'],
  [90, 'B+'],
  [93, 'A-'],
  [97, 'A'],
  [101, 'A+'],
];

const convertCyberScoreToGrade = (score) =>
  score > 100
    ? 'A+'
    : [...GRADES].find(([maxScore]) => score < maxScore)?.[1] || 'F';

export default convertCyberScoreToGrade;
