// date lib
import formatISODate from '../../date/formatters/formatISO.formatter.date';

// lib
import generateLabelFromKeys from '../../lib/generateLabelFromKeys.lib';

// number lib
import formattedCurrency from '../../number/lib/formattedCurrency.lib.number';
import formattedNumber from '../../number/lib/formattedNumber.lib.number';
import formattedInteger from '../../number/lib/formattedInteger.lib.number';
import shortenCurrency from '../../number/lib/shortenCurrency.lib.number';
import threeDecimals from '../../number/lib/threeDecimals.lib.number';
import twoDecimals from '../../number/lib/twoDecimals.lib.number';

// helper constants
const FORMATTERS = {
  currency: ({value, currency, ...attributes}) =>
    formattedCurrency({number: value, currency, ...attributes}),
  date: ({value, format, ...attributes}) =>
    formatISODate({date: value, dateFormat: format, ...attributes}),
  default: ({value}) => value,
  integer: ({value}) => formattedInteger(value),
  key: ({value}) => generateLabelFromKeys(value)?.toUpperCase?.(),
  number: ({value, ...attributes}) =>
    formattedNumber({number: value, ...attributes}),
  percentage: ({value}) => `${twoDecimals(value || 0)}%`,
  ratio: ({value}) => threeDecimals(value || 0),
  shortCurrency: ({value, currency, ...attributes}) =>
    shortenCurrency({number: value, currency, ...attributes}),
  yesNo: ({value}) => (value ? 'Yes' : 'No'),
};

const parseValueByType = ({value, type, ...attributes}) =>
  (FORMATTERS?.[type] || FORMATTERS.default)({value, ...attributes});

export default parseValueByType;
