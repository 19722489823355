// alert api
import createAlertApi from '../../alert/api/create.api.alert';

// uuid
// import {v4} from 'uuid';

// workflow userSettings
// import workflowsUserSettings from '../userSettings/userSettings.workflow';

const createWorkflowRepository = async ({workflow}) => {
  const response = await createAlertApi({
    emails: workflow.recipients,
    settings: workflow,
  });
  return response;

  // const workflowWithId = {
  //   ...workflow,
  //   id: v4(),
  // };
  // const workflowSettings = await workflowsUserSettings.getSettings();
  // const existingWorkflows = workflowSettings?.workflows || [];
  // const workflows = [workflowWithId, ...existingWorkflows];
  // await workflowsUserSettings.saveSettings({...workflowSettings, workflows});
  // return workflowWithId;
};

export default createWorkflowRepository;
