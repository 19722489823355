// company lib
import prepareSuppliersList from '../../company/lib/prepareSuppliersList.lib.company';

// redux store
import reduxStore from '../../redux/store';

// string lib
import sortByString from '../../string/lib/sortByString.lib.string';

const groupNotificationsByCompany = ({
  collectionScores = reduxStore.getState().dashboard.collectionScores,
  tenantData = reduxStore.getState().dashboard.tenantData,
  notifications,
  suppliers = reduxStore.getState().supplier.suppliersForSearch,
}) => {
  const preparedSuppliers = prepareSuppliersList({
    companies: suppliers,
    supplierIds: collectionScores?.SupplierIds,
    supplierScores: tenantData?.SupplierHaloScores,
  });
  return Object.values(
    [...notifications].reduce((combined, notification) => {
      const supplier =
        combined?.[notification?.supplierId] ||
        [...preparedSuppliers].find(
          (supplier) => supplier?.CompanyId === notification?.supplierId
        );
      return {
        ...combined,
        [notification?.supplierId]: {
          ...supplier,
          notifications: [...(supplier?.notifications || []), notification],
          hasUnreadNotifications:
            supplier?.hasUnreadNotifications || notification.read,
        },
      };
    }, {})
  )
    .sort((a, b) =>
      sortByString({valueA: a.companyName, valueB: b.companyName})
    )
    .sort((a, b) => {
      if (a.hasUnreadNotifications && !b.hasUnreadNotifications) return -1;
      if (!a.hasUnreadNotifications && b.hasUnreadNotifications) return 1;
      return 0;
    });
};

export default groupNotificationsByCompany;
