// alert lib
import parseAlertType from './parseAlertType.lib.alert';

// data lib
import getDefinedOrNullValue from '../../data/lib/getDefinedOrNullValue.lib.data';
import parseValueByType from '../../data/lib/parseValueByType.lib.data';

// number lib
import isNumber from 'is-number';
import twoDecimals from '../../number/lib/twoDecimals.lib.number';

const parseAlertMessage = ({alert, trigger}) => {
  const previousValue = getDefinedOrNullValue(alert.PreviousValue);
  const currentValue = getDefinedOrNullValue(alert.CurrentValue);

  const parseValue = (rawValue) =>
    rawValue === null
      ? null
      : isNumber(rawValue)
      ? !!trigger?.format
        ? parseValueByType({
            value: rawValue,
            type: trigger.format,
            displayCurrency: true,
            displayCurrencySymbol: true,
            currency: 'USD',
          })
        : twoDecimals(rawValue)
      : rawValue;

  const isCustomAlertType = previousValue === 'custom';

  const formattedPreviousValue = parseValue(previousValue);
  const formattedCurrentValue = parseValue(currentValue);

  const isDirectorsTrigger = trigger?.value === 'cs_directors';

  const triggerName = trigger?.title || parseAlertType(alert.Type);
  const period = alert.Period === 1 ? 'day' : `${alert.Period} days`;

  const message = isDirectorsTrigger
    ? [
        triggerName,
        `have been ${previousValue} in the last ${period}:`,
        currentValue,
      ]
    : isCustomAlertType
    ? [`${triggerName}:\n\n`, formattedCurrentValue]
    : [
        triggerName,
        `changed in the last ${period}`,
        previousValue !== null && `from ${formattedPreviousValue}`,
        currentValue !== null && `to ${formattedCurrentValue}`,
      ];

  return message.filter((sentence) => !!sentence).join(' ');
};

export default parseAlertMessage;
