import styled from 'styled-components';

// layout colors
import whiteColor from '../../../../layout/colors/white.color.layout';

const Tag = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 13px;
  color: ${whiteColor};
  text-align: center;
  padding: 2px;
  border-radius: 5px;
  background: ${({color}) => color};
  user-select: none;
  cursor: pointer;
`;

export default Tag;
