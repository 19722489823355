// number lib
import integer from '../../number/lib/integer.lib.number';

// local constants
const groups = ['United States', 'China'];

const renames = {
  'Russian Federation': 'Russia',
  'United States': 'United States of America',
};

const combineData = (globalResilienceIndexes) => {
  const {allCountries, ...groupedCountries} = [
    ...globalResilienceIndexes,
  ].reduce(
    (combined, country) => {
      const countryName = renames?.[country.Country] || country.Country;
      const group =
        groups.find((countryGroup) => countryName.startsWith(countryGroup)) ||
        'allCountries';
      const parsedCountry = {...country, Country: countryName};
      return {...combined, [group]: [...combined[group], parsedCountry]};
    },
    {
      allCountries: [],
      ...[...groups].reduce(
        (combined, country) => ({...combined, [country]: []}),
        {}
      ),
    }
  );
  const groupedParsedCountries = Object.entries(groupedCountries).map(
    ([country, countryDataList]) => {
      const countryName = renames?.[country] || country;
      const dataKeys = Object.keys(countryDataList[0]).filter(
        (key) => key !== 'Country'
      );
      const dataValues = dataKeys.reduce(
        (combined, dataKey) => ({
          ...combined,
          [dataKey]: dataKey.includes('Rank')
            ? Math.min(
                ...countryDataList.map((countryData) => countryData[dataKey])
              )
            : integer(
                countryDataList.reduce(
                  (sum, countryData) => sum + countryData[dataKey],
                  0
                ) / countryDataList.length
              ),
        }),
        {}
      );
      return {Country: countryName, ...dataValues};
    }
  );

  return [...allCountries, ...groupedParsedCountries];
};

export default combineData;
