// company components
import HighlightedScore from '../HighlightedScore/HighlightedScore';

// company filters
import complianceRiskLevelFilter from '../../filters/complianceRiskLevel.filter.company';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CompanyComplianceScoreColumn = ({company}) => (
  <HighlightedScore
    score={Number(company?.HaloScores?.Compliance_Area?.Value)}
    filter={complianceRiskLevelFilter.filter}
  />
);

CompanyComplianceScoreColumn.propTypes = {
  company: PropTypes.object,
};

export default CompanyComplianceScoreColumn;
