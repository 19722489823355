// columns
import actionsColumn from '../columns/actions.column';
import alertsColumn from '../columns/alerts.column';
import haloIntrinsicScoreColumn from '../columns/haloIntrinsicScore.column';
import supplierColumn from '../columns/supplier.column';

const getColumns = () => [
  supplierColumn,
  haloIntrinsicScoreColumn,
  alertsColumn,
  actionsColumn,
];

export default getColumns;
