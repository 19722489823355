// tenant lib
import prepareSingleCustomTenantDataForTable from './prepareSingleCustomDataForTable.lib.tenant';

const prepareCustomTenantDataForTable = (data = {}) =>
  Object.entries(data || {}).map(([entry, value]) => ({
    entry,
    value: prepareSingleCustomTenantDataForTable(value),
  }));

export default prepareCustomTenantDataForTable;
