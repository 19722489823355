// local components
import Container from './components/Container';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Section = ({children, title, withBorder}) => {
  return (
    <Container withBorder={withBorder} withTitle={!!title}>
      {!!title && <Title>{title}</Title>}
      {children}
    </Container>
  );
};

Section.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  withBorder: PropTypes.bool,
};

export default Section;
