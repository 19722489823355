import {Progress, Typography} from 'antd';
import React from 'react';
import {Grid} from './Layout';
import {SEGMENT_COLORS, SEGMENT_STOPS} from '../constants/constants';
import {last, initial} from 'lodash';

import styled from 'styled-components';
const {Text: AntText} = Typography;
const Text = styled(AntText)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  width: 2em;
`;
const isInRange =
  (x) =>
  ([min, max]) => {
    return (x - min) * (x - max) <= 0;
  };
const segmentStopsToSegmentTuple = SEGMENT_STOPS.reduce((acc, val) => {
  if (acc.length === 0) {
    return [[val]];
  }
  const lastTuple = last(acc);
  if (lastTuple.length === 1) {
    const allButLast = initial(acc);
    return allButLast.concat([lastTuple.concat(val)]);
  }
  const lastValueInTuple = last(lastTuple);
  return acc.concat([[lastValueInTuple + 1, val]]);
}, []);

const scoreToColor = (score) =>
  SEGMENT_COLORS[segmentStopsToSegmentTuple.findIndex(isInRange(score))];
export const ScoreProgress = ({score}) => (
  <Grid>
    <Progress
      showInfo={false}
      strokeColor={scoreToColor(score)}
      percent={score}
    />
    <Text>{score}</Text>
  </Grid>
);
