// propTypes
import PropTypes from 'prop-types';

// react
// import React from 'react';

const ScoreColumn = ({entry}) => {
  const {min, max} = entry;
  return !min && !max
    ? 'This includes bankruptcies, insufficient information, negative press and other special cases.'
    : `${min} - ${max}`;
};

ScoreColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default ScoreColumn;
