// ant
import {Tooltip} from 'antd';

// layout components
import Card from '../../../layout/components/Card/Card';
import FullIntegrationTag from '../../../layout/components/FullIntegrationTag/FullIntegrationTag';

// local components
import CardHeader from './components/CardHeader';
import Click from './components/Click';
import Container from './components/Container';
import Content from './components/Content';
import SubValue from './components/SubValue';
import SubValueConnection from './components/SubValueConnection';
import SubValueLine from './components/SubValueLine';
import SubValuesContainer from './components/SubValuesContainer';
import SubValuesLabel from './components/SubValuesLabel';
import Title from './components/Title';
import TooltipContainer from './components/TooltipContainer';
import Update from './components/Update';
import Value from './components/Value';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CyberSecurityRiskDataCard = ({
  max = 'N/A',
  min = 'N/A',
  value = '',
  onClick = () => {},
}) => (
  <Container>
    <Title>Probable Financial Impact</Title>
    <Click onClick={onClick}>
      <Card noPadding>
        <CardHeader>
          <div>RISK</div>
          <div>(Annualized)</div>
        </CardHeader>
        <Content>
          <Value>
            {value || (
              <div>
                <div>$</div> <Update>Update Records to get value</Update>
                <div>
                  <FullIntegrationTag />
                </div>
              </div>
            )}
            {!!value && (
              <TooltipContainer>
                <Tooltip title='Please update the "Exposure" box in the FAIR model to get more accurate estimation. Available upon integration.'>
                  <i className="mdi mdi-information" />
                </Tooltip>
              </TooltipContainer>
            )}
          </Value>
          {!!value && (
            <SubValuesContainer>
              <SubValue>
                <div>{min}</div>
                <div>Min</div>
              </SubValue>
              <SubValueConnection>
                <SubValueLine />
                Most Likely
                <SubValueLine />
              </SubValueConnection>
              <SubValue right>
                <div>{max}</div>
                <div>Max</div>
              </SubValue>
            </SubValuesContainer>
          )}
          {!!value && (
            <SubValuesLabel>
              The forecasted annualized loss based on the given parameters in
              the FAIR Model.
            </SubValuesLabel>
          )}
        </Content>
      </Card>
    </Click>
  </Container>
);

CyberSecurityRiskDataCard.propTypes = {
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default CyberSecurityRiskDataCard;
