// local lib
import getAllAvailableColumns from './getAllAvailableColumns.lib';

const getSelectedColumns = ({selectedColumns, suppliers}) => {
  const allColumns = getAllAvailableColumns({suppliers});
  return [...selectedColumns]
    .map((selectedColumn) =>
      [...allColumns].find((column) => column.key === selectedColumn)
    )
    .filter((column) => !!column);
};

export default getSelectedColumns;
