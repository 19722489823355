import styled from 'styled-components';

// colors
import green400Color from '../../../../layout/colors/green400.color.layout';

const Line = styled.div`
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: ${green400Color};
`;

export default Line;
