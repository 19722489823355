import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000000;
`;

export default Overlay;
