// error lib
import parseError from '../../../error/lib/parse.lib.error';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// ant components
import {message as messageApi} from 'antd';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// tag components
import TagEditorModal from '../../components/TagEditorModal/TagEditorModal';

// tag events
import showCreateModalEvent from '../../events/showCreateModal.event.tag';

// tag services
import createTagService from '../../services/create.service.tag';

class CreateTagContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    companies: [],
    date: '',
    description: '',
    loading: false,
    name: '',
    supplier: null,
    tag: '',
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showCreateModalEvent.subscribe(this.show));
  }

  show = ({supplier, supplierIds = []}) => {
    this.setState({
      ...this.constructor.DEFAULT_STATE,
      supplier,
      companies: [supplier?.CompanyId, ...supplierIds].filter(
        (supplierId) => !!supplierId
      ),
      visible: true,
    });
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  change = (key) => (e) => {
    if (this.state.loading) return;
    const value = !!e?.target ? e.target.value : e;
    this.setState({[key]: value});
  };

  changeSelection = (key) => (value) => {
    const {loading, supplier} = this.state;
    if (loading || (key === 'companies' && value === supplier?.CompanyId))
      return;
    const previousValues = [...this.state[key]];
    const updateValues = previousValues.includes(value)
      ? previousValues.filter((v) => v !== value)
      : [...previousValues, value];
    this.setState({[key]: updateValues});
  };

  save = async () => {
    const {
      companies,
      // date,
      description,
      loading,
      name,
      supplier,
      // tag,
    } = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      await createTagService({
        supplier,
        tag: {
          // date,
          description,
          name,
          company_ids: companies,
          // tag,
        },
      });
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      messageApi.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {companies, date, description, loading, name, tag, visible} =
      this.state;

    return (
      <TagEditorModal
        companies={companies}
        date={date}
        description={description}
        loading={loading}
        name={name}
        onChange={this.change}
        onChangeSelection={this.changeSelection}
        onClose={this.hide}
        onSave={this.save}
        tag={tag}
        title="Create Tag"
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(CreateTagContainer);
