// ant components
import {Button} from 'antd';

// ant icons
import {PrinterOutlined} from '@ant-design/icons';

// company components
import CompanyEsgDistributionSection from '../../../company/components/CompanyEsgDistributionSection/CompanyEsgDistributionSection';

// company component lib
import renderEsgSection from '../../../company/components/CompanyEsgDistributionSection/lib/renderEsgSection.lib';

// company containers
import CompanySideMenuContainer from '../../../company/containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../../company/lib/generateSubPages.lib.company';

// company routes
import suppliersDashboardRoute from '../../../company/pages/SuppliersDashboardPage/route';

// esg components
import NormalizedEsgSection from '../../components/NormalizedEsgSection/NormalizedEsgSection';

// esg constants
import ESG_HALO_GPT_CONVERSATION from '../../constants/esgHaloGptConversation.constants.esg';
// import NORMALIZED_DATA_DESCRIPTION from '../../constants/normalizedDataDescription.constant.esg';
// import SELF_REPORTED_DATA_DESCRIPTION from '../../constants/selfReportedDataDescription.constant.esg';

// esg lib
import prepareDataForHaloGptExplain from '../../lib/prepareDataForHaloGptExplain.lib.esg';

// haloGpt containers
import PageHaloGptContainer from '../../../haloGpt/containers/PageHaloGptContainer/PageHaloGptContainer';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// print components
import Print from '../../../print/components/Print/Print';
import PrintLayout from '../../../print/components/PrintLayout/PrintLayout';
import PrintSection from '../../../print/components/PrintSection/PrintSection';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const EsgLayout = () => {
  const {company, subPage} = useSelector((state) => ({
    company: state.company.company,
    subPage: state.layout.subPage,
  }));

  const haloGptData = prepareDataForHaloGptExplain(company);
  const questionFlow = ESG_HALO_GPT_CONVERSATION({data: haloGptData});

  const renderEsg = renderEsgSection({company}) || !!company?.esgInfo;

  return (
    <Print
      title="ESG Report"
      subtitle={company.CompanyName}
      trigger={({print}) => (
        <PageLayout
          alerts={company?.alerts || []}
          previousPageLabel="Back to Dashboard"
          previousPageUrl={suppliersDashboardRoute(company.CompanyId)}
          sideContent={({goToSection}) => (
            <CompanySideMenuContainer goToSection={goToSection} />
          )}
          subPage={subPage}
          subPages={generateSubPages(company)}
          sectionName="ESG"
          sections={[
            renderEsgSection({company}) && {
              section: 'ESG Factor',
              component: <CompanyEsgDistributionSection />,
              secondaryScore:
                company?.originalHaloScores?.HaloScores?.Operational_ESG
                  ?.Value || 0,
              moreButton: (
                <Button icon={<PrinterOutlined />} onClick={print}>
                  Print Report
                </Button>
              ),
            },
            !!company?.esgInfo && {
              section: 'Normalized & Aggregated',
              title: 'Based on Normalized & Aggregated Data from All Sources',
              component: <NormalizedEsgSection />,
              moreButton: !renderEsgSection({company}) ? (
                <Button icon={<PrinterOutlined />} onClick={print}>
                  Print Report
                </Button>
              ) : null,
            },
            !renderEsg && {
              section: 'No Data',
              component: (
                <div>
                  <p>
                    No ESG data available for this company. Please check back
                    later.
                  </p>
                </div>
              ),
            },
          ].filter((section) => !!section)}
          chat={
            <PageHaloGptContainer
              data={haloGptData}
              questionFlow={questionFlow}
            />
          }
        />
      )}
    >
      <PrintLayout>
        {renderEsgSection({company}) && (
          <PrintSection title="ESG Factor">
            <CompanyEsgDistributionSection />
          </PrintSection>
        )}
        {!!company?.esgInfo && (
          <PrintSection title="Normalized & Aggregated">
            <NormalizedEsgSection />
          </PrintSection>
        )}
      </PrintLayout>
    </Print>
  );
};

export default EsgLayout;
