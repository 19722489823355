import styled, {css} from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';
import green300Color from '../../../../layout/colors/green300.color.layout';
import green400Color from '../../../../layout/colors/green400.color.layout';
import grey300Color from '../../../../layout/colors/grey300.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

const Message = styled.div`
  padding: 8px 12px;
  font-size: 11px;
  border-radius: 16px;
  ${({user, onClick}) =>
    !!onClick
      ? css`
          cursor: pointer;
          background: ${green300Color};
          color: ${whiteColor};

          &:hover {
            background: ${green400Color};
          }
        `
      : user
      ? css`
          background: ${green400Color};
          color: ${whiteColor};
        `
      : css`
          background: ${grey300Color};
          color: ${blackColor};
        `}
`;

export default Message;
