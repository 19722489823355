// charts
import Highcharts from 'highcharts';

// layout colors
import blackColor from '../../../layout/colors/black.color.layout';
import blueGrey300Color from '../../../layout/colors/blueGrey300.color.layout';
import green300Color from '../../../layout/colors/green300.color.layout';
import grey300Color from '../../../layout/colors/grey300.color.layout';
import grey600Color from '../../../layout/colors/grey600.color.layout';
import red300Color from '../../../layout/colors/red300.color.layout';
import violet300Color from '../../../layout/colors/violet300.color.layout';
import yellow300Color from '../../../layout/colors/yellow300.color.layout';

// layout components
import Card from '../../../layout/components/Card/Card';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const ActivityModuleChartCard = ({categories = [], data = []}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      title: {
        text: 'Activity Ratios',
      },

      colors: [
        blueGrey300Color,
        green300Color,
        violet300Color,
        yellow300Color,
        red300Color,
        grey300Color,
        grey600Color,
        blackColor,
      ],

      yAxis: {
        title: {
          enabled: false,
        },
      },

      xAxis: {
        categories,
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          tooltip: {
            pointFormat: '{point.y:,.2f}',
          },
          // pointStart: categories?.[0] || null,
        },
      },

      series: data,
    });
    // eslint-disable-next-line
  }, [data]);

  return (
    <Card noPadding noPrintBreak>
      <div id={id} />
    </Card>
  );
};

ActivityModuleChartCard.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.array,
};

export default ActivityModuleChartCard;
