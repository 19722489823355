import styled from 'styled-components';

// layout colors
import whiteColor from '../../../../layout/colors/white.color.layout';

const ChatContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${whiteColor};
  box-shadow: 0px 4px 12px rgba(20, 40, 60, 0.1);
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export default ChatContainer;
