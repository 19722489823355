// countries
// import {getAlpha2Code, registerLocale} from 'i18n-iso-countries';
import {countryToAlpha2} from 'country-to-iso';

// country lib
import getCustomIso from '../../../lib/getCustomIso.lib.country';

// lib
import generateKeyFromLabel from '../../../../lib/generateKeyFromLabel.lib';

const parseCountries = ({countries, fullName, delimiter = '|'}) =>
  countries.split(delimiter).map((country) => {
    const isoCountry = fullName
      ? getCustomIso({
          countryIso: countryToAlpha2(country),
          countryName: country,
        })
      : country;
    const countryName = fullName ? country : country.toUpperCase();

    return {
      key: generateKeyFromLabel(country),
      isoCountry: !!isoCountry ? isoCountry.toLowerCase() : null,
      country: countryName,
    };
  });

export default parseCountries;
