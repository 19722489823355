import Company from './company';
import Arrangement from './arrangement';

class User {
  constructor(username, companies) {
    this.username = username;
    this.companies = companies || {};
  }

  static getUser(username) {
    const user = new User(username);
    const userDataString = localStorage.getItem(username);
    const userData = userDataString ? JSON.parse(userDataString) : {};
    Object.keys(userData.companies || {}).forEach((companyId) => {
      const company = Company.initCompany(companyId, user);
      const companyData = userData.companies[companyId];
      Object.keys(companyData.arrangements).forEach((arrangementId) => {
        const arrangementData = companyData.arrangements[arrangementId];
        const {
          name,
          answers,
          uploads,
          confirmations,
          created,
          updated,
          closed,
        } = arrangementData;
        Arrangement.initArrangement(
          company,
          name,
          answers,
          uploads,
          confirmations,
          new Date(created),
          new Date(updated),
          closed ? new Date(closed) : null
        );
      });
      const securityReportData = companyData.securityReport;
      if (securityReportData) {
        const {answers, uploads, confirmations, created, updated} =
          securityReportData;
        Arrangement.initArrangement(
          company,
          null,
          answers,
          uploads,
          confirmations,
          new Date(created),
          new Date(updated),
          null,
          true
        );
      }
    });
    return user;
  }

  save() {
    const userData = {};
    userData.username = this.username;
    userData.companies = {};
    Object.keys(this.companies).forEach((companyId) => {
      const company = this.companies[companyId];
      const companyData = {};
      companyData.id = companyId;
      companyData.arrangements = {};
      Object.keys(company.arrangements).forEach((arrangementId) => {
        const arrangement = company.arrangements[arrangementId];
        const arrangementData = arrangement.toJSON();
        const isSkip = !Object.keys(arrangementData.answers).length;
        if (!isSkip) companyData.arrangements[arrangementId] = arrangementData;
      });
      const securityReport = company.securityReport;
      if (securityReport) {
        const securityReportData = securityReport.toJSON();
        companyData.securityReport = securityReportData;
      }
      const isSkip =
        !Object.keys(companyData.arrangements).length && !securityReport;
      if (!isSkip) userData.companies[companyId] = companyData;
    });
    localStorage.setItem(this.username, JSON.stringify(userData));
  }
}

export default User;
