// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'NACE Code',
  componentProps: ({company}) => ({
    children: company?.Firmographic?.NACECode || '-',
  }),
  Component: CompanyDefaultDataColumn,
});
