// table components
import DynamicColumn from '../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateColumn from '../../table/lib/generateColumn.lib.table';

// tenant lib
import prepareSingleCustomTenantDataForTable from './prepareSingleCustomDataForTable.lib.tenant';

const generateCustomTenantDataColumns = ({
  companies,
  attachCustomKey = false,
}) => {
  const customData =
    [...companies].find((company) => !!company.customData)?.customData || {};

  return Object.keys(customData).map((entry) =>
    generateColumn({
      key: `customData__${entry}`,
      title: [attachCustomKey && 'CUSTOM - ', entry]
        .filter((title) => !!title)
        .join(''),
      componentProps: ({company}) => ({
        category: entry,
        entry: {
          [entry]: prepareSingleCustomTenantDataForTable(
            company?.customData?.[entry]
          ),
        },
      }),
      Component: DynamicColumn,
    })
  );
};

export default generateCustomTenantDataColumns;
