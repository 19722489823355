// local components
import Container from './components/Container';
import ShortDescription from './components/ShortDescription';

// local lib
import parseDescription from './lib/parseDescription.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useState} from 'react';

const CompanyDescriptionColumn = ({description, empty = '-'}) => {
  const [showMore, setShowMore] = useState(false);

  const {shortDescription, wasShortened} = parseDescription({
    description: description || '',
  });

  return (
    <Container>
      {!description ? (
        empty
      ) : showMore ? (
        <ShortDescription>
          {description}
          {wasShortened && (
            <span onClick={() => setShowMore(!showMore)}> Show less</span>
          )}
        </ShortDescription>
      ) : (
        <ShortDescription>
          {shortDescription}
          {wasShortened && !showMore && '...'}
          {wasShortened && (
            <span onClick={() => setShowMore(!showMore)}> Show more</span>
          )}
        </ShortDescription>
      )}
    </Container>
  );
};

CompanyDescriptionColumn.propTypes = {
  description: PropTypes.node,
  empty: PropTypes.node,
};

export default CompanyDescriptionColumn;
