// string lib
import sortByString from '../../../../string/lib/sortByString.lib.string';

const extractCorporateHierarchy = ({company}) => {
  const missingData = {data: [], height: 0};
  const listOfCompanies = [...(company?.corporateHierarchy || [])].filter(
    (company) => company.target !== company.source
  );
  const parentCompany = [...listOfCompanies].find(({source}) =>
    [null, undefined, '', '-'].includes(source)
  );

  const isInfiniteLoop = !![...listOfCompanies].find((company) => {
    const similarCompany = listOfCompanies.find(
      (companyToCheck) =>
        companyToCheck.source === company.target &&
        companyToCheck.target === company.source
    );
    return !!similarCompany;
  });

  if (!parentCompany || listOfCompanies.length > 2000 || isInfiniteLoop)
    return missingData;

  try {
    const hierarchy = [...listOfCompanies]
      .map((company) => ({
        id: company.target,
        parent: company.source,
        collapsed:
          listOfCompanies.length < 200
            ? false
            : company.target !== parentCompany.target,
        custom: {
          parent: company.source,
          source: company.source,
          target: company.target,
          relationshipType: company.relationshipType,
          weight: company.weight,
          assets: company.assets,
          reportedCurrency: company.currency,
          totalRevenue: company.totalRevenue,
          totalNetIncome: company.totalNetIncome,
          primaryIndustry: company.primaryIndustry,
          globalRegion: company.globalRegion,
          headquarters: company.hqAddress,
        },
      }))
      .sort((a, b) => sortByString({valueA: a.id, valueB: b.id}))
      .reverse();

    const largestLeaf =
      Object.values(
        [...hierarchy].reduce(
          (combined, company) => ({
            ...combined,
            [company.parent]: (combined?.[company.parent] || 0) + 1,
          }),
          {}
        )
      ).sort((a, b) => b - a)?.[0] || 0;

    const height = largestLeaf * 40 + 40;
    return {data: hierarchy, height};
  } catch (error) {
    return missingData;
  }
};

export default extractCorporateHierarchy;
