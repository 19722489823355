import styled from 'styled-components';

// layout colors
import grey200Color from '../../../../layout/colors/grey200.color.layout';

const Footer = styled.div`
  border-top: 1px solid ${grey200Color};
  padding: 8px;
  gap: 8px;
  display: flex;
`;

export default Footer;
