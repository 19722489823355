// chart components
import CyberSecurityChartData from '../../../chart/components/CyberSecurityChartData/CyberSecurityChartData';

// cyberSecurity constants
import COMPLIANCE_RATING_DESCRIPTION from '../../../cyberSecurity/constants/complianceRatingDescription.constant.cyberSecurity';
import CYBER_RATING_DESCRIPTION from '../../../cyberSecurity/constants/cyberRatingDescription.constant.cyberSecurity';
import PROBABLE_FINANCIAL_IMPACT_DESCRIPTION from '../../../cyberSecurity/constants/probableFinancialImpactDescription.constant.cyberSecurity';

// layout components
import {Column, Row} from '../../../layout/components/Grid/Grid';

// layout events
import notImplementedEvent from '../../../layout/events/notImplemented.event.layout';

// local handlers
import complianceClickHandler from './handlers/complianceClick.handler';
import cyberGradeClickHandler from './handlers/cyberGradeClick.handler';
import cyberRatingClickHandler from './handlers/cyberRatingClick.handler';

// local lib
import extractComplianceData from './lib/extractComplianceData.lib';
import extractCyberIndustryAverageData from './lib/extractCyberIndustryAverageData.lib';
import extractCyberRatingData from './lib/extractCyberRatingData.lib';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

// propTypes
import PropTypes from 'prop-types';

const CompanyCyberSecuritySection = ({
  condensedView = false,
  print = false,
}) => {
  const {company} = useSelector((state) => state.company);

  return (
    <Fragment>
      <Row>
        <Column>
          <CyberSecurityChartData
            blackKiteLinks={company?.blackKiteLinks}
            bottomIndexLabel="Ransomware Susceptibility Index (RSI)"
            bottomIndexValue={company?.CyberSecurity?.RansomwareIndex}
            complianceCompleteness={
              company?.CyberSecurity?.Compliance?.Completeness
            }
            companyName={company.CompanyName}
            complianceBreakdown={extractComplianceData({company})}
            complianceRating={company?.CyberSecurity?.Compliance?.Rating}
            complianceRatingDescription={COMPLIANCE_RATING_DESCRIPTION}
            condensedView={condensedView}
            cyberRatingChartData={extractCyberRatingData({company})}
            cyberRatingDescription={CYBER_RATING_DESCRIPTION}
            grade={company?.CyberSecurity?.Grade}
            industryAverageData={extractCyberIndustryAverageData({company})}
            max={company?.CyberSecurity?.Financial?.RatingMax}
            min={company?.CyberSecurity?.Financial?.RatingMin}
            onClick={() => notImplementedEvent.publish()}
            onComplianceClick={complianceClickHandler({company})}
            onCyberRatingClick={cyberRatingClickHandler({company})}
            onCyberSecurityGradeClick={cyberGradeClickHandler({company})}
            print={print}
            probableFinancialImpactDescription={
              PROBABLE_FINANCIAL_IMPACT_DESCRIPTION
            }
            topIndexLabel="Data Breach Index (DBI)"
            topIndexValue={company?.CyberSecurity?.BreachIndex}
            value={company?.CyberSecurity?.Financial?.Rating}
          />
        </Column>
      </Row>
    </Fragment>
  );
};

CompanyCyberSecuritySection.protoTypes = {
  condensedView: PropTypes.bool,
  print: PropTypes.bool,
};

export default CompanyCyberSecuritySection;
