// local components
import Container from './components/Container';

// number lib
import shortenNumber from '../../lib/shorten.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const PercentageTrend = ({percentage, reverse = false}) => {
  const originalDirection = percentage > 0 ? true : false;
  const direction = reverse ? !originalDirection : originalDirection;

  return !!percentage ? (
    <Container direction={direction ? 'positive' : 'negative'}>
      {shortenNumber({number: Math.abs(percentage)})}%
      <i className={`mdi mdi-menu-${originalDirection ? 'up' : 'down'}`} />
    </Container>
  ) : (
    '-'
  );
};

PercentageTrend.propTypes = {
  percentage: PropTypes.number,
  reverse: PropTypes.bool,
};

export default PercentageTrend;
