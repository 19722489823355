const extractKeySuppliers = ({company}) =>
  Object.entries(
    (company?.KeySuppliers || []).reduce((combined, current) => {
      const country = current.country || null;
      if (!country) return combined;
      const countryData = combined?.[country] || [];
      const updatedCountryData = [
        ...countryData,
        {
          name: current.companyName,
          value: current.marketCap || 1,
          industry: current.primaryIndustry,
          source: current.source,
          displayMarketCap: !!current.marketCap,
        },
      ];
      return {...combined, [country]: updatedCountryData};
    }, [])
  ).map(([country, data]) => ({name: country, data}));

export default extractKeySuppliers;
