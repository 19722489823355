export const capitalize = (word) =>
  word != null && word.length > 0
    ? `${word.charAt(0).toUpperCase()}${word.slice(1)}`
    : '';

/**
 * Really simple pluralize function if editiong this function more than once
 * pull an npm version
 */
export const pluralize = (word) => {
  if (word == null && word.length === 0) {
    return '';
  }
  const lastChar = word.charAt(word.length - 1);
  switch (lastChar) {
    case 'y':
      return `${word.substring(0, word.length - 1)}ies`;
    default:
      return `${word}s`;
  }
};

export const isJSONString = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return !!string;
};
