// company routes
import baseRoute from '../../../company/pages/CompanyPage/route';

// route lib
import parseRoute from '../../../route/lib/parseRoute.lib.route';

const route = (id = ':id', {query, hash} = {}) =>
  parseRoute({path: `${baseRoute(id)}/sanctions`, query, hash});

export default route;
