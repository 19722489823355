// alert api
import listAlertsApi from '../../alert/api/list.api.alert';

// workflow userSettings
// import workflowsUserSettings from '../userSettings/userSettings.workflow';

const listWorkflowsRepository = async () => {
  try {
    const {data} = await listAlertsApi();
    return data;
  } catch (error) {
    return [];
  }
  // const workflowSettings = await workflowsUserSettings.getSettings();
  // return workflowSettings?.workflows || [];
};

export default listWorkflowsRepository;
