// local lib
import generateColumn from './generateColumn.lib';

const getFundingColumns = () => [
  generateColumn({
    category: 'totalEquityFundingAmount',
    title: 'Total Equity Funding Amount (in millions USD)',
  }),
  generateColumn({
    category: 'numberOfFundingRounds',
    title: 'Number of Funding Rounds',
  }),
  generateColumn({category: 'fundingStatus', title: 'Funding Status'}),
  generateColumn({
    category: 'lastFundingAmount',
    title: 'Last Funding Amount (in millions USD)',
  }),
  generateColumn({category: 'lastFundingType', title: 'Last Funding Type'}),
  generateColumn({category: 'activeInvestors', title: 'Active Investors'}),
];

export default getFundingColumns;
