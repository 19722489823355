import styled from 'styled-components';

const PrintLayout = styled.div`
  width: 100%;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export default PrintLayout;
