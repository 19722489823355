// ant components
import {Col as Column, Row, Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';
import parseWeatherData from './lib/parseData.lib';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const WeatherData = () => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const data = parseWeatherData({company});
  const columns = generateColumns({
    aqi: data.aqi.categories,
    weather: data.weather.categories,
    fire: data.fire.categories,
    naturalDisaster: data.naturalDisaster.categories,
  });

  return (
    <Row gutter={[20, 20]}>
      <Column span={24 / 2}>
        <StatementBlock title="Fire">
          <Table
            columns={columns.fire}
            dataSource={data.fire.data}
            pagination={false}
            rowKey="date"
            size="small"
          />
        </StatementBlock>
      </Column>
      <Column span={24 / 2}>
        <StatementBlock title="Natural Disaster">
          <Table
            columns={columns.naturalDisaster}
            dataSource={data.naturalDisaster.data}
            pagination={false}
            rowKey={(record) => record?.date?.value}
            size="small"
          />
        </StatementBlock>
      </Column>
      <Column span={24 / 2}>
        <StatementBlock title="Air Quality">
          <Table
            columns={columns.aqi}
            dataSource={data.aqi.data}
            pagination={false}
            rowKey="entry"
            size="small"
          />
        </StatementBlock>
      </Column>
      <Column span={24 / 2}>
        <StatementBlock title="Weather">
          <Table
            columns={columns.weather}
            dataSource={data.weather.data}
            pagination={false}
            rowKey="entry"
            size="small"
          />
        </StatementBlock>
      </Column>
    </Row>
  );
};

WeatherData.propTypes = {};

export default WeatherData;
