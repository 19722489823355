// company lib
import extractMapData from '../../company/components/CompanyKeySuppliersSection/lib/extractMapData.lib';

const prepareDataForHaloGptExplain = ({company, globalResilienceIndexes}) => {
  const mapData = extractMapData({company, globalResilienceIndexes});
  return {
    Company: company.CompanyName,
    Country: company?.Firmographic?.HQAddress,
    Suppliers: [...mapData].reduce(
      (combined, country) => ({
        ...combined,
        [country.name]: {
          'Global Resilience Index': country.globalResilienceIndex,
          Companies: [...country.data].reduce(
            (combinedCompanies, company) => ({
              ...combinedCompanies,
              [company.name]: {
                Industry: company.industry,
                'Market Cap': company.marketCap,
              },
            }),
            {}
          ),
        },
      }),
      {}
    ),
  };
};

export default prepareDataForHaloGptExplain;
