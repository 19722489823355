// charts
import Highcharts from 'highcharts';

// layout colors
import blackColor from '../../../layout/colors/black.color.layout';

// layout components
import Card from '../../../layout/components/Card/Card';

// local constants
import drillDownTitle from './constants/drillDownTitle.constant';
import drillDownXTitle from './constants/drillDownXTitle.constant';
import drillDownYTitle from './constants/drillDownYTitle.constant';
import title from './constants/title.constant';
import xTitle from './constants/xTitle.constant';
import yTitle from './constants/yTitle.constant';

// number lib
import integer from '../../../number/lib/integer.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect, useState} from 'react';

// risk lib
import getGradeColor from '../../../risk/lib/getGradeColor.lib.risk';

// uuid
import {v4} from 'uuid';

const RiskAreaChartCard = ({
  data = [],
  drillDown = [],
  info,
  ratingInfo,
  factorInfo,
}) => {
  const id = v4();
  const [showRatingInfo, setShowRatingInfo] = useState(true);

  useEffect(() => {
    const chart = Highcharts.chart(id, {
      chart: {
        type: 'column',
        height: '360px',
        events: {
          drilldown: function (e) {
            chart.setTitle({text: drillDownTitle});
            chart.xAxis[0].setTitle({text: drillDownXTitle});
            chart.yAxis[0].setTitle({text: drillDownYTitle});
            setShowRatingInfo(false);
          },
          drillup: function (e) {
            chart.setTitle({text: title});
            chart.xAxis[0].setTitle({text: xTitle});
            chart.yAxis[0].setTitle({text: yTitle});
            setShowRatingInfo(true);
          },
        },
      },
      title: {
        text: title,
      },
      xAxis: {
        type: 'category',
        title: {
          enabled: false,
          text: xTitle,
        },
      },
      yAxis: {
        max: 130,
        title: {
          enabled: false,
          text: yTitle,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointPadding: 0,
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '14px',
              fontWeight: 700,
              zIndex: 1,
            },
            useHTML: true,
            formatter: function () {
              if (!!this?.point?.options?.grade) {
                const gradeColor = getGradeColor(this?.point?.options?.grade);
                return `<span style="color: ${gradeColor};">${this?.point?.options?.grade}</span>`;
              }
              return `<span>${
                this?.point?.options?.grade || integer(this.point.y)
              }</span>`;
            },
          },
        },
      },
      tooltip: {
        useHTML: true,
        outside: true,
        formatter: function () {
          return `<div><b>${this.point.name}</b><br/>${integer(
            this.point.y
          )}<div>${this?.point?.options?.description || ''}</div></div>`;
        },
        style: {
          zIndex: 10000,
        },
      },

      series: [
        {
          name: 'Back',
          colorByPoint: true,
          data,
        },
      ],

      drilldown: {
        breadcrumbs: {
          position: {
            align: 'right',
          },
          showFullPath: true,
          format: '{level.name}',
        },
        series: drillDown,
        activeDataLabelStyle: {
          textDecoration: 'none',
          color: blackColor,
        },
        activeAxisLabelStyle: {
          textDecoration: 'none',
          color: blackColor,
        },
      },
      accessibility: {
        enabled: false,
      },
    });
    // eslint-disable-next-line
  }, [data, drillDown]);
  return (
    <Card
      infoTooltip={showRatingInfo ? ratingInfo : factorInfo || ratingInfo}
      noPadding
    >
      <div id={id} />
    </Card>
  );
};

RiskAreaChartCard.propTypes = {
  data: PropTypes.array,
  drillDown: PropTypes.array,
  info: PropTypes.node,
};

export default RiskAreaChartCard;
