// charts
import Highcharts from 'highcharts';

// layout components
import Card from '../../../layout/components/Card/Card';

// number lib
import integer from '../../../number/lib/integer.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// risk lib
import convertScoreToGrade from '../../../risk/lib/convertScoreToGrade.lib.risk';
import getRiskColor from '../../../risk/lib/getRiskColor.lib.risk';

// uuid
import {v4} from 'uuid';

const FinancialMetricChartCard = ({score = 0}) => {
  const id = v4();

  const {primary, secondary} = getRiskColor(score);
  const grade = convertScoreToGrade(score);

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'solidgauge',
        borderWidth: '0px',
        height: '220px',
        marginBottom: -90,
      },

      title: {
        text: 'Financial Metrics Rating',
      },

      pane: {
        size: '80%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: secondary,
          innerRadius: '65%',
          outerRadius: '100%',
          shape: 'arc',
          borderWidth: 0,
        },
      },

      tooltip: {
        enabled: false,
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
        labels: {
          enabled: false,
        },
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: true,
            padding: 0,
            borderWidth: 0,
            style: {
              fontSize: '24px',
            },
            y: -24,
            formatter: function () {
              const value = this.y;
              return `${integer(value)} / ${grade}`;
            },
          },
          stickyTracking: false,
        },
      },

      series: [
        {
          name: 'Halo Intrinsic Score',
          data: [{y: score, color: primary}],
          color: primary,
          tooltip: {
            enabled: false,
          },
          borderWidth: 0,
        },
      ],
    });
    // eslint-disable-next-line
  }, [score]);

  return (
    <Card noPadding>
      <div id={id} />
    </Card>
  );
};

FinancialMetricChartCard.propTypes = {
  score: PropTypes.number,
};

export default FinancialMetricChartCard;
