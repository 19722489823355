const CHARTS = (creditSafe) => [
  {
    title: 'Equity',
    ...creditSafe.equity,
  },
  {
    title: 'Equity Ratio (%)',
    subtitle: 'Equity Ratio',
    ...creditSafe.equityRatio,
  },
  {
    title: 'Total Borrowing Ratio (%)',
    subtitle: 'Total Borrowing Ratio',
    ...creditSafe.totalBorrowingRatio,
  },
  {
    title: 'Debt Ratio (%)',
    subtitle: 'Debt Ratio',
    ...creditSafe.debtRatio,
  },
  {
    title: 'Cash Ratio (%)',
    subtitle: 'Cash Ratio',
    ...creditSafe.cashRatio,
  },
  {
    title: 'Liquidity Ratio (%)',
    subtitle: 'Liquidity Ratio',
    ...creditSafe.liquidityRatio,
  },
  {
    title: 'Current Ratio (%)',
    subtitle: 'Current Ratio',
    ...creditSafe.currentRatio,
  },
  {
    title: 'Equity / Assets Ratio A (%)',
    subtitle: 'Equity / Assets Ratio A',
    ...creditSafe.equityAssetsRatioA,
  },
  {
    title: 'Equity / Assets Ratio B (%)',
    subtitle: 'Equity / Assets Ratio B',
    ...creditSafe.equityAssetsRatioB,
  },
  {
    title: 'Revenue',
    ...creditSafe.revenue,
  },
  {
    title: 'Pre Tax Profit',
    ...creditSafe.preTaxProfit,
  },
  {
    title: 'Net Profit Ratio (%)',
    subtitle: 'Net Profit Ratio',
    ...creditSafe.netProfitRatio,
  },
  {
    title: 'Return on Assets (%)',
    subtitle: 'Return on Assets',
    ...creditSafe.returnOnAssets,
  },
  {
    title: 'Return on Capital Employed (%)',
    subtitle: 'Return on Capital Employed',
    ...creditSafe.returnOnCapitalEmployed,
  },
];

export default CHARTS;
