// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const DataLoadScreen = ({children}) => {
  const {loadingSuppliersForSearch, loadingDashboardData, customDataLoaded} =
    useSelector((state) => ({
      customDataLoaded: state.tenant.customDataLoaded,
      loadingDashboardData: state.dashboard.loading,
      loadingSuppliersForSearch: state.supplier.loadingSuppliersForSearch,
    }));

  return loadingDashboardData ||
    loadingSuppliersForSearch ||
    !customDataLoaded ? (
    <FullScreenLoader />
  ) : (
    children
  );
};

DataLoadScreen.propTypes = {
  children: PropTypes.node,
};

export default DataLoadScreen;
