// local components
import Column from '../components/Column';

const generateColumn = ({
  category,
  columnIndex,
  title = null,
  Component = Column,
  props = {},
}) => {
  return {
    title: title || (!columnIndex ? '' : category),
    key: category,
    dataIndex: category,
    align: !columnIndex ? 'left' : 'center',
    render: (_, entry, entryIndex) => (
      <Component
        category={category}
        columnIndex={columnIndex}
        entry={entry}
        entryIndex={entryIndex}
        {...props}
      />
    ),
  };
};

export default generateColumn;
