// date lib
import formatISODate from '../../date/formatters/formatISO.formatter.date';

const growthRatesDataMap = (company) => {
  const localFinancialsType = [
    ...(company?.financials?.report?.localFinancialStatements || []),
  ]?.[0]?.type;

  const {categories, entries} = [
    ...(company?.financials?.report?.localFinancialStatements || []),
  ]
    .filter(({type}) => type === localFinancialsType)
    .reduce(
      (combined, current) => {
        const updatedCategories = [
          ...combined.categories,
          {
            key: current.yearEndDate,
            label: formatISODate({
              date: current.yearEndDate,
              dateFormat: 'MM/dd/yy',
            }),
          },
        ];

        const entries = Object.entries(current?.ratios || {}).reduce(
          (combinedEntries, [entryKey, entryValue]) => {
            if (
              ![
                'netSalesGrowthRate',
                'sustainableGrowthRate',
                'growthInTotalAssets',
                'growthInGrossFixedAssets',
                'growthInFixedAssets',
                'growthInCurrentAssets',
                'growthInOperatingCurrentAssets',
                'growthInWorkingCapital',
                'growthInOperatingWorkingCapital',
                'growthInGrossInvestment',
                'growthInTotalDebt',
                'growthInNetTurnover',
                'growthInCashFlow',
                'growthInEbitda',
                'growthInEbit',
                'growthInNetProfit',
                'growthInNoplat',
                'growthInShareholdersEquity',
                'netSalesGrowthRate3Yr',
                'sustainableGrowthRate3Yr',
                'growthInTotalAssets3Yr',
                'growthInGrossFixedAssets3Yr',
                'growthInFixedAssets3Yr',
                'growthInCurrentAssets3Yr',
                'growthInOperatingCurrentAssets3Yr',
                'growthInWorkingCapital3Yr',
                'growthInOperatingWorkingCapital3Yr',
                'growthInGrossInvestment3Yr',
                'growthInTotalDebt3Yr',
                'growthInNetTurnover3Yr',
                'growthInCashFlow3Yr',
                'growthInEbitda3Yr',
                'growthInEbit3Yr',
                'growthInNetProfit3Yr',
                'growthInNoplat3Yr',
                'growthInShareholdersEquity3Yr',
                'netSalesGrowthRate5Yr',
                'sustainableGrowthRate5Yr',
                'growthInTotalAssets5Yr',
                'growthInGrossFixedAssets5Yr',
                'growthInFixedAssets5Yr',
                'growthInCurrentAssets5Yr',
                'growthOperatingCurrentAssets5Yr',
                'growthInWorkingCapital5Yr',
                'growthInOperatingCapital5Yr',
                'growthInGrossInvestment5Yr',
                'growthInTotalDebt5Yr',
                'growthInNetTurnover5Yr',
                'growthInCashFlow5Yr',
                'growthInEbitda5Yr',
                'growthInEbit5Yr',
                'growthInNetProfit5Yr',
                'growthInNoplat5Yr',
                'growthInShareholdersEquity5Yr',
              ].includes(entryKey)
            )
              return combinedEntries;
            const entry = [...combined.entries].find(
              (existingEntry) => existingEntry.entry.value === entryKey
            ) || {
              entry: {
                value: entryKey,
                type: 'key',
              },
            };
            entry[current.yearEndDate] = {
              value: entryValue,
              type: 'percentage',
            };
            return [...combinedEntries, entry];
          },
          []
        );

        return {
          categories: updatedCategories,
          entries,
        };
      },
      {
        categories: [],
        entries: [],
      }
    );

  const updatedCategories = [
    {
      key: 'entry',
      label: '',
    },
    ...categories.reverse(),
  ];
  const updatedEntries = [...entries].filter((entry) =>
    Object.entries(entry).every(
      ([key, value]) => key === 'entry' || !!value?.value
    )
  );
  return {
    categories: updatedCategories,
    entries: updatedEntries,
  };
};

export default growthRatesDataMap;
