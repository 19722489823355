// layout colors
import blue300Color from '../../layout/colors/blue300.color.layout';
import green300Color from '../../layout/colors/green300.color.layout';
import grey300Color from '../../layout/colors/grey300.color.layout';
import wineRed300Color from '../../layout/colors/wineRed300.color.layout';
import yellow200Color from '../../layout/colors/yellow200.color.layout';

const KEY_RISK_AREAS = [
  {
    label: 'Strategic',
    color: yellow200Color,
  },
  {
    label: 'Compliance',
    color: blue300Color,
  },
  {
    label: 'Reputational',
    color: grey300Color,
  },
  {
    label: 'Financial',
    color: green300Color,
  },
  {
    label: 'Operational',
    color: wineRed300Color,
  },
];

export default KEY_RISK_AREAS;
