import styled from 'styled-components';

// layout colors
import grey800Color from '../../../../layout/colors/grey800.color.layout';

const MonitoringSuppliers = styled.div`
  color: ${grey800Color};
  font-size: 14px;
  padding-bottom: 3px;
`;

export default MonitoringSuppliers;
