// local components
import Color from './Color';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ColorColumn = ({entry}) => {
  return (
    <div>
      <Color color={entry.color} /> {entry.grade}
    </div>
  );
};

ColorColumn.propTypes = {
  entry: PropTypes.object,
};

export default ColorColumn;
