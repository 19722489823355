// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralNotificationDataAction from './actions/setGeneralData.action.redux.notification';

// style redux constants
import DEFAULT_DASHBOARD_REDUX_STATE from './constants/defaultState.constant.redux.notification';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: DEFAULT_DASHBOARD_REDUX_STATE,
  reducers: {
    setGeneralNotificationData: setGeneralNotificationDataAction,
  },
});

export const {setGeneralNotificationData} = notificationSlice.actions;

export default notificationSlice.reducer;
