// propTypes
import PropTypes from 'prop-types';

// styled-components
import styled, {css} from 'styled-components';

// constants
const HEADINGS = {
  H1: css`
    font-size: 24px;
    font-weight: 700;
  `,
  H2: css`
    font-size: 20px;
    font-weight: 700;
  `,
  H3: css`
    font-size: 18px;
    font-weight: 700;
  `,
  H4: css`
    font-size: 16px;
    font-weight: 700;
  `,
  H5: css`
    font-size: 16px;
  `,
  H6: css`
    font-size: 14px;
  `,
};

const Title = styled.div`
  ${({heading}) => HEADINGS?.[heading] || HEADINGS.H1}
`;

Title.propTypes = {
  heading: PropTypes.oneOf(Object.keys(HEADINGS)),
};

export default Title;
