// company storages
import companyCustomColumnsStorage from '../storages/customColumns.storage.company';

// redux store
import reduxStore from '../../redux/store';

const getUserColumnsService = ({
  user = reduxStore.getState().auth.user,
} = {}) => {
  const customColumns = JSON.parse(companyCustomColumnsStorage.get() || '{}');
  return customColumns?.[user?.email] || [];
};

export default getUserColumnsService;
