const liquidityRatiosHaloGptPrompt = ({ data }) => ({
  message: 'Explain Liquidity Ratios',
  queries: [
    {
      role: 'system',
      content: `You are analyzing a company's financial performance over multiple years. Convert all financial figures to appropriate units (Million, Billion, Trillion, Quadrillion) using the provided rule - amounts < $1 Million stay as is, $1 Million-$999 Million will use Million, $1 Billion - $999 Billion will use Billion, $1 Trillion- $999 Trillion will use Trillion, and $1 Quadrillion will use Quadrillion, rounding to two decimal places.
      Ensure clarity, succinctness, and adherence to the specified format.

      Instructions:

      Describe the change in a company's financial over a three-year period. Mention the percentage increase or decrease in each year, along with the corresponding value, without specifying the exact date.
      Restrict the response to not more than 5 sentences and upto 100 words only.
      Describe the financial performance of a company over the past three years, highlighting trends in various growth rates and metrics. Emphasize any significant fluctuations or patterns observed across these metrics and discuss their implications for the company's future growth and stability.
      Perform the analysis chronologically. When comparing multiple years, accurately mention increase and decrease by comparing data across consecutive years. Do not compare data if the years are not consecutive.
      You are assessing company's financial performance and identifying risk. Risk indicates the potential areas of weakness, signaling heightened uncertainty or exposure to adverse events.
      Double check that increase and decrese mentioned in the response are only based on comparing data across consecutive years.
      Double-check all analyses for accuracy. Pay close attention to whether the numbers being compared are positive or negative.
      Double check if formatting is correct including proper spacing between the words.
      Double check the metric names and their values.
      Double check that the numbers are associated with corresponding years.
      Double check there are no specific dates and provide data only in four digit years. For example, instead of saying '12/31/21' say '2021'.
      Double check that the conversion to units like millions, billions and trillions is done correctly.

      Note:
      Only if the liquidity ratios is blank or no values are present, provide a response stating 'Liquidity Ratios are not available.'
      `,
    },
    {
      role: 'user',
      content: `Analyze and summarize liquidity ratios in a concise manner. Data: ${JSON.stringify(
        data?.financials?.liquidityRatios || {}
      )}.`,
    },
  ],
});

export default liquidityRatiosHaloGptPrompt;
