// financial containers
import CompanyCarbonEmissionsContainer from '../../../carbonEmissions/containers/CompanyCarbonEmissionsContainer/CompanyCarbonEmissionsContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const CarbonEmissionsPage = () => (
  <SubPage page={page}>
    <CompanyCarbonEmissionsContainer />
  </SubPage>
);

export default CarbonEmissionsPage;
