import styled from 'styled-components';

// layout colors
// import whiteColor from '../../../../layout/colors/white.color.layout';

const Circle = styled.div`
  color: ${({theme}) => theme};
  font-size: 24px;
`;

export default Circle;
