// local components
import Container from './components/Container';
import Investors from './components/Investors';

// local lib
import parseInvestors from './lib/parseInvestors.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useState} from 'react';

const CompanyActiveInvestorsColumn = ({investors, empty = '-'}) => {
  const [showMore, setShowMore] = useState(false);

  const investorsList = parseInvestors(investors);
  const shouldShowMore = investorsList.length > 3;

  return (
    <Container>
      {!investorsList.length ? (
        empty
      ) : showMore ? (
        <Investors>
          <ul>
            {investorsList.map((investor) => (
              <li key={investor}>{investor}</li>
            ))}
          </ul>
          {shouldShowMore && (
            <span onClick={() => setShowMore(!showMore)}> Show less</span>
          )}
        </Investors>
      ) : (
        <Investors>
          <ul>
            {investorsList.slice(0, 3).map((investor) => (
              <li key={investor}>{investor}</li>
            ))}
          </ul>
          {shouldShowMore && (
            <span onClick={() => setShowMore(!showMore)}> Show more</span>
          )}
        </Investors>
      )}
    </Container>
  );
};

CompanyActiveInvestorsColumn.propTypes = {
  investors: PropTypes.string,
  empty: PropTypes.node,
};

export default CompanyActiveInvestorsColumn;
