const GEOPOLITICAL_HALO_GPT_CONVERSATION = ({data}) => ({
  welcomeMessage: 'Welcome to HaloGPT. What can I help you with?',
  systemQueries: [
    {
      role: 'system',
      content: `You are the world's leading Geopolitical expert. You are to look into a company's geopolitical data. Please ensure your accuracy by double checking your work.
      If multiple countries are present in the dataset, emphasise any notable relationships between countries. Do not talk about the countries that are not listed in the dataset.`,
    },
  ],
  model: 'ft:gpt-3.5-turbo-1106:halo-ai::8IsOhg3F',
  messages: [
    {
      message: 'Resilience of Supplier Countries',
      queries: [
        {
          role: 'user',
          content: `Looking at this Data:  ${JSON.stringify(data)}, In 4 sentences or less, analyze specific geopolitical factors such as political stability, economic policies, and regional conflicts affecting supplier resilience with the countries listed in the data. 
          Emphasize risk mitigation strategies these suppliers might have in place.`,
        },
      ],
    },
    {
      message: 'Supply Chain Vulnerability',
      queries: [
        {
          role: 'user',
          content: `Look at this dataset:  ${JSON.stringify(data)}, In 4 sentences or less, examine the political and economic stability of the Country/Countries and assess how these might affect supply chain robustness.`,
        },
      ],
    },
    {
      message: 'Industry-Based Geopolitical Risk',
      queries: [
        {
          role: 'user',
          content: `Looking at this Data:  ${JSON.stringify(data)}, In 5 sentences or less, connect industry-specific risks to the overall geopolitical climate of each country, including recent policy changes or economic reforms. Use only the countries listed in the data.`,
        },
      ],
    },
    {
      message: 'Geopolitical Tensions and Supplier Reliability',
      queries: [
        {
          role: 'user',
          content: `Looking at this Data:  ${JSON.stringify(data)}, In 5 sentences or less, explore the impact of geopolitical tensions on supply chains and resilience measures in place, focusing on diplomatic relations and trade policies. Use only the countries listed in the data.`,
        },
      ],
    },
    {
      message: 'Economic Vulnerabilities of Key Suppliers',
      queries: [
        {
          role: 'user',
          content: `Looking at this Data:  ${JSON.stringify(data)}, In 5 sentences or less, broaden the analysis to global economic trends and regional economic policies impacting key suppliers' vulnerabilities. Use only the countries listed in the data.`,
        },
      ],
    },
    {
      message: 'Political Stability and Supply Chain Security',
      queries: [
        {
          role: 'user',
          content: `Data:  ${JSON.stringify(data)}, With a maximum of 5 sentences, delve into the historical context of political stability in the countries found in the dataset and its potential future trajectory. Use only the countries listed in the data.`,
        },
      ],
    },
    {
      message: 'Natural Disaster Risks and Supply Chain Continuity',
      queries: [
        {
          role: 'user',
          content: `Looking at this Data:  ${JSON.stringify(data)}, In 5 sentences or less, evaluate a country's infrastructure and preparedness for mitigating natural disaster risks in the context of supply chain continuity. Use only the countries listed in the data.`,
        },
      ],
    },
    {
      message: 'Long-Term Geopolitical Risk Forecasting',
      queries: [
        {
          role: 'user',
          content: `Looking at this Data:  ${JSON.stringify(data)}, In 5 sentences or less, analyze global trends like climate change or technological advancements and their influence on geopolitical stability in key supplier countries. Use only the countries listed in the data.`,
        },
      ],
    },
    {
      message: 'Impact of International Sanctions',
      queries: [
        {
          role: 'user',
          content: `Looking at this Data:  ${JSON.stringify(data)}, In 5 sentences or less, Analyze the effect of international sanctions on third-party suppliers in affected regions, assessing the risk of supply chain disruption and potential alternatives. Use only the countries listed in the data.`,
        },
      ],
    },
    {
      message: 'Third-Party Cybersecurity',
      queries: [
        {
          role: 'user',
          content: `Looking at this Data:  ${JSON.stringify(data)}, In 5 sentences or less, Evaluate the cybersecurity infrastructure and potential risks of cyber attacks on third-party suppliers in geopolitically sensitive areas. Use only the countries listed in the data.`,
        },
      ],
    },
    {
      message: 'Environmental Regulations and Compliance',
      queries: [
        {
          role: 'user',
          content: `Looking at this Data:  ${JSON.stringify(data)}, In 5 sentences or less, Investigate the environmental regulations in supplier countries, assessing the risk of non-compliance and its implications for the company. Use only the countries listed in the data.`,
        },
      ],
    },
  ],
});

export default GEOPOLITICAL_HALO_GPT_CONVERSATION;