// propTypes
import PropTypes from 'prop-types';

const BusinessPartnerRolesColumn = ({
  category,
  columnIndex,
  entry,
  entryIndex,
}) => {
  const value = entry[category];
  return !!value?.length ? value.map(({role}) => role).join(', ') : '-';
};

BusinessPartnerRolesColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default BusinessPartnerRolesColumn;
