import React, {useEffect, useMemo, useState} from 'react';
import {Card, Dropdown, Button, Select, Input, Space, Modal} from 'antd';
import styled from 'styled-components';
import {
  DeleteOutlined,
  DownOutlined,
  FileSearchOutlined,
  CheckOutlined,
  MenuOutlined,
  UploadOutlined,
  DownloadOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import {FiBarChart2, FiType} from 'react-icons/fi';
import {Link, useNavigate} from 'react-router-dom';

import ArrangementComparisonTable from './ArrangementComparisonTable';
import Criticality from './Criticality';
import Upload from './Upload';
import {Dial} from './Dial';
import {ScoreProgress} from './ScoreProgress';
import {GraphContainer} from './Layout';
import {Table, TableContainer, columnTextRenderer} from './common';
import dueDiligenceReportMicrosoft from '../assets/report-due-diligence-microsoft.png';
import dueDiligenceReportRocketHR from '../assets/report-due-diligence-rocket-hr.png';
import useApi from '../hooks/use-api';
import theme from '../constants/theme';

import {
  createArrangementRoute,
  getArrangementRoute,
} from '../../../pages/DueDiligencePage/route';

const CardContainer = styled.div`
  .ant-card-body {
    padding: ${({hideTitle}) => (hideTitle ? '0px' : '24px')};
  }
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const CardContent = styled.div`
  padding: ${({hideTitle}) => (hideTitle ? '0px' : '0 8px 0 8px')};
  display: flex;
  flex-direction: row;
  flex: 1;

  .ant-table-tbody > tr > td {
    padding: 27px 17px;
    height: 80px;
  }

  .ant-btn.urgent {
    border-color: ${theme.dueDilligenceProgress.pendingUrgent};
    color: ${theme.dueDilligenceProgress.pendingUrgent};
    &:hover {
      border-color: red;
      color: red;
    }
  }

  .ant-btn.done {
    border-color: ${theme.dueDilligenceProgress.done};
    color: ${theme.dueDilligenceProgress.done};
    &:hover {
      border-color: #00bd5c;
      color: #00bd5c;
    }
  }
`;
const CardTitle = styled.h2`
  margin: 0px 0px 10px 0px;
  padding: 8px 10px 8px 10px;
  font-weight: 500;
`;
const CardLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const DueDiligenceCard = ({
  style,
  company,
  fixedArrangementId,
  fixedWidth,
  hideTitle,
  isClient,
  onRefresh,
}) => {
  const api = useApi();
  const navigate = useNavigate();

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  const [renaming, setRenaming] = useState(false);
  const [modalContents, setModalContents] = useState();
  const [isCompareArrangementModalVisible, setCompareArrangementModalVisible] =
    useState(false);

  const [focusRenameArrangementInput, setFocusRenameArrangementInput] =
    useState(false);
  const [blurSelectArrangementInput, setBlurSelectArrangementInput] =
    useState(false);

  const arrangementIds = company.getActiveArrangementIds();
  const [currentArrangementId, setCurrentArrangementId] = useState(
    fixedArrangementId || arrangementIds[0]
  );
  const [currentArrangementName, setCurrentArrangementName] = useState(
    company.arrangements[currentArrangementId]?.name
  );
  const arrangement = company.arrangements[currentArrangementId];
  const uploads = arrangement?.uploads || {};
  const answers = arrangement?.answers || {};
  const draftArrangement = company.getDraftArrangement();
  const newArrangementButtonLabel = `+ New${
    draftArrangement ? ' (continue)' : ''
  }`;

  const sections = {};
  sections.it = {
    isNotify: false,
    isStarted: false,
    isDone: arrangement?.isConfirmed('it'),
  };
  sections.security = {
    isNotify: false,
    isStarted: false,
    isDone: arrangement?.isConfirmed('security'),
  };
  sections.bcm = {
    isNotify: false,
    isStarted: false,
    isDone: arrangement?.isConfirmed('bcm'),
  };
  sections.privacy = {
    isNotify: false,
    isStarted: false,
    isDone: arrangement?.isConfirmed('privacy'),
  };
  sections.compliance = {
    isNotify: false,
    isStarted: false,
    isDone: arrangement?.isConfirmed('compliance'),
  };
  sections.finance = {
    isNotify: false,
    isStarted: false,
    isDone: arrangement?.isConfirmed('finance'),
  };

  Object.keys(answers).forEach((key) => {
    const responseTriggers = answers[key].responseTriggers || [];
    const responseSection = answers[key].section;
    const responseNLP = answers[key].nlp;
    sections.it.isNotify =
      sections.it.isNotify || responseTriggers.includes('it');
    sections.security.isNotify =
      sections.security.isNotify || responseTriggers.includes('security');
    sections.bcm.isNotify =
      sections.bcm.isNotify || responseTriggers.includes('bcm');
    sections.privacy.isNotify =
      sections.privacy.isNotify || responseTriggers.includes('privacy');
    sections.compliance.isNotify =
      sections.compliance.isNotify || responseTriggers.includes('compliance');
    sections.finance.isNotify =
      sections.finance.isNotify || responseTriggers.includes('finance');
    sections.it.isStarted =
      sections.it.isStarted || (responseSection === 'it' && !responseNLP);
    sections.security.isStarted =
      sections.security.isStarted ||
      (responseSection === 'security' && !responseNLP);
    sections.bcm.isStarted =
      sections.bcm.isStarted || (responseSection === 'bcm' && !responseNLP);
    sections.privacy.isStarted =
      sections.privacy.isStarted ||
      (responseSection === 'privacy' && !responseNLP);
    sections.compliance.isStarted =
      sections.compliance.isStarted ||
      (responseSection === 'compliance' && !responseNLP);
    sections.finance.isStarted =
      sections.finance.isStarted ||
      (responseSection === 'finance' && !responseNLP);
  });

  const showModalCloseArrangement = () => {
    Modal.confirm({
      title: 'Are you sure you want to close this arrangement?',
      content: `${currentArrangementName}`,
      width: 500,
      zIndex: 1010,
      cancelText: 'Cancel',
      okText: 'Close Arrangement',
      okButtonProps: {danger: true},
      autoFocusButton: null,
      destroyOnClose: true,
      onOk: () => {
        closeArrangement();
      },
    });
  };

  const selectArrangement = (arrangementId) => {
    if (!arrangementId) return;
    setCurrentArrangementId(arrangementId);
    setCurrentArrangementName(company.arrangements[arrangementId].name);
  };

  const closeArrangement = () => {
    arrangement.closeArrangement();
    arrangement.save();
    selectArrangement(company.getActiveArrangementIds()[0]);
    setRefresh(true);
    onRefresh?.();
  };

  const startRenaming = () => {
    setRenaming(true);
    setFocusRenameArrangementInput(true);
  };

  useEffect(() => {
    if (focusRenameArrangementInput) {
      setFocusRenameArrangementInput(false);
      document.getElementById('arrangement-input').focus();
    }
  }, [focusRenameArrangementInput]);

  const onRenameArrangement = () => {
    const name = document.getElementById('arrangement-input').value;
    arrangement.renameArrangement(name);
    arrangement.save();
    onChangeArrangement(currentArrangementId);
  };

  const onChangeArrangement = (value) => {
    selectArrangement(value);
    setBlurSelectArrangementInput(true);
    setRenaming(false);
  };

  useEffect(() => {
    if (blurSelectArrangementInput) {
      setBlurSelectArrangementInput(false);
      document.getElementById('arrangement-select').blur();
    }
  }, [blurSelectArrangementInput]);

  const ArrangementDropDownItems = () => {
    const items = [];
    items.push({
      key: 'irq',
      label: (
        <Link to={getArrangementRoute(company.id, arrangement?.id)}>
          View Inherent Risk Questionnaire
        </Link>
      ),
      icon: <FileSearchOutlined />,
    });
    items.push({
      key: 'dd',
      label: 'View Due Diligence Report',
      icon: <FiBarChart2 />,
    });
    if (uploads.initial?.Arrangement)
      items.push({
        key: 'download',
        label: 'Download Statement of Work',
        icon: <DownloadOutlined />,
      });
    items.push({type: 'divider'});
    items.push({
      key: 'compare',
      label: 'Compare Arrangement',
      icon: <CopyOutlined />,
    });
    items.push({key: 'rename', label: 'Rename Arrangement', icon: <FiType />});
    items.push({
      key: 'close',
      label: 'Close Arrangement',
      icon: <DeleteOutlined />,
      danger: true,
    });
    return items;
  };

  const ArrangementDropDownClicked = ({key}) => {
    if (key === 'dd' && company.id === '1453')
      setModalContents(
        <img alt="" src={dueDiligenceReportMicrosoft} style={{width: 780}} />
      );
    else if (key === 'dd')
      setModalContents(
        <img alt="" src={dueDiligenceReportRocketHR} style={{width: 780}} />
      );
    else if (key === 'compare') setCompareArrangementModalVisible(true);
    else if (key === 'rename') startRenaming();
    else if (key === 'close') showModalCloseArrangement();
  };

  const ControlFunctionDropDownClicked = (item) => {
    if (item.domEvent.target.className !== 'ant-upload') {
      const target = item.domEvent.target;
      const inputElement =
        target.querySelector('input') ||
        target.parentElement.parentElement.querySelector('input');
      inputElement?.click();
    }
  };

  const columns = useMemo(() => {
    function ControlFunctionDropDownItems(value, record) {
      const isStarted = record.is_started;
      const isDone = record.is_done;
      const items = [];
      items.push({
        key: 0,
        label: (
          <Link
            to={`${getArrangementRoute(company.id, arrangement?.id)}?q=${
              value.param
            }`}
          >
            {isDone ? 'View' : isStarted ? 'Continue' : 'Start'} Questionnaire
          </Link>
        ),
        icon: <FileSearchOutlined />,
      });
      const uploadAnswers = api.getUploadAnswers();
      if (uploadAnswers[value.param])
        uploadAnswers[value.param].forEach((answer, index) => {
          if (isDone && uploads[value.param][answer.uploadType]) {
            items.push({
              key: index + 1 + 'download',
              label: `Download ${answer.uploadType}`,
              icon: <DownloadOutlined />,
            });
          } else if (
            !isDone &&
            (!uploads[value.param] || !uploads[value.param][answer.uploadType])
          ) {
            items.push({
              key: index + 1,
              label: (
                <Upload
                  arrangement={arrangement}
                  answer={answer}
                  onDone={() => {
                    setRefresh(true);
                  }}
                />
              ),
              icon: <UploadOutlined />,
            });
          } else if (!isDone) {
            const children = [];
            items.push({
              key: index + 1,
              label: `Manage ${answer.uploadType}`,
              icon: <CheckOutlined />,
              children: children,
            });
            children.push({
              key: index + 1 + 'download',
              label: `Download ${answer.uploadType}`,
              icon: <DownloadOutlined />,
            });
            children.push({
              key: index + 1 + 'update',
              label: (
                <Upload
                  arrangement={arrangement}
                  answer={answer}
                  action={'Update'}
                  onDone={() => {
                    setRefresh(true);
                  }}
                />
              ),
              icon: <UploadOutlined />,
            });
          }
        });
      return items;
    }

    return [
      {
        dataIndex: 'control_function',
        title: 'Control Function',
        render: columnTextRenderer,
      },
      {
        dataIndex: 'inherent_score',
        title: 'Inherent Risk',
        render: (value) => <ScoreProgress score={value} />,
        width: 200,
        align: 'center',
      },
      {
        dataIndex: 'due_diligence',
        title: 'Due Diligence',
        render: (value, record) => {
          const isNotify = record.due_diligence.value;
          const isDone = record.is_done;
          const label = isDone
            ? 'Completed'
            : isNotify
            ? 'Tasks'
            : 'Not Required';
          return isNotify ? (
            <Dropdown
              trigger={['click']}
              menu={{
                items: ControlFunctionDropDownItems(value, record),
                onClick: ControlFunctionDropDownClicked,
              }}
              getPopupContainer={() =>
                document.getElementById('dropdown-container-area')
              }
            >
              <Button
                className={isDone ? 'done' : 'urgent'}
                style={{width: '120px'}}
              >
                {`${label}`} <DownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <div
              style={{
                color: theme.dueDilligenceProgress.done,
                minWidth: '175px',
                margin: '6px 0px',
              }}
            >{`${label}`}</div>
          );
        },
        width: 175,
        align: 'center',
      },
      {
        dataIndex: 'residual_score',
        title: 'Current Residual Risk',
        render: (value, record) => {
          const isNotify = record.due_diligence.value;
          const isDone = record.is_done;
          return isDone || !isNotify ? (
            <ScoreProgress score={value} />
          ) : isNotify ? (
            <div style={{color: theme.dueDilligenceProgress.pendingUrgent}}>
              Due Diligence Required
            </div>
          ) : (
            <div style={{color: theme.dueDilligenceProgress.done}}>-</div>
          );
        },
        width: 200,
        align: 'center',
      },
    ];
    // eslint-disable-next-line
  }, [arrangement, company.id, uploads]);

  const dataSource = [
    {
      id: '1',
      control_function: 'Information Technology',
      inherent_score: 89,
      due_diligence: {
        value: sections.it.isNotify,
        param: 'it',
      },
      residual_score: 79,
      is_started: sections.it.isStarted,
      is_done: sections.it.isDone,
    },
    {
      id: '2',
      control_function: 'Information Security',
      inherent_score: 87,
      due_diligence: {
        value: sections.security.isNotify,
        param: 'security',
      },
      residual_score: 76,
      is_started: sections.security.isStarted,
      is_done: sections.security.isDone,
    },
    {
      id: '3',
      control_function: 'Business Continuity',
      inherent_score: 70,
      due_diligence: {
        value: sections.bcm.isNotify,
        param: 'bcm',
      },
      residual_score: 62,
      is_started: sections.bcm.isStarted,
      is_done: sections.bcm.isDone,
    },
    {
      id: '4',
      control_function: 'Privacy',
      inherent_score: 90,
      due_diligence: {
        value: sections.privacy.isNotify,
        param: 'privacy',
      },
      residual_score: 76,
      is_started: sections.privacy.isStarted,
      is_done: sections.privacy.isDone,
    },
    {
      id: '5',
      control_function: 'Compliance',
      inherent_score: 86,
      due_diligence: {
        value: sections.compliance.isNotify,
        param: 'compliance',
      },
      residual_score: 71,
      is_started: sections.compliance.isStarted,
      is_done: sections.compliance.isDone,
    },
    {
      id: '6',
      control_function: 'Finance',
      inherent_score: 82,
      due_diligence: {
        value: sections.finance.isNotify,
        param: 'finance',
      },
      residual_score: 77,
      is_started: sections.finance.isStarted,
      is_done: sections.finance.isDone,
    },
  ];

  const criticality = 1;

  return (
    <CardContainer hideTitle={hideTitle} style={style}>
      <Modal
        centered
        footer={null}
        onCancel={() => setModalContents()}
        style={{left: 235 / 4}}
        open={!!modalContents}
        width={840}
        zIndex={1010}
      >
        {modalContents}
      </Modal>
      <Modal
        centered
        footer={null}
        onCancel={() => setCompareArrangementModalVisible(false)}
        open={isCompareArrangementModalVisible}
        width={1450}
        zIndex={9999}
      >
        <ArrangementComparisonTable />
      </Modal>
      <Card
        id="dropdown-container-area"
        style={{
          ...(hideTitle
            ? {background: 'transparent', border: 'none'}
            : {
                borderRadius: '10px',
                border: '1px solid #d9d9d9',
                background: theme.card.background,
              }),
          ...(fixedWidth ? {width: '1165px'} : {}),
        }}
      >
        <CardLayout>
          <Space direction="horizontal">
            {!hideTitle && (
              <CardTitle>
                {isClient ? 'Vendor Requests' : 'Due Diligence'}
              </CardTitle>
            )}
            <Space
              style={{
                position: 'absolute',
                top: hideTitle ? '-55px' : '32px',
                ...(hideTitle
                  ? {
                      left: `${
                        622 - criticality * 20 - !!draftArrangement * 65
                      }px`,
                    }
                  : {right: fixedWidth ? '33px' : '32px'}),
              }}
            >
              <Criticality criticality={criticality} isFramed={true} />
              <Button
                onClick={() => navigate(createArrangementRoute(company.id))}
                style={{}}
              >
                {newArrangementButtonLabel}
              </Button>
              {!renaming ? (
                <Select
                  id="arrangement-select"
                  value={currentArrangementName}
                  showSearch
                  disabled={fixedArrangementId != null}
                  style={{width: 300}}
                  placeholder="Select an arrangement"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={onChangeArrangement}
                >
                  {arrangementIds.map((arrangementId) => {
                    return (
                      <Select.Option
                        key={`arrangement-${arrangementId}`}
                        value={arrangementId}
                      >
                        {company.arrangements[arrangementId].name}
                      </Select.Option>
                    );
                  })}
                </Select>
              ) : (
                <Input
                  id="arrangement-input"
                  defaultValue={currentArrangementName}
                  placeholder={currentArrangementName}
                  style={{width: 300}}
                  onPressEnter={onRenameArrangement}
                  onBlur={onRenameArrangement}
                  onKeyDown={(e) => {
                    if (e.key.toLowerCase() === 'escape')
                      onChangeArrangement(currentArrangementId);
                  }}
                />
              )}
              <Dropdown
                trigger={['click']}
                menu={{
                  items: ArrangementDropDownItems(),
                  onClick: ArrangementDropDownClicked,
                }}
                icon={<MenuOutlined />}
                //
              >
                <Button
                  icon={<MenuOutlined />}
                  style={{
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                    marginLeft: '-12px',
                  }}
                ></Button>
              </Dropdown>
            </Space>
          </Space>
          <CardContent hideTitle={hideTitle}>
            <Left>
              <Space style={{alignItems: 'initial'}}>
                <Space direction="vertical">
                  <GraphContainer
                    ph={1}
                    style={{
                      marginRight: '15px',
                      marginBottom: '15px',
                      padding: '5px 10px 27px',
                    }}
                  >
                    <Dial value={84} label="Overall Inherent Risk" />
                  </GraphContainer>
                  <GraphContainer
                    ph={1}
                    style={{marginRight: '15px', padding: '5px 10px 27px'}}
                  >
                    <Dial value={77} label="Overall Residual Risk" />
                  </GraphContainer>
                </Space>
                <TableContainer>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    rowKey="id"
                  />
                </TableContainer>
              </Space>
            </Left>
          </CardContent>
        </CardLayout>
      </Card>
    </CardContainer>
  );
};

export default DueDiligenceCard;
