const TABLES = [
  {
    title: 'Financial Year',
    dataPoint: (creditSafe) => creditSafe?.financials?.financialYear,
  },
  {
    title: 'Assets',
    dataPoint: (creditSafe) => creditSafe?.financials?.assets,
  },
  {
    title: 'Liabilities',
    dataPoint: (creditSafe) => creditSafe?.financials?.liabilities,
  },
  {
    title: 'Profit & Loss',
    dataPoint: (creditSafe) => creditSafe?.financials?.profitLoss,
  },
  {
    title: 'IRFS',
    dataPoint: (creditSafe) => creditSafe?.financials?.IFRS,
  },
  {
    title: 'Tangible Assets',
    dataPoint: (creditSafe) => creditSafe?.financials?.tangibleAssets,
  },
  {
    title: 'Intangible Assets',
    dataPoint: (creditSafe) => creditSafe?.financials?.intangibleAssets,
  },
  {
    title: 'Other Fixed Assets',
    dataPoint: (creditSafe) => creditSafe?.financials?.otherFixedAssets,
  },
  {
    title: 'Inventory',
    dataPoint: (creditSafe) => creditSafe?.financials?.inventory,
  },
  {
    title: 'Receivables',
    dataPoint: (creditSafe) => creditSafe?.financials?.receivables,
  },
  {
    title: 'Liabilities',
    dataPoint: (creditSafe) => creditSafe?.financials?.additionalLiabilities,
  },
  {
    title: 'Long Term Liabilities',
    dataPoint: (creditSafe) => creditSafe?.financials?.longTermLiabilities,
  },
  {
    title: 'Share Capital & Reserves',
    dataPoint: (creditSafe) => creditSafe?.financials?.shareCapitalAndReserves,
  },
  {
    title: 'Other Financial Items',
    dataPoint: (creditSafe) => creditSafe?.financials?.otherFinancialItems,
  },
  {
    title: 'Profit & Loss',
    dataPoint: (creditSafe) => creditSafe?.financials?.additionalProfitAndLoss,
  },
];

export default TABLES;
