import styled from 'styled-components';

// layout colors
import blueGrey400Color from '../../../../../colors/blueGrey400.color.layout';
import whiteColor from '../../../../../colors/white.color.layout';

const Tab = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: ${blueGrey400Color};
  color: ${whiteColor};
  padding: 0px 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid ${blueGrey400Color};

  &:hover {
    background: transparent;
    color: ${blueGrey400Color};
  }
`;

export default Tab;
