import styled from 'styled-components';

const LabelY = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  left: -10px;
  transform: translateY(-50%) rotate(-90deg);
  font-weight: 500;
  height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

export default LabelY;
