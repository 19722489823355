// layout components
import SideMenuItem from '../SideMenuItem/SideMenuItem';

// local components
import Container from './components/Container';
import Link from './components/Link';
import SectionName from './components/SectionName';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const SideMenuItems = ({
  onSection,
  previousPageLabel,
  previousPageUrl,
  sectionName,
  sections,
  selectedSection,
}) => {
  return (
    <Container>
      {!!sectionName && <SectionName>{sectionName}</SectionName>}
      {!!previousPageLabel && !!previousPageUrl && (
        <Link to={previousPageUrl}>
          <i className="mdi mdi-chevron-left" /> {previousPageLabel}
        </Link>
      )}
      {sections.map((section) => (
        <SideMenuItem
          key={section.section}
          highlight={!!selectedSection && selectedSection === section.section}
          onClick={() => onSection(section.section)}
        >
          {section.label}
        </SideMenuItem>
      ))}
    </Container>
  );
};

SideMenuItems.propTypes = {
  onSection: PropTypes.func,
  previousPageLabel: PropTypes.node,
  previousPageUrl: PropTypes.string,
  sectionName: PropTypes.node,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  selectedSection: PropTypes.string,
};

export default SideMenuItems;
