// company components
import DueDiligenceSection from '../DueDiligenceSection/DueDiligenceSection';

// company containers
import CompanySideMenuContainer from '../../containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../lib/generateSubPages.lib.company';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const DueDiligenceLayout = () => {
  const {company, subPage} = useSelector((state) => ({
    company: state.company.company,
    subPage: state.layout.subPage,
  }));

  return (
    <PageLayout
      sideContent={() => <CompanySideMenuContainer />}
      subPage={subPage}
      subPages={generateSubPages(company)}
      sections={[
        {
          section: '',
          component: <DueDiligenceSection />,
          contentStyle: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 0%',
            WebkitBoxAlign: 'center',
            alignItems: 'center',
          },
        },
      ].filter((section) => !!section)}
    />
  );
};

export default DueDiligenceLayout;
