// data lib
import prepareData from '../../data/lib/prepare.lib.data';

// supplier lib
import isSupplierAvailable from '../../supplier/lib/isSupplierAvailable.lib.supplier';

const prepareSuppliersList = ({
  customData,
  companies,
  supplierScores,
  tenantData,
}) => {
  return [...companies]
    .filter((company) => isSupplierAvailable({supplier: company}))
    .map((company) => {
      const financialImpact =
        [...(tenantData?.[company.CompanyId]?.FinancialImpacts || [])].find(
          ({RiskType}) => RiskType === 'Data Breach'
        ) || null;

      const cyberSecurity = {
        RansomwareIndex:
          company?.CyberSecurity?.['RansomwareSusceptibilityIndex(RSI)'],
        BreachIndex: company?.CyberSecurity?.['DataBreachIndex(DBI)'],
        Compliance: {
          Completeness: company?.CyberSecurity?.ComplianceCompleteness,
        },
        CyberAlerts: company?.CyberSecurity?.CyberAlerts,
        CyberRating: company?.CyberSecurity?.CyberRating,
        Grade: company?.CyberSecurity?.Grade,
        Financial: {
          RatingMin: financialImpact?.RatingMin,
          RatingMax: financialImpact?.RatingMax,
          Rating: financialImpact?.Rating,
        },
      };
      return {
        ...company,
        customData: customData?.[company?.CompanyId] || null,
        CyberSecurity: cyberSecurity,
        HaloScores: prepareData({
          scores: [supplierScores?.[company.CompanyId]],
        }).haloScores,
      };
    });
};

export default prepareSuppliersList;
