// dom lib
import DOMPurify from 'dompurify';

// local components
import Container from './components/Container';
import Explanation from './components/Explanation';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const PrintSection = ({children, explanation, title}) => (
  <Container>
    {!!title && <Title>{title}</Title>}
    {!!explanation && (
      <Explanation>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(explanation),
          }}
        />
      </Explanation>
    )}
    {children}
  </Container>
);

PrintSection.propTypes = {
  children: PropTypes.node,
  explanation: PropTypes.node,
  title: PropTypes.node,
};

export default PrintSection;
