// ant components
import {Input, Modal, Space} from 'antd';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ContactModal = ({
  email,
  loading,
  message,
  name,
  onChange,
  onClose,
  onContact,
  subject,
  visible,
}) => (
  <Modal
    title="Contact Us"
    centered
    open={visible}
    onOk={onContact}
    onCancel={onClose}
    width="500px"
  >
    <Space direction="vertical" style={{width: '100%'}}>
      <Space.Compact direction="horizontal" style={{width: '100%'}}>
        <Input
          value={name}
          onChange={onChange('name')}
          placeholder="Name"
          style={{width: '50%'}}
        />
        <Input
          type="email"
          value={email}
          onChange={onChange('email')}
          placeholder="Email"
          style={{width: '50%'}}
        />
      </Space.Compact>
      <Input
        value={subject}
        onChange={onChange('subject')}
        placeholder="Subject"
      />
      <Input.TextArea
        value={message}
        onChange={onChange('message')}
        placeholder="Message"
        autoSize={{
          minRows: 4,
          maxRows: 8,
        }}
      />
    </Space>
  </Modal>
);

ContactModal.propTypes = {
  email: PropTypes.string,
  loading: PropTypes.bool,
  message: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onContact: PropTypes.func,
  subject: PropTypes.string,
  visible: PropTypes.bool,
};

export default ContactModal;
