// number lib
import differenceInPercentage from '../../../../number/lib/differenceInPercentage.lib.number';

const parseCarbonEmissionsData = ({company}) => {
  const scienceBasedTargets = [
    ...(company?.carbonEmissions?.sbti?.data || []),
  ]?.[0];

  const unResolutions = [
    'Carbon Pricing Champions',
    'Responsible Climate Policy Engagement',
    'Climate For Care',
    'Climate Ambition Accelerator',
    'CEO Water Mandate',
  ];

  const emissionEntries = [
    {
      entry: 'Emissions Factor (Global Scope 1, 2, 3)',
      extractFn: (data) => data?.emission_factor || null,
      dataType: 'ratio',
      chart: {
        type: 'spline',
      },
    },
    {
      entry: 'Emissions Factor (Global Scope 1-3 Upstream)',
      extractFn: (data) => null,
      dataType: 'ratio',
    },
    {
      entry: 'Emissions Factor (Global Scope 1,2)',
      extractFn: (data) => data?.scope_1_and_2_emission_factor || null,
      dataType: 'ratio',
      chart: {
        type: 'spline',
      },
    },
    {
      entry: 'Emissions Factor (Sector Benchmark)',
      extractFn: (data) => null,
      dataType: 'ratio',
    },
    {
      entry: 'Scope 1: Total',
      extractFn: (data) => data?.emissions?.scope_1?.total || null,
      dataType: 'integer',
      chart: {
        type: 'column',
        yAxis: 1,
      },
    },
    {
      entry: 'Scope 2: Total',
      extractFn: (data) => data?.emissions?.scope_2?.total || null,
      dataType: 'integer',
      chart: {
        type: 'column',
        yAxis: 1,
      },
    },
    {
      entry: 'Scope 3: Total',
      extractFn: (data) => data?.emissions?.scope_3?.total || null,
      dataType: 'integer',
      chart: {
        type: 'column',
        yAxis: 1,
      },
    },
    {
      entry: 'Scope 3: Purchase goods and services',
      extractFn: (data) =>
        data?.emissions?.scope_3?.purchased_goods_and_services || null,
      dataType: 'integer',
    },
    {
      entry: 'Scope 3: Capital goods',
      extractFn: (data) => data?.emissions?.scope_3?.capital_goods || null,
      dataType: 'integer',
    },
    {
      entry: 'Scope 3: Fuel and energy-related',
      extractFn: (data) =>
        data?.emissions?.scope_3?.fuel_and_energy_related_activities || null,
      dataType: 'integer',
    },
    {
      entry: 'Scope 3: Upstream transportation',
      extractFn: (data) =>
        data?.emissions?.scope_3?.upstream_transportation_and_distribution ||
        null,
      dataType: 'integer',
    },
    {
      entry: 'Scope 3: Business travel',
      extractFn: (data) => data?.emissions?.scope_3?.business_travel || null,
      dataType: 'integer',
    },
    {
      entry: 'Scope 3: Employee Commute',
      extractFn: (data) => data?.emissions?.scope_3?.employee_commute || null,
      dataType: 'integer',
    },
  ];

  return {
    carbonEmissionsScore: (company?.carbonEmissions?.score || 0) * 100,
    cdpDisclosed: company?.carbonEmissions?.cdp?.is_listed || false,
    scienceBasedTargets: {
      categories: [
        {
          key: 'entry',
          label: '',
        },
        {
          key: 'value',
          label: '',
        },
      ],
      data: [
        {
          entry: 'CDP Disclosed',
          value: company?.carbonEmissions?.cdp?.is_listed ? 'Yes' : 'No',
        },
        {
          entry: 'Net-Zero Committed',
          value: scienceBasedTargets?.net_zero?.has_target
            ? !!scienceBasedTargets?.net_zero?.target_year
              ? `Yes - ${scienceBasedTargets?.net_zero?.target_year}`
              : 'Yes'
            : 'No',
        },
        {
          entry: 'Science Based Targets',
          value: scienceBasedTargets?.business_ambition_1_5?.has_target
            ? 'Target Set 1.5°C'
            : 'No',
        },
        {
          entry: 'Near Term Status',
          value: scienceBasedTargets?.near_term?.has_target ? 'Set' : 'Not Set',
        },
        {
          entry: 'Near Term Classification',
          value: scienceBasedTargets?.near_term?.target_status,
        },
        {
          entry: 'Near Term Year',
          value: scienceBasedTargets?.near_term?.target_year,
        },
        {
          entry: 'Long Term Status',
          value: scienceBasedTargets?.long_term?.has_target ? 'Set' : 'Not Set',
        },
        {
          entry: 'Long Term Classification',
          value: scienceBasedTargets?.long_term?.target,
        },
        {
          entry: 'Long Term Year',
          value: scienceBasedTargets?.long_term?.target_year,
        },
      ],
    },
    unitedNationsGlobalCompact: {
      categories: [
        {
          key: 'entry',
          label: '',
        },
        {
          key: 'initiative',
          label: '',
        },
      ],
      data: [...unResolutions].map((resolution) => ({
        entry: resolution,
        initiative: !![
          ...(company?.carbonEmissions?.un?.initiatives || []),
        ].find(({slug}) =>
          resolution.toLowerCase().split(' ').join('-').includes(slug)
        )
          ? 'Yes'
          : 'No',
      })),
    },
    sources: [...(company?.carbonEmissions?.sources || [])],
    emissions: {
      table: [...(company?.carbonEmissions?.performances || [])]
        .filter(({region}) => region === 'GLOBAL')
        .sort((a, b) => a.year - b.year)
        .slice(-3)
        .reduce(
          (combined, current, currentIndex, originalArray) => {
            const categories = [
              ...combined.categories,
              {
                key: current.year,
                label: current.year,
              },
            ];
            const nextItem = originalArray?.[currentIndex + 1];
            const nextItemCategory = `${current.year}${nextItem?.year}`;
            const updatedCategories = !!nextItem
              ? [
                  ...categories,
                  {
                    key: nextItemCategory,
                    label: '',
                  },
                ]
              : categories;

            const entries = [...emissionEntries].reduce(
              (combinedEntries, {entry: entryName, extractFn, dataType}) => {
                const entry = [...combined.entries].find(
                  (existingEntry) => existingEntry.entry === entryName
                );
                if (!entry) return combinedEntries;
                entry[current.year] = {
                  value: extractFn(current),
                  type: dataType,
                };
                if (!!nextItem) {
                  const currentEntryValue = extractFn(current) || null;
                  const nextEntryValue = extractFn(nextItem) || null;
                  entry[nextItemCategory] = {
                    value: differenceInPercentage(
                      currentEntryValue,
                      nextEntryValue
                    ),
                    type: 'trendInPercentage',
                    reverse: true,
                  };
                }
                return [...combinedEntries, entry];
              },
              []
            );

            return {
              categories: updatedCategories,
              entries,
            };
          },
          {
            categories: [
              {
                key: 'entry',
                label: '',
              },
            ],
            entries: [...emissionEntries].map(({entry}) => ({entry})),
          }
        ),
      chart: [...(company?.carbonEmissions?.performances || [])]
        .filter(({region}) => region === 'GLOBAL')
        .sort((a, b) => a.year - b.year)
        .reduce(
          (combined, current) => {
            const categories = [...combined.categories, current.year];

            const entries = [...combined.entries].map((entry) => ({
              ...entry,
              data: [...entry.data, entry.extractFn(current)],
            }));

            return {
              categories,
              entries,
            };
          },
          {
            categories: [],
            entries: [...emissionEntries]
              .filter(({chart}) => !!chart)
              .map(({chart, entry, extractFn}) => ({
                name: entry,
                data: [],
                extractFn,
                ...chart,
              })),
          }
        ),
    },
  };
};

export default parseCarbonEmissionsData;
