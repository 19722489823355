// company components
import VSIModal from '../../components/VSIModal/VSIModal';

// company events
import showVSIModalEvent from '../../events/showVSIModal.event.company';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class VSIContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    company: null,
  };

  componentDidMount() {
    this.props.subscribe(showVSIModalEvent.subscribe(this.show));
  }

  show = ({company}) => {
    if (!company?.VSI) return;
    this.setState({visible: true, company});
  };

  hide = () => {
    this.setState({visible: false});
  };

  render() {
    const {company, visible} = this.state;
    return <VSIModal visible={visible} company={company} onClose={this.hide} />;
  }
}

export default subscriptionHOC(VSIContainer);
