// ant components
import {Table} from 'antd';

// local lib
import getColumns from './lib/getColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const WorkflowsTable = ({loading, workflows}) => (
  <Table
    loading={loading}
    columns={getColumns()}
    dataSource={workflows}
    pagination={false}
    rowKey="id"
    size="small"
    locale={{emptyText: 'No workflows found'}}
  />
);

WorkflowsTable.propTypes = {
  loading: PropTypes.bool,
  workflows: PropTypes.array,
};

export default WorkflowsTable;
