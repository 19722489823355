// local columnGroup
import postStaticColumnGroup from '../columnGroups/postStatic.columnGroup';
import preStaticColumnGroup from '../columnGroups/preStatic.columnGroup';

// table lib
import generateColumnGroup from '../../../../table/lib/generateColumnGroup.lib.table';

const generateLocalColumnGroup = ({
  columns = [],
  preColumns = preStaticColumnGroup,
  postColumns = postStaticColumnGroup,
  dynamicColumns = () => [],
  size = null,
  ...props
}) =>
  generateColumnGroup({
    columns: [...preColumns, ...columns],
    dynamicColumns: (...props) => {
      const dynamicColumnsResult = dynamicColumns(...props);
      return [...dynamicColumnsResult, ...postColumns];
    },
    size,
    ...props,
  });

export default generateLocalColumnGroup;
