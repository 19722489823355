// ant components
import {Table} from 'antd';

// local lib
import getColumns from './lib/getColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const MembersList = ({members}) => (
  <Table
    columns={getColumns()}
    dataSource={members}
    pagination={false}
    rowKey="Description"
    size="small"
    locale={{emptyText: 'No linked members'}}
  />
);

MembersList.propTypes = {
  members: PropTypes.array,
};

export default MembersList;
