// dashboard components
// import DashboardCyberSecuritySection from '../DashboardCyberSecuritySection/DashboardCyberSecuritySection';
import DashboardHaloScoresSection from '../DashboardHaloScoresSection/DashboardHaloScoresSection';
import DashboardTop5Section from '../DashboardTop5Section/DashboardTop5Section';

// dashboard containers
import DashboardSideMenuContainer from '../../containers/DashboardSideMenuContainer/DashboardSideMenuContainer';

// dashboard constants
// import CYBER_SECURITY_DESCRIPTION from '../../constants/cyberSecurityDescription.constant.dashboard';
// import PORTFOLIO_DASHBOARD_DESCRIPTION from '../../constants/portfolioDashboardDescription.constant.dashboard';

// collection containers
import CollectionSelectorContainer from '../../../collection/containers/CollectionSelectorContainer/CollectionSelectorContainer';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// local components
import NumberOfSuppliers from './components/NumberOfSuppliers';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

// resource components
// import Resources from '../../../resource/components/Resources/Resources';

// risk lib
// import convertCyberScoreToGrade from '../../../risk/lib/convertCyberScoreToGrade.lib.risk';

// supplier constants
import HIGH_RISK_SUPPLIERS_DESCRIPTION from '../../../supplier/constants/highRiskSuppliersDescription.constant.supplier';

const DashboardLayout = () => {
  const {collectionScores} = useSelector((state) => state.dashboard);
  const numberOfSuppliers = collectionScores?.SupplierIds?.length;

  return (
    <PageLayout
      title="Dashboard"
      sideContent={({goToSection}) => (
        <DashboardSideMenuContainer goToSection={goToSection} />
      )}
      sections={[
        {
          section: 'Portfolio / Collections',
          title: (
            <span>
              <CollectionSelectorContainer
                size="large"
                style={{width: '300px'}}
              />
              <NumberOfSuppliers>
                {numberOfSuppliers}{' '}
                {numberOfSuppliers === 1 ? 'Supplier' : 'Suppliers'}
              </NumberOfSuppliers>
            </span>
          ),
          // titleTooltip: PORTFOLIO_DASHBOARD_DESCRIPTION,
          component: <DashboardHaloScoresSection />,
        },
        // {
        //   section: 'Cyber Security',
        //   title: 'Cyber Security Factor',
        //   titleTooltip: CYBER_SECURITY_DESCRIPTION,
        //   component: <DashboardCyberSecuritySection />,
        //   secondaryGrade: convertCyberScoreToGrade(
        //     collectionScores?.PortfolioScores?.TechnicalRatingAverage?.Value || 0
        //   ),
        //   secondaryScore:
        //     collectionScores?.PortfolioScores?.TechnicalRatingAverage?.Value ||
        //     0,
        //   tabs: [
        //     {
        //       key: 'blackKiteCompanyDashboard',
        //       label: 'More on Cyber Security',
        //       url: 'https://app.blackkitetech.com/Dashboard/',
        //       openNewTab: true,
        //     },
        //   ],
        // },
        {
          section: 'High Risk Suppliers',
          titleTooltip: HIGH_RISK_SUPPLIERS_DESCRIPTION,
          component: <DashboardTop5Section />,
        },
        // {
        //   section: 'Resource Center',
        //   component: <Resources />,
        // },
      ].filter((section) => !!section)}
    />
  );
};

export default DashboardLayout;
