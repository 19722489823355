// local columns
import complianceScoreColumn from '../columns/complianceScore.column';
import financialScoreColumn from '../columns/financialScore.column';
import operationalScoreColumn from '../columns/operationalScore.column';
import reputationalScoreColumn from '../columns/reputationalScore.column';
import strategicScoreColumn from '../columns/strategicScore.column';

// local lib
import generateColumnGroup from '../lib/generateColumnGroup.lib';

const defaultColumnGroup = generateColumnGroup({
  name: 'Halo Scores',
  columns: [
    operationalScoreColumn,
    financialScoreColumn,
    complianceScoreColumn,
    reputationalScoreColumn,
    strategicScoreColumn,
  ],
});

export default defaultColumnGroup;
