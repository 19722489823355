import styled from 'styled-components';

// layout colors
import whiteColor from '../../../../layout/colors/white.color.layout';

const Container = styled.nav`
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 500;
  padding: 0px 24px 0px 18px;
  ${'' /* box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.05); */}
  background: ${whiteColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Container;
