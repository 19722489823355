// aws api profiles
import api from '../../aws/api/profiles/json.profile.api.aws';

const getCreditReportPdfApi = (supplierId) =>
  new Promise(async (resolve) => {
    try {
      const pdf = await api().get(
        `/data/supplier/${supplierId}/credit-report.pdf`,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
        }
      );

      const reader = new FileReader();
      reader.readAsDataURL(pdf);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      };
    } catch (error) {
      resolve(null);
    }
  });

export default getCreditReportPdfApi;
