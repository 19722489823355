// any components
import {Select} from 'antd';

// collection lib
import getCollectionOptions from '../../lib/getCollectionOptions.lib.collection';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CollectionSelector = ({
  loading,
  onChange,
  value,
  withPortfolio,
  ...props
}) => (
  <Select
    size="small"
    style={{width: '100%'}}
    placeholder="Select a collection"
    value={value}
    onChange={onChange}
    options={getCollectionOptions({withPortfolio})}
    loading={loading}
    {...props}
  />
);

CollectionSelector.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  withPortfolio: PropTypes.bool,
};

export default CollectionSelector;
