// ant components
import {Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// financial components
import LifetimeCreditBalancesChart from '../LifetimeCreditBalancesChart/LifetimeCreditBalancesChart';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const LifetimeCreditBalancesCard = ({currency, valueCategories, entries}) => {
  const columns = generateColumns({currency, valueCategories});

  const chartCategories = [...valueCategories].filter(
    ({key}) => key !== 'entry'
  );
  const chartData = [...entries].map((entry) => ({
    name: entry.entry,
    data: [...chartCategories].map(({key}) => entry?.[key]?.value || null),
  }));

  return (
    <StatementBlock title="Lifetime Credit Balances">
      <Table
        columns={columns.valueCategories}
        dataSource={entries}
        pagination={false}
        rowKey="status"
        size="small"
      />
      <LifetimeCreditBalancesChart
        categories={[...chartCategories].map(({label}) => label)}
        entries={chartData}
      />
    </StatementBlock>
  );
};

LifetimeCreditBalancesCard.propTypes = {
  currency: PropTypes.string,
  valueCategories: PropTypes.array,
  entries: PropTypes.array,
};

export default LifetimeCreditBalancesCard;
