import styled from 'styled-components';

// router
import {Link as RouterLink} from 'react-router-dom';

const Link = styled(RouterLink)`
  display: block;
  text-decoration: none;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: -5px;
  transform: translate(-5px, -4px);

  & i {
    transform: translateY(1px);
  }
`;

export default Link;
