// axios
import axios from 'axios';

const loadLocalJson = async (path) => {
  try {
    const response = await axios.get(path);
    // Local file paths that don't exist still return a rendered html string,
    // so we need to verify that json was returned to determine if a local json file exists
    const isFileExists =
      response?.headers?.['content-type']?.includes('application/json');
    return (isFileExists && response?.data) || null;
  } catch (error) {
    return null;
  }
};

export default loadLocalJson;
