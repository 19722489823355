// react
import React from 'react';

// router
import {useNavigate} from 'react-router-dom';

const withNavigation = (WrappedComponent) =>
  function WithNavigationComponent({...props}) {
    const navigate = useNavigate();
    return <WrappedComponent {...props} navigate={navigate} />;
  };

export default withNavigation;
