// layout colors
import blackColor from '../../../layout/colors/black.color.layout';
import green300Color from '../../../layout/colors/green300.color.layout';
import violet100Color from '../../../layout/colors/violet100.color.layout';

// layout components
import LineScore from '../LineScore/LineScore';

// local components
import Container from './components/Container';
import Divider from './components/Divider';
import Label from './components/Label';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const LabeledLineScore = ({
  children,
  backgroundColor = violet100Color,
  barColor = green300Color,
  maxScore = 100,
  score,
  textColor = blackColor,
}) => (
  <Container color={textColor}>
    <Label>
      <Divider>{children}</Divider>
      <Divider>{score}</Divider>
    </Label>
    <LineScore
      backgroundColor={backgroundColor}
      barColor={barColor}
      maxScore={maxScore}
      score={score}
    />
  </Container>
);

LabeledLineScore.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  barColor: PropTypes.string,
  maxScore: PropTypes.number,
  score: PropTypes.number,
  textColor: PropTypes.string,
};

export default LabeledLineScore;
