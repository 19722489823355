const generateParametersForExplainApi = ({
  frequency_penalty = 0.5, // Number between -2.0 and 2.0. Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim.
  max_tokens = 1024, // The maximum number of tokens to generate in the chat completion.
  messages,

  //Fine Tuned Model Specifically for Financial
  //model = 'ft:gpt-3.5-turbo-1106:halo-ai::8SD9ebvZ',
  //Newest Fine Tuned Model:
  model = 'ft:gpt-3.5-turbo-1106:halo-ai::8IsOhg3F',
  //Older Fine Tuned Model:
  //model = 'ft:gpt-3.5-turbo-0613:halo-ai::7yt8bTxd',
  //ChatGPT 4 Model
  //model = 'ft:gpt-3.5-turbo-1106:halo-ai::9FnLWAX4',

  presence_penalty = 0.5, // Number between -2.0 and 2.0. Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.
  stop = '', // Up to 4 sequences where the API will stop generating further tokens.
  temperature = 0.5, // What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.
  top_p = 0.2, // An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered.
}) => ({
  model,
  messages,
  temperature,
  top_p,
  stop,
  max_tokens,
  presence_penalty,
  frequency_penalty,
});

export default generateParametersForExplainApi;
