const FINANCIAL_HALO_GPT_CONVERSATION = (
  data,
  {privateFinancials = false, publicFinancials = false, publicSections = []}
) => ({
  welcomeMessage: 'Welcome to HaloGPT. What can I help you with?',
  systemQueries: [
    {
      role: 'system',
      content: `You are the world's greatest Financial risk expert who keep your answers concise, no longer than 5 sentences. The year is 2023.
      Verify accuracy by double checking. All financial figures in the dataset must be converted to Millions, Billions, Trillion, Quadrillion, using the rule: amounts < $1 Million stay as is, $1 Million-$999 Million will use Milion, $1 Billion - $999 Billion will use Billion, $1 Trillion- $999 Trillion will use Trillion, and $1 Quadrillion will use Quadrillion, rounding to two decimal places.
      Do not specify dates, only 4 digit years. Perform the analysis in chronological order.
      Double check your analyses to make sure that it is correct. Make sure you denote any increases or decrease in numbers correctly. Pay close attention to peaks, anomilies, large drops, etc. Make sure all numbers are formatted properly.
      Make sure to highlight trends of recent times first before moving on to later years.`,
    },
  ],
  model : 'ft:gpt-3.5-turbo-1106:halo-ai::8SD9ebvZ',
  messages: [
    privateFinancials && {
      message: 'Explain Financials Overview',
      queries: [
        {
          role: 'user',
          content: `In a maximum of 5 sentences, provide a analysis of the company's financials, emphasizing on their potential growth towards their predicted exit class.
          Your analysis must include:
          Growth: Highlight trends in employee count, revenue, and growth rates (CAGR or YoY) from the company's inception to the present, as well as 
          Financial Health: Analyze the company's financial status and future outlook using key indicators such as revenue, funding, and valuation.
          Double check your analyses to make sure that it is correct, especially when formatting numbers. Data: ${JSON.stringify(
            data?.financialsOverview || {}
          )}.`,
        },
      ],
    },
    ...(publicFinancials
      ? [
          publicSections.includes('keyDataAndRatios') && {
            message: 'Explain Key Data and Ratios',
            queries: [
              {
                role: 'user',
                content: `In 5 sentences or less, create an analysis to highlight key trends and any anomalies. Focus your attention to any significant changes or irregular patterns in the data over time.
                All financial figures must be converted to either Millions, Billions, Trillions, or Quadrillions as per the rule: amounts under $1 Million remain as is, $1 Million-$999 Million are labeled 'Million', $1 Billion-$999 Billion as 'Billion', $1 Trillion-$999 Trillion as 'Trillion', and amounts of $1 Quadrillion and above as 'Quadrillion', rounding to two decimal places.
                Double check your analyses to make sure that it is correct especially when formatting numbers. Data: ${JSON.stringify(
                  data?.keyDataAndRatios || {}
                )}.`,
              },
            ],
          },
          publicSections.includes('cashFlowSummary') && {
            message: 'Explain Cash Flow Summary',
            queries: [
              {
                role: 'user',
                content: `Provide a concise, 5-sentence analysis of the cash flow, noting any significant trends or changes. Pay special attention to anomilies or irregular patterns in the data over time. Provide an analysis and potential reasons for either good or bad numbers.
                All financial figures must be converted to either Millions, Billions, Trillions, or Quadrillions as per the rule: amounts under $1 Million remain as is, $1 Million-$999 Million are labeled 'Million', $1 Billion-$999 Billion as 'Billion', $1 Trillion-$999 Trillion as 'Trillion', and amounts of $1 Quadrillion and above as 'Quadrillion', rounding to two decimal places.
                Double check your analyses to make sure that it is correct, especially when formatting numbers.
                Data: ${JSON.stringify(data?.cashFlowSummary || {})}`,
              },
            ],
          },
          publicSections.includes('balanceSheet') && {
            message: 'Explain Balance Sheet',
            queries: [
              {
                role: 'user',
                content: ` In max 5 sentences, analyze the balance sheet data, focusing on key trends and significant changes over the years using chronological data in full format. Pay special attention to significant changes or irregular patterns in the data over time.
                All financial figures must be converted to either Millions, Billions, Trillions, or Quadrillions as per the rule: amounts under $1 Million remain as is, $1 Million-$999 Million are labeled 'Million', $1 Billion-$999 Billion as 'Billion', $1 Trillion-$999 Trillion as 'Trillion', and amounts of $1 Quadrillion and above as 'Quadrillion', rounding to two decimal places.
                Double check your analyses to make sure that it is correct, especially the number formatting.
                Data: ${JSON.stringify(
                  data?.balanceSheet || {}
                )}.
                `,
              },
            ],
          },
          publicSections.includes('liquidityModule') && {
            message: 'Explain Liquidity Module',
            queries: [
              {
                role: 'user',
                content: `In 5 sentences or less, discuss the liquidity module, focusing on financial risk. 
                Highlight significant trends and anomalies, using chronological data in full format. 
                Double check your analyses to make sure that it is correct, especially when formatting numbers.
                All financial figures must be converted to either Millions, Billions, Trillions, or Quadrillions as per the rule: amounts under $1 Million remain as is, $1 Million-$999 Million are labeled 'Million', $1 Billion-$999 Billion as 'Billion', $1 Trillion-$999 Trillion as 'Trillion', and amounts of $1 Quadrillion and above as 'Quadrillion', rounding to two decimal places.
                Data: ${JSON.stringify(data?.liquidityModule || {})}`,
              },
            ],
          },
          publicSections.includes('activityModule') && {
            message: 'Explain Activity Module',
            queries: [
              {
                role: 'user',
                content: `Provide a concise analysis of the activity module in 5 sentences, focusing on financial risk. 
                Double check your analyses to make sure that it is correct, especially when formatting numbers. 
                Verify accuracy by double checking. All financial figures must be converted to either Millions, Billions, Trillions, or Quadrillions as per the rule: amounts under $1 Million remain as is, $1 Million-$999 Million are labeled 'Million', $1 Billion-$999 Billion as 'Billion', $1 Trillion-$999 Trillion as 'Trillion', and amounts of $1 Quadrillion and above as 'Quadrillion', rounding to two decimal places.
                Data: ${JSON.stringify(data?.activityModule || {})}`,
              },
            ],
          },
          publicSections.includes('duPontROA') && {
            message: 'Explain DuPont ROA Analysis',
            queries: [
              {
                role: 'user',
                content: `With a max of 3 sentences only, Interpret the data with a focus on financial risk. Highlight significant trends, changes, or anomalies. Interpret the data chronologically, starting with earlier years.
          Only analyze and discuss data that are present. Double check your analyses to make sure that it is correct.
          Data: ${JSON.stringify(
            data?.duPontROAAnalysis || {}
          )}`,
              },
            ],
          },
        ].filter((gptPrompt) => !!gptPrompt)
      : []),
  ].filter((message) => !!message),
});

export default FINANCIAL_HALO_GPT_CONVERSATION;
