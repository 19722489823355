// ant
import {Menu} from 'antd';

// layout components
import Card from '../../../layout/components/Card/Card';

// local lib
import generateChart from './lib/generateChart.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect, useState, Fragment} from 'react';

// uuid
import {v4} from 'uuid';

const RiskLevelFactorChartCard = ({data = {}}) => {
  const lowPerformingChartId = v4();
  const topPerformingChartId = v4();

  const [category, setCategory] = useState(Object.keys(data)[0] || null);

  useEffect(() => {
    generateChart({
      domId: lowPerformingChartId,
      category,
      dataPoints: [...(data?.[category]?.lowToHigh || [])],
      topPerforming: false,
    });
    generateChart({
      domId: topPerformingChartId,
      category,
      dataPoints: [...(data?.[category]?.highToLow || [])],
      topPerforming: true,
    });
    // eslint-disable-next-line
  }, [data, category]);
  return (
    <Fragment>
      <Card noPadding>
        <Menu
          onClick={({key: newCategory}) => setCategory(newCategory)}
          selectedKeys={[category]}
          mode="horizontal"
          items={Object.keys(data).map((dataKey) => ({
            key: dataKey,
            label: dataKey,
          }))}
          style={{background: 'transparent'}}
        />
      </Card>
      <Card noPadding>
        <div id={lowPerformingChartId} />
      </Card>
      <Card noPadding>
        <div id={topPerformingChartId} />
      </Card>
    </Fragment>
  );
};

RiskLevelFactorChartCard.propTypes = {
  data: PropTypes.object,
};

export default RiskLevelFactorChartCard;
