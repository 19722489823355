// layout colors
import grey400Color from '../../colors/grey400.color.layout';

// loaders
import {ColorRing} from 'react-loader-spinner';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Loader = ({number = '80', color = grey400Color}) => (
  <ColorRing
    visible
    height={number}
    width={number}
    colors={[color, color, color, color, color]}
  />
);

Loader.propTypes = {
  color: PropTypes.string,
  number: PropTypes.string,
};

export default Loader;
