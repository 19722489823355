// haloGpt api
import askHaloGptApi from '../../api/ask.api.haloGpt';

// haloGpt lib
import generateParametersForExplainApi from '../../lib/generateParametersForExplainApi.lib.haloGpt';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

class PrepareHaloGptAnswersContainer extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    queries: PropTypes.object,
  };

  state = {
    answers: {},
    generating: true,
  };

  componentDidMount() {
    this.mounted = true;
    this.generateAnswers();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  generateAnswers = async () => {
    this.setState({generating: true});

    const {queries} = this.props;
    const listOfAnswers = await Promise.all(
      [...(queries?.messages || [])].map((query) =>
        this.generateAnswer({
          query,
          systemQueries: queries?.systemQueries,
          settings: queries?.settings || {},
        })
      )
    );
    const answers = [...listOfAnswers].reduce(
      (combined, {dataKey, answer}) =>
        !!answer ? {...combined, [dataKey]: answer} : combined,
      {}
    );

    if (!this.mounted) return;
    this.setState({answers, generating: false});
  };

  generateAnswer = async ({query, systemQueries, settings = {}}) => {
    const dataKey = query.message;

    try {
      const messageQuery = [...systemQueries, ...query.queries];

      const response = await askHaloGptApi(
        generateParametersForExplainApi({
          messages: messageQuery,
          ...(settings || {}),
        })
      );
      const content = response?.message?.choices?.[0]?.message?.content || '';
      return {dataKey, answer: content};
    } catch (error) {
      return {dataKey, answer: null};
    }
  };

  render() {
    const {children} = this.props;
    const {answers, generating} = this.state;
    return children({answers, generatingAnswers: generating});
  }
}

export default PrepareHaloGptAnswersContainer;
