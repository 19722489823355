// ant
import {Tooltip} from 'antd';

// company components
import HighlightedScore from '../../../components/HighlightedScore/HighlightedScore';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// local tooltips
import pciDssTooltip from './tooltips/pciDss.tooltip.company';

export default generateColumn({
  key: 'pciDss',
  title: <Tooltip title={pciDssTooltip}>PCI-DSS</Tooltip>,
  componentProps: ({company}) => ({
    score: Number(company?.Compliance?.['PCI-DSSRating']),
    suffix: '%',
  }),
  Component: HighlightedScore,
});
