import styled from 'styled-components';

// layout colors
import green400Color from '../../../../../../layout/colors/green400.color.layout';

const ShortDescription = styled.div`
  width: 100%;

  & span {
    margin-left: 6px;
    display: inline-block;
    cursor: pointer;
    color: ${green400Color};
  }
`;

export default ShortDescription;
