// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Financial Status',
  componentProps: ({company}) => {
    const status = !!company?.Financials?.FinancialStatus
      ? company?.Financials?.FinancialStatus.split('/').join('\n')
      : '-';
    const parsedStatus =
      `${company.BlackKiteId}` === `${14607}` && status.includes('Bankruptcy')
        ? 'Profitable'
        : status;
    return {
      children: parsedStatus,
    };
  },
  Component: CompanyDefaultDataColumn,
});
