import styled from 'styled-components';

const Underline = styled.div`
  width: 100%;
  height: 4px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0px;
  background: ${({color}) => color};
  pointer-events: none;
`;

export default Underline;
