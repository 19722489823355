// local components
import AlertsColumns from '../components/AlertsColumns';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Alerts',
  Component: AlertsColumns,
  align: 'left',
});
