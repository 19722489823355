// local components
import Container from './components/Container';
import Date from './components/Date';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FiscalYearTableHeader = ({period, ending}) => (
  <Container>
    <Title>
      {period.includes('LTM') ? 'LTM' : `${period}`.replace('FQ4', 'FY')}
    </Title>
    <Date>{ending}</Date>
  </Container>
);

FiscalYearTableHeader.propTypes = {
  period: PropTypes.string,
  ending: PropTypes.string,
};

export default FiscalYearTableHeader;
