// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';
import CompanyFilterColumn from '../../../components/CompanyFilterColumn/CompanyFilterColumn';

// company filters
import countryFilter from '../../../filters/country.filter.company';

// country components
import Flags from '../../../../country/components/Flags/Flags';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'HQ Country',
  componentProps: ({company}) => ({
    children: (
      <CompanyFilterColumn
        filter={countryFilter.filter}
        filterValue={company?.Firmographic?.HQCountry || null}
      >
        <Flags countries={company?.Firmographic?.HQCountry} fullName />
      </CompanyFilterColumn>
    ),
  }),
  Component: CompanyDefaultDataColumn,
});
