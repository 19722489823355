const getNewsCategories = ({newsArticle}) =>
  [...(newsArticle?.categories || [])]
    .sort((a, b) => b.score - a.score)
    .map((entity) => ({
      id: entity.id,
      label: entity.label,
    }))
    .filter(({label}) => !!label);

export default getNewsCategories;
