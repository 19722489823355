// usa states
import usaStates from 'states-us';

const ISO = [
  ['ci', ["Côte d'Ivoire"]],
  ['gb', ['London', 'Brighton']],
  ['kh', ['Cambodia']],
  ['kr', ['South Korea']],
  ['ly', ['Libyan Arab']],
  ['mk', ['Macedonia']],
  ['nl', ['Netherlands']],
  ['rs', ['Serbia']],
  ['us', [...usaStates].map(({name}) => name)],
  ['global', ['Global']],
];

const getCustomIso = ({countryName, countryIso}) => {
  if (!!countryIso) return countryIso;
  const isoValue =
    [...ISO].find(
      ([iso, countries]) =>
        !!countries.find((country) =>
          countryName.toLowerCase().includes(country.toLowerCase())
        )
    )?.[0] || null;
  return isoValue;
};

export default getCustomIso;
