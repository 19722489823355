// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// layout events
import notImplementedEvent from '../../events/notImplemented.event.layout';

// local components
import Close from './components/Close';
import Container from './components/Container';
import Logo from './components/Logo';
import Text from './components/Text';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class NotImplemented extends Component {
  static propTypes = {subscribe: PropTypes.func};

  static DEFAULT_TEXT = 'Available in fully integrated version';

  state = {
    visible: false,
    text: this.constructor.DEFAULT_TEXT,
  };

  componentDidMount() {
    this.props.subscribe(notImplementedEvent.subscribe(this.show));
  }

  show = ({text = this.constructor.DEFAULT_TEXT} = {}) =>
    this.setState({text, visible: true});

  hide = () => this.setState({visible: false});

  render() {
    const {visible, text} = this.state;
    return (
      <Container visible={visible}>
        <Logo color="white" />
        <Text>{text}</Text>
        <Close onClick={this.hide}>Close</Close>
      </Container>
    );
  }
}

export default subscriptionHOC(NotImplemented);
