// userSettings store
import getUserSettingsService from '../services/getUserSettings.service.userSettings';
import saveUserSettingsService from '../services/saveUserSettings.service.userSettings';

const generateUserSettingsStore = ({domain, defaultSettings = {}}) => ({
  getSettings: () => getUserSettingsService({domain, defaultSettings}),
  saveSettings: (settings) => saveUserSettingsService({domain, settings}),
});

export default generateUserSettingsStore;
