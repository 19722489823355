import styled from 'styled-components';

const MessageRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({rtl}) => (rtl ? 'flex-end' : 'flex-start')};
  gap: 8px;
`;

export default MessageRow;
