// charts
import Highcharts from 'highcharts';

// layout colors
import grey400Color from '../../../layout/colors/grey400.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const SmallTrendChart = ({color, score = 0}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'solidgauge',
        borderWidth: '0px',
        width: 100,
        height: 100,
        marginBottom: -50,
      },

      exporting: {
        enabled: false,
      },

      title: {
        text: '',
        enabled: false,
      },

      pane: {
        // size: '80%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: grey400Color,
          innerRadius: '65%',
          outerRadius: '100%',
          shape: 'arc',
          borderWidth: 0,
        },
      },

      tooltip: {
        enabled: false,
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
        labels: {
          enabled: false,
        },
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
        },
      },

      series: [
        {
          name: '',
          data: [{y: score, color: color}],
          color: color,
          tooltip: {
            enabled: false,
          },
          borderWidth: 0,
        },
      ],
    });
    // eslint-disable-next-line
  }, [color, score]);

  return <div id={id} />;
};

SmallTrendChart.propTypes = {
  color: PropTypes.string,
  score: PropTypes.number,
};

export default SmallTrendChart;
