// ant
import {Tooltip} from 'antd';

// local components
import Attribute from './components/Attribute';
import Attributes from './components/Attributes';

// news lib
import getNewsPublishDate from '../../lib/getPublishDate.lib.news';
import getNewsRelativePublishDate from '../../lib/getRelativePublishDate.lib.news';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const NewsAttributes = ({newsArticle}) => (
  <Attributes>
    <Attribute>
      <i className="mdi mdi-web" /> {newsArticle.source.name}
    </Attribute>
    <Tooltip title={getNewsPublishDate({newsArticle})}>
      <Attribute>
        <i className="mdi mdi-calendar" />{' '}
        {getNewsRelativePublishDate({newsArticle})} ago
      </Attribute>
    </Tooltip>
  </Attributes>
);

NewsAttributes.propTypes = {
  newsArticle: PropTypes.object,
};

export default NewsAttributes;
