// risk lib
import convertScoreToGrade from '../../../../risk/lib/convertScoreToGrade.lib.risk';

const extractConsolidatedData = ({company}) => ({
  categories: ['Innovation Outlook', 'Innovation Velocity', 'Halo Projected'],
  data: [
    {
      name: '',
      data: [
        {
          y:
            company?.Innovation?.TwentyFourMonthsInnovationView
              ?.HaloModeledInnovationoutlook || null,
          grade: convertScoreToGrade(
            company?.Innovation?.TwentyFourMonthsInnovationView
              ?.HaloModeledInnovationoutlook
          ),
        },
        {
          y:
            company?.Innovation?.TwentyFourMonthsInnovationView
              ?.HaloModeledInnovationVelocity || null,
          grade: convertScoreToGrade(
            company?.Innovation?.TwentyFourMonthsInnovationView
              ?.HaloModeledInnovationVelocity
          ),
        },
        {
          y:
            company?.Innovation?.TwentyFourMonthsInnovationView
              ?.HaloModeledInnovationScore || null,
          grade: convertScoreToGrade(
            company?.Innovation?.TwentyFourMonthsInnovationView
              ?.HaloModeledInnovationScore
          ),
        },
      ],
    },
  ],
});

export default extractConsolidatedData;
