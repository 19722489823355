// ant components
import {Modal} from 'antd';
import {message as messageApi} from 'antd';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// error lib
import parseError from '../../../error/lib/parse.lib.error';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// workflow events
import showWorkflowDeleteModalEvent from '../../events/showDeleteModal.event.workflow';

// workflow services
import deleteWorkflowService from '../../services/delete.service.workflow';

class DeleteWorkflowContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    saving: false,
    visible: false,
    workflow: null,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showWorkflowDeleteModalEvent.subscribe(this.show));
  }

  show = ({workflow}) => {
    this.setState({
      visible: true,
      workflow,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  save = async () => {
    const {saving, workflow} = this.state;

    if (saving) return;

    this.setState({saving: true});

    try {
      await deleteWorkflowService({workflow});
      this.setState({saving: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      messageApi.error(message);
      this.setState({saving: false});
    }
  };

  render() {
    const {saving, visible, workflow} = this.state;
    return (
      <Modal
        title="Workflow Editor"
        centered
        open={visible}
        confirmLoading={saving}
        onOk={this.save}
        okText="Delete"
        onCancel={this.hide}
        width="600px"
      >
        Are you sure you want to delete {workflow?.name}?
      </Modal>
    );
  }
}

export default subscriptionHOC(DeleteWorkflowContainer);
