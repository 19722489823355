// ant components
import {Col as Column, Row, Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FinancialLegalFilingsUCCsCard = ({currency, ucc, printView}) => {
  const columns = generateColumns({currency, ...ucc});

  return (
    <Row gutter={[20, 20]}>
      <Column span={24}>
        <StatementBlock title="Summary">
          <Table
            columns={columns.summary}
            dataSource={ucc.summary.entries}
            pagination={false}
            rowKey="total"
            size="small"
          />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Cautionary Uniform Commercial Code (UCC) Filings">
          <Table
            columns={columns.cautionaryUCCs}
            dataSource={ucc.cautionaryUCCs.entries}
            pagination={false}
            rowKey="total"
            size="small"
          />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Uniform Commercial Code (UCC) Filings">
          <Table
            columns={columns.uccs}
            dataSource={ucc.uccs.entries}
            pagination={false}
            rowKey="total"
            size="small"
          />
        </StatementBlock>
      </Column>
    </Row>
  );
};

FinancialLegalFilingsUCCsCard.propTypes = {
  currency: PropTypes.string,
  printView: PropTypes.bool,
  ucc: PropTypes.bool,
};

export default FinancialLegalFilingsUCCsCard;
