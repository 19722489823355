// charts
import Highcharts from 'highcharts';

const generateChart = ({domId, category, dataPoints, topPerforming = true}) => {
  Highcharts.chart(domId, {
    chart: {
      type: 'column',
    },
    title: {
      text: `${category} Risk Area - ${
        topPerforming ? 'Top' : 'Bottom'
      } Performing Factors by Weighted Score`,
    },
    plotOptions: {
      series: {
        grouping: false,
        borderWidth: 0,
        dataLabels: {
          format: '{point.y:,.0f}',
        },
        tooltip: {
          pointFormat: '{point.y:,.0f}',
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
    },
    xAxis: {
      type: 'category',
      title: {
        text: 'Halo Factors',
      },
      labels: {
        useHTML: true,
        animate: true,
        style: {
          textAlign: 'center',
        },
      },
      reversed: !topPerforming,
    },
    yAxis: [
      {
        title: {
          text: 'Risk Level',
        },
        showFirstLabel: false,
      },
    ],
    series: [
      {
        name: '2020',
        dataSorting: {
          enabled: true,
          matchByName: true,
        },
        dataLabels: [
          {
            enabled: true,
            inside: true,
            style: {
              fontSize: '16px',
            },
          },
        ],
        data: dataPoints,
      },
    ],
  });
};

export default generateChart;
