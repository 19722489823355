// workflow events
import workflowUpdatedEvent from '../events/updated.event.workflow';

// workflow repositories
import updateWorkflowRepository from '../repositories/update.repository.workflow';

// workflow validators
import updateWorkflowValidator from '../validators/update.validator.workflow';

const updateWorkflowService = async ({
  tenantWide,
  updateWorkflow = updateWorkflowRepository,
  validateWorkflow = updateWorkflowValidator,
  workflow,
  workflowUpdated = workflowUpdatedEvent.publish,
}) => {
  validateWorkflow({workflow});
  workflow.emails = tenantWide ? [] : workflow.emails;
  workflow.settings.recipients = tenantWide ? [] : workflow.settings.recipients;
  const updatedWorkflow = await updateWorkflow({workflow});
  workflowUpdated({workflow: updatedWorkflow});
  return updatedWorkflow;
};

export default updateWorkflowService;
