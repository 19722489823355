import styled from 'styled-components';

// layout colors
import green300Color from '../../../../layout/colors/green300.color.layout';
import grey200Color from '../../../../layout/colors/grey200.color.layout';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  background: ${grey200Color};

  [data-amplify-authenticator] {
    --amplify-components-authenticator-router-border-style: none;
    --amplify-components-button-primary-background-color: ${green300Color};
    --amplify-components-button-primary-active-background-color: #9ce2ac;
    --amplify-components-button-primary-disabled-background-color: #39ac55;
    --amplify-components-button-primary-focus-background-color: #9ce2ac;
    --amplify-components-button-primary-hover-background-color: #39ac55;
    --amplify-components-tabs-item-hover-color: ${green300Color};
    --amplify-components-tabs-item-active-color: ${green300Color};
    --amplify-components-tabs-item-active-border-color: ${green300Color};
    --amplify-components-tabs-item-focus-color: ${green300Color};
  }

  [data-amplify-authenticator] [data-amplify-router] {
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  button.amplify-button--link {
    color: ${green300Color};
  }

  .amplify-textfield {
    --amplify-components-fieldcontrol-border-color: #c4c4c4;
    --amplify-components-fieldcontrol-focus-border-color: ${green300Color};
    --amplify-components-fieldcontrol-focus-box-shadow: none;
  }

  .amplify-field__show-password,
  .amplify-field__show-password:active,
  .amplify-field__show-password:focus {
    border-color: #c4c4c4 #c4c4c4 #c4c4c4 transparent;
    box-shadow: none;
  }

  .amplify-button--primary:active,
  .amplify-button--primary:focus,
  .amplify-button--default:active,
  .amplify-button--default:focus,
  .amplify-button--link:active,
  .amplify-button--link:focus {
    box-shadow: none;
  }

  .amplify-button--default {
    background-color: #6c757d;
    color: white;
  }

  .amplify-button--default:hover {
    background-color: #5c636a;
    border-color: #5c636a;
  }

  .amplify-button--default:focus {
    background-color: #828a91;
    border-color: #828a91;
  }
`;

export default Container;
