// propTypes
import PropTypes from 'prop-types';

const PercentColumn = ({category, columnIndex, entry, entryIndex}) => {
  const value = entry[category];
  return `${value}%`;
};

PercentColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default PercentColumn;
