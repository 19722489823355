// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralDashboardDataAction from './actions/setGeneralData.action.redux.dashboard';

// style redux constants
import DEFAULT_DASHBOARD_REDUX_STATE from './constants/defaultState.constant.redux.dashboard';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: DEFAULT_DASHBOARD_REDUX_STATE,
  reducers: {
    setGeneralDashboardData: setGeneralDashboardDataAction,
  },
});

export const {setGeneralDashboardData} = dashboardSlice.actions;

export default dashboardSlice.reducer;
