const extractAwsTokenData = (data) =>
  !!data?.signInUserSession
    ? {
        accessToken: {
          token: data?.signInUserSession?.accessToken?.jwtToken || null,
          data: data?.signInUserSession?.accessToken?.payload || null,
        },
        idToken: {
          token: data?.signInUserSession?.idToken?.jwtToken || null,
          data: data?.signInUserSession?.idToken?.payload || null,
        },
        refreshToken: data?.signInUserSession?.refreshToken?.token || null,
      }
    : !!data?.accessToken
    ? {
        accessToken: {
          token: data?.accessToken?.jwtToken || null,
          data: data?.accessToken?.payload || null,
        },
        idToken: {
          token: data?.idToken?.jwtToken || null,
          data: data?.idToken?.payload || null,
        },
        refreshToken: data?.refreshToken?.token || null,
      }
    : null;

export default extractAwsTokenData;
