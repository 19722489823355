// number lib
import millionToBillion from '../../../../number/lib/millionToBillion.lib.number';

const extractPrivateRevenueData = ({company}) => {
  const revenue = company?.PrivateFinancialData?.Revenue || 0;
  const revenueGrowthPercentage =
    company?.PrivateFinancialData?.RevenueGrowthPercentage || 0;
  const growthRatePercentile =
    company?.PrivateFinancialData?.GrowthRatePercentile || 0;
  const growthRatePercentileSuffix =
    growthRatePercentile === 0
      ? ''
      : growthRatePercentile === 1
      ? 'st'
      : growthRatePercentile === 2
      ? 'nd'
      : growthRatePercentile === 3
      ? 'rd'
      : 'th';
  return [
    {
      label: 'Revenue',
      value: revenue,
      max: revenue,
      formattedValue: millionToBillion({number: revenue}),
      suffix: 'M',
    },
    {
      label: 'Revenue Growth Percentage',
      value: revenueGrowthPercentage,
      max: 100,
      formattedValue: `${revenueGrowthPercentage}%`,
      suffix: '%',
    },
    {
      label: 'Growth Rate Percentile',
      value: growthRatePercentile,
      max: 100,
      formattedValue: `${growthRatePercentile}${growthRatePercentileSuffix}`,
      suffix:
        growthRatePercentile === 0
          ? ''
          : growthRatePercentile === 1
          ? 'st'
          : growthRatePercentile === 2
          ? 'nd'
          : growthRatePercentile === 3
          ? 'rd'
          : 'th',
    },
  ];
};

export default extractPrivateRevenueData;
