import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  top: 72px;
  right: 10px;
  z-index: 500;
`;

export default Container;
