// date formatters
import isoDateFormatter from '../../date/formatters/formatISO.formatter.date';

const getCreditSafeAlerts = ({companyId, companyName, alerts = []}) => {
  const areLatestAlerts = !alerts?.data?.length;
  const creditSafeAlerts = (areLatestAlerts ? alerts : alerts?.data) || [];
  return [...creditSafeAlerts].map((creditSafeAlert) =>
    areLatestAlerts
      ? {
          CurrentValue: creditSafeAlert.oldValue,
          Period: 0,
          PreviousValue: creditSafeAlert.newValue,
          Threshold: 0,
          Type: `CreditSafe_${creditSafeAlert.localEventCode}`,
          id: `${creditSafeAlert.eventId}`,
          message: `${creditSafeAlert.localEventCode} changed from ${
            creditSafeAlert.oldValue
          } to ${creditSafeAlert.newValue} on ${isoDateFormatter({
            date: creditSafeAlert.eventDate,
            dateFormat: 'M/d/yy',
          })}`,
          supplierId: companyId,
          supplierName: companyName,
        }
      : {
          CurrentValue: creditSafeAlert.oldValue,
          Period: 0,
          PreviousValue: creditSafeAlert.newValue,
          Threshold: 0,
          Type: `CreditSafe_${creditSafeAlert.globalEventCode}`,
          id: `${creditSafeAlert.eventId}`,
          message: `${creditSafeAlert.ruleName} changed from ${
            creditSafeAlert.oldValue
          } to ${creditSafeAlert.newValue} on ${isoDateFormatter({
            date: creditSafeAlert.eventDate,
            dateFormat: 'M/d/yy',
          })}`,
          supplierId: companyId,
          supplierName: companyName,
        }
  );
};

export default getCreditSafeAlerts;
