import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal, Upload as AntUpload} from 'antd';
import useApi from '../hooks/use-api';

const UploadContainer = styled.div``;

const Upload = ({
  arrangement,
  answer,
  action = 'Upload',
  okText = 'OK',
  onDone,
}) => {
  const api = useApi();

  const {uploadType, questionId, section} = answer;
  const [file, setFile] = useState();

  const showModalNLP = (answers, filename) => {
    Modal.success({
      title: `File '${filename}' was processed`,
      content: `${Object.values(answers).reduce(
        (count, answersBySection) =>
          count + Object.values(answersBySection).length,
        0
      )} answers found`,
      width: 450,
      zIndex: 1010,
      okText: okText,
      destroyOnClose: true,
      onOk: onDone,
    });
  };

  useEffect(() => {
    const getAnswersNLP = async (filename) => {
      const nlpParentKey = `${section}-${questionId}`;
      const answersNLP = api.getAnswersNLP(uploadType);
      if (answersNLP) {
        Object.keys(answersNLP).forEach((section) => {
          answersNLP[section].forEach((answer) => {
            if (answer.responses?.length) {
              const nlpChildKey = `${section}-${answer.id}`;
              arrangement.answers[nlpChildKey] = {
                nlp: nlpParentKey,
                isNLP: true,
                questionId: answer.id,
                questionText: answer.text,
                responseId: answer.responses[0].id,
                responseText: answer.responses[0].text,
                responseAction: answer.responses[0].action,
                responseTriggers: answer.responses[0].triggers,
                section,
              };
            }
          });
        });
      }
      answer.responseText = filename;
      arrangement.answers[nlpParentKey] = answer;
      arrangement.uploads[section] = arrangement.uploads[section] || {};
      arrangement.uploads[section][uploadType] = answer.responseText;
      arrangement.save();
      if (answersNLP) showModalNLP(answersNLP, filename);
      else onDone();
    };
    if (file) {
      getAnswersNLP(file.name);
      setFile();
    }
    // eslint-disable-next-line
  }, [file]);

  return (
    <UploadContainer>
      <AntUpload
        fileList={[]}
        onChange={(info) => {
          if (info.file) setFile(info.file);
        }}
      >{`${action} ${uploadType}`}</AntUpload>
    </UploadContainer>
  );
};

export default Upload;
