const sortByString = ({
  valueA,
  valueB,
  ascending = true,
  caseInsensitive = true,
}) => {
  const a = caseInsensitive ? valueA?.toLowerCase?.() : valueA;
  const b = caseInsensitive ? valueB?.toLowerCase?.() : valueB;

  if (a < b) return ascending ? -1 : 1;
  if (a > b) return ascending ? 1 : -1;
  return 0;
};

export default sortByString;
