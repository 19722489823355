// collection lib
import getAllCollectionSuppliers from '../../collection/lib/getAllCollectionSuppliers.lib.collection';

// redux store
import reduxStore from '../../redux/store';

const isSupplierAvailable = ({
  supplier,
  supplierIds = getAllCollectionSuppliers(),
  supplierScores = reduxStore.getState()?.dashboard?.tenantData
    ?.SupplierHaloScores,
}) =>
  (supplierIds.includes(Number(supplier.BlackKiteID)) ||
    supplierIds.includes(supplier.CompanyId)) &&
  !!supplierScores?.[supplier.CompanyId];

export default isSupplierAvailable;
