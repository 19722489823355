// layout color
import green100Color from '../../layout/colors/green100.color.layout';
import red100Color from '../../layout/colors/red100.color.layout';
import yellow100Color from '../../layout/colors/yellow100.color.layout';

// helpers
const LEVELS = [
  [55, red100Color],
  [70, yellow100Color],
  [101, green100Color],
];

const getSecondaryScoreColor = (score) => {
  const minLevel = [...LEVELS][0];
  const maxLevel = [...LEVELS].slice(-1);
  return score > maxLevel[0]
    ? maxLevel[1]
    : [...LEVELS].find(([maxScore]) => score < maxScore)?.[1] || minLevel[1];
};

export default getSecondaryScoreColor;
