import activeEngagements from '../columns/activeEngagements.column';
import activeInvestors from '../columns/activeInvestors.column';
import alsoKnownAs from '../columns/alsoKnownAs.column';
import analystScore from '../columns/analystScore.column';
import businessUnit from '../columns/businessUnit.column';
import caiq from '../columns/caiq.column';
import cmmc2 from '../columns/cmmc2.column';
// import complianceScore from '../columns/complianceScore.column';
import countriesOfOperation from '../columns/countriesOfOperation.column';
import country from '../columns/country.column';
import creditHealthmark from '../columns/creditHealthmark.column';
import creditLimit from '../columns/creditLimit.column';
import creditOutlook from '../columns/creditOutlook.column';
import creditRating from '../columns/creditRating.column';
import creditRecommendation from '../columns/creditRecommendation.column';
import creditRiskLevel from '../columns/creditRiskLevel.column';
import creditRiskScore from '../columns/creditRiskScore.column';
import creditScore from '../columns/creditScore.column';
import creditTrend from '../columns/creditTrend.column';
import customerSentimentFactorRating from '../columns/customerSentimentFactorRating.column';
// import cyberAlerts from '../columns/cyberAlerts.column';
// import cyberSecurityGrade from '../columns/cyberSecurityGrade.column';
// import cyberSecurityRating from '../columns/cyberSecurityRating.column';
import dataAxleGroup from '../columns/dataAxleGroup.column';
// import dataBreachIndex from '../columns/dataBreachIndex.column';
import diversity from '../columns/diversity.column';
import dueDiligencePercentComplete from '../columns/dueDiligencePercentComplete.column';
import duns from '../columns/duns.column';
import ebitda from '../columns/ebitda.column';
import economicFactorRating from '../columns/economicFactorRating.column';
import employeeSentimentFactorRating from '../columns/employeeSentimentFactorRating.column';
import enterpriseValue from '../columns/enterpriseValue.column';
import facebook from '../columns/facebook.column';
import fhr from '../columns/fhr.column';
// import financialImpact from '../columns/financialImpact.column';
import financialScore from '../columns/financialScore.column';
import financialStatus from '../columns/financialStatus.column';
import fiscalPeriod from '../columns/fiscalPeriod.column';
import gdpr from '../columns/gdpr.column';
import geopoliticalFactorRating from '../columns/geopoliticalFactorRating.column';
// import haloComplianceFactorRating from '../columns/haloComplianceFactorRating.column';
import haloEsgFactorRating from '../columns/haloEsgFactorRating.column';
import haloFinancialFactorRating from '../columns/haloFinancialFactorRating.column';
import haloGovernanceFactorRating from '../columns/haloGovernanceFactorRating.column';
import hipaa from '../columns/hipaa.column';
import hqAddress from '../columns/hqAddress.column';
import hqCountry from '../columns/hqCountry.column';
import hqState from '../columns/hqState.column';
import incorporationDate from '../columns/incorporationDate.column';
import inherentRisk from '../columns/inherentRisk.column';
import instagram from '../columns/instagram.column';
import ipoDate from '../columns/ipoDate.column';
import iso27001 from '../columns/iso27001.column';
import legalName from '../columns/legalName.column';
import lei from '../columns/lei.column';
import linkedIn from '../columns/linkedIn.column';
import longDescription from '../columns/longDescription.column';
import mediaSentimentFactorRating from '../columns/mediaSentimentFactorRating.column';
import naceCode from '../columns/naceCode.column';
import naceNaming from '../columns/naceNaming.column';
import naicsCodes from '../columns/naicsCodes.column';
import netDebt from '../columns/netDebt.column';
import nist80053 from '../columns/nist80053.column';
import nist800171 from '../columns/nist800171.column';
import nistCsf from '../columns/nistCsf.column';
import numberOfEmployees from '../columns/numberOfEmployees.column';
import numberOfLinkedSpecialInterestEntities from '../columns/numberOfLinkedSpecialInterestEntities.column';
import numberOfPoliticallyExposedPersons from '../columns/numberOfPoliticallyExposedPersons.column';
import numberOfRelativesAndCloseAssociates from '../columns/numberOfRelativesAndCloseAssociates.column';
import numberOfSpecialInterestPersons from '../columns/numberOfSpecialInterestPersons.column';
import operatesInSanctionedCountry from '../columns/operatesInSanctionedCountry.column';
import operationalScore from '../columns/operationalScore.column';
import overallEnvironmentalImpact from '../columns/overallEnvironmentalImpact.column';
import overallGovernanceImpact from '../columns/overallGovernanceImpact.column';
import overallSocialImpact from '../columns/overallSocialImpact.column';
import pciDss from '../columns/pciDss.column';
import phone from '../columns/phone.column';
import primaryIndustry from '../columns/primaryIndustry.column';
// import ransomwareSusceptibilityIndex from '../columns/ransomwareSusceptibilityIndex.column';
import rapidRatingGroup from '../columns/rapidRatingGroup.column';
import reputationalScore from '../columns/reputationalScore.column';
import residualRisk from '../columns/residualRisk.column';
import resilienceFactorRating from '../columns/resilienceFactorRating.column';
import revenueGrowth from '../columns/revenueGrowth.column';
import rgRiskLevel from '../columns/rgRiskLevel.column';
import riskGaugeGroup from '../columns/riskGaugeGroup.column';
import riskGaugeRating from '../columns/riskGaugeRating.column';
import riskQualityFactorRating from '../columns/riskQualityFactorRating.column';
import rrFinancialHealthRating from '../columns/rrFinancialHealthRating.column';
import sa2022 from '../columns/sa2022.column';
import sanctionedCountry from '../columns/sanctionedCountry.column';
import serviceAreas from '../columns/serviceAreas.column';
import shortDescription from '../columns/shortDescription.column';
import sicCode from '../columns/sicCode.column';
import socialMedia from '../columns/socialMedia.column';
import spAnalystsRecommendations from '../columns/spAnalystsRecommendations.column';
import spConsensusScores from '../columns/spConsensusScores.column';
import specialInterestEntity from '../columns/specialInterestEntity.column';
import spGroup from '../columns/spGroup.column';
import spRating from '../columns/spRating.column';
import strategicScore from '../columns/strategicScore.column';
import supplyChainFactorRating from '../columns/supplyChainFactorRating.column';
import taxId from '../columns/taxId.column';
import totalRelationshipValue from '../columns/totalRelationshipValue.column';
import twitter from '../columns/twitter.column';
import ultimateParentCompany from '../columns/ultimateParentCompany.column';
import webAddress from '../columns/webAddress.column';
import youtube from '../columns/youtube.column';

// tenant lib
import generateCustomTenantDataColumns from '../../../../tenant/lib/generateCustomDataColumns.lib.tenant';

const getAllAvailableColumns = ({
  suppliers = [],
  attachCustomKey = false,
} = {}) => [
  activeEngagements,
  activeInvestors,
  alsoKnownAs,
  analystScore,
  businessUnit,
  caiq,
  cmmc2,
  // complianceScore,
  countriesOfOperation,
  country,
  creditHealthmark,
  creditLimit,
  creditOutlook,
  creditRating,
  creditRecommendation,
  creditRiskLevel,
  creditRiskScore,
  creditScore,
  creditTrend,
  customerSentimentFactorRating,
  // cyberAlerts,
  // cyberSecurityGrade,
  // cyberSecurityRating,
  dataAxleGroup,
  // dataBreachIndex,
  diversity,
  dueDiligencePercentComplete,
  duns,
  ebitda,
  economicFactorRating,
  employeeSentimentFactorRating,
  enterpriseValue,
  facebook,
  fhr,
  // financialImpact,
  financialScore,
  financialStatus,
  fiscalPeriod,
  gdpr,
  geopoliticalFactorRating,
  // haloComplianceFactorRating,
  haloEsgFactorRating,
  haloFinancialFactorRating,
  haloGovernanceFactorRating,
  hipaa,
  hqAddress,
  hqCountry,
  hqState,
  incorporationDate,
  inherentRisk,
  instagram,
  ipoDate,
  iso27001,
  legalName,
  lei,
  linkedIn,
  longDescription,
  mediaSentimentFactorRating,
  naceCode,
  naceNaming,
  naicsCodes,
  netDebt,
  nist80053,
  nist800171,
  nistCsf,
  numberOfEmployees,
  numberOfLinkedSpecialInterestEntities,
  numberOfPoliticallyExposedPersons,
  numberOfRelativesAndCloseAssociates,
  numberOfSpecialInterestPersons,
  operatesInSanctionedCountry,
  operationalScore,
  overallEnvironmentalImpact,
  overallGovernanceImpact,
  overallSocialImpact,
  pciDss,
  phone,
  primaryIndustry,
  // ransomwareSusceptibilityIndex,
  rapidRatingGroup,
  reputationalScore,
  residualRisk,
  resilienceFactorRating,
  revenueGrowth,
  rgRiskLevel,
  riskGaugeGroup,
  riskGaugeRating,
  riskQualityFactorRating,
  rrFinancialHealthRating,
  sa2022,
  sanctionedCountry,
  serviceAreas,
  shortDescription,
  sicCode,
  socialMedia,
  spAnalystsRecommendations,
  spConsensusScores,
  specialInterestEntity,
  spGroup,
  spRating,
  strategicScore,
  supplyChainFactorRating,
  taxId,
  totalRelationshipValue,
  twitter,
  ultimateParentCompany,
  webAddress,
  youtube,

  ...generateCustomTenantDataColumns({
    companies: suppliers,
    attachCustomKey,
  }),
];

export default getAllAvailableColumns;
