const storeCollectionAction = (
  state,
  {payload: {collection, collectionData, collections = []} = {}} = {}
) => {
  const {collectionsData, loadedCollections} = state;

  const updatedCollectionsData = !!collection
    ? {
        ...collectionsData,
        [collection?.id]: collectionData,
      }
    : {
        ...collectionsData,
      };
  const updatedLoadedCollections =
    !!collection && !loadedCollections.includes(collection.id)
      ? [...loadedCollections, collection.id]
      : loadedCollections;
  const newState = !!collection
    ? {
        ...state,
        collection,
        collectionsData: updatedCollectionsData,
        loadedCollections: updatedLoadedCollections,
      }
    : {
        ...state,
        collectionsData: updatedCollectionsData,
        loadedCollections: updatedLoadedCollections,
      };

  return !!collections.length ? {...newState, collections} : newState;
};

export default storeCollectionAction;
