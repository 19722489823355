// data lib
import getLatestDataObject from './getLatestDataObject.lib.data';

const prepareData = ({scores}) => {
  const parsedScores = Array.isArray(scores) ? scores : Object.values(scores);

  return {
    historicHaloScores: parsedScores,
    haloScores: getLatestDataObject(parsedScores),
  };
};

export default prepareData;
