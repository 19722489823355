// number lib
import currencyFormat from '../../../../number/lib/currency.lib.number';

// propTypes
import PropTypes from 'prop-types';

const AmountInMillionsColumn = ({category, columnIndex, entry, entryIndex}) => {
  const value = entry[category];
  return !!value ? `${currencyFormat(value)}M` : '';
};

AmountInMillionsColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default AmountInMillionsColumn;
