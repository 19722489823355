// ant components
import {Table} from 'antd';

// local lib
import getColumns from './lib/getColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const LinkedCompaniesList = ({companies}) => (
  <Table
    columns={getColumns()}
    dataSource={companies}
    pagination={false}
    rowKey="Description"
    size="small"
    locale={{emptyText: 'No linked companies'}}
  />
);

LinkedCompaniesList.propTypes = {
  companies: PropTypes.array,
};

export default LinkedCompaniesList;
