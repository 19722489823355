// propTypes
import PropTypes from 'prop-types';

const BusinessPartnersColumn = ({category, columnIndex, entry, entryIndex}) => {
  const value = entry[category];
  return !!value?.length ? value.map(({orgName}) => orgName).join(', ') : '-';
};

BusinessPartnersColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default BusinessPartnersColumn;
