// cyberSecurity constants
import COMPLIANCE_FACTOR_DESCRIPTIONS from '../../../../compliance/constants/complianceFactorsDescriptions.constant.compliance';

// risk lib
import getGradeColor from '../../../../risk/lib/getGradeColor.lib.risk';
import convertCyberScoreToGrade from '../../../../risk/lib/convertCyberScoreToGrade.lib.risk';

const extractComplianceData = ({company}) =>
  (company?.CyberSecurity?.Compliance?.Standards || []).map(
    (complianceEntry) => ({
      name: complianceEntry.displayName,
      standard: complianceEntry.Standard,
      y: complianceEntry.Rating || 0,
      score: complianceEntry.Rating || 0,
      completeness: complianceEntry.Completeness || 0,
      confidence: complianceEntry.Confidence || 0,
      color: getGradeColor(convertCyberScoreToGrade(complianceEntry.Rating)),
      shortDescription:
        COMPLIANCE_FACTOR_DESCRIPTIONS?.[complianceEntry.Standard] || '',
      compliance: {
        ...complianceEntry,
        shortDescription:
          COMPLIANCE_FACTOR_DESCRIPTIONS?.[complianceEntry.Standard] || '',
      },
    })
  );

export default extractComplianceData;
