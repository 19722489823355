// app components
import App from './app/components/App/App';

// app lib
import isProduction from './app/lib/isProduction.lib.app';

// aws components
import {Authenticator} from '@aws-amplify/ui-react';

// aws styles
import '@aws-amplify/ui-react/styles.css';

// charts
import './chart/lib/setupHighcharts.lib.chart';

// country
import '/node_modules/flag-icons/css/flag-icons.min.css';

// intercom lib
import setupIntercomService from './intercom/service/setup.service.intercom';

// posthog components
import {PostHogProvider} from 'posthog-js/react';

// posthog services
import initializePosthogService from './posthog/services/initialize.service.posthog';

// react
import React from 'react';
import ReactDOM from 'react-dom/client';

// redux
import {Provider as ReduxProvider} from 'react-redux';

// redux stores
import reduxStore from './redux/store';

// sentry services
import initializeSentryService from './sentry/services/initialize.service.sentry';

// setup
const posthog = isProduction() && initializePosthogService();
initializeSentryService();

// setup intercom
setupIntercomService();

const AppWrapper = () => {
  return (
    <Authenticator.Provider>
      <ReduxProvider store={reduxStore}>
        <PostHogProvider client={posthog}>
          <App />
        </PostHogProvider>
      </ReduxProvider>
    </Authenticator.Provider>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(<AppWrapper />);
