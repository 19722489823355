// collection components
import CollectionSelector from '../../components/CollectionSelector/CollectionSelector';

// collection events
import collectionSelectedEvent from '../../events/selected.event.collection';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class CollectionSelectorContainer extends Component {
  static propTypes = {
    collection: PropTypes.object,
    collections: PropTypes.array,
    loadingCollection: PropTypes.bool,
  };

  change = (collectionId) => {
    const {collections} = this.props;
    const collection = [...collections].find(({id}) => id === collectionId);
    if (!collection) return;
    collectionSelectedEvent.publish(collection);
  };

  render() {
    const {collection, loadingCollection, ...props} = this.props;
    return (
      <CollectionSelector
        loading={loadingCollection}
        onChange={this.change}
        value={collection?.id}
        withPortfolio
        {...props}
      />
    );
  }
}

export default connect((state) => ({
  collection: state.collection.collection,
  collections: state.collection.collections,
  loadingCollection: state.collection.loadingCollection,
}))(CollectionSelectorContainer);
