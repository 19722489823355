// api constants
import LOCAL_BACKEND_URL from '../../../api/constants/localBackendUrl.constant.api';

// axios
import axios from 'axios';

// auth stores
import authStore from '../../../auth/stores/store.auth';

// aws services
import refreshAwsTokenService from '../../services/refreshToken.service.aws';

// tenant stores
import tenantStore from '../../../tenant/stores/store.tenant';

const awsJsonProfile = ({
  authorize = true,
  baseURL = LOCAL_BACKEND_URL() || tenantStore.getData()?.tenant?.apiURL,
  token = authStore.getData()?.token?.idToken?.token,
} = {}) => {
  const api = axios.create({
    baseURL,
  });
  if (authorize && !!token)
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  api.interceptors.response.use(
    (response) => response?.data || null,
    async (error) => {
      try {
        const config = error?.config;

        if (
          (error?.response?.status === 401 || error?.code === 'ERR_NETWORK') &&
          !config?.sent
        ) {
          config.sent = true;

          const updatedToken = await refreshAwsTokenService();

          if (!updatedToken) return Promise.reject(error);

          config.headers = {
            ...config.headers,
            authorization: `Bearer ${updatedToken?.idToken?.token}`,
          };

          return api(config);
        }

        return Promise.reject(error);
      } catch (executionError) {
        return Promise.reject(executionError);
      }
    }
  );
  return api;
};

export default awsJsonProfile;
