// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Web Address',
  componentProps: ({company}) => ({
    children: !!company?.Firmographic?.WebAddress ? (
      <a
        href={`https://${company?.Firmographic?.WebAddress}`}
        target="_blank"
        rel="noreferrer"
      >
        {company?.Firmographic?.WebAddress}
      </a>
    ) : (
      '-'
    ),
  }),
  Component: CompanyDefaultDataColumn,
});
