const COMPLIANCE_FACTORS_DESCRIPTIONS = {
  CAIQ: "This printable spreadsheet of yes/no questions reflects CSA's Cloud Controls Matrix (CCM) cybersecurity controls strategy. IaaS, PaaS, and SaaS CSPs may document security using CAIQ. Customers may assess CSP cloud security.",
  CCPA: "The CCPA protects Californians' data privacy. The rule requires firms to tell customers about how their data is used and offers them more choice over data sharing.",
  'CMMC 2.0':
    'Federal Contract Information (FCI) and Controlled Unclassified Information (CUI) are protected under the Defense Industrial Base (DIB) cybersecurity standard Cybersecurity Maturity Model Certification (CMMC) (CUI). The DOD, UARCs, FFRDCs, and DIB sector created the CMMC framework.',
  CSF: 'The NIST Cybersecurity Framework helps businesses identify hazards. "Core," "Profile," and "Tiers" form the framework. 1) "Framework Core" incorporates cybersecurity activities, outcomes, and references. 2) The "Framework Implementation Tiers" show partners how a firm views cybersecurity risk and how sophisticated its management plan is. 3) An organization\'s "Framework Profile" includes category and subcategory results.',
  GDPR: 'The EU Parliament, Council, and Commission established the GDPR (Regulation (EU) 2016/679) to strengthen and unify data protection for all EU people (EU). It addresses EU-exported personal data. The GDPR aims to give citizens control over their personal data and simplify international business law by harmonising EU rules. It does not need national governments to enact enabling legislation like a directive.',
  HIPAA:
    'HIPAA protects medical data. HIPAA requires health care providers, organisations, and their business associates to establish and implement systems to safeguard PHI when it is transferred, received, retained, or communicated. PHI—paper, oral, electronic—is affected. Business health information only. HIPAA Title I safeguards health insurance for former workers and their families. 2) Title II of HIPAA, the Administrative Simplification (AS) provisions, establishes national standards for electronic health care transactions and national IDs for providers, health insurance organisations, and employers. Title III controls pre-tax medical spending accounts. Title IV regulates group insurance. Title V handles company-owned life insurance.',
  ISO27001:
    'ISO/IEC 27001, part of the ISO/IEC 27000 family of standards, was last updated in September 2013. ISO/IEC JTC 1/SC 27 publishes it. ISO/IEC 27001 requires a management system to handle information security. A recognised certification authority can certify organisations that satisfy the standards after an audit.',
  'NIST 800-171':
    'Protecting Controlled Unclassified Information in Nonfederal Systems and Organizations',
  'NIST 800-53 R5':
    'NIST Special Publication 800-53 governs federal information system security except national security. National Institute of Standards and Technology publishes it. Federal agencies use NIST standards, guidelines, and other resources to implement the Federal Information Security Management Act of 2002 (FISMA) and manage cost-effective information security operations. Developmental information systems must conform when launched.',
  'NY DFS':
    'Covered financial institutions must comply with the NYDFS Cybersecurity Rule (23 NYCRR 500). Covered institutions must assess their cybersecurity risks and develop plans to proactively address them. The NYDFS Cybersecurity Regulation allowed firms four phases to establish stronger policies and procedures.',
  'PCI-DSS':
    'Merchants and service providers must safeguard cardholder data according to the PCI DSS. MasterCard, American Express, VISA, JCB, and Discover founded the PCI Security Standard Council. These rules safeguard cardholder data. PCI DSS compliance is necessary for secure card transactions.',
  SA22: 'SIG analyses a service provider\'s 18 risk control areas, or "domains," to assess security risk management. Our Membership designed it to help service providers aggregate entire risk domain information in one document.',
};

export default COMPLIANCE_FACTORS_DESCRIPTIONS;
