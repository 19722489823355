// local components
import DirectionColumn from '../components/DirectionColumn';
import HighlightedColumn from '../components/HighlightedColumn';
import PercentColumn from '../components/PercentColumn';

// local lib
import generateColumn from './generateColumn.lib';

const getColumns = () => ({
  creditRisk: [
    generateColumn({category: 'label', title: 'Credit Markers'}),
    generateColumn({
      category: 'trend',
      title: 'Direction',
      Component: DirectionColumn,
    }),
    generateColumn({
      category: 'score',
      title: 'Score',
      Component: HighlightedColumn,
    }),
  ],
  financialStrength: [
    generateColumn({category: 'label', title: 'Financial Markers'}),
    generateColumn({
      category: 'trend',
      title: 'Direction',
      Component: DirectionColumn,
    }),
    generateColumn({
      category: 'score',
      title: 'Score',
      Component: HighlightedColumn,
    }),
  ],
  legalFilings: [
    generateColumn({category: 'label', title: 'Filing Markers'}),
    generateColumn({
      category: 'trend',
      title: 'Direction',
      Component: DirectionColumn,
    }),
    generateColumn({
      category: 'score',
      title: 'Score/Status',
      Component: HighlightedColumn,
    }),
  ],
  businessStrength: [
    generateColumn({category: 'label', title: 'Category'}),
    generateColumn({
      category: 'value',
      title: 'Value',
      Component: HighlightedColumn,
    }),
  ],
  supplierViability: [
    generateColumn({category: 'label', title: 'Other Paydex Interpretations'}),
    generateColumn({category: 'unavailable', title: 'Unavailable'}),
    generateColumn({category: 'over120', title: 'Over 120 DBT'}),
    generateColumn({category: '120', title: '120 DBT'}),
    generateColumn({category: '90', title: '90 DBT'}),
    generateColumn({category: '60', title: '60 DBT'}),
    generateColumn({category: '30', title: '30 DBT'}),
    generateColumn({category: '22', title: '22 DBT'}),
    generateColumn({category: '15', title: '15 DBT'}),
    generateColumn({category: 'prompt', title: 'Prompt'}),
    generateColumn({category: 'discount', title: 'Discount'}),
    generateColumn({category: 'anticipate', title: 'Anticipate'}),
    generateColumn({category: 'last', title: ''}),
  ],
  viabilityScoreRanking: [
    generateColumn({category: 'score', title: 'Viability Score'}),
    generateColumn({
      category: 'totalPercent',
      title: 'Percent of Total',
      Component: PercentColumn,
    }),
    generateColumn({
      category: 'outOfBusinessRate',
      title: 'Out of Business Rate',
      Component: PercentColumn,
    }),
    generateColumn({
      category: 'cumulativePercentOfTotal',
      title: 'Cumulative Percent of Total',
      Component: PercentColumn,
    }),
    generateColumn({
      category: 'cumulativePercentOfBadsCaptured',
      title: 'Cumulative Percent of Bads Captured',
      Component: PercentColumn,
    }),
    generateColumn({
      category: 'cumulativeOutOfBusinessRate',
      title: 'Cumulative Out of Business (Bad) Rate',
      Component: PercentColumn,
    }),
  ],
});

export default getColumns;
