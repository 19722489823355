import styled from 'styled-components';

// layout colors
import whiteColor from '../../../../layout/colors/white.color.layout';

const Filters = styled.div`
  width: 100%;
  background: ${whiteColor};
  border-radius: 16px;
  padding: 16px;
  box-shadow: ${({noShadow}) =>
    noShadow
      ? 'none'
      : `rgba(255, 255, 255, 0.05) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.15) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.2) 0px 30px 60px -30px`};
`;

export default Filters;
