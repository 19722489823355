// company events
import filterEvent from '../../events/filter.event.company';

// local components
import Container from './components/Containers';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// risk lib
import getScoreColor from '../../../risk/lib/getScoreColor.lib.risk';
import getRiskLevel from '../../../risk/lib/getRiskLevel.lib.risk';

const HighlightedScore = ({score = 0, filter, affix = '', suffix = ''}) => {
  const primary = getScoreColor(score);

  return !score ? (
    'Insufficient Data'
  ) : (
    <Container
      color={primary}
      onClick={
        !!filter
          ? () =>
              filterEvent.publish({
                filter,
                filterValue: getRiskLevel(score),
              })
          : null
      }
    >
      {affix}
      {Math.round(score)}
      {suffix}
    </Container>
  );
};

HighlightedScore.propTypes = {
  affix: PropTypes.string,
  filter: PropTypes.string,
  score: PropTypes.number,
  suffix: PropTypes.string,
};

export default HighlightedScore;
