// company lib
import getFullDiversity from './getFullDiversity.lib.company';

const extractDiversities = (companies) =>
  [...companies]
    .map((company) => (company?.Firmographic?.Diversity || '').split('|'))
    .flat()
    .reduce((diversities, diversity) => {
      return !diversity || diversities.includes(diversity)
        ? diversities
        : [...diversities, diversity];
    }, [])
    .map((diversity) => ({
      value: diversity,
      label: getFullDiversity({diversity, empty: diversity}),
    }));

export default extractDiversities;
