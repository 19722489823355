// company api
import loadAndAttachData from '../../../company/api/mock/lib/loadAndAttachData';

// company redux actions
import {setGeneralCompanyData as setGeneralCompanyDataAction} from '../../../company/redux/reducer.redux.company';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// propType
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// weather components
import WeatherLayout from '../../components/WeatherLayout/WeatherLayout';

class CompanyWeatherContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const {company, dispatch} = this.props;

    if (!!company?.weather || company?.weatherLoaded)
      return this.setState({loading: false});

    try {
      const loadedData = await loadAndAttachData({
        company: {},
        dataList: [
          {
            filePath: `/mock/ambee/aqi.json`,
            dataKey: 'aqi',
          },
          {
            filePath: `/mock/ambee/fire.json`,
            dataKey: 'fire',
          },
          {
            filePath: `/mock/ambee/naturalDisaster.json`,
            dataKey: 'naturalDisaster',
          },
          {
            filePath: `/mock/ambee/weather.json`,
            dataKey: 'weather',
          },
        ],
      });

      const combinedCompany = {
        ...company,
        weather: {
          ...loadedData,
        },
        weatherLoaded: true,
      };

      dispatch(
        setGeneralCompanyDataAction({loading: false, company: combinedCompany})
      );
      this.setState({loading: false});
    } catch (error) {
      this.setState({loading: false});
    }
  };

  render() {
    const {loading} = this.state;
    return loading ? <FullScreenLoader /> : <WeatherLayout />;
  }
}

export default connect((state) => ({company: state.company.company}))(
  CompanyWeatherContainer
);
