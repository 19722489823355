// ant
import {Tag} from 'antd';

// layout colors
import green300Color from '../../../layout/colors/green300.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FullIntegrationTag = ({children = 'Full Integration Required'}) => (
  <Tag color={green300Color}>{children}</Tag>
);

FullIntegrationTag.propTypes = {
  children: PropTypes.node,
};

export default FullIntegrationTag;
