import styled from 'styled-components';
import theme from '../constants/theme';

const TEMP_THEME_UNIT = 10;

export const Grid = styled.div`
  display: flex;
  flex-direction: ${({direction = 'row'}) => direction};
  gap: ${({spacing = 5}) => `${spacing}px`};
  align-items: ${({alignItems = 'inherit'}) => alignItems};
`;
// TODO ideally the `unit` should come from the theme

export const Item = styled.div`
  width: 100%;
  ${({pb}) =>
    pb &&
    `
  padding-bottom: ${pb * TEMP_THEME_UNIT}px;
  `}
`;

export const GraphContainer = styled.div`
  background-color: ${theme.dashboard.spiderGraph.background};
  border: 1px solid ${theme.graphContainer.border};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 24px;
  ${({ph}) =>
    ph &&
    `
  padding-left: ${ph * TEMP_THEME_UNIT}px;
  padding-right: ${ph * TEMP_THEME_UNIT}px;
  `}
`;
