// data lib
import generateAreaScoreKey from '../../../../data/lib/generateAreaScoreKey.lib.data';

// date
import {format, parse} from 'date-fns';

// number lib
import integer from '../../../../number/lib/integer.lib.number';

// risk constants
import KEY_RISK_AREAS from '../../../../risk/constants/keyRiskAreas.constant.risk';

const extractHistoricKeyRiskAreaScores = ({data}) =>
  [...KEY_RISK_AREAS].map((riskArea) => ({
    name: riskArea.label,
    color: riskArea.color,
    marker: false,
    data: [...data].map(({Date: date, ...areaScores}) => {
      const value = integer(
        areaScores?.[generateAreaScoreKey(riskArea.label)]?.Value || 0
      );
      return {
        name: format(parse(date, 'yyyyMMdd', new Date()), 'MMM d'),
        y: value,
      };
    }),
  }));

export default extractHistoricKeyRiskAreaScores;
