// uuid lib
import {v4} from 'uuid';

const attachMessageIdsToQuestionFlow = (messages) =>
  [...messages].map((message) => {
    const messageWithId = {...message, id: v4()};
    return !!message?.messages
      ? {
          ...messageWithId,
          messages: attachMessageIdsToQuestionFlow(message.messages),
        }
      : messageWithId;
  });

export default attachMessageIdsToQuestionFlow;
