// charts
import Highcharts from 'highcharts';

// layout colors
import whiteColor from '../../../layout/colors/white.color.layout';
import brightYellow300Color from '../../../layout/colors/brightYellow300.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const GlobalResilienceIndexMapChart = ({data = [], map}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.mapChart(id, {
      chart: {
        height: '800px',
      },

      title: {
        text: 'FM Global Resilience Index',
        x: 0,
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          y: 30,
          x: -80,
        },
      },

      colorAxis: {
        min: 0,
        stops: [
          [0, '#eee'],
          [0.2, '#cce4ef'],
          [0.4, '#7dc0d8'],
          [0.6, '#21a9bf'],
          [0.8, '#0078ae'],
        ],
      },

      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'bottom',
      },

      tooltip: {
        useHTML: true,
        formatter: function () {
          const data = this?.point?.extraData;
          return !!data
            ? `<div>
                <div><b>${data.Country}</b></div>
                <div>Country Rank: #${data['Country Rank']}</div>
                <div>Country Score: ${data['Country Score']}</div>
                <div>Economic Score: ${data['Economic Score']}</div>
                <div>Risk Quality Score: ${data['Risk Quality Score']}</div>
                <div>Supply Chain Score: ${data['Supply Chain Score']}</div>
              </div>`
            : `<b>${this.series.name}</b>`;
        },
      },

      series: [
        {
          mapData: map,
          name: 'Index',
          data,
          joinBy: ['name', 'name'],
          borderColor: whiteColor,
          borderWidth: 1,
          nullColor: '#eee',
          states: {
            hover: {
              color: brightYellow300Color,
            },
          },
        },
      ],
    });
    // eslint-disable-next-line
  }, [data, map]);

  return <div id={id} />;
};

GlobalResilienceIndexMapChart.propTypes = {
  data: PropTypes.array,
  map: PropTypes.object,
};

export default GlobalResilienceIndexMapChart;
