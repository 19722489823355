const LINE_CHART_CATEGORIES = [
  {
    key: 'quartile25',
    label: '25% Quartile',
  },
  {
    key: 'median',
    label: 'Median',
  },
  {
    key: 'quartile75',
    label: '75% Quartile',
  },
];

export default LINE_CHART_CATEGORIES;
