// workflow repositories
import listWorkflowsRepository from '../repositories/list.repository.workflow';

const listWorkflowsService = async ({
  listWorkflows = listWorkflowsRepository,
} = {}) => {
  const workflows = await listWorkflows();
  return workflows;
};

export default listWorkflowsService;
