// local components
import Container from './components/Container';

// local lib
import getIcon from './lib/getIcon.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const NewsEntity = ({entity, onClick}) => {
  const icon = getIcon(entity);
  return (
    <Container onClick={!!onClick ? () => onClick(entity) : null}>
      {!!icon && <i className={`mdi mdi-${icon}`} />}
      {entity.label}
    </Container>
  );
};

NewsEntity.propTypes = {
  entity: PropTypes.object,
  onClick: PropTypes.func,
};

export default NewsEntity;
