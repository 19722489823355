// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralCollectionDataAction from './actions/setGeneralData.action.redux.collection';
import storeCollectionAction from './actions/storeCollection.action.redux.collection';

// style redux constants
import DEFAULT_COLLECTION_REDUX_STATE from './constants/defaultState.constant.redux.collection';

const collectionSlice = createSlice({
  name: 'collection',
  initialState: DEFAULT_COLLECTION_REDUX_STATE,
  reducers: {
    setGeneralCollectionData: setGeneralCollectionDataAction,
    storeCollection: storeCollectionAction,
  },
});

export const {setGeneralCollectionData, storeCollection} =
  collectionSlice.actions;

export default collectionSlice.reducer;
