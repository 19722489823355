const inverseLegendItemClick = ({chart, event}) => {
  const selected = chart.series
    .filter((series) => series.visible)
    .map((series) => series.index);
  if (selected.length === 1 && selected.includes(event.target.index)) {
    event.preventDefault();
    return chart.series.forEach((series) => {
      series.show();
    });
  }
  const allVisible = chart.series.every((series) => series.visible);
  if (allVisible) {
    chart.series.forEach((series) => {
      series.hide();
    });
  }
};

export default inverseLegendItemClick;
