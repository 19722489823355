import styled from 'styled-components';

// router
import {Link} from 'react-router-dom';

const NavigationTitleTab = styled(Link)`
  display: flex;
  align-items: center;
  width: 214px;
  max-width: 214px;
  position: relative;
  color: #153443;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  padding-left: 18px;
`;

export default NavigationTitleTab;
