// chart lib
import inverseLegendItemClick from '../../lib/inverseLegendItemClick.lib.chart';

// charts
import Highcharts from 'highcharts';

// layout components
import Card from '../../../layout/components/Card/Card';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect, useState} from 'react';

// uuid
import {v4} from 'uuid';

const KeyFinancialsMultiChartCard = ({categories = [], data = []}) => {
  const [containerId] = useState(v4());

  useEffect(() => {
    const chart = Highcharts.chart(containerId, {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: 'Cash Flow & Balance Sheet',
      },
      xAxis: [
        {
          categories,
          crosshair: true,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          title: {
            text: '',
          },
          opposite: true,
          labels: {
            format: '{value}%',
          },
        },
        {
          // Secondary yAxis
          gridLineWidth: 0,
          title: {
            text: '',
          },
          labels: {
            // eslint-disable-next-line
            format: '${value}M',
          },
        },
      ],
      plotOptions: {
        series: {
          events: {
            legendItemClick: (event) => inverseLegendItemClick({chart, event}),
          },
        },
      },
      tooltip: {
        shared: true,
      },
      series: data,
    });

    // eslint-disable-next-line
  }, [categories, data]);
  return (
    <Card noPadding>
      <div id={containerId} />
    </Card>
  );
};

KeyFinancialsMultiChartCard.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.array,
};

export default KeyFinancialsMultiChartCard;
