// esg lib
import getGeneralPercentileValue from '../../../lib/getGeneralPercentileValue.lib.esg';

// number lib
import isNumber from 'is-number';

const getOverallRating = ({company, percentileView}) => {
  const esgRating = company?.esgInfo?.ratings?.overall;
  const parsedEsgRating = isNumber(esgRating) ? esgRating : 0;

  return (
    (percentileView
      ? getGeneralPercentileValue(company?.esgInfo?.percentileRatings?.overall)
      : parsedEsgRating) || 0
  );
};

export default getOverallRating;
