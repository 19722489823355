const getNewsTitle = ({newsArticle, language = 'en', missingTitle = '-'}) => {
  if (!newsArticle) return missingTitle;
  return (
    (!language || language === newsArticle.language
      ? newsArticle.title
      : newsArticle?.translations?.[language]?.title) ||
    newsArticle.title ||
    missingTitle
  );
};

export default getNewsTitle;
