// ant components
import {Col as Column, Row} from 'antd';

// chart components
import HaloIntrinsicScoreChartCard from '../../../chart/components/HaloIntrinsicScoreChartCard/HaloIntrinsicScoreChartCard';
import RiskAreaBreakdownChartCard from '../../../chart/components/RiskAreaBreakdownChartCard/RiskAreaBreakdownChartCard';

// financial components
import FinancialCreditLimitCard from '../FinancialCreditLimitCard/FinancialCreditLimitCard';
import FinancialMetricChartCard from '../FinancialMetricChartCard/FinancialMetricChartCard';
import FinancialRiskScoreCard from '../FinancialRiskScoreCard/FinancialRiskScoreCard';
import FinancialsSummaryCard from '../FinancialsSummaryCard/FinancialsSummaryCard';

// local lib
import extractRiskAreaBreakdownData from './lib/extractRiskAreaBreakdownData.lib';
import extractFinancialSummaryData from './lib/extractFinancialSummaryData.lib';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

// propTypes
import PropTypes from 'prop-types';

const FinancialsSummary = ({printView = false}) => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  return (
    <Row gutter={[20, 20]}>
      <Column span={printView ? 24 : 8}>
        <Row gutter={[20, 20]}>
          <Column span={printView ? 12 : 24}>
            <HaloIntrinsicScoreChartCard
              score={company?.HaloScores?.Financial_Area?.Value}
              title="Financial Area Score"
              height="320px"
            />
          </Column>
          <Column span={printView ? 12 : 24}>
            <FinancialMetricChartCard
              score={
                company?.originalHaloScores?.HaloScores?.Financial_Financial
                  ?.Value
              }
            />
          </Column>
        </Row>
      </Column>
      <Column span={printView ? 24 : 16}>
        <RiskAreaBreakdownChartCard
          data={extractRiskAreaBreakdownData({company})}
          title="Financial Area Ratings"
          height="560px"
        />
      </Column>
      <Column span={24}>
        <FinancialsSummaryCard
          {...extractFinancialSummaryData({company})}
          printView={printView}
        />
      </Column>
      <Column span={18}>
        <FinancialRiskScoreCard
          internationalScore={company.financials.internationalScore}
          riskScore={company.financials.riskScore}
        />
      </Column>
      <Column span={6}>
        <FinancialCreditLimitCard
          currency={company.financials.currency}
          creditLimit={company.financials.creditLimit}
        />
      </Column>
    </Row>
  );
};

FinancialsSummary.propTypes = {
  printView: PropTypes.bool,
};

export default FinancialsSummary;
