const prepareMessageQueriesForChatGPTFromFlatQuestionFlow = ({
  questionFlow,
  messageId,
  data = {},
}) => {
  const message = questionFlow?.messages?.[messageId] || null;
  return !!message
    ? [
        ...(questionFlow?.systemQueries || []),
        ...(message?.parents?.map?.((message) => message.queries)?.flat?.() ||
          []),
        ...(message?.queries || []),
      ].map((query) => ({
        ...query,
        content: query.content.replace('{{data}}', JSON.stringify(data)),
      }))
    : [];
};

export default prepareMessageQueriesForChatGPTFromFlatQuestionFlow;
