// local lib
import generateColumn from './generateColumn.lib';

const getInnovationColumns = () => [
  generateColumn({
    category: 'totalPatentDocuments',
    title: 'Total Patent Documents',
  }),
];

export default getInnovationColumns;
