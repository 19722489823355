import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';

const LinkButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  color: ${blackColor};
`;

export default LinkButton;
