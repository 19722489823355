// charts
import Highcharts from 'highcharts';

// layout components
import Card from '../../../layout/components/Card/Card';

// local lib
import renderDataRow from './lib/renderDataRow.lib';

// number lib
import currencyFormat from '../../../number/lib/currency.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const HorizontalCorporateHierarchyChartCard = ({data = [], height = 1000}) => {
  const id = v4();

  useEffect(() => {
    // Create the chart and store its reference
    const chartInstance = Highcharts.chart(id, {
      chart: {
        height,
        marginTop: 40,
      },
      // colors: [red300Color],
      tooltip: {
        useHTML: true,
        hideDelay: 5,
        formatter: function () {
          const dataPoint = this.point.custom;
          return !!dataPoint
            ? `
            <div>
              ${renderDataRow({data: dataPoint.target, label: 'Company Name'})}
              ${renderDataRow({data: dataPoint.parent, label: 'Parent'})}
              ${renderDataRow({
                data: dataPoint.relationshipType,
                label: 'Relationship',
              })}
              ${renderDataRow({
                data: dataPoint.reportedCurrency,
                label: 'Currency',
              })}
              ${renderDataRow({
                data: dataPoint.assets,
                label: 'Assets',
                format: currencyFormat,
              })}
              ${renderDataRow({
                data: dataPoint.totalRevenue,
                label: 'Total Revenue',
                format: currencyFormat,
              })}
              ${renderDataRow({
                data: dataPoint.totalNetIncome,
                label: 'Total Net Income',
                format: currencyFormat,
              })}
              ${renderDataRow({
                data: dataPoint.primaryIndustry,
                label: 'Primary industry',
              })}
              ${renderDataRow({
                data: dataPoint.globalRegion,
                label: 'Region',
              })}
              ${renderDataRow({
                data: dataPoint.headquarters,
                label: 'HQ',
              })}
            </div>
          `
            : this.point.options.id;
        },
      },
      series: [
        {
          type: 'treegraph',
          clip: false,
          marker: {
            symbol: 'circle',
            radius: 6,
            fillColor: '#ffffff',
            lineWidth: 3,
          },
          dataLabels: {
            pointFormat: '{point.id}',
            style: {
              whiteSpace: 'nowrap',
              pointerEvents: 'none',
            },
            y: 15,
          },
          levels: [
            {
              level: 1,
              levelIsConstant: false,
            },
            {
              level: 2,
              colorByPoint: true,
            },
            {
              level: 3,
              colorVariation: {
                key: 'brightness',
                to: -0.5,
              },
            },
          ],
          data,
        },
      ],
    });

    // Function to hide the tooltip
    const hideTooltip = () => {
      if (chartInstance && chartInstance.tooltip) {
        chartInstance.tooltip.hide();
      }
    };

    // Add mouseout event listener to the chart's container
    const chartContainer = document.getElementById(id);
    if (chartContainer) {
      chartContainer.addEventListener('mouseout', hideTooltip);
    }

    // Cleanup function
    return () => {
      if (chartContainer) {
        chartContainer.removeEventListener('mouseout', hideTooltip);
      }
    };

    // eslint-disable-next-line
  }, [data, height, id]);

  return (
    <Card noPadding>
      <div id={id} />
    </Card>
  );
};

HorizontalCorporateHierarchyChartCard.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
};

export default HorizontalCorporateHierarchyChartCard;
