const RISK_AREA_DESCRIPTIONS = (forSupplier = true) => ({
  strategic: forSupplier
    ? "This supplier's Halo Intrinsic Score as it relates to your company's Strategic standing"
    : "Your Portfolio's Halo Intrinsic Score as it relates to your company's Strategic standing",
  compliance: forSupplier
    ? "This supplier's Halo Intrinsic Score as it relates to your company's Compliance standing"
    : "Your Portfolio's Halo Intrinsic Score as it relates to your company's Compliance standing",
  reputational: forSupplier
    ? "This supplier's Halo Intrinsic Score as it relates to your company's Reputational standing"
    : "Your Portfolio's Halo Intrinsic Score as it relates to your company's Reputational standing",
  financial: forSupplier
    ? "This supplier's Halo Intrinsic Score as it relates to your company's Financial standing"
    : "Your Portfolio's Halo Intrinsic Score as it relates to your company's Financial standing",
  operational: forSupplier
    ? "This supplier's Halo Intrinsic Score as it relates to your company's Operational standing"
    : "Your Portfolio's Halo Intrinsic Score as it relates to your company's Operational standing",
});

export default RISK_AREA_DESCRIPTIONS;
