// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// layout components
import FormattedNumber from '../../../../layout/components/FormattedNumber/FormattedNumber';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Net Debt (M)',
  componentProps: ({company}) => ({
    children: <FormattedNumber number={company?.Financials?.['NetDebt(M)']} />,
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    return (
      Number(a?.Financials?.['NetDebt(M)'] || 0) -
      Number(b?.Financials?.['NetDebt(M)'] || 0)
    );
  },
});
