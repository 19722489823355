// validator lib
import isEmail from 'validator/lib/isEmail'; // workflow lib

// workflow lib
import isPeriodRequired from '../lib/isPeriodRequired.lib.workflow';
import isThresholdRequired from '../lib/isThresholdRequired.lib.workflow';

const updateWorkflowValidator = ({workflow}) => {
  if (!workflow?.id) throw new Error('Missing workflow id');
  if (!workflow?.settings?.name) throw new Error('Please enter a name');
  if (!workflow?.settings?.trigger?.length)
    throw new Error('Please select a trigger');
  if (
    isPeriodRequired({trigger: workflow?.settings?.trigger}) &&
    !workflow?.settings?.period
  )
    throw new Error('Please select a period');
  if (
    isThresholdRequired({trigger: workflow?.settings?.trigger}) &&
    !workflow?.settings?.threshold
  )
    throw new Error('Please select a threshold');

  const invalidRecipients = [...(workflow?.recipients || [])].filter(
    (email) => !isEmail(email)
  );
  if (invalidRecipients.length)
    throw new Error(
      `Please correct the following email address: ${invalidRecipients.join(
        ', '
      )}`
    );
  // if (!workflow?.settings?.actions?.length)
  //   throw new Error('Please select actions');
  // if (!workflow?.actionsPerCompany)
  //   throw new Error('Please select a frequency of actions per company');
};

export default updateWorkflowValidator;
