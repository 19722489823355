// local lib
import generateColumnGroup from '../lib/generateColumnGroup.lib';

// tenant lib
import generateCustomTenantDataColumns from '../../../../tenant/lib/generateCustomDataColumns.lib.tenant';

const customDataColumnGroup = generateColumnGroup({
  name: 'My Company Data',
  size: {x: 4000},
  dynamicColumns: (companies) => generateCustomTenantDataColumns({companies}),
  columns: [],
});

export default customDataColumnGroup;
