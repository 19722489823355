// layout containers
import Page from '../../../layout/containers/Page/Page';

// notification containers
import NotificationsContainer from '../../containers/NotificationsContainer/NotificationsContainer';

// local page
import page from './page';

// react
import React from 'react';

const NotificationsPage = () => (
  <Page page={page}>
    <NotificationsContainer />
  </Page>
);

export default NotificationsPage;
