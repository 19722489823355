import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';
import grey300Color from '../../../../layout/colors/grey300.color.layout';

const InputContainer = styled.form`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex: 1;
  padding: 4px;
  gap: 4px;
  align-items: flex-end;
  border-radius: 17px;
  background: ${grey300Color};
  color: ${blackColor};
  margin: 0px;
`;

export default InputContainer;
