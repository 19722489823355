// ant components
import {Button, Modal, Space} from 'antd';

// ant icons
import {
  // DeleteOutlined,
  // DownloadOutlined,
  PrinterOutlined,
} from '@ant-design/icons';

// company objects
import User from '../DueDiligenceSection/objects/user';
import Company from '../DueDiligenceSection/objects/company';

// company assets
// import companyInfoReportMicrosoft from '../DueDiligenceSection/assets/report-company-info-microsoft.png';
// import companyInfoReportRocketHR from '../DueDiligenceSection/assets/report-company-info-rocket-hr.png';

// company components
import DueDiligenceCard from '../DueDiligenceSection/components/DueDiligenceCard';
import CompanyCyberSecuritySection from '../CompanyCyberSecuritySection/CompanyCyberSecuritySection';
import CompanyEsgDistributionSection from '../CompanyEsgDistributionSection/CompanyEsgDistributionSection';
import CompanyForensicBreakdownSection from '../CompanyForensicBreakdownSection/CompanyForensicBreakdownSection';
import CompanyHaloScoresSection from '../CompanyHaloScoresSection/CompanyHaloScoresSection';
import CompanyInnovationSection from '../CompanyInnovationSection/CompanyInnovationSection';
import CompanyKeyFinancialsSection from '../CompanyKeyFinancialsSection/CompanyKeyFinancialsSection';
import CompanyKeySuppliersSection from '../CompanyKeySuppliersSection/CompanyKeySuppliersSection';

// company containers
import CompanySideMenuContainer from '../../containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company constants
import SUPPLIER_DASHBOARD_DESCRIPTION from '../../constants/supplierDashboardDescription.constant.company';

// company lib
import generateSubPages from '../../lib/generateSubPages.lib.company';

// company component lib
import renderEsgSection from '../CompanyEsgDistributionSection/lib/renderEsgSection.lib';
import renderFinancialSection from '../CompanyKeyFinancialsSection/lib/renderFinancialSection.lib';
import renderForensicSection from '../CompanyForensicBreakdownSection/lib/renderForensicSection.lib';
import renderInnovationSection from '../CompanyInnovationSection/lib/renderInnovationSection.lib';
import renderKeySuppliersSection from '../CompanyKeySuppliersSection/lib/renderKeySuppliersSection.lib';

// company pages
import financialsRoute from '../../pages/FinancialsPage/route';

// cyberSecurity constants
// import CYBER_SECURITY_FACTOR_DESCRIPTION from '../../../cyberSecurity/constants/cyberSecurityFactorDescription.constant.cyberSecurity';

// esg constants
import ESG_SECTION_DESCRIPTION from '../../../esg/constants/esgSectionDescription.constant.esg';

// file components
// import DownloadJsonButton from '../../../file/components/DownloadJsonButton/DownloadJsonButton';

// icon components
// import {FiBarChart2, FiBox} from 'react-icons/fi';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// print components
import Print from '../../../print/components/Print/Print';
import PrintLayout from '../../../print/components/PrintLayout/PrintLayout';
import PrintSection from '../../../print/components/PrintSection/PrintSection';

// react
import React, {useEffect, useState} from 'react';

// redux
import {useSelector} from 'react-redux';

// report containers
// import BlackKiteReportGeneratorContainer from '../../../report/containers/BlackKiteReportGeneratorContainer/BlackKiteReportGeneratorContainer';

// supplier components
import HorizontalCorporateHierarchySection from '../../../supplier/components/HorizontalCorporateHierarchySection/HorizontalCorporateHierarchySection';

// supplier components lib
import renderCorporateHierarchySection from '../../../supplier/components/HorizontalCorporateHierarchySection/lib/renderCorporateHierarchySection.lib';

const SuppliersDashboard = () => {
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  const {user, company, subPage} = useSelector((state) => ({
    user: state.auth.user,
    company: state.company.company,
    subPage: state.layout.subPage,
  }));

  const userObject = User.getUser(user.email);
  const companyObject = Company.getCompany(company.CompanyId, userObject);
  // const draftArrangementObject = companyObject.getDraftArrangement();
  // const isOnboarded = companyObject.isOnboarded();
  const isActive = companyObject.isActive();
  // const arrangementButtonLabel = `${
  //   !isOnboarded ? 'Onboard' : '+ New Arrangement'
  // }${draftArrangementObject ? ' (continue)' : ''}`;

  const [modalContents, setModalContents] = useState();

  // const showModalReset = () => {
  //   Modal.confirm({
  //     title: `Are you sure you want to reset ${company.CompanyName}?`,
  //     content: 'This action is permanent and cannot be undone.',
  //     width: 500,
  //     zIndex: 1010,
  //     cancelText: 'Cancel',
  //     okText: 'Reset',
  //     okButtonProps: {danger: true},
  //     autoFocusButton: null,
  //     destroyOnClose: true,
  //     onOk: () => {
  //       companyObject.reset();
  //       setRefresh(true);
  //     },
  //   });
  // };

  // const getDropDownItems = () =>
  //   [
  //     {key: 'simulator', label: 'Risk Simulator', icon: <FiBox />},
  //     {key: 'company', label: 'Company Info', icon: <FiBarChart2 />},
  //     isOnboarded && {
  //       key: 'msa',
  //       label: 'Download MSA',
  //       icon: <DownloadOutlined />,
  //     },
  //     {type: 'divider'},
  //     {
  //       key: 'reset',
  //       label: 'Reset',
  //       icon: <DeleteOutlined />,
  //       danger: true,
  //     },
  //   ].filter((dropDownItem) => !!dropDownItem);

  // const onDropDownClick = ({key}) => {
  //   // if(key === 'simulator') setSimulatorVisible(true);
  //   if (key === 'company' && company.CompanyId === '1453')
  //     setModalContents(
  //       <img alt="" src={companyInfoReportMicrosoft} style={{width: 780}} />
  //     );
  //   else if (key === 'company')
  //     setModalContents(
  //       <img alt="" src={companyInfoReportRocketHR} style={{width: 780}} />
  //     );
  //   else if (key === 'reset') showModalReset();
  // };

  return (
    <Print
      title="Supplier Dashboard"
      subtitle={company.CompanyName}
      trigger={({print}) => (
        <PageLayout
          alerts={company?.alerts || []}
          sideContent={({goToSection}) => (
            <CompanySideMenuContainer goToSection={goToSection} />
          )}
          subPage={subPage}
          subPages={generateSubPages(company)}
          sections={[
            {
              section: 'Supplier Dashboard',
              titleTooltip: SUPPLIER_DASHBOARD_DESCRIPTION,
              component: <CompanyHaloScoresSection />,
              // primaryButton: {
              //   key: 'createArrangement',
              //   label: arrangementButtonLabel,
              //   url: createArrangementRoute(company.CompanyId),
              // },
              moreButton: (
                <Space direction="horizontal">
                  <Button icon={<PrinterOutlined />} onClick={print}>
                    Print Report
                  </Button>
                  {/* <DownloadJsonButton
                    data={company}
                    fileName={`${company.CompanyName} Dashboard`}
                  /> */}
                  {/* {!isOnboarded ? null : (
                    <Dropdown
                      trigger={['click']}
                      menu={{
                        items: getDropDownItems(),
                        onClick: onDropDownClick,
                      }}
                    >
                      <Button
                        type="primary"
                        icon={<MenuOutlined />}
                        style={{marginLeft: '10px'}}
                      />
                    </Dropdown>
                  )} */}
                </Space>
              ),
              modalContents: (
                <Modal
                  centered
                  footer={null}
                  onCancel={() => setModalContents()}
                  style={{left: 235 / 4}}
                  open={!!modalContents}
                  width={840}
                  zIndex={1010}
                >
                  {' '}
                  {modalContents}{' '}
                </Modal>
              ),
            },
            ...(isActive
              ? [
                  {
                    section: 'Due Diligence Center',
                    component: (
                      <DueDiligenceCard
                        company={companyObject}
                        hideTitle={true}
                        onRefresh={() => setRefresh(true)}
                      />
                    ),
                  },
                ]
              : []),
            renderFinancialSection({company}) && {
              section: 'Financial Risk',
              title: 'Financial Risk Area',
              component: <CompanyKeyFinancialsSection />,
              primaryScore: company?.HaloScores?.Financial_Area?.Value || 0,
              tabs:
                !!company?.historicalFinancials ||
                !!company.PrivateFinancialData ||
                !!company.extendedPrivateFinancials
                  ? [
                      {
                        key: 'historicalFinancial',
                        label: 'More on Financials',
                        url: financialsRoute(company.CompanyId),
                      },
                    ]
                  : [],
            },
            renderForensicSection({company}) && {
              section: 'Performing Factors',
              title:
                'Risk Area Spotlight: Performing Factors by Weighted Score',
              component: <CompanyForensicBreakdownSection />,
            },
            renderEsgSection({company}) && {
              section: 'ESG',
              title: 'ESG Factor',
              titleTooltip: ESG_SECTION_DESCRIPTION,
              component: <CompanyEsgDistributionSection />,
              secondaryScore:
                company?.originalHaloScores?.HaloScores?.Operational_ESG
                  ?.Value || 0,
            },
            renderInnovationSection({company}) && {
              section: 'Innovation',
              title: 'Innovation Factor',
              component: <CompanyInnovationSection />,
              secondaryScore:
                company?.originalHaloScores?.HaloScores?.Strategic_Innovation
                  ?.Value || 0,
            },
            renderKeySuppliersSection({company}) && {
              section: 'Supply Chain',
              component: <CompanyKeySuppliersSection />,
            },
            renderCorporateHierarchySection({company}) && {
              section: 'Corporate Hierarchy',
              component: <HorizontalCorporateHierarchySection />,
            },
          ].filter((section) => !!section)}
        />
      )}
    >
      <PrintLayout>
        <PrintSection title="Supplier Dashboard">
          <CompanyHaloScoresSection print />
        </PrintSection>
        <PrintSection title="Cyber Security">
          <CompanyCyberSecuritySection print />
        </PrintSection>
        {renderFinancialSection({company}) && (
          <PrintSection title="Financial Risk">
            <CompanyKeyFinancialsSection />
          </PrintSection>
        )}
        {renderForensicSection({company}) && (
          <PrintSection title="Performing Factors">
            <CompanyForensicBreakdownSection />
          </PrintSection>
        )}
        {renderEsgSection({company}) && (
          <PrintSection title="ESG">
            <CompanyEsgDistributionSection />
          </PrintSection>
        )}
        {renderInnovationSection({company}) && (
          <PrintSection title="Innovation">
            <CompanyInnovationSection />
          </PrintSection>
        )}
        {renderKeySuppliersSection({company}) && (
          <PrintSection title="Supply Chain">
            <CompanyKeySuppliersSection />
          </PrintSection>
        )}
        {renderCorporateHierarchySection({company}) && (
          <PrintSection title="Corporate Hierarchy">
            <HorizontalCorporateHierarchySection />
          </PrintSection>
        )}
      </PrintLayout>
    </Print>
  );
};

export default SuppliersDashboard;
