// axios
import axios from 'axios';

const getCountryMapApi = async ({countryIso, mapType = 'all'}) => {
  try {
    const response = await axios.get(
      `https://code.highcharts.com/mapdata/countries/${countryIso}/${countryIso}-${mapType}.topo.json`
    );
    return response?.data || null;
  } catch (error) {
    return null;
  }
};

export default getCountryMapApi;
