// mock lib
import loadLocalJson from './loadLocalJson';

const loadAndAttachData = async ({company, dataList = []}) => {
  const loadedListOfData = await Promise.all(
    dataList.map(async ({filePath, dataKey, defaultValue = null} = {}) => {
      const loadedData = await loadLocalJson(filePath);
      return {dataKey, data: loadedData || defaultValue};
    })
  );

  const parsedCompany = [...loadedListOfData].reduce(
    (combined, {dataKey, data}) => ({...combined, [dataKey]: data}),
    {...company}
  );

  return parsedCompany;
};

export default loadAndAttachData;
