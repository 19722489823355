// local assets
import logoBlack from './assets/logoBlack.png';
import logoWhite from './assets/logoWhite.svg';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// helpers
const logo = {
  black: logoBlack,
  white: logoWhite,
};

const Logo = ({color = 'black', ...props}) => (
  <img src={logo[color]} alt="halloai_logo" {...props} />
);

Logo.propTypes = {
  color: PropTypes.oneOf(['black', 'white']),
};

export default Logo;
