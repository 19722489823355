// notification lib
import convertToNotificationFromAlert from '../lib/convertToNotificationFromAlert.lib.notification';

// notification repositories
import listNotificationsRepository from '../repositories/list.repository.notification';

const generateNotificationsFromAlertsService = async ({
  alerts,
  listNotifications = listNotificationsRepository,
} = {}) => {
  const existingNotifications = await listNotifications();
  const notifications = [...alerts].map((alert) =>
    convertToNotificationFromAlert({alert, existingNotifications})
  );
  return notifications.sort((a, b) => {
    if (a.read && !b.read) return 1;
    if (!a.read && b.read) return -1;
    return 0;
  });
};

export default generateNotificationsFromAlertsService;
