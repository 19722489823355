// ant components
import {Table} from 'antd';

// local lib
import getColumns from './lib/getColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const SanctionNewsList = ({news}) => (
  <Table
    columns={getColumns()}
    dataSource={news}
    pagination={false}
    rowKey="URL"
    size="small"
    locale={{emptyText: 'No news'}}
  />
);

SanctionNewsList.propTypes = {
  news: PropTypes.array,
};

export default SanctionNewsList;
