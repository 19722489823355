const convertToNotificationFromAlert = ({
  alert,
  existingNotifications = [],
}) => {
  const existingNotification = [...existingNotifications].find(
    (notification) => notification.id === alert.id
  );
  return !!existingNotification
    ? existingNotification
    : {
        id: alert.id,
        message: alert.message,
        supplierId: alert.supplierId,
        read: false,
      };
};

export default convertToNotificationFromAlert;
