// local lib
import generateColumn from './generateColumn.lib';

const getColumns = () => ({
  info: [
    generateColumn({category: 'yearFounded', title: 'Year Founded'}),
    generateColumn({category: 'employeeGrowth', title: 'Employee Growth'}),
    generateColumn({category: 'ownershipStatus', title: 'Ownership Status'}),
  ],
  funding: [generateColumn({category: 'totalRaised', title: 'Total Raised'})],
  revenue: [
    generateColumn({category: 'revenue', title: 'Revenue'}),
    generateColumn({
      category: 'revenueGrowthPercentage',
      title: 'Revenue Growth Percentage',
    }),
    generateColumn({
      category: 'growthRatePercentile',
      title: 'Growth Rate Percentile',
    }),
  ],
  valuation: [
    generateColumn({
      category: 'lastKnownValuation',
      title: 'Last Known Valuation',
    }),
    generateColumn({
      category: 'lastKnownValuationDate',
      title: 'Last Known Valuation Date',
    }),
    generateColumn({
      category: 'lastKnownValuationDealType',
      title: 'Last Known Valuation Deal Type',
    }),
    generateColumn({
      category: 'predictedExitClass',
      title: 'Predicted Exit Class',
    }),
  ],
  innovation: [
    generateColumn({
      category: 'totalPatentDocuments',
      title: 'Total Patent Documents',
    }),
  ],
});

export default getColumns;
