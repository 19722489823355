import React from 'react';
import styled from 'styled-components';
import {Row, Col, Divider} from 'antd';
import DOMPurify from 'dompurify';
import theme from '../constants/theme';
import {isJSONString} from '../lib/strings';
import {ReactComponent as HaloSmall} from '../assets/halo-small-single-color.svg';

const AnswersReviewContainer = styled.div`
  margin: 20px auto 30px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 24px 64px;
  background-color: #ffffff;
`;

const AnswerContainer = styled.div`
  margin-bottom: 15px;
`;

const AnswerText = styled.h3`
  color: ${theme.arrangement.mineShaft};
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 400;
`;

const Label = styled.h4`
  margin-left: 10px;
  color: #2ec352;
  margin-bottom: 0;
  margin-top: 0px;
  font-weight: 500;
`;

const LogoContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Question = styled.h2`
  color: #405b68;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
`;

const styleLogo = {
  width: '16px',
  marginTop: '1px',
};

function formatResponseText(string) {
  let formattedString = '';
  if (isJSONString(string)) {
    JSON.parse(string).forEach((key, index) => {
      const formattedKey = index + 1 + ') ' + key;
      formattedString = !formattedString
        ? formattedKey
        : formattedString + '</br>' + formattedKey;
    });
  } else {
    formattedString = string;
  }
  return formattedString || '-';
}

const AnswersReview = ({answers, width, onJump}) => {
  return (
    <AnswersReviewContainer style={{width: Math.min(width, 1250) - 83}}>
      {answers.map((answer, index) => {
        const isValidQuestionId = answer.questionId > 0;
        return (
          <AnswerContainer
            style={{marginBottom: index < answers.length - 1 ? '15px' : '0px'}}
            key={index}
          >
            <Row wrap={false}>
              <Col span={1}>
                <Question>{index + 1}</Question>
              </Col>
              <Col flex="auto">
                <Question
                  style={{...(!isValidQuestionId ? {cursor: 'inherit'} : {})}}
                  onClick={() => {
                    if (isValidQuestionId) {
                      onJump(answer.section + '-' + answer.questionId);
                    }
                  }}
                >
                  {answer.questionText}
                </Question>
                <AnswerText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        formatResponseText(answer.responseText)
                      ),
                    }}
                  />
                </AnswerText>
                {answer.isNLP ? (
                  <LogoContainer>
                    <HaloSmall style={styleLogo} />{' '}
                    <Label>Answered by Halo Ai</Label>
                  </LogoContainer>
                ) : null}
              </Col>
            </Row>
            {index < answers.length - 1 ? (
              <Divider
                style={{
                  backgroundColor: theme.arrangement.divider,
                  margin: '20px 0 20px',
                }}
              />
            ) : null}
          </AnswerContainer>
        );
      })}
    </AnswersReviewContainer>
  );
};

export default AnswersReview;
