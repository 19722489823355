// company lib
import filterByRiskLevel from '../lib/filterByRiskLevel.lib.company';
import generateCompanyFilter from '../lib/generateFilter.lib.company';

export default generateCompanyFilter({
  label: 'Financial Risk Score',
  options: [
    {value: 'low', label: 'Low'},
    {value: 'medium', label: 'Medium'},
    {value: 'high', label: 'High'},
  ],
  fn: ({entry, filterValue}) =>
    filterByRiskLevel({
      score: entry?.HaloScores?.Financial_Area?.Value || 0,
      riskLevel: filterValue,
    }),
});
