// local lib
import extractEsgData from './extractEsgData.lib';

const renderEsgSection = ({company}) => {
  const {data} = extractEsgData({company});
  return [...data].every(
    (dataPoint) => ![undefined, null].includes(dataPoint?.data?.[0]?.y)
  );
};

export default renderEsgSection;
