// layout color
import brightGreen300Color from '../../../colors/brightGreen300.color.layout';
import brightRed300Color from '../../../colors/brightRed300.color.layout';
import brightRed500Color from '../../../colors/brightRed500.color.layout';
import brightYellow500Color from '../../../colors/brightYellow500.color.layout';
import green300Color from '../../../colors/green300.color.layout';

const getScoreColor = (score) =>
  score <= 0.2
    ? brightGreen300Color
    : score <= 0.4
    ? green300Color
    : score <= 0.6
    ? brightYellow500Color
    : score <= 0.8
    ? brightRed300Color
    : brightRed500Color;

export default getScoreColor;
