// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// country components
import Flags from '../../../../country/components/Flags/Flags';

// layout components
import TableCellWidth from '../../../../layout/components/TableCellWidth/TableCellWidth';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Service Areas',
  componentProps: ({company}) => ({
    children: (
      <TableCellWidth width="180px">
        <Flags
          countries={company?.Firmographic?.ServiceAreas}
          condense
          fullName
        />
      </TableCellWidth>
    ),
  }),
  Component: CompanyDefaultDataColumn,
});
