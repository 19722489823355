const RISK_AREA_KEYS = {
  ESG: 'ESG Metrics',
  EthicalRisk: 'Ethical Conduct',
  Crime: 'Criminal Conduct',
  SpecialInterestIndividuals: 'Special Interest Persons',
  Compliance: 'Compliance Metrics',
  GeopoliticalFactorRating: 'Geopolitical Indices',
  ResilienceFactorRating: 'Resilience Indices',
  Financial: 'Finance Metrics',
  Innovation: 'Innovation Metrics',
};

export default RISK_AREA_KEYS;
