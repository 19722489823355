// numeral
import numeral from 'numeral';

const shortenNumber = ({
  number,
  removeZeroDecimals = true,
  valueIfMissing = '-',
  withDecimals = true,
}) =>
  number === null
    ? valueIfMissing
    : numeral(number).format(
        withDecimals ? (removeZeroDecimals ? '0[.]0[0]a' : '0.00a') : '0a'
      );

export default shortenNumber;
