// layout colors
import green100Color from '../../layout/colors/green100.color.layout';
import brightGreen300Color from '../../layout/colors/brightGreen300.color.layout';
import red100Color from '../../layout/colors/red100.color.layout';
import brightRed300Color from '../../layout/colors/brightRed300.color.layout';
import yellow100Color from '../../layout/colors/yellow100.color.layout';
import brightYellow500Color from '../../layout/colors/brightYellow500.color.layout';

// risk lib
import getScoreColor from './getScoreColor.lib.risk';

const getRiskColor = (score) => {
  const {secondary} =
    score <= 35
      ? {primary: brightRed300Color, secondary: red100Color}
      : score <= 75
      ? {primary: brightYellow500Color, secondary: yellow100Color}
      : {primary: brightGreen300Color, secondary: green100Color};
  const scoreColor = getScoreColor(score);
  return {primary: scoreColor, secondary};
};

export default getRiskColor;
