// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// local columns
import creditRatingColumn from './creditRating.column';
import creditScoreColumn from './creditScore.column';
import creditTrendColumn from './creditTrend.column';

export default generateColumn({
  title: 'Data-Axle',
  children: [creditRatingColumn, creditScoreColumn, creditTrendColumn],
});
