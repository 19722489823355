// table components
import DynamicColumn from '../../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const columns = () => [
  generateGeneralColumn({
    category: 'name',
    Component: DynamicColumn,
    title: 'Collection',
  }),
];

export default columns;
