// antd
import {Table} from 'antd';

// chart components
import ActivityModuleChartCard from '../../../chart/components/ActivityModuleChartCard/ActivityModuleChartCard';

// layout components
import Card from '../../../layout/components/Card/Card';
import Column from '../../../layout/components/Grid/Column/Column';
import Row from '../../../layout/components/Grid/Row/Row';

// local lib
import extractActivityModule from './lib/extractActivityModule.lib';
import parseChartData from './lib/parseChartData.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialsActivityModuleSection = ({print}) => {
  const {company} = useSelector((state) => state.company);

  const parsedData = extractActivityModule({company});
  const chartData = parseChartData({company, parsedData});

  return (
    <Row>
      <Column size={print ? 1 : 3 / 5} margin={print}>
        <ActivityModuleChartCard {...chartData} />
      </Column>
      <Column size={print ? 1 : 2 / 5}>
        <Card noPadding>
          <Table
            columns={parsedData.categories}
            dataSource={parsedData.entries}
            pagination={false}
            rowKey="section"
            size="small"
            locale={{emptyText: 'Activity module not available'}}
          />
        </Card>
      </Column>
    </Row>
  );
};

FinancialsActivityModuleSection.propTypes = {
  print: PropTypes.bool,
};

export default FinancialsActivityModuleSection;
