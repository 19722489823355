import styled from 'styled-components';

const SubValueConnection = styled.div`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 14px;
`;

export default SubValueConnection;
