// workflow services
import getTriggerOptionsService from './getTriggerOptions.service.workflow';

const getFlatTriggerOptionsService = ({
  triggerOptions = getTriggerOptionsService(),
} = {}) =>
  [...triggerOptions].reduce(
    (combined, {children, ...option}) =>
      !!children?.length
        ? [
            ...combined,
            ...getFlatTriggerOptionsService({triggerOptions: children}),
          ]
        : [...combined, option],
    []
  );

export default getFlatTriggerOptionsService;
