// company lib
import generateCompanyFilter from '../lib/generateFilter.lib.company';

export default generateCompanyFilter({
  label: 'Ownership',
  options: [
    {value: 'public', label: 'Public'},
    {value: 'private', label: 'Private'},
  ],
  fn: ({entry, filterValue}) =>
    filterValue === 'private' ? !entry?.Ticker : !!entry?.Ticker,
});
