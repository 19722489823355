// layout components
import Card from '../../../layout/components/Card/Card';

// local components
import Section from './components/Section';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// resources constants
import RESOURCES from '../../constants/resources.constant.resource';

const Resources = ({resources = RESOURCES}) => (
  <Card>
    {resources.map((section) => (
      <div key={section.section}>
        <Section>{section.section}</Section>
        <ul>
          {section.resources.map((resource) => (
            <li key={resource.title}>
              <a href={resource.url} target="_blank" rel="noreferrer">
                {resource.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </Card>
);

Resources.propTypes = {
  resources: PropTypes.array,
};

export default Resources;
