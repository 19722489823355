// layout colors
import blueGrey100Color from '../../../../layout/colors/blueGrey100.color.layout';
import blueGrey200Color from '../../../../layout/colors/blueGrey200.color.layout';
import blueGrey300Color from '../../../../layout/colors/blueGrey300.color.layout';
import blueGrey400Color from '../../../../layout/colors/blueGrey400.color.layout';
import blueGrey500Color from '../../../../layout/colors/blueGrey500.color.layout';
import green100Color from '../../../../layout/colors/green100.color.layout';
import green200Color from '../../../../layout/colors/green200.color.layout';
import orange300Color from '../../../../layout/colors/orange300.color.layout';
import orange500Color from '../../../../layout/colors/orange500.color.layout';
import red100Color from '../../../../layout/colors/red100.color.layout';
import red200Color from '../../../../layout/colors/red200.color.layout';
import red300Color from '../../../../layout/colors/red300.color.layout';
import red400Color from '../../../../layout/colors/red400.color.layout';
import red500Color from '../../../../layout/colors/red500.color.layout';
import surfGreen300Color from '../../../../layout/colors/surfGreen300.color.layout';
import surfGreen500Color from '../../../../layout/colors/surfGreen500.color.layout';

// lib
import generateLabelFromKeys from '../../../../lib/generateLabelFromKeys.lib';

// risk constants
import KEY_RISK_AREAS from '../../../../risk/constants/keyRiskAreas.constant.risk';

// constants
const colors = [
  blueGrey500Color,
  blueGrey400Color,
  blueGrey300Color,
  blueGrey200Color,
  blueGrey100Color,
  orange500Color,
  orange300Color,
  surfGreen500Color,
  surfGreen300Color,
  red500Color,
  red400Color,
  red300Color,
  red200Color,
  red100Color,
  green200Color,
  green100Color,
];

const extractForensicData = ({company, numberOfCompanies = 5}) =>
  [...KEY_RISK_AREAS].reduce((combined, keyRiskArea) => {
    const areaLabel = keyRiskArea.label;
    const scores = Object.entries(company.HaloScores)
      .filter(
        ([key, {Value}]) =>
          key.startsWith(`${areaLabel}_`) &&
          !key.endsWith('_Area') &&
          !key.includes('AreaDistribution') &&
          ![null, undefined].includes(Value)
      )
      .map(([key, {Value}], index) => ({
        name: generateLabelFromKeys(key.split('_')[1]),
        y: Value,
        color: colors?.[index] || colors[0],
      }));
    const highToLow = scores
      .sort((a, b) => b.y - a.y)
      .slice(0, numberOfCompanies);
    const lowToHigh = scores
      .sort((a, b) => a.y - b.y)
      .slice(0, numberOfCompanies);
    return {
      ...combined,
      [areaLabel]: {
        highToLow,
        lowToHigh,
        scores,
      },
    };
  }, {});

export default extractForensicData;
