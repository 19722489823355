// ant components
import {message as messageApi} from 'antd';

// contact components
import ContactModal from '../../components/ContactModal/ContactModal';

// contact events
import showContactFormEvent from '../../events/showForm.event.contact';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class ContactContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    email: '',
    loading: false,
    message: '',
    name: '',
    subject: '',
    visible: false,
  };

  state = {...this.constructor.DEFAULT_STATE};

  componentDidMount() {
    this.props.subscribe(showContactFormEvent.subscribe(this.show));
  }

  show = () => {
    if (this.state.loading) return;
    this.setState({
      ...this.constructor.DEFAULT_STATE,
      visible: true,
    });
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  change = (key) => (e) => {
    if (this.state.loading) return;
    this.setState({[key]: e.target.value});
  };

  contact = async () => {
    const {email, loading, message, name, subject} = this.state;

    if (loading) return;

    if (
      !name.trim().length ||
      !email.trim().length ||
      !subject.trim().length ||
      !message.trim().length
    )
      return messageApi.warning('Please insert all fields');

    this.setState({visible: false});
  };

  render() {
    const {email, loading, message, name, subject, visible} = this.state;
    return (
      <ContactModal
        email={email}
        loading={loading}
        message={message}
        name={name}
        onChange={this.change}
        onClose={this.hide}
        onContact={this.contact}
        subject={subject}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(ContactContainer);
