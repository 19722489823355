// globalResilience components
import GlobalResilienceIndexLayout from '../../components/GlobalResilienceIndexLayout/GlobalResilienceIndexLayout';
import GlobalResilienceIndexMapChart from '../../components/GlobalResilienceIndexMapChart/GlobalResilienceIndexMapChart';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// local lib
import convertToMapData from './lib/convertToMapData.lib';

// map api
import getWorldMapApi from '../../../map/api/getWorld.api.map';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class GlobalResilienceIndexContainer extends Component {
  static propTypes = {
    globalResilienceIndexes: PropTypes.array,
  };

  state = {
    loading: true,
    map: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({loading: true});

    try {
      const map = await getWorldMapApi();
      this.setState({loading: false, map});
    } catch (error) {
      this.setState({loading: false});
    }
  };

  render() {
    const {globalResilienceIndexes} = this.props;
    const {loading, map} = this.state;
    return loading ? (
      <FullScreenLoader />
    ) : (
      <GlobalResilienceIndexLayout>
        <GlobalResilienceIndexMapChart
          data={convertToMapData(globalResilienceIndexes)}
          map={map}
        />
      </GlobalResilienceIndexLayout>
    );
  }
}

export default connect((state) => ({
  globalResilienceIndexes: state.dashboard.globalResilienceIndexes,
}))(GlobalResilienceIndexContainer);
