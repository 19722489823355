import styled from 'styled-components';

// layout colors
import brightGreen300Color from '../../../../layout/colors/brightGreen300.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

const CardHeader = styled.div`
  width: 100%;
  height: 44px;
  background: ${brightGreen300Color};
  color: ${whiteColor};
  font-weight: 700;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px 10px 0px 0px;
`;

export default CardHeader;
