import styled from 'styled-components';

const Container = styled.div`
  display: block;

  & span,
  & i {
    line-height: 1em;
    display: block;
    font-size: 18px;
  }
`;

export default Container;
