// esg components
import EsgHistoricChartCard from '../EsgHistoricChartCard/EsgHistoricChartCard';

// local lib
import extractData from './lib/extractData.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const EsgHistory = ({percentileView}) => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  return <EsgHistoricChartCard {...extractData({company, percentileView})} />;
};

EsgHistory.propTypes = {
  percentileView: PropTypes.bool,
};

export default EsgHistory;
