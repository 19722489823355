// dashboard routes
import dashboardRoute from '../../../dashboard/pages/DashboardPage/route';

// auth services
import signOutService from '../../../auth/services/signOut.service.auth';

// local lib
import generatePages from './lib/generatePages.lib';

// navigation components
import Navigation from '../../components/Navigation/Navigation';

// react
import React from 'react';

// router
import {useNavigate} from 'react-router-dom';

// redux
import {useDispatch, useSelector} from 'react-redux';

const NavigationContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {tenant} = useSelector((state) => state.auth);
  const {page} = useSelector((state) => state.layout);
  return (
    <Navigation
      onLogoClick={() => navigate(dashboardRoute())}
      onSignOut={() => signOutService({dispatch, tenant})}
      pages={generatePages({page})}
    />
  );
};

export default NavigationContainer;
