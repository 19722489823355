// date
import {parseISO, formatDistance} from 'date-fns';

const getRelativePublishDate = ({
  newsArticle,
  missingDate = '-',
  currentDate = new Date(),
}) =>
  !!newsArticle?.published_at
    ? formatDistance(parseISO(newsArticle.published_at), currentDate)
    : missingDate;

export default getRelativePublishDate;
