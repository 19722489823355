// date
import {parse, getUnixTime} from 'date-fns';

const orderByDate = ({
  list,
  dateAttribute,
  dateFormat = 'yyyy-MM-dd',
  ascending = true,
}) =>
  [...list].sort((a, b) => {
    const dateA = getUnixTime(parse(a[dateAttribute], dateFormat, new Date()));
    const dateB = getUnixTime(parse(b[dateAttribute], dateFormat, new Date()));
    return ascending ? dateA - dateB : dateB - dateA;
  });

export default orderByDate;
