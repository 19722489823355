import styled from 'styled-components';

// layout colors
import grey200Color from '../../../../layout/colors/grey200.color.layout';

const TableRow = styled.div`
  width: 100%;
  background-color: ${grey200Color};
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-size: 12px;

  &:nth-child(odd) {
    text-align-right;
  }
`;

export default TableRow;
