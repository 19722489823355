// layout colors
import whiteColor from '../../../layout/colors/white.color.layout';

// layout components
import LabeledLineScore from '../../../layout/components/LabeledLineScore/LabeledLineScore';

// local components
import Container from './components/Container';
// import Header from './components/Header';
// import Logo from './components/Logo';
// import LogoContainer from './components/LogoContainer';
// import Name from './components/Name';
// import StockExchange from './components/StockExchange';
import Tag from './components/Tag';
// import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// risk lib
import getRiskColor from '../../../risk/lib/getRiskColor.lib.risk';

const CompanySideMenu = ({
  intrinsicScore,
  logo,
  name,
  stockExchange,
  tags = [],
}) => (
  <Container>
    <LabeledLineScore
      score={intrinsicScore}
      barColor={getRiskColor(intrinsicScore).primary}
      textColor={whiteColor}
    >
      Halo Intrinsic Score
    </LabeledLineScore>
    {tags.map((tag) => (
      <Tag key={tag.key} onClick={tag.onClick} color={tag.color}>
        {tag.label}
      </Tag>
    ))}
  </Container>
);

CompanySideMenu.propTypes = {
  intrinsicScore: PropTypes.number,
  logo: PropTypes.string,
  name: PropTypes.string,
  stockExchange: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
      key: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default CompanySideMenu;
