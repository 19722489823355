// redux store
import reduxStore from '../../redux/store';

// supplier lib
import isSupplierAvailable from '../../supplier/lib/isSupplierAvailable.lib.supplier';

const getCompaniesOptionsService = ({
  companies = reduxStore.getState().supplier.suppliersForSearch,
} = {}) =>
  [...companies]
    .filter((supplier) => isSupplierAvailable({supplier}))
    .map(({CompanyId, CompanyName}) => ({
      value: CompanyId,
      label: CompanyName,
    }))
    .sort((a, b) => {
      const labelA = a.label;
      const labelB = b.label;
      if (labelA < labelB) return -1;
      if (labelA > labelB) return 1;
      return 0;
    });

export default getCompaniesOptionsService;
