import React from 'react';
import styled from 'styled-components';
import ReactSpeedometer from 'react-d3-speedometer';
import {Grid} from './Layout';
import {SEGMENT_COLORS, SEGMENT_STOPS} from '../constants/constants';
import {Typography} from 'antd';
import theme from '../constants/theme';
const {Title: AntTitle} = Typography;

const Title = styled(AntTitle)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
`;

export const Dial = ({value, label, height = 175, width = 300}) => {
  return (
    <Grid direction="column" alignItems="center" spacing={0}>
      <ReactSpeedometer
        textColor={theme.dial.text}
        height={height}
        width={width}
        segments={3}
        minValue={0}
        maxValue={100}
        customSegmentStops={SEGMENT_STOPS}
        value={value}
        segmentColors={SEGMENT_COLORS}
        ringWidth={20}
        valueTextFontSize="32"
        maxSegmentLabels={0}
        needleColor={theme.dashboard.speedometer.needle}
        needleHeightRatio={0.7}
        paddingVertical={10}
      />
      <Title level={5}>{label}</Title>
    </Grid>
  );
};
