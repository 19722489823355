const CYBER_SECURITY_RISK_DESCRIPTIONS = {
  PatchManagement:
    'Company asset system versions are collected from internet-wide scanners like Censys, Shodan, Zoomeye etc. These version numbers are converted into the corresponding common platform enumeration number (CPE-ID) and are correlated with NIST NVD and MITRE CVSS databases to detect and approximate any unmitigated known vulnerabilities.',
  ApplicationSecurity:
    'The contents of each web application are collected from various internet-wide scanners and are analyzed for application level weaknesses i.e. Cross Site Request Forgery, Cross Content Mixing, Plain Text Transmission of Sensitive Information etc. The results are also correlated with MITRE CWE database to detect the severity level of each finding.',
  CDNSecurity:
    'A content delivery network (CDN) is a large distributed system of servers deployed in multiple data centers across the Internet. Companies use CDNs for online libraries like JQuery. This section analyzes the CDN content to detect possible vulnerabilities.',
  WebsiteSecurity:
    'This is a special analysis of the company\u00e2\u20ac\u2122s main website. The findings are collected from the SSL/TLS Strength, Patch Management, Application Security, Web Ranking and Brand Monitoring sub-categories.',
  BrandMonitoring:
    'Brand monitoring is a business analytics process concerned with monitoring various channels on the web or other media to gain insight about the company, brand, and anything explicitly connected to the company in cyberspace.',
  IPReputation:
    'The asset reputation score is based on the number of IPs or domains are blacklisted or that are used for sophisticated APT attacks. The reputation feeds are collected from VirusTotal, Cymon, Firehol, BlackList DNS servers, etc.',
  FraudulentApps:
    'Fraudulent or pirate mobile or desktop applications are used to hack or phish employee or customer data. Possible fraudulent or pirate mobile or desktop apps on Google Play, App Store and pirate app stores are provided.',
  FraudulentDomains:
    'Fraudulent domains and subdomains are extracted from the domain registration database. The registered domains database holds more than 300M records.',
  WebRanking:
    'Cisco, Alexa, and Majestic track web sites and rank them according to popularity, back-links, references, etc. This subcategory shows Alexa and Majestic trends, Google Page insight speed test results as well as Web Content Accessibility Guidelines (WCAG) 2.0 parsing compliance findings.',
  AttackSurface:
    "Attack surface is the technical analysis of open critical ports, out-of-date services, application weaknesses, SSL/TLS strength, and any misconfigurations. This information is gathered from Censys and Shodan databases and service/application versions are correlated with other subcategories' results.",
  DNSHealth:
    'The DNS Health report is generated from 40+ control items which are collected from online services like IntoDNS, Robtex, Netcraft, and HackerTarget. Since DNS queries are recursive, it is almost impossible to detect a hacker\u00e2\u20ac\u2122s footprints from the DNS servers.',
  EmailSecurity:
    "Potential email servers and SMTP misconfigurations like open relay, unauthenticated logins, restricted relay, and SMTP 'Verify' vulnerabilities are collected from the online services like MxToolbox and eMailSecurityGrader.",
  DDoSResiliency:
    'This section shows the result of 15 different potential DDoS checks and detects any potential DrDoS amplification endpoints. The data is collected from non-intrusive scanners and other internet-wide scanners.',
  NetworkSecurity:
    'This section analyzes network level problems and detects any open critical ports, unprotected network devices, misconfigured firewalls, and service endpoints on public-facing assets.',
  SSLStrength:
    'SSL/TLS configurations and vulnerabilities are provided by several third-party online services. The results come from various online SSL grading services like Qualys SSL Labs scanner, HTBridge, Mozilla Website Observatory etc.',
  CredentialManagement:
    'There are 5+ billion hacked emails and passwords available on the internet and underground forums. This section shows the leaked or hacked emails and passwords that were discovered. * Only the findings that have upper than low severity levels are shown.',
  HacktivistShares:
    'Hackers publicize their targets in underground forums or on the darkweb.  Black Kite collects information from hundreds of darkweb forums, criminal sites, and hacktivist sites and filters the results for information pertaining to the company.',
  SocialNetwork:
    'Hackers publicize their targets or victims on social network sites to motivate other hackers to attack the same target. The results are filtered from billions of pieces of social media content.',
  InformationDisclosure:
    'Misconfigured services or other public assets may disclose local IPs, email addresses, version numbers, whois privacy records, and other sensitive information to the internet.',
};

export default CYBER_SECURITY_RISK_DESCRIPTIONS;
