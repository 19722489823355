// local columns
import alsoKnownAsColumn from '../columns/alsoKnownAs.column';
import countriesOfOperationColumn from '../columns/countriesOfOperation.column';
// import diversityColumn from '../columns/diversity.column';
import dunsColumn from '../columns/duns.column';
import hqAddressColumn from '../columns/hqAddress.column';
import hqCountryColumn from '../columns/hqCountry.column';
import hqStateColumn from '../columns/hqState.column';
import incorporationDateColumn from '../columns/incorporationDate.column';
// import ipoDateColumn from '../columns/ipoDate.column';
import legalNameColumn from '../columns/legalName.column';
// import leiColumn from '../columns/lei.column';
import longDescriptionColumn from '../columns/longDescription.column';
// import naceCodeColumn from '../columns/naceCode.column';
// import naceNamingColumn from '../columns/naceNaming.column';
import naicsCodesColumn from '../columns/naicsCodes.column';
import numberOfEmployeesColumn from '../columns/numberOfEmployees.column';
import phoneColumn from '../columns/phone.column';
import primaryIndustryColumn from '../columns/primaryIndustry.column';
// import serviceAreasColumn from '../columns/serviceAreas.column';
// import shortDescriptionColumn from '../columns/shortDescription.column';
import sicCodeColumn from '../columns/sicCode.column';
import socialMediaColumn from '../columns/socialMedia.column';
import taxIdColumn from '../columns/taxId.column';
import ultimateParentCompanyColumn from '../columns/ultimateParentCompany.column';
import webAddressColumn from '../columns/webAddress.column';

// local lib
import generateColumnGroup from '../lib/generateColumnGroup.lib';

const firmographicColumnGroup = generateColumnGroup({
  name: 'Firmographic',
  size: {x: 4500},
  columns: [
    ultimateParentCompanyColumn,
    numberOfEmployeesColumn,
    primaryIndustryColumn,
    hqAddressColumn,
    hqStateColumn,
    hqCountryColumn,
    phoneColumn,
    webAddressColumn,
    // leiColumn,
    incorporationDateColumn,
    // ipoDateColumn,
    sicCodeColumn,
    // naceCodeColumn,
    // naceNamingColumn,
    naicsCodesColumn,
    dunsColumn,
    taxIdColumn,
    // serviceAreasColumn,
    // shortDescriptionColumn,
    longDescriptionColumn,
    countriesOfOperationColumn,
    // diversityColumn,
    legalNameColumn,
    alsoKnownAsColumn,
    socialMediaColumn,
  ],
});

export default firmographicColumnGroup;
