const parseChartData = ({company, parsedData}) => {
  const categories = [
    ...(company?.historicalFinancials?.liquidityModule?.periods || []),
  ];
  return {
    categories,
    data: [...parsedData.entries].map(({section, key, ...values}) => ({
      name: section,
      data: [...categories].reduce(
        (combined, category) => [...combined, values[category]],
        []
      ),
    })),
  };
};

export default parseChartData;
