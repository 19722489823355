// esg constants
import ENVIRONMENT_DESCRIPTION from '../../../../esg/constants/environmentDescription.constant.esg';
import GOVERNANCE_DESCRIPTION from '../../../../esg/constants/governanceDescription.constant.esg';
import SOCIAL_DESCRIPTION from '../../../../esg/constants/socialDescription.constant.esg';

// layout colors
import blueGrey100Color from '../../../../layout/colors/blueGrey100.color.layout';
import blueGrey300Color from '../../../../layout/colors/blueGrey300.color.layout';
import green100Color from '../../../../layout/colors/green100.color.layout';
import green400Color from '../../../../layout/colors/green400.color.layout';
import red100Color from '../../../../layout/colors/red100.color.layout';
import red400Color from '../../../../layout/colors/red400.color.layout';

const extractEsgData = ({company}) => ({
  categories: ['Environment', 'Social', 'Governance'],
  descriptions: {
    Environment: ENVIRONMENT_DESCRIPTION,
    Social: SOCIAL_DESCRIPTION,
    Governance: GOVERNANCE_DESCRIPTION,
  },
  data: [
    {
      name: 'Environment Score',
      data: [{name: 'Environment', y: company?.ESG?.Environment}],
      color: green400Color,
    },
    {
      name: 'Environment Emissions',
      data: [{name: 'Environment', y: company?.ESG?.EnvironmentEmissions}],
      color: green100Color,
    },
    {
      name: 'Environment Resource use',
      data: [{name: 'Environment', y: company?.ESG?.EnvironmentResourceUse}],
      color: green100Color,
    },
    {
      name: 'Environment Innovation',
      data: [{name: 'Environment', y: company?.ESG?.EnvionmentInnovation}],
      color: green100Color,
    },
    {
      name: 'Social Score',
      data: [{name: 'Social', y: company?.ESG?.Social}],
      color: blueGrey300Color,
    },
    {
      name: 'Social Human Rights',
      data: [{name: 'Social', y: company?.ESG?.SocialHumanRights}],
      color: blueGrey100Color,
    },
    {
      name: 'Social Product Responsibility',
      data: [{name: 'Social', y: company?.ESG?.SocialProductReponsibility}],
      color: blueGrey100Color,
    },
    {
      name: 'Social Workforce',
      data: [{name: 'Social', y: company?.ESG?.SocialWorkforce}],
      color: blueGrey100Color,
    },
    {
      name: 'Social Community',
      data: [{name: 'Social', y: company?.ESG?.SocialCommunity}],
      color: blueGrey100Color,
    },
    {
      name: 'Governance Score',
      data: [{name: 'Governance', y: company?.ESG?.Governance}],
      color: red400Color,
    },
    {
      name: 'Governance Management',
      data: [{name: 'Governance', y: company?.ESG?.GovernanceManagement}],
      color: red100Color,
    },
    {
      name: 'Governance Shareholders',
      data: [{name: 'Governance', y: company?.ESG?.GovernanceShareholders}],
      color: red100Color,
    },
    {
      name: 'CSR Strategy',
      data: [{name: 'Governance', y: company?.ESG?.CSRStrategy}],
      color: red100Color,
    },
  ],
});

export default extractEsgData;
