// company components
import CompanyComplianceScoreColumn from '../../../components/CompanyComplianceScoreColumn/CompanyComplianceScoreColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Compliance Score',
  Component: CompanyComplianceScoreColumn,
  sorter: (a, b) => {
    return (
      Number(a?.HaloScores?.Compliance_Area?.Value || 0) -
      Number(b?.HaloScores?.Compliance_Area?.Value || 0)
    );
  },
});
