// antd
import {Table} from 'antd';

// layout components
import Card from '../../../layout/components/Card/Card';

// local lib
import extractCashFlowSummary from './lib/extractCashFlowSummary.lib';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialsCashFlowSummarySection = () => {
  const {company} = useSelector((state) => state.company);

  const parsedData = extractCashFlowSummary({company});

  return (
    <Card noPadding>
      <Table
        columns={parsedData.categories}
        dataSource={parsedData.entries}
        pagination={false}
        rowKey="section"
        size="small"
        locale={{emptyText: 'Cash flow summary not available'}}
      />
    </Card>
  );
};

export default FinancialsCashFlowSummarySection;
