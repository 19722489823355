import styled from 'styled-components';

// layout colors
import blueGrey200Color from '../../../../layout/colors/blueGrey200.color.layout';
import whiteColor from '../../../colors/white.color.layout';

const Content = styled.div`
  width: 100%;
  border: 1px solid ${blueGrey200Color};
  background-color: ${whiteColor};
  padding: ${({padding}) => (padding ? '10px' : '0')};
`;

export default Content;
