// cyberSecurity events
import showCyberRatingDetailEvent from '../../../../cyberSecurity/events/showRatingDetail.event.cyberSecurity';

const cyberGradeClickHandler =
  ({company}) =>
  () => {
    const cyberRating = {
      value: company?.CyberSecurity?.CyberRating || 0,
      Description: 'Total aggregated score of all findings',
      shortDescription: 'Total aggregated score of all findings',
      grade: company?.CyberSecurity?.Grade || 'F',
      tag: 'Total',
      tagName: 'All Findings',
    };
    showCyberRatingDetailEvent.publish({cyberRating, company});
  };

export default cyberGradeClickHandler;
