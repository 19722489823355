const cyberWeights = {
  DigitalFootprint: 0.0,
  DNSHealth: 0.06,
  EmailSecurity: 0.06,
  SSLStrength: 0.06,
  ApplicationSecurity: 0.09,
  DDoSResiliency: 0.04,
  NetworkSecurity: 0.06,
  FraudulentDomains: 0.05,
  FraudulentApps: 0.03,
  CredentialManagement: 0.09,
  IPReputation: 0.07,
  HacktivistShares: 0.05,
  SocialNetwork: 0.03,
  AttackSurface: 0.04,
  BrandMonitoring: 0.03,
  PatchManagement: 0.1,
  WebRanking: 0.02,
  InformationDisclosure: 0.03,
  CDNSecurity: 0.03,
  WebsiteSecurity: 0.06,
};

const getCyberWeight = (cyberFactor) => cyberWeights?.[cyberFactor] || 0;

export default getCyberWeight;
