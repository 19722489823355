const CREDIT_SAFE_STATUS_ALERT_TRIGGERS = [
  {
    value: 'cs_companyStatus',
    title: 'Company Status',
    provider: 'cs',
    type: 'boolean',
  },
  {
    value: 'cs_bankruptcy',
    title: 'Bankruptcy',
    provider: 'cs',
    type: 'boolean',
  },
  {
    value: 'cs_possibleOFAC',
    title: 'Possible OFAC',
    provider: 'cs',
    type: 'boolean',
  },
];

export default CREDIT_SAFE_STATUS_ALERT_TRIGGERS;
