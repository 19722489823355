// chart components
import InnovationChartCard from '../../../chart/components/InnovationChartCard/InnovationChartCard';
import InnovationConsolidatedChartCard from '../../../chart/components/InnovationConsolidatedChartCard/InnovationConsolidatedChartCard';

// layout components
import {Column, Row} from '../../../layout/components/Grid/Grid';

// local lib
import extractConsolidatedData from './lib/extractConsolidatedData.lib';
import extractInnovationScore from './lib/extractInnovationScore.lib';
import renderInnovationChart from './lib/renderInnovationChart.lib';
import renderConsolidatedInnovationChart from './lib/renderConsolidatedInnovationChart.lib';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const CompanyInnovationSection = () => {
  const {company} = useSelector((state) => state.company);

  return (
    <Fragment>
      <Row>
        <Column>
          <Row>
            <Column size={3 / 5}>
              {renderInnovationChart({company}) && (
                <InnovationChartCard
                  {...extractInnovationScore({company})}
                  companyName={company.CompanyName}
                />
              )}
            </Column>
            <Column size={2 / 5}>
              {renderConsolidatedInnovationChart({company}) && (
                <InnovationConsolidatedChartCard
                  {...extractConsolidatedData({company})}
                />
              )}
            </Column>
          </Row>
        </Column>
      </Row>
    </Fragment>
  );
};

export default CompanyInnovationSection;
