// string lib
import isStringUppercase from '../string/lib/isUppercase.lib.string';

const generateLabelFromKeys = (key, {manualOverrides = {}} = {}) =>
  manualOverrides?.[key] ||
  // (key === key.toUpperCase() ? key : key.replace(/([a-z])([A-Z])/g, '$1 $2'));
  (key === key.toUpperCase()
    ? key
    : key
        .split(/([A-Z])/g)
        .filter((word) => !!word.trim().length)
        .reduce((combined, current, index, words) => {
          const nextWord = words?.[index + 1];
          return [combined, current].join(
            !index ||
              !isStringUppercase(current) ||
              (isStringUppercase(current) &&
                nextWord !== undefined &&
                isStringUppercase(nextWord))
              ? ''
              : ' '
          );
        }, ''));

export default generateLabelFromKeys;
