// dashboard api
import getDashboardApi from '../api/getDashboard.api.dashboard';

const loadDashboardData = async () => {
  const tenantData = await getDashboardApi();

  return tenantData;
};

export default loadDashboardData;
