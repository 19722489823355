// chart components
import HaloHistoricIntrinsicScoreChartCard from '../../../chart/components/HaloHistoricIntrinsicScoreChartCard/HaloHistoricIntrinsicScoreChartCard';
import HaloHistoricKeyAreaScoresChartCard from '../../../chart/components/HaloHistoricKeyAreaScoresChartCard/HaloHistoricKeyAreaScoresChartCard';
import HaloIntrinsicScoreChartCard from '../../../chart/components/HaloIntrinsicScoreChartCard/HaloIntrinsicScoreChartCard';
import RiskAreaChartCard from '../../../chart/components/RiskAreaChartCard/RiskAreaChartCard';
import StrategicScoresChartCard from '../../../chart/components/StrategicScoresChartCard/StrategicScoresChartCard';

// company constants
import HALO_INTRINSIC_SCORE_DESCRIPTION from '../../constants/haloIntrinsicScoreDescription.constant.company';
import HALO_SCORES_FOR_KEY_RISK_AREAS_DESCRIPTION from '../../constants/haloScoresForKeyRiskAreasDescription.constant.company';
import SCORE_DISTRIBUTION_ACROSS_KEY_AREAS_DESCRIPTION from '../../constants/scoreDistributionAcrossKeyAreasDescription.constant.company';
import SCORE_DISTRIBUTION_RISK_AREA_DESCRIPTIONS from '../../constants/scoreDistributionRiskAreaDescriptions.constant.company';

// layout components
import {Column, Row} from '../../../layout/components/Grid/Grid';

// local lib
import extractHistoricIntrinsicScoreData from './lib/extractHistoricIntrinsicScoreData.lib';
import extractHistoricKeyRiskAreaScores from './lib/extractHistoricKeyRiskAreaScores.lib';
import extractRiskAreaData from './lib/extractRiskAreaData.lib';
import extractStrategicScores from './lib/extractStrategicScores.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const CompanyHaloScoresSection = ({print = false}) => {
  const {company} = useSelector((state) => state.company);

  return (
    <Fragment>
      <Row margin>
        <Column margin={print} size={print ? 1 / 2 : 1 / 5}>
          <HaloIntrinsicScoreChartCard
            info={HALO_INTRINSIC_SCORE_DESCRIPTION}
            score={company?.HaloScores?.HaloScore?.Value}
          />
        </Column>
        <Column size={print ? 1 : 4 / 5}>
          <HaloHistoricIntrinsicScoreChartCard
            data={extractHistoricIntrinsicScoreData({company})}
          />
        </Column>
      </Row>
      <Row margin>
        <Column margin={print} size={print ? 1 : 1 / 2}>
          <StrategicScoresChartCard
            data={extractStrategicScores({company})}
            dataDescriptions={SCORE_DISTRIBUTION_RISK_AREA_DESCRIPTIONS}
            info={SCORE_DISTRIBUTION_ACROSS_KEY_AREAS_DESCRIPTION}
          />
        </Column>
        <Column size={print ? 1 : 1 / 2}>
          <RiskAreaChartCard
            {...extractRiskAreaData({company})}
            ratingInfo={HALO_SCORES_FOR_KEY_RISK_AREAS_DESCRIPTION}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <HaloHistoricKeyAreaScoresChartCard
            data={extractHistoricKeyRiskAreaScores({company})}
          />
        </Column>
      </Row>
    </Fragment>
  );
};

CompanyHaloScoresSection.propTypes = {
  print: PropTypes.bool,
};

export default CompanyHaloScoresSection;
