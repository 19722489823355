import styled from 'styled-components';

// layout colors
import whiteColor from '../../../colors/white.color.layout';

const Text = styled.div`
  font-weight: 700;
  color: ${whiteColor};
  font-size: 20px;
`;

export default Text;
