// date
import {format, parse} from 'date-fns';

// number lib
import twoDecimals from '../../../../number/lib/twoDecimals.lib.number';

// risk lib
import getRiskColor from '../../../../risk/lib/getRiskColor.lib.risk';

const extractHistoricIntrinsicScoreData = ({data}) =>
  [...data].map(({Date: date, HaloScore}) => {
    const value = twoDecimals(HaloScore?.Value || 0);
    return {
      name: format(parse(date, 'yyyyMMdd', new Date()), 'MMM d'),
      y: value,
      color: getRiskColor(value).primary,
    };
  });

export default extractHistoricIntrinsicScoreData;
