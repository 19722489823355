// ant components
import {Col as Column, Row, Table} from 'antd';

// financial components
import CreditSafeYearlyCharts from '../CreditSafeYearlyCharts/CreditSafeYearlyCharts';

// layout colors
import brightGreen300Color from '../../../layout/colors/brightGreen300.color.layout';
import orange300Color from '../../../layout/colors/orange300.color.layout';

// local assets
import scoreChartAsset from './assets/scoreChart.png';
import trendChartAsset from './assets/trendChart.png';

// layout components
import Card from '../../../layout/components/Card/Card';
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local components
import SummaryColumn from './components/SummaryColumn';
import SummaryImage from './components/SummaryImage';
import SummaryLine from './components/SummaryLine';
import SummaryRow from './components/SummaryRow';
import SummaryText from './components/SummaryText';
import SummaryValue from './components/SummaryValue';
import SummaryValueLine from './components/SummaryValueLine';

// local constants
import CHARTS from './constants/charts.constant';
import TABLES from './constants/tables.constant';

// local lib
import getColumns from './lib/getColumns.lib';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const CreditSafeSection = () => {
  const {company} = useSelector((state) => state.company);

  const columns = getColumns({
    years: company?.creditSafe?.years,
  });

  const charts = CHARTS(company?.creditSafe);

  return (
    <Fragment>
      <Row gutter={[20, 20]}>
        <Column span={24}>
          <StatementBlock title={company.CompanyName}>
            <Card noPadding>
              <SummaryRow>
                <SummaryColumn>
                  Credit index
                  <SummaryValue>
                    {company?.creditSafe?.general?.creditIndex}
                  </SummaryValue>
                  <SummaryValueLine color={brightGreen300Color} />
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  Risk Score
                  <SummaryValue>
                    {company?.creditSafe?.general?.riskScore}
                  </SummaryValue>
                  <SummaryValueLine color={brightGreen300Color} />
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  International Score
                  <SummaryValue>
                    {company?.creditSafe?.general?.internationalScore}
                  </SummaryValue>
                  <SummaryValueLine color={brightGreen300Color} />
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  Probability of default
                  <SummaryValue>
                    {company?.creditSafe?.general?.probabilityOfDefault}
                  </SummaryValue>
                  <SummaryValueLine color={brightGreen300Color} />
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  Credit Limit
                  <SummaryValue>
                    {company?.creditSafe?.general?.creditLimit}
                  </SummaryValue>
                  <SummaryValueLine color={brightGreen300Color} />
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  DBT
                  <SummaryValue>
                    {company?.creditSafe?.general?.DBT}
                  </SummaryValue>
                  <SummaryValueLine />
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  Status
                  <SummaryValue>
                    {company?.creditSafe?.general?.status}
                  </SummaryValue>
                  <SummaryValueLine />
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  DBT Trends
                  <SummaryValue>
                    <SummaryImage src={scoreChartAsset} />
                  </SummaryValue>
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  Inquiries Trends
                  <SummaryValue>
                    <SummaryImage src={trendChartAsset} />
                  </SummaryValue>
                </SummaryColumn>
              </SummaryRow>
              <SummaryText>{company?.creditSafe?.general?.info}</SummaryText>
            </Card>
          </StatementBlock>
        </Column>
        <Column span={12}>
          <StatementBlock title="Revenue">
            <Card noPadding>
              <Table
                columns={columns.summaryRevenue}
                dataSource={company?.creditSafe?.summary?.revenue}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={12}>
          <StatementBlock
            title="Employee Information"
            tooltip={company?.creditSafe?.summary?.employeeInformation?.info}
          >
            <Card noPadding>
              <Table
                columns={columns.employeeInformation}
                dataSource={
                  company?.creditSafe?.summary?.employeeInformation?.data
                }
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock title="Key Financials">
            <Card noPadding>
              <Table
                columns={columns.yearlyTableWithGrowth}
                dataSource={company?.creditSafe?.keyFinancials}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock
            title="Payment Details Summary"
            tooltip={company?.creditSafe?.paymentDetailsSummary?.info}
          >
            <Card noPadding>
              <SummaryRow small>
                <SummaryColumn>
                  Company DBT
                  <SummaryValue>
                    {company?.creditSafe?.paymentDetailsSummary?.companyDBT}
                  </SummaryValue>
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  Industry DBT
                  <SummaryValue>
                    {company?.creditSafe?.paymentDetailsSummary?.industryDBT}
                  </SummaryValue>
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  Region DBT
                  <SummaryValue>
                    {company?.creditSafe?.paymentDetailsSummary?.regionDBT}
                  </SummaryValue>
                </SummaryColumn>
              </SummaryRow>
            </Card>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock title="Commentaries">
            <Card>
              {(company?.creditSafe?.commentaries || []).map((comment) => (
                <div key={comment}>{comment}</div>
              ))}
            </Card>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock title="Score summary">
            <Card noPadding>
              <SummaryRow>
                <SummaryColumn>
                  Today's Credit Index
                  <SummaryValue>
                    {
                      company?.creditSafe?.scoreLimit?.scoreSummary
                        ?.todaysCreditIndex
                    }
                  </SummaryValue>
                  <SummaryValueLine color={brightGreen300Color} />
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  Previous Credit Index
                  <SummaryValue>
                    {
                      company?.creditSafe?.scoreLimit?.scoreSummary
                        ?.previousCreditIndex
                    }
                  </SummaryValue>
                  <SummaryValueLine color={orange300Color} />
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  Credit Worthiness
                  <SummaryValue>
                    {
                      company?.creditSafe?.scoreLimit?.scoreSummary
                        ?.creditWorthiness
                    }
                  </SummaryValue>
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  Low Risk Profile
                  <SummaryValue>
                    {
                      company?.creditSafe?.scoreLimit?.scoreSummary
                        ?.lowRiskProfile
                    }
                  </SummaryValue>
                </SummaryColumn>
              </SummaryRow>
            </Card>
          </StatementBlock>
        </Column>
        <Column span={12}>
          <StatementBlock
            title="Credit Limit"
            tooltip={company?.creditSafe?.creditLimit?.definition}
          >
            <Card noPadding>
              <SummaryRow small>
                <SummaryColumn>
                  Today's Limit
                  <SummaryValue>
                    {company?.creditSafe?.creditLimit?.todaysLimit}
                  </SummaryValue>
                </SummaryColumn>
                <SummaryLine />
                <SummaryColumn>
                  Previous Limit
                  <SummaryValue>
                    {company?.creditSafe?.creditLimit?.previousLimit}
                  </SummaryValue>
                </SummaryColumn>
              </SummaryRow>
            </Card>
          </StatementBlock>
        </Column>
        <Column span={12}>
          <StatementBlock
            title="Contract Limit"
            tooltip={company?.creditSafe?.contractLimit?.definition}
          >
            <Card noPadding>
              <SummaryRow small>
                <SummaryColumn>
                  Today's Limit
                  <SummaryValue>
                    {company?.creditSafe?.contractLimit?.todaysLimit}
                  </SummaryValue>
                </SummaryColumn>
              </SummaryRow>
            </Card>
          </StatementBlock>
        </Column>
        {[...TABLES].map((table) => (
          <Column span={24} key={table.title}>
            <StatementBlock title={table.title}>
              <Card noPadding>
                <Table
                  columns={columns.yearlyTableWithGrowth}
                  dataSource={table.dataPoint(company?.creditSafe)}
                  pagination={false}
                  rowKey="section"
                  size="small"
                />
              </Card>
            </StatementBlock>
          </Column>
        ))}
        {charts.map((chart) => (
          <Column span={24} key={chart.title}>
            <CreditSafeYearlyCharts
              {...chart}
              years={company?.creditSafe?.years}
            />
          </Column>
        ))}
        <Column span={12}>
          <StatementBlock title="Company Information">
            <Card noPadding>
              <Table
                columns={columns.headlessKeyValue}
                dataSource={company?.creditSafe?.companyInformation}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={12}>
          <StatementBlock title="Contact Information">
            <Card noPadding>
              <Table
                columns={columns.headlessKeyValue}
                dataSource={company?.creditSafe?.contactInformation}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock title="Legal Events">
            <Card noPadding>
              <Table
                columns={columns.legalEvents}
                dataSource={company?.creditSafe?.legalEvents}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock title="Ultimate Holding Company">
            <Card noPadding>
              <Table
                columns={columns.ultimateHoldingCompany}
                dataSource={company?.creditSafe?.ultimateHoldingCompany}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock title="Previous Names">
            <Card noPadding>
              <Table
                columns={columns.previousNames}
                dataSource={company?.creditSafe?.previousNames}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock title="Legal Contracts">
            <Card noPadding>
              <Table
                columns={columns.legalContracts}
                dataSource={company?.creditSafe?.legalContracts}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock title="Auditor Details">
            <Card noPadding>
              <Table
                columns={columns.auditorDetails}
                dataSource={company?.creditSafe?.auditorDetails}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column span={24}>
          <StatementBlock title="Bankers Details">
            <Card noPadding>
              <Table
                columns={columns.bankerDetails}
                dataSource={company?.creditSafe?.bankerDetails}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
      </Row>
    </Fragment>
  );
};

export default CreditSafeSection;
