import questionsJSON from '../mocks/questions.json';
import nlpJSON from '../mocks/nlp.json';
import nlpBCPJSON from '../mocks/nlp-bcp.json';
import nlpDRPJSON from '../mocks/nlp-drp.json';

const useApi = () => {
  const getQuestions = (type) => {
    return {[type]: questionsJSON[type]};
  };

  const getAnswersNLP = (type) => {
    const nlp = {
      Arrangement: nlpJSON,
      BCP: nlpBCPJSON,
      DRP: nlpDRPJSON,
    };
    return nlp[type];
  };

  const getUploadAnswers = () => {
    const uploadAnswers = {};
    Object.keys(questionsJSON).forEach((section) => {
      questionsJSON[section].forEach((question) => {
        const uploadResponse = question.responses.reduce(
          (previous, current) =>
            current.action === 'upload' ? current : previous,
          null
        );
        if (uploadResponse) {
          uploadAnswers[section] = uploadAnswers[section] || [];
          uploadAnswers[section].push({
            uploadType: uploadResponse.type,
            questionId: question.id,
            questionText: question.text,
            responseId: uploadResponse.id,
            responseText: uploadResponse.text,
            responseAction: uploadResponse.action,
            responseTriggers: uploadResponse.triggers,
            section: section,
          });
        }
      });
    });
    return uploadAnswers;
  };

  return {
    getQuestions,
    getAnswersNLP,
    getUploadAnswers,
  };
};

export default useApi;
