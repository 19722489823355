// company components
import CompanyActiveInvestorsColumn from '../../../components/CompaniesTableColumns/dynamicColumns/CompanyActiveInvestorsColumn/CompanyActiveInvestorsColumn';
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Active Investors',
  width: 250,
  componentProps: ({company}) => ({
    children: (
      <CompanyActiveInvestorsColumn
        investors={company?.Financials?.ActiveInvestors}
      />
    ),
  }),
  Component: CompanyDefaultDataColumn,
});
