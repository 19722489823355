// company routes
import {
  dueDiligenceRoute,
  createArrangementRoute,
  confirmedArrangementRoute,
} from '../../pages/DueDiligencePage/route';
import supplierDashboardRoute from '../../pages/SuppliersDashboardPage/route';

// ant components
import {
  Button,
  Radio,
  Space,
  Spin,
  Steps,
  Upload,
  Input,
  Select,
  DatePicker,
  Collapse,
  Modal,
  Typography,
} from 'antd';

// react
import React, {useEffect, useReducer, useState, Fragment} from 'react';

// react redux
import {useSelector} from 'react-redux';

// styled components
import styled from 'styled-components';

import {FiArrowLeft, FiArrowRight} from 'react-icons/fi';
import {Link, useParams, useLocation, useNavigate} from 'react-router-dom';
import {UploadOutlined} from '@ant-design/icons';

// dayjs
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

// import {useDispatch} from 'react-redux';

import AnswersReview from './components/AnswersReview';
import List from './components/List';
import DueDiligenceCard from './components/DueDiligenceCard';
import useApi from './hooks/use-api';
import useDimensions from './hooks/use-dimensions';
import theme from './constants/theme';
import User from './objects/user';
import Company from './objects/company';
import Arrangement from './objects/arrangement';

import {ReactComponent as HaloSmall} from './assets/halo-small-single-color.svg';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const {TextArea} = Input;
const {Option} = Select;
const {Panel} = Collapse;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1px 0px;

  #radio-group-space .ant-space-item {
    width: 100%;
  }

  .ant-picker-input input {
    color: black;
  }

  .ant-list-item {
    border-bottom: none;
  }

  .ant-steps-item {
    margin-bottom: -12px;
    padding: 15px 0 4px 0 !important;
    top: -12px;
    border-radius: 10px;
    &:after,
    &:before {
      display: none !important;
    }
  }

  .ant-steps-item-active {
    background-color: #405b68;

    .ant-steps-item-icon {
      background-color: #2ec352;
      border-color: #2ec352;

      .ant-progress-circle-path {
        stroke: #2ec352;
      }

      .ant-progress-circle-trail {
        stroke: #f0fff1;
      }
    }

    .ant-steps-item-title {
      color: ${theme.arrangement.textActive} !important;
    }
  }

  .ant-steps-navigation {
    background-color: ${theme.arrangement.step};
    border-radius: 10px;
    border: 1px solid #d9d9d9;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const ConfirmedContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 8px;
`;

const Question = styled.div`
  margin: 20px auto 0;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 24px 64px;
  background-color: #ffffff;
`;

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;

  h2 {
    color: #405b68;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
  }

  h3 {
    color: ${theme.arrangement.answer};
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }

  span {
    color: gray;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  padding-right: 10px;
`;

const Label = styled.h4`
  margin-left: 10px;
  color: #2ec352;
  margin-bottom: 0;
  margin-top: 0px;
  font-weight: 500;
`;

const StepsContainer = styled.div`
  display: flex;
  position: fixed;
  width: ${({width}) => Math.min(width, 1250) - 50}px;
  z-index: 1;
  padding: 6px 32px 0 0;
  background-color: ${theme.arrangement.background};
  margin-top: 48px;
`;

const Top = styled.div`
  display: flex;
  position: fixed;
  width: ${({width}) => Math.min(width, 1250) - 80}px;
  z-index: 2;
  padding: 5px 99px 16px 0;
  background-color: ${theme.arrangement.background};
`;

const Left = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const Right = styled.div`
  align-items: center;
  display: flex;
  margin-top: 13px;
  margin-right: -97px;
`;

const ConditionalWrapper = ({condition, wrapper, children}) =>
  condition ? wrapper(children) : children;

const DueDiligenceSection = () => {
  useDimensions();
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  const {dimensions} = useSelector((state) => state.dimensions);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(dimensions.width - 235);
  }, [dimensions]);

  const isCreate = location.pathname.endsWith('/create');
  const isOverview = location.pathname.endsWith('/due-diligence');
  const isSelfSecurityReport = location.pathname === '/reports/security';
  const isStart = isSelfSecurityReport;
  const startingStartSection = isSelfSecurityReport ? 'self' : null;

  const {tenant} = useSelector((state) => state.auth);
  const {user, company} = useSelector((state) => ({
    user: state.auth.user,
    company: !isSelfSecurityReport ? state.company.company : {
      CompanyId: tenant.id,
      CompanyName: tenant.name
    },
  }));

  const userObject = User.getUser(user.email);
  const companyObject = Company.getCompany(company.CompanyId, userObject);
  const isActive = companyObject.isActive() || isSelfSecurityReport;

  const isCreateRedirect = !isCreate && !isActive;
  useEffect(() => {
    if (isCreateRedirect) {
      navigate(createArrangementRoute(company.CompanyId));
    }
  }, [isCreateRedirect, navigate, company.CompanyId]);

  const params = useParams();
  const {arrangementId} = isStart ? {} : params;
  const [currentArrangementId, setCurrentArrangementId] = useState(null);
  const arrangementObject = isSelfSecurityReport
    ? companyObject.securityReport ||
      Arrangement.createArrangement(companyObject, true)
    : companyObject.arrangements[currentArrangementId] ||
      companyObject.arrangements[arrangementId] ||
      companyObject.getDraftArrangement() ||
      (!isOverview && Arrangement.createArrangement(companyObject)) ||
      new Arrangement();

  if (currentArrangementId === null)
    setCurrentArrangementId(arrangementObject.id);

  const [reviewed, setReviewed] = useState(false);
  const search = new URLSearchParams(location.search);
  const param = search.get('q');
  const isContinueIT = param === 'it';
  const isContinueSecurity = param === 'security';
  const isContinueBCM = param === 'bcm';
  const isContinuePrivacy = param === 'privacy';
  const isContinueCompliance = param === 'compliance';
  const isContinueFinance = param === 'finance';
  const isContinue =
    isContinueIT ||
    isContinueSecurity ||
    isContinueBCM ||
    isContinuePrivacy ||
    isContinueCompliance ||
    isContinueFinance;
  const controlFunction = isContinueIT
    ? 'Information Technology'
    : isContinueSecurity
    ? 'Information Security'
    : isContinueBCM
    ? 'Business Continuity'
    : isContinuePrivacy
    ? 'Privacy'
    : isContinueCompliance
    ? 'Compliance'
    : isContinueFinance
    ? 'Finance'
    : null;
  const startingQuestion = 'initial-1';
  const startingNLPQuestion = startingQuestion;
  const startingSection = reviewed
    ? 'review'
    : isStart
    ? 'start'
    : isContinue
    ? 'continue'
    : 'initial';
  const startingContinueSection = isContinue ? param : null;

  const reducerAnswers = (state, action) => {
    switch (action.type) {
      case 'add': {
        const {
          nlp = '',
          isNLP,
          uploadType,
          questionId,
          questionText,
          questionCondition,
          responseId,
          responseText,
          responseAction,
          responseTriggers,
          section,
        } = action.payload;
        const newState = {
          ...state,
          [`${section}-${questionId}`]: {
            nlp,
            isNLP,
            uploadType,
            questionId,
            questionText,
            questionCondition,
            responseId,
            responseText,
            responseAction,
            responseTriggers,
            section,
          },
        };
        if (uploadType) {
          arrangementObject.uploads[section] =
            arrangementObject.uploads[section] || {};
          arrangementObject.uploads[section][uploadType] =
            responseAction === 'upload' && responseText;
        }
        arrangementObject.answers = newState;
        arrangementObject.save();
        setRefresh(true);
        return newState;
      }
      case 'reset': {
        const newState = {};
        if (isStart || isContinue) {
          const section = startingStartSection || startingContinueSection;
          Object.keys(state).forEach((key) => {
            const isKeepAnswer =
              state[key].section !== section ||
              (state[key].nlp && state[key].nlp.split('-')[0] !== section);
            if (isKeepAnswer) newState[key] = state[key];
          });
          delete arrangementObject.uploads[section];
          delete arrangementObject.confirmations[section];
          arrangementObject.answers = newState;
          arrangementObject.save();
        } else {
          delete companyObject.arrangements[arrangementObject.id];
          companyObject.save();
        }
        setRefresh(true);
        return newState;
      }
      case 'resync': {
        const newState = arrangementObject.answers;
        setRefresh(true);
        return newState;
      }
      default:
        return state;
    }
  };

  const reducerQuestions = (state, action) => {
    switch (action.type) {
      case 'add': {
        const {question, section} = action.payload;
        return {
          ...state,
          [`${section}-${question.id}`]: {...question, section},
        };
      }
      case 'reset':
        return {};
      default:
        return state;
    }
  };

  const [currentQuestion, setCurrentQuestion] = useState(startingQuestion);
  const [currentResponse, setCurrentResponse] = useState();
  const [currentSection, setCurrentSection] = useState(startingSection);
  const [file, setFile] = useState();
  const [changeId, setChangeId] = useState();
  const [responseText, setResponseText] = useState();
  const [ready, setReady] = useState(false);
  const [nextReset, setNextReset] = useState(false);
  const [nextReady, setNextReady] = useState(false);
  const [it, setIT] = useState(false);
  const [security, setSecurity] = useState(false);
  const [bcm, setBCM] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [compliance, setCompliance] = useState(false);
  const [finance, setFinance] = useState(false);
  const [self, setSelf] = useState(false);
  const [confirmed, setConfirmed] = useState(
    arrangementObject.isConfirmed(
      startingStartSection || startingContinueSection
    )
  );
  const [nextSteps, setNextSteps] = useState(false);

  const [answers, dispatchAnswers] = useReducer(
    reducerAnswers,
    arrangementObject.answers
  );
  const answerIds = Object.keys(answers);
  const responseIds = {};

  const [questions, dispatchQuestions] = useReducer(reducerQuestions, {});
  const questionIds = Object.keys(questions);

  // eslint-disable-next-line no-unused-vars
  const [sections, setSections] = useState({});
  sections.start = {
    index: 0,
    isShow: isStart,
    isStarted: false,
    next: startingStartSection,
  };
  sections.initial = {
    index: 1,
    isShow: !isStart && !isContinue,
    isStarted: false,
    next: 'risk',
  };
  sections.continue = {
    index: 2,
    isShow: isContinue,
    isStarted: false,
    next: startingContinueSection,
  };
  sections.risk = {index: 3, isShow: false, isStarted: false, next: 'bcm'};
  sections.it = {
    index: 4,
    isShow: isContinueIT,
    isNotify: false,
    isStarted: false,
    next: 'review',
  };
  sections.security = {
    index: 5,
    isShow: isContinueSecurity,
    isNotify: false,
    isStarted: false,
    next: 'review',
  };
  sections.bcm = {
    index: 6,
    isShow: isContinueBCM,
    isNotify: false,
    isStarted: false,
    next: 'review',
  };
  sections.privacy = {
    index: 7,
    isShow: isContinuePrivacy,
    isNotify: false,
    isStarted: false,
    next: 'review',
  };
  sections.compliance = {
    index: 8,
    isShow: isContinueCompliance,
    isNotify: false,
    isStarted: false,
    next: 'review',
  };
  sections.finance = {
    index: 9,
    isShow: isContinueFinance,
    isNotify: false,
    isStarted: false,
    next: 'review',
  };
  sections.self = {
    index: 10,
    isShow: isSelfSecurityReport,
    isNotify: false,
    isStarted: false,
    next: 'review',
  };
  sections.review = {index: 11, isShow: true};
  sections.next = {index: 12, isShow: nextSteps};
  answerIds.forEach((key) => {
    const responseId = answers[key].responseId;
    const responseTriggers = answers[key].responseTriggers || [];
    const responseSection = answers[key].section;
    const responseNLP = answers[key].nlp;
    sections.risk.isShow =
      sections.risk.isShow ||
      (responseTriggers.includes('risk') && !isStart && !isContinue);
    sections.it.isNotify =
      sections.it.isNotify || responseTriggers.includes('it');
    sections.security.isNotify =
      sections.security.isNotify || responseTriggers.includes('security');
    sections.bcm.isNotify =
      sections.bcm.isNotify || responseTriggers.includes('bcm');
    sections.privacy.isNotify =
      sections.privacy.isNotify || responseTriggers.includes('privacy');
    sections.compliance.isNotify =
      sections.compliance.isNotify || responseTriggers.includes('compliance');
    sections.finance.isNotify =
      sections.finance.isNotify || responseTriggers.includes('finance');
    sections.initial.isStarted =
      sections.initial.isStarted ||
      (responseSection === 'initial' && !responseNLP);
    sections.risk.isStarted =
      sections.risk.isStarted || (responseSection === 'risk' && !responseNLP);
    sections.it.isStarted =
      sections.it.isStarted || (responseSection === 'it' && !responseNLP);
    sections.security.isStarted =
      sections.security.isStarted ||
      (responseSection === 'security' && !responseNLP);
    sections.bcm.isStarted =
      sections.bcm.isStarted || (responseSection === 'bcm' && !responseNLP);
    sections.privacy.isStarted =
      sections.privacy.isStarted ||
      (responseSection === 'privacy' && !responseNLP);
    sections.compliance.isStarted =
      sections.compliance.isStarted ||
      (responseSection === 'compliance' && !responseNLP);
    sections.finance.isStarted =
      sections.finance.isStarted ||
      (responseSection === 'finance' && !responseNLP);
    sections.self.isStarted =
      sections.self.isStarted || (responseSection === 'self' && !responseNLP);
    sections.review.done =
      !sections.review.done && responseTriggers.includes('review')
        ? key
        : sections.review.done;
    responseIds[responseId] = true;
  });
  sections.start.isStarted = sections[startingStartSection]?.isStarted;
  sections.continue.isStarted = sections[startingContinueSection]?.isStarted;
  questionIds.forEach((key) => {
    const question = questions[key];
    const section = question.section;
    const currentQuestionId = parseInt(currentQuestion.split('-')[1]);
    const nlpParentAnswer = answers[key] ? answers[answers[key].nlp] : null;
    const isDone = ['review', 'next'].includes(currentSection);
    const isQuestionConditionInvalid =
      questions[key]?.condition &&
      !questions[key].condition.filter(
        (conditionalResponseId) => responseIds[conditionalResponseId]
      ).length &&
      questions[key].id <= currentQuestionId;
    const isPrecedingAnswer =
      answers[key] && answers[key].questionId < currentQuestionId;
    const isAnswerValidNLP =
      answers[key] &&
      ((answers[key].nlp &&
        ![startingStartSection, startingContinueSection].includes(
          answers[key].nlp.split('-')[0]
        ) &&
        (isStart || isContinue)) ||
        (nlpParentAnswer &&
          nlpParentAnswer.questionId < currentQuestionId &&
          nlpParentAnswer.responseAction === 'upload'));
    const isAnswered =
      isDone ||
      isQuestionConditionInvalid ||
      isPrecedingAnswer ||
      (isAnswerValidNLP && !reviewed);
    if (!isAnswered)
      sections[section].remaining = !sections[section].remaining
        ? 1
        : sections[section].remaining + 1;
    sections[section].total = !sections[section].total
      ? 1
      : sections[section].total + 1;
  });
  const lastQuestionBeforeReview = questionIds.length
    ? questionIds.reduce((previous, current) =>
        current === sections.review.done ||
        (!sections.review.done &&
          parseInt(current.split('-')[1]) > parseInt(previous.split('-')[1]) &&
          (!questions[current].condition ||
            questions[current].condition.filter(
              (conditionalResponseId) => responseIds[conditionalResponseId]
            ).length))
          ? current
          : previous
      )
    : null;
  const currentStepIndex = Object.keys(sections).filter(
    (key) =>
      sections[key].isShow &&
      sections[key].index < sections[currentSection].index
  ).length;
  const currentStepCompletion = ![
    'start',
    'continue',
    'review',
    'next',
  ].includes(currentSection)
    ? (1 -
        sections[currentSection].remaining / sections[currentSection].total) *
      100
    : undefined;
  const currentQuestionUploadType = questions[
    currentQuestion
  ]?.responses.reduce(
    (previous, current) => (current.action === 'upload' ? current : previous),
    null
  )?.type;
  const isAnswerLayoutHorizontal = questions[currentQuestion]?.responses.find(
    (response) => {
      return response.action === 'button';
    }
  );

  const validAnswerCount = questionIds.filter(
    (key) =>
      answers[key] &&
      (!answers[key].questionCondition ||
        answers[key].questionCondition.filter(
          (conditionalResponseId) => responseIds[conditionalResponseId]
        ).length)
  ).length;
  const validQuestionCount = questionIds.filter(
    (key) =>
      !questions[key].condition ||
      questions[key].condition.filter(
        (conditionalResponseId) => responseIds[conditionalResponseId]
      ).length
  ).length;
  const isReviewingAnswers =
    !!sections.review.done || validAnswerCount === validQuestionCount;
  if (reviewed && !isReviewingAnswers) setReviewed(false);

  const isInvalidControlFunction = param && !isContinue;
  useEffect(() => {
    if (isInvalidControlFunction) {
      navigate(location.pathname);
    }
  }, [isInvalidControlFunction, navigate, location.pathname]);

  const addAnswer = ({
    uploadType,
    questionId,
    questionText,
    questionCondition,
    responseId,
    responseText,
    responseAction,
    responseTriggers,
    section,
  }) => {
    dispatchAnswers({
      type: 'add',
      payload: {
        uploadType,
        questionId,
        questionText,
        questionCondition,
        responseId,
        responseText,
        responseAction,
        responseTriggers,
        section,
      },
    });
  };

  const addAnswersNLP = (answersNLP, nlpParentKey, isOverwrite) => {
    Object.keys(answersNLP).forEach((section) => {
      answersNLP[section].forEach((answer) => {
        const answerKey = section + '-' + answer.id;
        const responseText =
          answer.id > 0
            ? answer.responses[0].text
            : answer.responses[0].text.replaceAll(
                '$COMPANY_NAME$',
                company.CompanyName
              );
        if (
          answer.responses?.length &&
          (!answers[answerKey] || answers[answerKey].nlp || isOverwrite)
        ) {
          dispatchAnswers({
            type: 'add',
            payload: {
              nlp: nlpParentKey,
              isNLP: true,
              questionId: answer.id,
              questionText: answer.text,
              questionCondition: answer.condition,
              responseId: answer.responses[0].id,
              responseText,
              responseAction: answer.responses[0].action,
              responseTriggers: answer.responses[0].triggers,
              section,
            },
          });
        }
      });
    });
  };

  const fetchQuestions = (section) => {
    if (section === 'it') setIT(true);
    else if (section === 'security') setSecurity(true);
    else if (section === 'bcm') setBCM(true);
    else if (section === 'privacy') setPrivacy(true);
    else if (section === 'compliance') setCompliance(true);
    else if (section === 'finance') setFinance(true);
    else if (section === 'self') setSelf(true);
    const questions = api.getQuestions(section);
    addQuestions(questions);
  };

  const addQuestions = (questions) => {
    Object.keys(questions).forEach((section) => {
      questions[section].forEach((question) => {
        dispatchQuestions({
          type: 'add',
          payload: {question, section},
        });
      });
    });
  };

  const getQuestionText = (questionText) => {
    const questionText1 = questionText.replace(/\s*\[.*\]\s*/g, '');
    const questionText2 = questionText
      .match(/\s*\[.*\]\s*/g)
      ?.join(' ')
      .replaceAll('[', '')
      .replaceAll(']', '');
    return [questionText1, questionText2];
  };

  const showModalNLP = (answers, filename, okText) => {
    Modal.success({
      title: `File '${filename}' was processed`,
      content: `${Object.values(answers).reduce(
        (count, answersBySection) =>
          count + Object.values(answersBySection).length,
        0
      )} answers found`,
      width: 500,
      zIndex: 1010,
      okText: okText,
      destroyOnClose: true,
      onOk: () => {
        setNextReady(true);
      },
    });
  };

  const showModalReset = () => {
    Modal.confirm({
      title:
        'Are you sure you want to reset your progress on ' +
        (isSelfSecurityReport
          ? 'the security report?'
          : isContinue
          ? 'this control function?'
          : 'the entire arrangement?'),
      content: 'This action is permanent and cannot be undone.',
      width: 500,
      zIndex: 1010,
      cancelText: 'Cancel',
      okText: 'Reset',
      okButtonProps: {danger: true},
      autoFocusButton: null,
      destroyOnClose: true,
      onOk: () => {
        reset(true);
      },
    });
  };

  const showModalConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to confirm?',
      content: '',
      width: 500,
      zIndex: 1010,
      cancelText: 'Cancel',
      okText: 'Confirm',
      okButtonProps: {},
      autoFocusButton: null,
      destroyOnClose: true,
      onOk: () => {
        confirm();
      },
    });
  };

  const back = () => {
    const keys = answerIds.filter((questionKey) => {
      const isAnswerConditionValid =
        !answers[questionKey].questionCondition ||
        answers[questionKey].questionCondition.filter(
          (conditionalResponseId) => responseIds[conditionalResponseId]
        ).length;
      return isAnswerConditionValid && (!answers[questionKey].nlp || reviewed);
    });
    keys.sort((a, b) => {
      return parseInt(a.split('-')[1]) - parseInt(b.split('-')[1]);
    });
    let previousQuestion = currentQuestion;
    let x = 1;
    while (
      currentSection !== 'review' &&
      parseInt(previousQuestion.split('-')[1]) >=
        parseInt(currentQuestion.split('-')[1])
    ) {
      previousQuestion =
        keys.length - x >= 0 ? keys[keys.length - x] : startingQuestion;
      x++;
    }
    const previousSection = questions[previousQuestion]
      ? questions[previousQuestion].section
      : isStart
      ? 'start'
      : 'continue';
    const isNewSection = previousSection !== currentSection;
    setChangeId();
    setResponseText();
    setCurrentQuestion(previousQuestion);
    if (isNewSection) setCurrentSection(previousSection);
  };

  const next = (isReset) => {
    const isJumpToUnanswered =
      isReset &&
      ((!isStart && !isContinue && sections.initial.isStarted) ||
        (isStart && sections.start.isStarted) ||
        (isContinue && sections.continue.isStarted));
    const isJumpToConfirmed =
      isReset &&
      arrangementObject.isConfirmed(
        startingStartSection || startingContinueSection
      );
    const isJumpToReview = isReset && (isReviewingAnswers || isJumpToConfirmed);
    const isJumpToStart = isReset && !isJumpToUnanswered && !isJumpToReview;
    let question = currentQuestion;
    let nextQuestion = question;
    let isNextQuestionFound = false;
    const isNextQuestion =
      question !== sections.review.done &&
      !isJumpToReview &&
      (!isReset || isJumpToUnanswered);
    if (isNextQuestion) {
      do {
        question =
          questionIds[
            // eslint-disable-next-line
            questionIds.findIndex((questionId) => questionId === question) + 1
          ];
        if (!isNextQuestionFound) nextQuestion = question;
        const nlpParentAnswer = answers[question]
          ? answers[answers[question].nlp]
          : null;
        const isQuestionConditionInvalid =
          questions[question]?.condition &&
          !questions[question].condition.filter(
            (conditionalResponseId) => responseIds[conditionalResponseId]
          ).length;
        const isAnswerValidNLP =
          answers[question] &&
          ((answers[question].nlp &&
            ![startingStartSection, startingContinueSection].includes(
              answers[question].nlp.split('-')[0]
            ) &&
            (isStart || isContinue)) ||
            nlpParentAnswer?.responseAction === 'upload');
        const isClearNLP =
          answers[question] &&
          answers[question].nlp !== '' &&
          !isAnswerValidNLP;
        if (isClearNLP) answers[question].nlp = '';
        isNextQuestionFound =
          isNextQuestionFound ||
          (!isQuestionConditionInvalid &&
            ((!isJumpToUnanswered && (!answers[question]?.nlp || reviewed)) ||
              (isJumpToUnanswered && !answers[question])));
      } while (questions[question]);
    }
    const isNextSectionFetched = isNextQuestion && nextQuestion;
    const isNextSectionRisk =
      isNextQuestion &&
      sections.risk.isShow &&
      sections.risk.index > sections[currentSection].index;
    const isNextSectionIT =
      isNextQuestion &&
      sections.it.isShow &&
      sections.it.index > sections[currentSection].index;
    const isNextSectionSecurity =
      isNextQuestion &&
      sections.security.isShow &&
      sections.security.index > sections[currentSection].index;
    const isNextSectionBCM =
      isNextQuestion &&
      sections.bcm.isShow &&
      sections.bcm.index > sections[currentSection].index;
    const isNextSectionPrivacy =
      isNextQuestion &&
      sections.privacy.isShow &&
      sections.privacy.index > sections[currentSection].index;
    const isNextSectionCompliance =
      isNextQuestion &&
      sections.compliance.isShow &&
      sections.compliance.index > sections[currentSection].index;
    const isNextSectionFinance =
      isNextQuestion &&
      sections.finance.isShow &&
      sections.finance.index > sections[currentSection].index;
    const isNextSectionSelf =
      isNextQuestion &&
      sections.self.isShow &&
      sections.self.index > sections[currentSection].index;
    let nextSection = isJumpToStart
      ? startingSection
      : isNextSectionFetched
      ? questions[nextQuestion].section
      : isNextSectionRisk
      ? 'risk'
      : isNextSectionIT
      ? 'it'
      : isNextSectionSecurity
      ? 'security'
      : isNextSectionBCM
      ? 'bcm'
      : isNextSectionPrivacy
      ? 'privacy'
      : isNextSectionCompliance
      ? 'compliance'
      : isNextSectionFinance
      ? 'finance'
      : isNextSectionSelf
      ? 'self'
      : 'review';
    while (sections[nextSection]?.next && !sections[nextSection].isShow)
      nextSection = sections[nextSection].next;
    const isNewSection = nextSection !== currentSection;
    const isNextSectionReview = nextSection === 'review';
    if (isNextSectionReview) nextQuestion = lastQuestionBeforeReview;
    setChangeId();
    setResponseText();
    setCurrentResponse();
    if (nextQuestion) setCurrentQuestion(nextQuestion);
    if (isNewSection) setCurrentSection(nextSection);
    if (isNextSectionReview) setReviewed(true);
    if (isReset) setConfirmed(isJumpToConfirmed);
  };

  const save = () => {
    const response = questions[currentQuestion].responses.find((response) => {
      return response.id === changeId;
    });
    addAnswer({
      questionId: questions[currentQuestion].id,
      questionText: questions[currentQuestion].text,
      questionCondition: questions[currentQuestion].condition,
      responseId: response.id,
      responseText: responseText,
      responseAction: response.action,
      responseTriggers: response.triggers,
      section: currentSection,
    });
    setChangeId();
  };

  const review = () => {
    setChangeId();
    setResponseText();
    setCurrentResponse();
    setCurrentQuestion(lastQuestionBeforeReview);
    setCurrentSection('review');
  };

  const jump = (question) => {
    setChangeId();
    setResponseText();
    setCurrentResponse();
    setCurrentQuestion(question);
    setCurrentSection(question.split('-')[0]);
  };

  const confirm = async () => {
    setReady(false);
    arrangementObject.confirmations[
      questions[lastQuestionBeforeReview].section
    ] = true;
    arrangementObject.save();
    setConfirmed(true);
    if (!isStart) {
      setNextSteps(true);
      setCurrentSection('next');
    }
    setReady(true);
    navigate(isSelfSecurityReport ? location.pathname :
      `${confirmedArrangementRoute(companyObject.id, arrangementObject.id)}${
        param ? `?q=${param}` : ''
      }`
    );
  };

  const reset = async (isResetAnswers) => {
    setReady(false);
    setCurrentArrangementId(null);
    setCurrentQuestion(startingQuestion);
    setCurrentResponse();
    setCurrentSection(startingSection);
    setFile();
    setChangeId();
    setResponseText();
    setIT(false);
    setSecurity(false);
    setBCM(false);
    setPrivacy(false);
    setCompliance(false);
    setFinance(false);
    setSelf(false);
    setReviewed(false);
    setNextSteps(false);
    dispatchAnswers({type: isResetAnswers ? 'reset' : 'resync'});
    dispatchQuestions({type: 'reset'});
    if (isStart) {
      if (isSelfSecurityReport) fetchQuestions('self');
    } else if (isContinue) {
      const answers = api.getAnswersNLP('Arrangement');
      const nlpParentKey = startingNLPQuestion;
      if (isContinueIT) {
        addAnswersNLP({it: answers['it'] || []}, nlpParentKey);
        fetchQuestions('it');
      }
      if (isContinueSecurity) {
        addAnswersNLP({security: answers['security'] || []}, nlpParentKey);
        fetchQuestions('security');
      }
      if (isContinueBCM) {
        addAnswersNLP({bcm: answers['bcm'] || []}, nlpParentKey);
        fetchQuestions('bcm');
      }
      if (isContinuePrivacy) {
        addAnswersNLP({privacy: answers['privacy'] || []}, nlpParentKey);
        fetchQuestions('privacy');
      }
      if (isContinueCompliance) {
        addAnswersNLP({compliance: answers['compliance'] || []}, nlpParentKey);
        fetchQuestions('compliance');
      }
      if (isContinueFinance) {
        addAnswersNLP({finance: answers['finance'] || []}, nlpParentKey);
        fetchQuestions('finance');
      }
    } else {
      fetchQuestions('initial');
      fetchQuestions('risk');
    }
    setNextReset(true);
  };

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOverview,
    isContinueIT,
    isContinueSecurity,
    isContinueBCM,
    isContinuePrivacy,
    isContinueCompliance,
    isContinueFinance,
    isSelfSecurityReport,
  ]);

  useEffect(() => {
    if (currentResponse) {
      window.setTimeout(() => {
        delete currentResponse.timeout;
        addAnswer({
          uploadType: currentQuestionUploadType,
          questionId: questions[currentQuestion].id,
          questionText: questions[currentQuestion].text,
          questionCondition: questions[currentQuestion].condition,
          responseId: currentResponse.id,
          responseText: responseText,
          responseAction: currentResponse.action,
          responseTriggers: currentResponse.triggers,
          section: currentSection,
        });
        setNextReady(true);
      }, currentResponse.timeout || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResponse]);

  useEffect(() => {
    const isCurrentSectionQuestionsFetched =
      (currentSection === 'it' && it) ||
      (currentSection === 'security' && security) ||
      (currentSection === 'bcm' && bcm) ||
      (currentSection === 'privacy' && privacy) ||
      (currentSection === 'compliance' && compliance) ||
      (currentSection === 'finance' && finance) ||
      (currentSection === 'self' && self);
    const isFetchQuestions =
      !['start', 'initial', 'continue', 'risk', 'review', 'next'].includes(
        currentSection
      ) && !isCurrentSectionQuestionsFetched;
    if (isFetchQuestions) {
      setReady(false);
      fetchQuestions(currentSection);
      setReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection]);

  useEffect(() => {
    const getAnswersNLP = async (filename, type) => {
      const answers = api.getAnswersNLP(type);
      if (answers) {
        addAnswersNLP(answers, currentQuestion, true);
        showModalNLP(
          answers,
          filename,
          type === 'Arrangement' ? 'Start' : 'Continue'
        );
      } else {
        setNextReady(true);
      }
    };
    if (file) {
      setReady(false);
      getAnswersNLP(file.name, answers[currentQuestion].uploadType);
      setFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (nextReady) {
      setNextReady(false);
      next();
      setReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextReady]);

  useEffect(() => {
    if (nextReset) {
      setNextReset(false);
      next(true);
      setReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextReset]);

  const steps = [
    ...(sections.start.isShow ? [{title: 'Instructions'}] : []),
    ...(sections.initial.isShow ? [{title: 'Initial Screener'}] : []),
    ...(sections.continue.isShow ? [{title: 'Welcome & Instructions'}] : []),
    ...(sections.risk.isShow ? [{title: 'Inherent Risk Questionnaire'}] : []),
    ...(sections.it.isShow
      ? [{title: 'Information Technology Questionnaire'}]
      : []),
    ...(sections.security.isShow
      ? [{title: 'Information Security Questionnaire'}]
      : []),
    ...(sections.bcm.isShow
      ? [{title: 'Business Continuity Questionnaire'}]
      : []),
    ...(sections.privacy.isShow ? [{title: 'Privacy Questionnaire'}] : []),
    ...(sections.compliance.isShow
      ? [{title: 'Compliance Questionnaire'}]
      : []),
    ...(sections.finance.isShow ? [{title: 'Finance Questionnaire'}] : []),
    ...(sections.self.isShow ? [{title: 'Security Report Questionnaire'}] : []),
    ...(sections.review.isShow
      ? [{title: !confirmed || nextSteps ? 'Review & Confirm' : 'Review'}]
      : []),
    ...(sections.next.isShow ? [{title: 'Next Steps'}] : []),
  ];

  return isCreateRedirect ? null : (
    <Container style={{marginTop: '-40px'}}>
      <Space direction="vertical" size="large">
        <Top width={width}>
          <Left>
            <Typography.Title
              level={3}
              style={{
                fontFamily: '"Open Sans", sans-serif',
                marginRight: '15px',
                fontSize: '20px',
                fontWeight: '700',
                color: '#153443',
              }}
            >
              {isSelfSecurityReport ? (
                <span>Security Report</span>
              ) : isOverview ? (
                <span>Due Diligence Center</span>
              ) : (
                <Fragment>
                  <span>{arrangementObject.name}</span>
                  {controlFunction && (
                    <span style={{fontWeight: '500'}}>
                      {' '}
                      / {controlFunction} Due Diligence
                    </span>
                  )}
                </Fragment>
              )}
            </Typography.Title>
          </Left>
          <Right>
            {ready &&
              (!confirmed || !nextSteps) &&
              !isOverview &&
              ((!isStart && !isContinue && sections.initial.isStarted) ||
                (isStart && sections.start.isStarted) ||
                (isContinue && sections.continue.isStarted)) && (
                <Space direction="vertical">
                  <Space style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {!['start', 'continue', 'review'].includes(
                      currentSection
                    ) &&
                      answers[currentQuestion]?.isNLP && (
                        <LogoContainer>
                          <HaloSmall
                            style={{width: '16px', marginTop: '1px'}}
                          />{' '}
                          <Label>Answered by Halo Ai</Label>
                        </LogoContainer>
                      )}
                    {((currentQuestion !== startingQuestion &&
                      !['start', 'continue'].includes(currentSection)) ||
                      (answers[currentQuestion] &&
                        currentSection !== 'review')) && (
                      <Fragment>
                        <Button
                          icon={<FiArrowLeft />}
                          onClick={back}
                          type="default"
                          shape="circle"
                          disabled={
                            currentQuestion === startingQuestion ||
                            ['start', 'continue'].includes(currentSection)
                          }
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}
                        />
                        <Button
                          icon={<FiArrowRight />}
                          onClick={() => next()}
                          type="default"
                          shape="circle"
                          disabled={
                            !answers[currentQuestion] ||
                            currentSection === 'review'
                          }
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}
                        />
                      </Fragment>
                    )}
                    {!confirmed && changeId && (
                      <Button onClick={save}>Save</Button>
                    )}
                    {(!confirmed || isSelfSecurityReport) && (
                      <Button onClick={showModalReset} danger>
                        Reset
                      </Button>
                    )}
                    {reviewed &&
                      !['review', 'next'].includes(currentSection) && (
                        <Button
                          onClick={review}
                          style={{width: '82px'}}
                          type="primary"
                        >
                          Review
                        </Button>
                      )}
                    {!confirmed && currentSection === 'review' && (
                      <Button
                        onClick={showModalConfirm}
                        style={{width: '82px'}}
                        type="primary"
                      >
                        Confirm
                      </Button>
                    )}
                  </Space>
                </Space>
              )}
            {ready &&
              (nextSteps || (confirmed && currentSection === 'review')) &&
              !isOverview && !isSelfSecurityReport && (
                <Link to={dueDiligenceRoute(company.CompanyId)}>
                  <Button type="primary" style={{marginLeft: '8px'}}>
                    Done
                  </Button>
                </Link>
              )}
          </Right>
        </Top>
        {!isOverview && (
          <StepsContainer width={width}>
            <Steps
              current={currentStepIndex}
              type="navigation"
              percent={currentStepCompletion}
              items={steps}
            />
          </StepsContainer>
        )}
      </Space>
      <Space
        direction="vertical"
        size="large"
        style={{
          ...(isOverview
            ? {marginTop: '56px', zIndex: '10'}
            : {marginTop: '115px'}),
          width: Math.min(width, 1250) - 83,
        }}
      >
        {ready ? (
          nextSteps || isOverview ? (
            <ConfirmedContainer>
              <Space direction="horizontal" size="large">
                <DueDiligenceCard
                  style={{paddingTop: '10px'}}
                  company={companyObject}
                  fixedArrangementId={!isOverview ? arrangementObject.id : null}
                  hideTitle={true}
                  onRefresh={() => {
                    const isStillActive = companyObject.isActive();
                    if (isStillActive) reset();
                    else navigate(supplierDashboardRoute(company.CompanyId));
                  }}
                />
              </Space>
            </ConfirmedContainer>
          ) : currentSection === 'start' ? (
            <Question>
              <Space direction="vertical">
                <Wrapper>
                  <h2>
                    You have been requested to complete a self-assessment of{' '}
                    {company.CompanyName}.
                  </h2>
                </Wrapper>
                <Space style={{marginLeft: -2, marginTop: -8}}>
                  <Button
                    className={'active'}
                    key={currentQuestion}
                    onClick={() => next()}
                  >
                    Start
                  </Button>
                </Space>
              </Space>
            </Question>
          ) : currentSection === 'continue' ? (
            <Question>
              <Space direction="vertical">
                <Wrapper>
                  <h2>
                    As part of the {controlFunction} team, you have been
                    requested to complete a third-party assessment of{' '}
                    {company.CompanyName}
                  </h2>
                </Wrapper>
                <Space style={{marginLeft: -2, marginTop: -8}}>
                  <Button
                    className={'active'}
                    key={currentQuestion}
                    onClick={() => next()}
                  >
                    Start
                  </Button>
                </Space>
              </Space>
            </Question>
          ) : currentSection === 'review' ? (
            <AnswersReview
              answers={Object.values(answers)
                .filter(
                  (answer) =>
                    ((answer.section === 'initial' &&
                      sections.initial.isShow) ||
                      (answer.section === 'risk' && sections.risk.isShow) ||
                      (answer.section === 'it' && sections.it.isShow) ||
                      (answer.section === 'security' &&
                        sections.security.isShow) ||
                      (answer.section === 'bcm' && sections.bcm.isShow) ||
                      (answer.section === 'privacy' &&
                        sections.privacy.isShow) ||
                      (answer.section === 'compliance' &&
                        sections.compliance.isShow) ||
                      (answer.section === 'finance' &&
                        sections.finance.isShow) ||
                      (answer.section === 'self' && sections.self.isShow)) &&
                    (!answer.questionCondition ||
                      answer.questionCondition.filter(
                        (conditionalResponseId) =>
                          responseIds[conditionalResponseId]
                      ).length) &&
                    (!sections.review.done ||
                      answer.questionId <=
                        parseInt(sections.review.done.split('-')[1]))
                )
                .sort((a, b) => a.questionId - b.questionId)}
              width={width}
              onJump={jump}
            />
          ) : questions[currentQuestion] ? (
            <Question>
              <Space direction="vertical" style={{width: '100%'}}>
                <Wrapper>
                  <h2>
                    {getQuestionText(questions[currentQuestion].text)[0]}
                    <span>
                      <br />
                      {getQuestionText(questions[currentQuestion].text)[1]}
                    </span>
                  </h2>
                </Wrapper>
                {questions[currentQuestion].qa && (
                  <Collapse style={{marginBottom: '20px'}}>
                    {questions[currentQuestion].qa &&
                      Object.keys(questions[currentQuestion].qa).map(
                        (key, i) => {
                          return (
                            <Panel header={key} key={'qa-' + i}>
                              {questions[currentQuestion].qa[key]}
                            </Panel>
                          );
                        }
                      )}
                  </Collapse>
                )}
                <Space
                  id="radio-group-space"
                  style={{marginLeft: -2, marginTop: -8, width: '100%'}}
                >
                  <Radio.Group
                    onChange={() => {}}
                    value={currentResponse?.id}
                    style={{
                      width: '100%',
                      ...(isAnswerLayoutHorizontal && {display: 'inline-flex'}),
                    }}
                  >
                    <ConditionalWrapper
                      condition={!isAnswerLayoutHorizontal}
                      wrapper={(children) => (
                        <Space direction="vertical" style={{width: '100%'}}>
                          {children}
                        </Space>
                      )}
                    >
                      {questions[currentQuestion].responses.map((response) => {
                        const currentResponseText =
                          (['comment', 'input', 'list', 'select'].includes(
                            response.action
                          ) &&
                            responseText) ||
                          (['list', 'select'].includes(response.action) &&
                            !answers[currentQuestion]?.responseText &&
                            '[]') ||
                          (!changeId &&
                            response.id ===
                              answers[currentQuestion]?.responseId &&
                            answers[currentQuestion]?.responseText);
                        const isContinueButton = [
                          'comment',
                          'input',
                          'select',
                        ].includes(response.action);
                        return (
                          <span key={`response-${response.id}`}>
                            {response.action === 'upload' ? (
                              <Fragment>
                                {response.id ===
                                  answers[currentQuestion]?.responseId && (
                                  <Button
                                    className={'active'}
                                    onClick={() => next()}
                                    style={{marginRight: '10px'}}
                                    disabled={confirmed}
                                  >
                                    {`Continue (${answers[currentQuestion]?.responseText})`}
                                  </Button>
                                )}
                                <Upload
                                  fileList={[]}
                                  onChange={(info) => {
                                    if (info.file) {
                                      addAnswer({
                                        uploadType: currentQuestionUploadType,
                                        questionId:
                                          questions[currentQuestion].id,
                                        questionText:
                                          questions[currentQuestion].text,
                                        questionCondition:
                                          questions[currentQuestion].condition,
                                        responseId: response.id,
                                        responseText: info.file.name,
                                        responseAction: response.action,
                                        responseTriggers: response.triggers,
                                        section: currentSection,
                                      });
                                      setFile(info.file);
                                    }
                                  }}
                                >
                                  <Button
                                    icon={<UploadOutlined />}
                                    onClick={() => {}}
                                    type="default"
                                    disabled={confirmed}
                                  >
                                    {response.text}
                                  </Button>
                                </Upload>
                              </Fragment>
                            ) : response.action === 'comment' ? (
                              <Fragment>
                                <TextArea
                                  style={{
                                    display: 'block',
                                    width: '650px',
                                    marginBottom: '10px',
                                    backgroundColor: 'white',
                                  }}
                                  rows={4}
                                  placeholder={response.text}
                                  value={currentResponseText || ''}
                                  onChange={(event) => {
                                    setChangeId(response.id);
                                    setResponseText(event.target.value);
                                  }}
                                />
                              </Fragment>
                            ) : response.action === 'input' ? (
                              <Fragment>
                                <Input
                                  style={{
                                    width: '250px',
                                    margin: '0 10px 10px 0',
                                    backgroundColor: 'white',
                                  }}
                                  value={
                                    currentResponseText
                                      ? currentResponseText
                                      : ''
                                  }
                                  placeholder={response.text}
                                  disabled={confirmed}
                                  onChange={(event) => {
                                    setChangeId(response.id);
                                    setResponseText(event.target.value);
                                  }}
                                />
                              </Fragment>
                            ) : response.action === 'date' ? (
                              <Fragment>
                                <DatePicker
                                  allowClear={false}
                                  placeholder={response.text}
                                  disabled={confirmed}
                                  {...(currentResponseText && {
                                    value: dayjs(
                                      response.pretext
                                        ? currentResponseText.split(
                                            response.pretext
                                          )[1]
                                        : currentResponseText,
                                      response.type
                                    ),
                                  })}
                                  format={(value) =>
                                    `${response.pretext || ''}${value.format(
                                      response.type
                                    )}`
                                  }
                                  onChange={(date, dateString) => {
                                    setResponseText(dateString);
                                    setCurrentResponse(response);
                                  }}
                                />
                              </Fragment>
                            ) : response.action === 'list' ? (
                              <Fragment>
                                <List
                                  item={response.type}
                                  min={response.min}
                                  value={currentResponseText}
                                  disabled={confirmed}
                                  onChange={(values) => {
                                    const valueString = JSON.stringify(values);
                                    const isInit =
                                      (!values.length ||
                                        valueString ===
                                          answers[currentQuestion]
                                            ?.responseText) &&
                                      !changeId;
                                    if (!isInit) setChangeId(response.id);
                                    setResponseText(valueString);
                                  }}
                                  onSubmit={(values) => {
                                    const valueString = JSON.stringify(values);
                                    setResponseText(valueString);
                                    setCurrentResponse(response);
                                  }}
                                />
                              </Fragment>
                            ) : response.action === 'select' ? (
                              <Fragment>
                                <Select
                                  style={{width: '550px', marginRight: '10px'}}
                                  mode="tags"
                                  placeholder={response.text}
                                  disabled={confirmed}
                                  {...(currentResponseText && {
                                    defaultValue:
                                      JSON.parse(currentResponseText),
                                  })}
                                  onChange={(values) => {
                                    const sortedValues = values
                                      .slice()
                                      .sort((a, b) => {
                                        const indexA =
                                          response.options.indexOf(a);
                                        const indexB =
                                          response.options.indexOf(b);
                                        const existsA = indexA >= 0;
                                        const existsB = indexB >= 0;
                                        return existsA && existsB
                                          ? indexA - indexB
                                          : existsA && !existsB
                                          ? -1
                                          : !existsA && existsB
                                          ? 1
                                          : 0;
                                      });
                                    setChangeId(response.id);
                                    setResponseText(
                                      JSON.stringify(sortedValues)
                                    );
                                  }}
                                >
                                  {response.options &&
                                    response.options.map((value) => {
                                      return (
                                        <Option key={value} value={value}>
                                          {value}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </Fragment>
                            ) : response.action === 'button' ? (
                              <Fragment>
                                <Button
                                  className={
                                    response.id ===
                                      answers[currentQuestion]?.responseId &&
                                    'active'
                                  }
                                  style={{marginLeft: '10px'}}
                                  disabled={confirmed}
                                  onClick={() => {
                                    setResponseText(response.text);
                                    setCurrentResponse(response);
                                  }}
                                >
                                  {response.text}
                                </Button>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <span
                                  onMouseOver={() => {
                                    document
                                      .getElementById(`response-${response.id}`)
                                      .focus();
                                  }}
                                  onMouseOut={() => {
                                    document
                                      .getElementById(`response-${response.id}`)
                                      .blur();
                                  }}
                                >
                                  <Radio
                                    id={`response-${response.id}`}
                                    style={{width: '100%', fontSize: '16px'}}
                                    disabled={
                                      confirmed ||
                                      (!!currentResponse &&
                                        response.id !== currentResponse.id)
                                    }
                                    {...(!currentResponseText && {
                                      value: response.id,
                                    })}
                                    {...(currentResponseText && {
                                      checked: true,
                                      defaultChecked: true,
                                    })}
                                    onClick={() => {
                                      response.timeout = 300;
                                      setResponseText(response.text);
                                      setCurrentResponse(response);
                                    }}
                                  >
                                    {response.text}
                                  </Radio>
                                </span>
                              </Fragment>
                            )}
                            {isContinueButton && (
                              <Button
                                className={'active'}
                                disabled={
                                  confirmed ||
                                  ((!currentResponseText ||
                                    currentResponseText === '[]') &&
                                    !questions[currentQuestion].optional)
                                }
                                onClick={() => {
                                  setResponseText(currentResponseText);
                                  setCurrentResponse(response);
                                }}
                              >
                                Continue
                              </Button>
                            )}
                          </span>
                        );
                      })}
                    </ConditionalWrapper>
                  </Radio.Group>
                </Space>
              </Space>
            </Question>
          ) : null
        ) : (
          <Loading>
            <Spin size="large" />
          </Loading>
        )}
      </Space>
    </Container>
  );
};

export default DueDiligenceSection;
