import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';
import green400Color from '../../../../layout/colors/green400.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

const Activity = styled.a`
  width: 100%;
  background-color: ${whiteColor};
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px;
  color: ${blackColor};

  &:hover {
    color: ${green400Color};
  }
`;

export default Activity;
