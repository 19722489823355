// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Column = ({dataKey, entry, entryIndex}) => {
  const value = entry[dataKey];
  return dataKey === 'name' ? (
    <div>
      {[entry?.['First Name'], entry?.['Middle Name'], entry?.['Last Name']]
        .filter((name) => !!name)
        .join(' ') || '-'}
    </div>
  ) : (
    <div>{value || '-'}</div>
  );
};

Column.propTypes = {
  dataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default Column;
