// company components
import HighlightInfo from '../../../components/HighlightInfo/HighlightInfo';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Overall Social Impact',
  componentProps: ({company}) => ({
    children: company?.ESGCSR?.OverallSocialImpact || '-',
  }),
  Component: HighlightInfo,
});
