// collection lib
import getPrimaryCollection from './getPrimaryCollection.lib.collection';

// redux store
import store from '../../redux/store';

const getCollectionOptions = ({
  collections = store.getState()?.collection?.collections || [],
} = {}) => {
  const primaryCollection = getPrimaryCollection(collections);
  const remainingCollections = [...collections].filter(
    ({id}) => id !== primaryCollection?.id
  );
  return [primaryCollection, ...remainingCollections]
    .filter((collection) => !!collection)
    .map((collection) => ({
      value: collection.id,
      label: collection.name,
    }));
};

export default getCollectionOptions;
