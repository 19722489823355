// ant components
import {Button, Space} from 'antd';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// workflow components
import WorkflowsTable from '../../components/WorkflowsTable/WorkflowsTable';

// workflow containers
import CreateWorkflowContainer from '../CreateWorkflowContainer/CreateWorkflowContainer';
import DeleteWorkflowContainer from '../DeleteWorkflowContainer/DeleteWorkflowContainer';
import EditWorkflowContainer from '../EditWorkflowContainer/EditWorkflowContainer';

// workflow events
import showWorkflowCreatorModalEvent from '../../events/showCreatorModal.event.workflow';
import workflowCreatedEvent from '../../events/created.event.workflow';
import workflowDeletedEvent from '../../events/deleted.event.workflow';
import workflowUpdatedEvent from '../../events/updated.event.workflow';

// workflow services
import listWorkflowsService from '../../services/list.service.workflow';

class WorkflowsContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    loading: true,
    workflows: [],
  };

  componentDidMount() {
    this.loadWorkflows();
    this.props.subscribe(
      workflowCreatedEvent.subscribe(this.workflowCreated),
      workflowDeletedEvent.subscribe(this.workflowDeleted),
      workflowUpdatedEvent.subscribe(this.workflowEdited)
    );
  }

  loadWorkflows = async () => {
    const workflows = await listWorkflowsService();
    this.setState({loading: false, workflows});
  };

  workflowCreated = ({workflow}) => {
    this.setState({
      workflows: [workflow, ...this.state.workflows],
    });
  };

  workflowEdited = ({workflow}) => {
    const workflows = [...this.state.workflows].map((existingWorkflow) =>
      existingWorkflow.id === workflow.id ? workflow : existingWorkflow
    );

    this.setState({workflows});
  };

  workflowDeleted = ({workflow}) => {
    const workflows = [...this.state.workflows].filter(
      (existingWorkflow) => existingWorkflow.id !== workflow.id
    );

    this.setState({workflows});
  };

  showCreator = () => showWorkflowCreatorModalEvent.publish();

  handleDeleteWorkflow = (workflow) => () => this.deleteWorkflow(workflow);

  deleteWorkflow = () => {};

  handleEditWorkflow = (workflow) => () => this.deleteWorkflow(workflow);

  editWorkflow = () => {};

  render() {
    const {loading, workflows} = this.state;
    return (
      <Fragment>
        <PageLayout>
          {({Content}) => (
            <Content
              title="Alert Workflows"
              moreButton={
                <Space direction="horizontal">
                  <Button onClick={this.showCreator}>New Workflow</Button>
                </Space>
              }
            >
              <WorkflowsTable
                loading={loading}
                workflows={workflows}
                onDelete={this.deleteWorkflow}
                onEdit={this.editWorkflow}
              />
            </Content>
          )}
        </PageLayout>
        <CreateWorkflowContainer />
        <DeleteWorkflowContainer />
        <EditWorkflowContainer />
      </Fragment>
    );
  }
}

export default subscriptionHOC(WorkflowsContainer);
