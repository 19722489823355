// ant components
import {Modal, Tag} from 'antd';

// layout colors
import violet400Color from '../../../layout/colors/violet400.color.layout';

// local components
import Container from './components/Container';
import Description from './components/Description';
import Header from './components/Header';
import TagContainer from './components/TagContainer';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CreditSafeAlertsModal = ({alerts, company, onClose, visible}) => (
  <Modal
    title={
      <Header>
        <Title>Financial Alerts</Title>
        <TagContainer>
          <Tag color={violet400Color}>Financial Alerts</Tag>
        </TagContainer>
      </Header>
    }
    centered
    open={visible}
    onOk={onClose}
    onCancel={onClose}
    width="500px"
  >
    <Container>
      <Description>
        {alerts.map((alertMessage) => (
          <p key={alertMessage.id}>{alertMessage.message}</p>
        ))}
      </Description>
    </Container>
  </Modal>
);

CreditSafeAlertsModal.propTypes = {
  alerts: PropTypes.array,
  company: PropTypes.object,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default CreditSafeAlertsModal;
