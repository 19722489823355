import styled from 'styled-components';

// layout colors
import green400Color from '../../../../layout/colors/green400.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

const Search = styled.div`
  width: 350px;
  margin: 0px 0px 5px 0px;
  border: 1px solid ${green400Color};
  border-radius: 4px;
  overflow: hidden;
  background: ${whiteColor};
`;

export default Search;
