// layout colors
import whiteColor from '../../../layout/colors/white.color.layout';

// layout components
import LabeledLineScore from '../../../layout/components/LabeledLineScore/LabeledLineScore';

// local components
import Container from './components/Container';
import Tag from './components/Tag';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// risk lib
import getRiskColor from '../../../risk/lib/getRiskColor.lib.risk';

const DashboardSideMenu = ({intrinsicScore, tags = []}) => (
  <Container>
    <LabeledLineScore
      score={intrinsicScore}
      barColor={getRiskColor(intrinsicScore).primary}
      textColor={whiteColor}
    >
      Halo Portfolio Score
    </LabeledLineScore>
    {tags.map((tag) => (
      <Tag key={tag.key} onClick={tag.onClick} color={tag.color}>
        {tag.label}
      </Tag>
    ))}
  </Container>
);

DashboardSideMenu.propTypes = {
  intrinsicScore: PropTypes.number,
  logo: PropTypes.string,
  name: PropTypes.string,
  stockExchange: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
      key: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default DashboardSideMenu;
