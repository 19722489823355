// chart components
import RiskLevelFactorChartCard from '../../../chart/components/RiskLevelFactorChartCard/RiskLevelFactorChartCard';

// layout components
import {Column, Row} from '../../../layout/components/Grid/Grid';

// local lib
import extractForensicData from './lib/extractForensicData.lib';
import renderForensicSection from './lib/renderForensicSection.lib';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const CompanyForensicBreakdownSection = () => {
  const {company} = useSelector((state) => state.company);

  return (
    renderForensicSection({company}) && (
      <Fragment>
        <Row>
          <Column>
            <RiskLevelFactorChartCard data={extractForensicData({company})} />
          </Column>
        </Row>
      </Fragment>
    )
  );
};

export default CompanyForensicBreakdownSection;
