// aws api profiles
import api from '../../aws/api/profiles/json.profile.api.aws';

// tenant stores
import tenantStore from '../stores/store.tenant';

const loadJsonAwsApiGenerator =
  (file) =>
  ({tenant = tenantStore.getData()?.tenant} = {}) =>
    api().get(`/data/tenant/${tenant?.id}/${file}.json`);

export default loadJsonAwsApiGenerator;
