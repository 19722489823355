// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// layout components
import FormattedNumber from '../../../../layout/components/FormattedNumber/FormattedNumber';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Revenue Growth (%)',
  componentProps: ({company}) => ({
    children: (
      <FormattedNumber
        number={
          company?.Financials?.RevenueGrowthPercentage === null
            ? null
            : company?.Financials?.RevenueGrowthPercentage * 100
        }
      />
    ),
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    return (
      Number(a?.Financials?.RevenueGrowthPercentage || 0) -
      Number(b?.Financials?.RevenueGrowthPercentage || 0)
    );
  },
});
