const CREDIT_SAFE_FIRMOGRAPHIC_ALERT_TRIGGERS = [
  {
    value: 'cs_companyName',
    title: 'Company Name',
    provider: 'cs',
    type: 'boolean',
  },
  {
    value: 'cs_address',
    title: 'Address',
    provider: 'cs',
    type: 'boolean',
  },
  {
    value: 'cs_estimatedAnnualSalesAmount',
    title: 'Estimated Annual Sales Amount',
    provider: 'cs',
    type: 'boolean',
  },
  {
    value: 'cs_primarySICCode',
    title: 'Primary SIC Code',
    provider: 'cs',
    type: 'boolean',
  },
  {
    value: 'cs_primaryNAICSCode',
    title: 'Primary NAICS Code',
    provider: 'cs',
    type: 'boolean',
  },
  {
    value: 'cs_companyEstablishDate',
    title: 'Company Establish Date',
    provider: 'cs',
    type: 'boolean',
  },
  {
    value: 'cs_companyURL',
    title: 'Company URL',
    provider: 'cs',
    type: 'boolean',
  },
  // {
  //   value: 'cs_changeInCompanyTaxIDOrEIN',
  //   title: 'Change in Company Tax ID / EIN',
  //   provider: 'cs',
  //   type: 'boolean',
  // },
].filter((option) => !!option);

export default CREDIT_SAFE_FIRMOGRAPHIC_ALERT_TRIGGERS;
