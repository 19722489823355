// layout components
import FullIntegrationTag from '../../../../../layout/components/FullIntegrationTag/FullIntegrationTag';

// layout events
import notImplementedEvent from '../../../../../layout/events/notImplemented.event.layout';

// local components
import Container from './components/Container';

// react
import React from 'react';

const CompanyFullIntegrationColumn = () => (
  <Container>
    <div onClick={() => notImplementedEvent.publish()}>
      <FullIntegrationTag />
    </div>
  </Container>
);

export default CompanyFullIntegrationColumn;
