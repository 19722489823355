const DIVERSITIES = {
  wbe: 'Woman-owned business enterprise',
  mbe: 'Minority-owned business enterprise',
  vbe: 'Veteran-owned business enterprise',
  sdvbe: 'Service-disabled Veteran-owned business enterprise',
  lgbtbe: 'Lesbian Gay Bisexual Transgender-owned business enterprise',
  dobe: 'Disabled-owned business enterprise',
  sbe: 'Small business enterprise',
  sdb: 'Small disabled-owned business',
  wosb: 'Woman-owned small business',
  vosb: 'Veteran-owned small business',
  sdvosb: 'Service-disabled veteran-owned small business',
};

export default DIVERSITIES;
