// collection components
import CollectionEditorModal from '../../components/CollectionEditorModal/CollectionEditorModal';

// collection events
import showCreateModalEvent from '../../events/showCreateModal.event.collection';

// collection services
import createCollectionService from '../../services/create.service.collection';

// error lib
import parseError from '../../../error/lib/parse.lib.error';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// ant components
import {message as messageApi} from 'antd';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class CreateCollectionContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    companies: [],
    loading: false,
    name: '',
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showCreateModalEvent.subscribe(this.show));
  }

  show = () => {
    this.setState({...this.constructor.DEFAULT_STATE, visible: true});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  change = (key) => (e) => {
    if (this.state.loading) return;
    const value = !!e?.target ? e.target.value : e;
    this.setState({[key]: value});
  };

  changeSelection = (key) => (value) => {
    const previousValues = [...this.state[key]];
    const updateValues = previousValues.includes(value)
      ? previousValues.filter((v) => v !== value)
      : [...previousValues, value];
    this.setState({[key]: updateValues});
  };

  save = async () => {
    const {companies, loading, name} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      await createCollectionService({
        collection: {
          company_ids: companies,
          name,
        },
      });
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      messageApi.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {companies, loading, name, visible} = this.state;
    return (
      <CollectionEditorModal
        companies={companies}
        loading={loading}
        name={name}
        onChange={this.change}
        onChangeSelection={this.changeSelection}
        onClose={this.hide}
        onSave={this.save}
        title="Create Collection"
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(CreateCollectionContainer);
