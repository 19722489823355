// propTypes
import PropTypes from 'prop-types';

// react
// import React from 'react';

const WorkExperienceColumn = ({
  category,
  columnIndex,
  entry,
  entryIndex,
  organizationId,
}) => {
  const workExperiences = entry[category];
  const workExperience = [...workExperiences]
    .filter(({orgId}) => orgId === organizationId)
    .map(({title}) => title)
    .join(', ');
  return workExperience || '-';
};

WorkExperienceColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default WorkExperienceColumn;
