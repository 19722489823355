// table components
import DynamicColumn from '../../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const generateColumns = ({currency, categories}) =>
  [...categories].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      props: {currency},
      title: label,
    })
  );

export default generateColumns;
