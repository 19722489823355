// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralTenantDataAction from './actions/setGeneralData.action.redux.tenant';

// style redux constants
import DEFAULT_TENANT_REDUX_STATE from './constants/defaultState.constant.redux.tenant';

const tenantSlice = createSlice({
  name: 'tenant',
  initialState: DEFAULT_TENANT_REDUX_STATE,
  reducers: {
    setGeneralTenantData: setGeneralTenantDataAction,
  },
});

export const {setGeneralTenantData} = tenantSlice.actions;

export default tenantSlice.reducer;
