// ant
import {Tooltip} from 'antd';

// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Flag = ({country, displayName, isoCountry}) => {
  const iso = isoCountry || null;
  const name = country || isoCountry || null;

  return (
    <Container>
      <Tooltip title={name}>
        <div>
          {!iso ? (
            <i className="mdi mdi-help-box-outline" />
          ) : iso === 'global' ? (
            <i className="mdi mdi-earth" />
          ) : (
            <span className={`fi fi-${iso}`} />
          )}
        </div>
      </Tooltip>
    </Container>
  );
};

Flag.propTypes = {
  country: PropTypes.string,
  displayName: PropTypes.bool,
  isoCountry: PropTypes.string,
};

export default Flag;
