const differenceInPercentage = (num1, num2) => {
  if (!num1 || !num2) return 0;

  const difference = Math.abs(num1 - num2);
  const isPositiveDifference = num2 > num1;

  const percentageDifference =
    (difference /
      Math.abs((isPositiveDifference ? Math.min : Math.max)(num1, num2))) *
    100;

  return isPositiveDifference
    ? percentageDifference
    : percentageDifference * -1;
};

export default differenceInPercentage;
