import styled from 'styled-components';

// constants
const SIZE = '10px';

const Color = styled.div`
  display: inline-block;
  width: ${SIZE};
  height: ${SIZE};
  border-radius: 50%;
  background: ${({color}) => color};
  margin-right: 6px;
  transform: translateY(1px);
`;

export default Color;
