// local lib
import getAllAvailableColumns from './getAllAvailableColumns.lib';

const getAllAvailableColumnOptions = ({suppliers}) =>
  getAllAvailableColumns({suppliers, attachCustomKey: true}).map((column) => ({
    value: column.key,
    label: column.title,
  }));

export default getAllAvailableColumnOptions;
