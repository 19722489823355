// app components
import RedirectToHome from '../../../app/components/RedirectToHome/RedirectToHome';

// collection pages
import CollectionsPage from '../../../collection/pages/CollectionsPage/CollectionsPage';

// collection routes
import collectionsRoute from '../../../collection/pages/CollectionsPage/route';

// company pages
import CarbonEmissionsPage from '../../../company/pages/CarbonEmissionsPage/CarbonEmissionsPage';
import CompaniesPage from '../../../company/pages/CompaniesPage/CompaniesPage';
import CompanyPage from '../../../company/pages/CompanyPage/CompanyPage';
import DueDiligencePage from '../../../company/pages/DueDiligencePage/DueDiligencePage';
import FinancialsPage from '../../../company/pages/FinancialsPage/FinancialsPage';
import SuppliersDashboardPage from '../../../company/pages/SuppliersDashboardPage/SuppliersDashboardPage';
import WeatherPage from '../../../company/pages/WeatherPage/WeatherPage';

// company routes
import carbonEmissionsRoute from '../../../company/pages/CarbonEmissionsPage/route';
import companiesRoute from '../../../company/pages/CompaniesPage/route';
import companyRoute from '../../../company/pages/CompanyPage/route';
import {
  dueDiligenceRoute,
  createArrangementRoute,
  getArrangementRoute,
  confirmedArrangementRoute,
} from '../../../company/pages/DueDiligencePage/route';
import financialsRoute from '../../../company/pages/FinancialsPage/route';
import supplierDashboardRoute from '../../../company/pages/SuppliersDashboardPage/route';
import weatherRoute from '../../../company/pages/WeatherPage/route';

// // compliance pages
// import CompliancePage from '../../../compliance/pages/CompliancePage/CompliancePage';

// // compliance routes
// import complianceRoute from '../../../compliance/pages/CompliancePage/route';

// // cyberSecurity pages
// import CyberSecurityPage from '../../../cyberSecurity/pages/CyberSecurityPage/CyberSecurityPage';

// // cyberSecurity routes
// import cyberSecurityRoute from '../../../cyberSecurity/pages/CyberSecurityPage/route';

// dashboard pages
import DashboardPage from '../../../dashboard/pages/DashboardPage/DashboardPage';

// dashboard routes
import dashboardRoute from '../../../dashboard/pages/DashboardPage/route';

// esg pages
import EsgPage from '../../../esg/pages/EsgPage/EsgPage';

// esg routes
import esgRoute from '../../../esg/pages/EsgPage/route';

// geopolitical pages
import GeopoliticalPage from '../../../geopolitical/pages/GeopoliticalPage/GeopoliticalPage';

// geopolitical routes
import geopoliticalRoute from '../../../geopolitical/pages/GeopoliticalPage/route';

// reports pages
import SecurityReportPage from '../../../reports/pages/SecurityReportPage/SecurityReportPage';

// reports routes
import securityReportRoute from '../../../reports/pages/SecurityReportPage/route';

// globalResilience pages
import GlobalResilienceIndexPage from '../../../globalResilience/pages/GlobalResilienceIndexPage/GlobalResilienceIndexPage';

// globalResilience routes
import globalResilienceIndexRoute from '../../../globalResilience/pages/GlobalResilienceIndexPage/route';

// layout components
import Layout from '../../../layout/components/Layout/Layout';

// notification pages
import NotificationsPage from '../../../notification/pages/NotificationsPage/NotificationsPage';

// notification routes
import notificationsRoute from '../../../notification/pages/NotificationsPage/route';

// news pages
import NewsPage from '../../../news/pages/NewsPage/NewsPage';

// news routes
import newsRoute from '../../../news/pages/NewsPage/route';

// sanction pages
import SanctionsPage from '../../../sanction/pages/SanctionsPage/SanctionsPage';

// sanction routes
import sanctionsRoute from '../../../sanction/pages/SanctionsPage/route';

// resource pages
import ResourcesPage from '../../../resource/pages/ResourcesPage/ResourcesPage';

// resource routes
import resourcesRoute from '../../../resource/pages/ResourcesPage/route';

// react
import React from 'react';

// router
import {BrowserRouter, Route, Routes as Router} from 'react-router-dom';

// tenant pages
import CustomDataPage from '../../../tenant/pages/CustomDataPage/CustomDataPage';

// tenant routes
import customDataRoute from '../../../tenant/pages/CustomDataPage/route';

// workflow pages
import WorkflowsPage from '../../../workflow/pages/WorkflowsPage/WorkflowsPage';

// workflow routes
import workflowsRoute from '../../../workflow/pages/WorkflowsPage/route';

const Routes = () => (
  <BrowserRouter>
    <Layout>
      <Router>
        <Route path={dashboardRoute()} element={<DashboardPage />} exact />
        <Route path={companiesRoute()} element={<CompaniesPage />} exact />
        <Route path={companyRoute()} element={<CompanyPage />}>
          <Route
            path={supplierDashboardRoute()}
            element={<SuppliersDashboardPage />}
            exact
          />
          <Route path={dueDiligenceRoute()} element={<DueDiligencePage />}>
            <Route
              path={createArrangementRoute()}
              element={<DueDiligencePage exact />}
            />
            <Route
              path={getArrangementRoute()}
              element={<DueDiligencePage exact />}
            />
            <Route
              path={confirmedArrangementRoute()}
              element={<DueDiligencePage exact />}
            />
          </Route>
          <Route path={financialsRoute()} element={<FinancialsPage />} exact />
          <Route
            path={carbonEmissionsRoute()}
            element={<CarbonEmissionsPage />}
            exact
          />
          <Route path={weatherRoute()} element={<WeatherPage />} exact />
          <Route path={customDataRoute()} element={<CustomDataPage />} exact />
          {/* <Route
            path={cyberSecurityRoute()}
            element={<CyberSecurityPage />}
            exact
          />
          <Route path={complianceRoute()} element={<CompliancePage />} exact /> */}
          <Route path={sanctionsRoute()} element={<SanctionsPage />} exact />
          <Route path={newsRoute()} element={<NewsPage />} exact />
          <Route path={esgRoute()} element={<EsgPage />} exact />
          <Route
            path={geopoliticalRoute()}
            element={<GeopoliticalPage />}
            exact
          />
        </Route>
        <Route
          path={securityReportRoute()}
          element={<SecurityReportPage />}
          exact
        />
        <Route path={collectionsRoute()} element={<CollectionsPage />} exact />
        <Route
          path={globalResilienceIndexRoute()}
          element={<GlobalResilienceIndexPage />}
          exact
        />
        <Route path={workflowsRoute()} element={<WorkflowsPage />} exact />
        <Route
          path={notificationsRoute()}
          element={<NotificationsPage />}
          exact
        />
        <Route path={resourcesRoute()} element={<ResourcesPage />} exact />
        <Route path="*" element={<RedirectToHome />} />
      </Router>
    </Layout>
  </BrowserRouter>
);

export default Routes;
