// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// tag components
import BlackKiteTagDetailModal from '../../components/BlackKiteTagDetailModal/BlackKiteTagDetailModal';

// tag events
import showBlackKiteDetailModalEvent from '../../events/showBlackKiteDetailModal.event.tag';

class BlackKiteTagDetailContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    tag: null,
  };

  componentDidMount() {
    this.props.subscribe(showBlackKiteDetailModalEvent.subscribe(this.show));
  }

  show = ({tag}) => {
    const missingRequiredFields = ['TagId', 'Title', 'TagName'].some(
      (attribute) => !tag[attribute]
    );
    if (missingRequiredFields) return;
    this.setState({visible: true, tag});
  };

  hide = () => {
    this.setState({visible: false});
  };

  render() {
    const {tag, visible} = this.state;
    return (
      <BlackKiteTagDetailModal
        visible={visible}
        tag={tag}
        onClose={this.hide}
      />
    );
  }
}

export default subscriptionHOC(BlackKiteTagDetailContainer);
