import styled from 'styled-components';

// colors
import blueGrey200Color from '../../../../layout/colors/blueGrey200.color.layout';

const Date = styled.div`
  color: ${blueGrey200Color};
`;

export default Date;
