// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// local columns
import creditHealthmarkColumn from './creditHealthmark.column';
import creditRiskLevelColumn from './creditRiskLevel.column';
import rrFinancialHealthRatingColumn from './rrFinancialHealthRating.column';

export default generateColumn({
  title: 'RapidRatings',
  children: [
    rrFinancialHealthRatingColumn,
    creditHealthmarkColumn,
    creditRiskLevelColumn,
  ],
});
