// charts
import Highcharts from 'highcharts';

// layout components
import Card from '../../../layout/components/Card/Card';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const HaloHistoricIntrinsicScoreChartCard = ({
  data = [],
  info = '',
  title = 'Halo Intrinsic Score Trend',
}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'column',
        height: '324px',
      },
      title: {
        text: title,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        tickInterval: 2,
        // labels: {
        //   formatter() {
        //     if (this.isFirst || this.isLast) {
        //       return this.value;
        //     } else {
        //       return '';
        //     }
        //   },
        // },
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0,
        },
      },
      series: [
        {
          name: 'Halo Score',
          data,
        },
      ],
    });
    // eslint-disable-next-line
  }, [data]);
  return (
    <Card infoTooltip={info} noPadding>
      <div id={id} />
    </Card>
  );
};

HaloHistoricIntrinsicScoreChartCard.propTypes = {
  data: PropTypes.array,
  info: PropTypes.node,
  title: PropTypes.node,
};

export default HaloHistoricIntrinsicScoreChartCard;
