// local components
import NameColumn from '../components/NameColumn';

// local lib
import generateColumn from '../lib/generateColumn.lib';

export default generateColumn({
  key: 'name',
  title: 'Name',
  ColumnComponent: NameColumn,
});
