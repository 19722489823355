// company events
import filterEvent from '../../events/filter.event.company';

// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CompanyFilterColumn = ({children, filter, filterValue}) => (
  <Container
    onClick={
      !!filter
        ? () =>
            filterEvent.publish({
              filter,
              filterValue,
            })
        : null
    }
  >
    {children}
  </Container>
);

CompanyFilterColumn.propTypes = {
  children: PropTypes.node,
  filter: PropTypes.string,
  filterValue: PropTypes.any,
};

export default CompanyFilterColumn;
