import styled from 'styled-components';

// router
import {Link as RouterLink} from 'react-router-dom';

const Link = styled(RouterLink)`
  display: block;
  text-decoration: none;
`;

export default Link;
