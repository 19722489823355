// chart components
import SmallTrendChart from '../../../chart/components/SmallTrendChart/SmallTrendChart';

// layout colors
import blue300Color from '../../../layout/colors/blue300.color.layout';
import brightYellow500Color from '../../../layout/colors/brightYellow500.color.layout';

// local components
import Card from './components/Card';
import Container from './components/Container';
import Title from './components/Title';
import Value from './components/Value';

// number lib
import shortenCurrency from '../../../number/lib/shortenCurrency.lib.number';
import shortenNumber from '../../../number/lib/shorten.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// score components
import HighlightedScore from '../../../score/components/HighlightedScore/HighlightedScore';

const FinancialsSummaryCard = ({
  creditLimit,
  currency,
  dbt,
  inquiriesTrend,
  internationalScore,
  judgmentFilings,
  judgmentSum,
  paymentExperiences,
  paymentTrend,
  possibleOfac,
  printView,
  riskScore,
}) => {
  return (
    <Container multiRows={printView}>
      <Card fixedWidth={printView}>
        <Title>Delinquency Risk Score</Title>
        <Value>
          <HighlightedScore>{riskScore}</HighlightedScore>
        </Value>
      </Card>
      <Card fixedWidth={printView}>
        <Title>International Delinquency Risk Score</Title>
        <Value>
          <HighlightedScore>{internationalScore}</HighlightedScore>
        </Value>
      </Card>
      <Card fixedWidth={printView}>
        <Title>Credit Limit</Title>
        <Value>
          <HighlightedScore>
            {shortenCurrency({
              number: creditLimit,
              currency,
            })}
          </HighlightedScore>
        </Value>
      </Card>
      <Card fixedWidth={printView}>
        <Title>Derogatory Legal</Title>
        <Value>
          <HighlightedScore>
            {judgmentFilings} (
            {shortenCurrency({
              number: judgmentSum,
              currency,
            })}
            )
          </HighlightedScore>
        </Value>
      </Card>
      <Card fixedWidth={printView}>
        <Title>Possible OFAC</Title>
        <Value>
          <HighlightedScore>{possibleOfac ? 'Yes' : 'No'}</HighlightedScore>
        </Value>
      </Card>
      <Card fixedWidth={printView}>
        <Title>DBT</Title>
        <Value>
          <HighlightedScore>{dbt}</HighlightedScore>
        </Value>
      </Card>
      <Card fixedWidth={printView}>
        <Title>Payment Experiences</Title>
        <Value>
          <HighlightedScore>
            {shortenNumber({
              number: paymentExperiences,
            })}
          </HighlightedScore>
        </Value>
      </Card>
      <Card fixedWidth={printView}>
        <Title>Payment Trend</Title>
        <Value>
          <SmallTrendChart color={brightYellow500Color} score={paymentTrend} />
        </Value>
      </Card>
      <Card fixedWidth={printView}>
        <Title>Inquiries Trend</Title>
        <Value>
          <SmallTrendChart color={blue300Color} score={inquiriesTrend} />
        </Value>
      </Card>
    </Container>
  );
};

FinancialsSummaryCard.propTypes = {
  creditLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  dbt: PropTypes.number,
  inquiriesTrend: PropTypes.number,
  internationalScore: PropTypes.string,
  judgmentFilings: PropTypes.number,
  judgmentSum: PropTypes.number,
  paymentExperiences: PropTypes.number,
  paymentTrend: PropTypes.number,
  possibleOfac: PropTypes.bool,
  printView: PropTypes.bool,
  riskScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FinancialsSummaryCard;
