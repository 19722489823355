// jquery
import $ from 'jquery';

// layout redux reducer
import {setLayoutSubPage as setLayoutSubPageAction} from '../../redux/reducer.redux.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import {useEffect} from 'react';

// redux
import {useDispatch} from 'react-redux';

const SubPage = ({children, page}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    $(window).scrollTop(0);
    dispatch(setLayoutSubPageAction(page));
    // eslint-disable-next-line
  }, []);

  return children;
};

SubPage.propTypes = {
  children: PropTypes.node,
  page: PropTypes.string,
};

export default SubPage;
