// company data
import extractKeyDataAndRatios from '../../../../company/components/FinancialsKeyDataAndRatiosSection/lib/extractKeyDataAndRatios.lib';
import extractCashFlowSummary from '../../../../company/components/FinancialsCashFlowSummarySection/lib/extractCashFlowSummary.lib';
import extractBalanceSheet from '../../../../company/components/FinancialsBalanceSheetSection/lib/extractBalanceSheet.lib';
import extractLiquidityModule from '../../../../company/components/FinancialsLiquidityModuleSection/lib/extractLiquidityModule.lib';
import extractActivityModule from '../../../../company/components/FinancialsActivityModuleSection/lib/extractActivityModule.lib';
import extractDuPontROA from '../../../../company/components/FinancialsDuPontROASection/lib/extractDuPontROA.lib';

// financial data
import extractPrivateFinancialsData from '../../PrivateCompanyFinancialsTable/lib/extractTableData.lib';

// local lib
import formatEntries from './formatEntries.lib';
import prepareForGPT from './prepareForGPT.lib';

// number lib
import convertFromMillions from '../../../../number/lib/convertFromMillions.lib.number';

const getGptData = ({company, alternativeFormatting = true}) => ({
  financialsOverview: prepareForGPT({
    alternativeFormatting,
    data: extractPrivateFinancialsData({company}),
    formatData: formatEntries(),
  }),
  keyDataAndRatios: prepareForGPT({
    alternativeFormatting,
    data: extractKeyDataAndRatios({company}).entries,
    formatData: formatEntries({
      millions: [
        'Total Assets',
        'Total Liabilities',
        'Total Revenue',
        'EBITDA (non-GAAP)',
        'Operating Profit (non-GAAP)',
      ],
      percentages: [
        'Total Rev / Total Assets',
        'Operating Profit / Total Rev %',
        'FCF / Total Debt',
      ],
      decimals: ['Operating Profit / Interest Exp'],
    }),
  }),
  cashFlowSummary: prepareForGPT({
    alternativeFormatting,
    data: extractCashFlowSummary({company}).entries,
    formatData: ({value}) => convertFromMillions(value),
  }),
  balanceSheet: prepareForGPT({
    alternativeFormatting,
    data: extractBalanceSheet({company}).entries,
    formatData: ({value}) => convertFromMillions(value),
  }),
  liquidityModule: prepareForGPT({
    alternativeFormatting,
    data: extractLiquidityModule({company}).entries,
    ignoredParameters: [
      'Direction',
      'key',
      'lastCategory',
      'lastValue',
      'penultimateCategory',
      'penultimateValue',
      'LTM',
    ],
    formatData: formatEntries(),
  }),
  activityModule: prepareForGPT({
    alternativeFormatting,
    data: extractActivityModule({company}).entries,
    ignoredParameters: [
      'Direction',
      'key',
      'lastCategory',
      'lastValue',
      'penultimateCategory',
      'penultimateValue',
      'LTM',
    ],
    formatData: formatEntries(),
  }),
  duPontROAAnalysis: prepareForGPT({
    alternativeFormatting,
    data: extractDuPontROA({company}).entries,
    formatData: formatEntries({
      millions: ['Operating Profit (Non-GAAP)', 'Revenue', 'Total Assets'],
      percentages: ['Operating Margin', 'Return on Assets'],
    }),
  }),
});

export default getGptData;
