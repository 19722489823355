import styled from 'styled-components';

// layout colors
import green300Color from '../../../../layout/colors/green300.color.layout';

const Line = styled.div`
  position: absolute;
  bottom: 0px;
  left: -12px;
  width: calc(100% + 24px);
  height: 2px;
  background: ${green300Color};
`;

export default Line;
