const GEOPOLITICAL_HALO_GPT_CONVERSATION = ({data}) => ({
  welcomeMessage: 'Welcome to HaloGPT. What can I help you with?',
  systemQueries: [
    {
      role: 'system',
      content: `You are the world's leading ESG expert. Please analyze the company's ESG data and answer the following questions as best you can. Data: ${JSON.stringify(data)}. Ensure accuracy and depth in your analysis. If the data is not available, do not talk about it.`,
    },
  ],
  model: 'ft:gpt-3.5-turbo-1106:halo-ai::8IsOhg3F',
  messages: [
    {
      message: 'Comprehensive ESG Analysis',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, provide a comprehensive analysis of the ESG data ${JSON.stringify(data.default)}? Highlight critical environmental, social, and governance aspects, and identify any areas of concern or excellence.`,
        },
      ],
    },
    {
      message: 'ESG Impact on Financials',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, How does the ESG performance impact the company's financial health and investor perception? Consider both direct and indirect influences.`,
        },
      ],
    },
    {
      message: 'Strategic ESG Risks & Opportunities',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, Identify and evaluate key strategic risks and opportunities from the ESG data provided. Suggest actionable strategies for mitigating risks and maximizing opportunities.`,
        },
      ],
    },
    {
      message: 'Stakeholder Engagement & ESG Initiatives',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, Assess the impact of the company's ESG initiatives on stakeholder engagement and trust. How do these initiatives align with stakeholder expectations and values?`,
        },
      ],
    },
    {
      message: 'ESG Benchmarking VS Industry',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, How does our ESG performance compare with industry benchmarks? Identify areas where the company leads or lags in ESG practices. Approximate the average scores of the companies within the same industry.`,
        },
      ],
    },
    {
      message: 'Third-Party ESG Risk Assessment',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, Evaluate the ESG risks associated with third-party partners and suppliers. How do these risks impact our overall ESG performance and what measures can mitigate these risks?`,
        },
      ],
    },
    {
      message: 'Supply Chain Sustainability & ESG Impact',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, Analyze the sustainability of our supply chain from an ESG perspective. Identify key areas for improvement and potential benefits of a more sustainable supply chain.`,
        },
      ],
    },
    {
      message: 'Long-term ESG Strategy and Global Trends',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, How should the company adapt its long-term ESG strategy in response to global sustainability trends and regulatory changes? Suggest a roadmap for future ESG initiatives.`,
        },
      ],
    },
    {
      message: 'Corporate Social Responsibility (CSR) Impact',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, Assess the effectiveness of our current CSR initiatives in terms of ESG outcomes. What improvements can be made to enhance social and environmental impact?`,
        },
      ],
    },
    {
      message: 'Green Finance Opportunities and ESG',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, Explore opportunities in green finance related to our ESG performance. How can leveraging green finance enhance our ESG credentials and support sustainability objectives?`,
        },
      ],
    },
    {
      message: 'ESG and Innovation Strategy',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, Analyze the interplay between our ESG performance and innovation strategy. How can we leverage innovation to drive ESG goals and gain competitive advantage?`,
        },
      ],
    },
    {
      message: 'Third-Party Risk Assessment',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, Evaluate the ESG risks associated with third-party partners and suppliers. How do these risks impact our overall ESG performance and what measures can mitigate these risks?`,
        },
      ],
    },
    {
      message: 'Third-Party Compliance',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, Assess the compliance of third-party partners and suppliers with our ESG standards. How can we ensure alignment and manage non-compliance risks?`,
        },
      ],
    },
    {
      message: 'Third-Party Sustainable Goals',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, How do our third-party partners and suppliers contribute to our overall sustainability goals? Identify areas where these partnerships can be improved for better ESG outcomes.`,
        },
      ],
    },
    {
      message: 'Monitoring & Reporting of ESG Practices',
      queries: [
        {
          role: 'user',
          content: `In under 5 sentences, Describe the mechanisms in place for monitoring and reporting on the ESG practices of our third-party partners and suppliers. How effective are these mechanisms in ensuring ESG compliance?`,
        },
      ],
    }
  ],
});

export default GEOPOLITICAL_HALO_GPT_CONVERSATION;
