// propTypes
import PropTypes from 'prop-types';

const CompanyDefaultDataColumn = ({children}) => children;

CompanyDefaultDataColumn.propTypes = {
  children: PropTypes.node,
};

export default CompanyDefaultDataColumn;
