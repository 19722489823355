import React, {Fragment} from 'react';
import styled from 'styled-components';
import {ReactComponent as CriticalityIndicator} from '../assets/criticality-indicator.svg';
import theme from '../constants/theme';
import {Grid, GraphContainer} from './Layout';

const Title = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
`;

const CriticalityContainer = styled.h3`
  display: flex;
  justify-content: space-around
  padding: 7px 12px 5px 12px;
  margin: 10px -12px 12px 0px;
`;

const criticalityIndicatorStyles = {
  margin: '0 0px -3px -4px',
  height: '22px',
};

const Criticality = ({
  style,
  criticality = 0,
  isRising = false,
  isFramed = false,
}) => {
  const renderCriticality = (criticality, isRising) => {
    const elements = [];
    for (let i = 0; i < 3; i++) {
      const fill =
        i < criticality
          ? theme.criticality.redCard
          : isRising && i === criticality
          ? theme.criticality.yellowCard
          : theme.criticality.greyCard;
      if (i < criticality)
        elements.push(
          <CriticalityIndicator
            fill={fill}
            style={criticalityIndicatorStyles}
            key={`${criticality}_${i}`}
          />
        );
    }
    return elements;
  };

  return (
    <Fragment>
      {isFramed ? (
        <GraphContainer
          style={{
            margin: '10px 0 -5px 0',
            padding: '7px 0px',
            position: 'relative',
            top: '-7px',
            left: '-8px',
            width: `${118 + criticality * 20}px`,
            height: '33px',
          }}
        >
          <Grid
            direction="row"
            alignItems="center"
            spacing={0}
            style={{margin: '-12px 0 0 10px'}}
          >
            <Title style={{padding: '0px 7px 0px 0'}}>Criticality Tier:</Title>
            <CriticalityContainer style={style}>
              {renderCriticality(criticality, isRising)}
            </CriticalityContainer>
          </Grid>
        </GraphContainer>
      ) : (
        <CriticalityContainer style={style}>
          {renderCriticality(criticality, isRising)}
        </CriticalityContainer>
      )}
    </Fragment>
  );
};

export default Criticality;
