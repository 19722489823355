const ACTIVITY_TYPES = [
  {
    key: 'News',
    label: 'News',
    icon: 'newspaper',
  },
  {
    key: 'Investments',
    label: 'Investments',
    icon: 'currency-usd',
  },
  {
    key: 'Acquisition',
    label: 'Mergers & Acquisition',
    icon: 'merge',
  },
  {
    key: 'IPO',
    label: 'IPO',
    icon: 'flag',
  },
  {
    key: 'Funds Raised',
    label: 'Funds Raised',
    icon: 'chart-timeline-variant',
  },
];

export default ACTIVITY_TYPES;
