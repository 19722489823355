// local components
import Container from './components/Containers';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const HighlightInfo = ({children}) => {
  return <Container>{children}</Container>;
};

HighlightInfo.propTypes = {
  children: PropTypes.node,
};

export default HighlightInfo;
