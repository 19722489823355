import styled from 'styled-components';

const Grade = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  color: ${({color}) => color};
  font-size: 60px;
  font-weight: 700;
  transform: translate(-50%, calc(-50% + 12px));
`;

export default Grade;
