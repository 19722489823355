export const palette = {
  // Color names -> https://chir.ag/projects/name-that-color/
  anzac: '#e09d3f',
  alizarinCrimson: '#DE1920',
  alizarinCrimson5: '#DE19200D',
  alizarinCrimson20: '#DE192033',
  alto: '#d9d9d9',
  backgroundDark: '#f6f8fc',
  backgroundLight: '#F4F5F5',
  bianca: '#fdfaf7',
  black: '#000000',
  black85: '#000000D9',
  cascade: '#88ab9e',
  clementine: '#ef6700',
  conch: '#c0d2cb',
  copperRust: '#9c4a56',
  dangerDefault: '#D77575',
  dangerHover: '#CB4D4D',
  dangerPressed: '#E9A5A5',
  doveGray: '#666666',
  dustyGray: '#6e6e6e',
  gallery: '#eeeeee',
  greenHaze: '#00a550',
  hippiePink: '#A84455',
  hotCinnamon: '#DF6F1C',
  info100: 'DDE1EE',
  jetStream: '#B5D4CB',
  lightGray: '#C2C8CB',
  mineShaft: '#333333',
  mojo: '#c34b36',
  oceanGreen: '#4faa7f',
  poloBlue: '#7CA5CE',
  primary100: '#C6ECCF',
  primaryDefault: '#63D57E',
  primaryHover: '#39AC55',
  primaryPressed: '#9CE2AC',
  rajah: '#FAC26C',
  rawSienna: '#d27434',
  robRoy: '#ebc584',
  secondaryDefault: '#6B7F89',
  secondaryHover: '#405B68',
  secondaryPressed: '#97A9B2',
  shadyLady: '#a692a6',
  sidebarLabel: '#DEE1E3',
  sidebarActiveLabel: '#2E3538',
  sidebarActiveBackground: '#377A6F',
  silver: '#cccccc',
  silverSolid: '#C0C0C0',
  supernova: '#F3CA00',
  transparent: 'transparent',
  waikawaGray: '#59658a',
  waikawaGrayAprox: '#717EA7',
  white: '#ffffff',
  gigas: '#4943AF',
};

// const getRandomColor = () => {
//   const colors = Object.keys(palette);
//   return palette[colors[Math.floor(Math.random() * colors.length)]];
// };

const theme = {
  addThirdParty: {
    background: palette.white,
    icon: palette.poloBlue,
  },
  arrangement: {
    answer: palette.cascade,
    answerDivider: palette.silver,
    background: palette.backgroundLight,
    questionId: palette.poloBlue,
    questionText: palette.poloBlue,
    logo: palette.hotCinnamon,
    section: palette.poloBlue,
    step: palette.white,
    stepItem: palette.gallery,
    divider: palette.gallery,
    stepActive: palette.waikawaGrayAprox,
    stepIcon: palette.poloBlue,
    text: palette.dustyGray,
    textActive: palette.bianca,
    textCompleted: palette.waikawaGrayAprox,
    title: palette.poloBlue,
    scoreLabel: palette.mineShaft,
  },
  card: {
    background: palette.white,
  },
  client: {
    arrowDown: palette.oceanGreen,
    arrowUp: palette.mojo,
    circle: palette.dustyGray,
    itemTitle: palette.mineShaft,
    divider: palette.mineShaft,
  },
  criticality: {
    redCard: palette.alizarinCrimson,
    yellowCard: palette.supernova,
    greyCard: palette.dustyGray,
  },
  commonList: {
    title: palette.waikawaGrayAprox,
    tableTH: palette.mineShaft,
    suspenderStatus: palette.alizarinCrimson,
    button: {
      border: palette.gallery,
      background: palette.white,
      color: palette.mineShaft,
    },
    tableContainer: {
      background: palette.white,
      border: palette.gallery,
    },
  },
  compareTable: {
    downloadPDFButtonBorder: palette.rajah,
    downloadPDFButtonColor: palette.rajah,
    rowBackground: palette.white,
    containerBackground: palette.silver,
    titleColor: palette.black,
  },
  dashboard: {
    instance: palette.poloBlue,
    known: palette.shadyLady,
    speedometer: {
      background: palette.white,
      label: palette.bianca,
      needle: palette.black,
      segments: [
        palette.oceanGreen,
        palette.poloBlue,
        palette.anzac,
        palette.mojo,
      ],
      text: palette.doveGray,
    },
    spiderGraph: {
      background: palette.white,
      border: palette.doveGray,
      title: palette.mineShaft,
    },
    title: palette.doveGray,
  },
  dial: {
    text: palette.black,
  },
  dueDilligenceProgress: {
    pendingLabel: palette.white,
    urgentLabel: palette.white,
    doneLabel: palette.mineShaft,
    done: palette.greenHaze,
    pending: palette.supernova,
    pendingUrgent: palette.alizarinCrimson,
  },
  slider: {
    green: palette.greenHaze,
    yellow: palette.supernova,
    red: palette.alizarinCrimson,
  },
  simulator: {
    buttonBackgound: palette.secondaryDefault,
    buttonBorderColor: palette.secondaryDefault,
    buttonColor: palette.white,
    title: palette.doveGray,
    paddedContainerBackgroundColor: palette.white,
    paddedContainerBorder: palette.gallery,
    sliderHandleBorder: palette.mineShaft,
  },
  suspendedNotification: {
    title: palette.alizarinCrimson,
    background: palette.alizarinCrimson5,
    border: palette.alizarinCrimson20,
    text: palette.mineShaft,
  },
  thirdPartyMoreDetails: {
    title: palette.black,
    companyName: palette.poloBlue,
    divider: palette.gallery,
    panel: {
      title: palette.black85,
      icon: palette.waikawaGray,
    },
    na: palette.dustyGray,
  },
  general: {
    background: palette.backgroundLight,
    button: {
      background: palette.bianca,
      border: palette.poloBlue,
      color: palette.poloBlue,
      hover: {
        background: palette.white,
        border: palette.cascade,
        color: palette.cascade,
      },
    },
    buttonDanger: {
      background: palette.dangerDefault,
      border: palette.dangerDefault,
      color: palette.bianca,
      focus: {
        background: palette.dangerPressed,
        border: palette.dangerPressed,
        color: palette.white,
      },
      hover: {
        background: palette.dangerHover,
        border: palette.dangerHover,
        color: palette.white,
      },
    },
    buttonPrimary: {
      background: palette.primaryDefault,
      border: palette.primaryDefault,
      color: palette.bianca,
      focus: {
        background: palette.primaryPressed,
        border: palette.primaryPressed,
        color: palette.white,
      },
      hover: {
        background: palette.primaryHover,
        border: palette.primaryHover,
        color: palette.white,
      },
    },
    buttonDefault: {
      background: palette.secondaryDefault,
      border: palette.secondaryDefault,
      color: palette.bianca,
      focus: {
        background: palette.secondaryPressed,
        border: palette.secondaryPressed,
        color: palette.white,
      },
      hover: {
        background: palette.secondaryHover,
        border: palette.secondaryHover,
        color: palette.white,
      },
    },
    list: {
      action: palette.dustyGray,
      even: {
        background: '#f9f9f9',
        hover: '#f3f3f3',
      },
      expandedRow: {
        background: palette.white,
        label: palette.dustyGray,
        text: palette.doveGray,
      },
      head: {
        background: palette.anzac,
        text: palette.mineShaft,
      },
      link: palette.poloBlue,
      odd: {
        background: '#f8ebd8',
        hover: '#f3f3f3',
      },
      select: palette.bianca,
      text: palette.doveGray,
    },
    spin: palette.waikawaGray,
    switch: palette.silver,
    switchChecked: palette.cascade,
    text: palette.mineShaft,
    title: palette.doveGray,
  },
  graphContainer: {
    border: palette.alto,
  },
  login: {
    logo: palette.silver,
  },
  navbar: {
    avatar: '#1f79c7',
    background: palette.white,
    icon: palette.dustyGray,
    sunColor: palette.dustyGray,
  },
  notFound: {
    backgroundColor: palette.silver,
  },
  score: {
    high: palette.mojo,
    low: palette.oceanGreen,
    medium: palette.anzac,
  },
  quickSight: {
    border: palette.alto,
  },
  scores: {
    text: palette.conch,
  },
  search: {
    background: palette.bianca,
    border: palette.silver,
    button: palette.primaryDefault,
    text: palette.doveGray,
    focus: {
      background: palette.primaryPressed,
      border: palette.primaryDefault,
    },
    hover: {
      background: palette.primaryHover,
    },
  },
  sidebar: {
    active: palette.sidebarActiveLabel,
    activeBackground: palette.white,
    background: palette.white,
    border: palette.gallery,
    hover: palette.poloBlue,
    icon: palette.sidebarLabel,
    iconBackground: palette.transparent,
    item: palette.mineShaft,
    innerBackground: palette.sidebarActiveBackground,
    innerIcon: palette.mineShaft,
    label: palette.sidebarLabel,
    logo: palette.mineShaft,
    popperBackground: palette.sidebarActiveBackground,
    popperContainerBackground: palette.white,
  },
  unauthorizedNotification: {
    title: palette.hippiePink,
    background: palette.white,
    border: palette.gallery,
    text: palette.mineShaft,
  },
};

export default theme;
