// antd
import {Table, Space} from 'antd';

// layout components
import Card from '../../../layout/components/Card/Card';
import Column from '../../../layout/components/Grid/Column/Column';
import Row from '../../../layout/components/Grid/Row/Row';
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local components
// import Crunbase from './components/Crunbase';

// local lib
import extractTableData from './lib/extractTableData.lib';
import getAdditionalSupplierColumns from './lib/getAdditionalSupplierColumns.lib';
import getCreditScoreColumns from './lib/getCreditScoreColumns.lib';
import getFundingColumns from './lib/getFundingColumns.lib';
import getInnovationColumns from './lib/getInnovationColumns.lib';
import getParentCompanyColumns from './lib/getParentCompanyColumns.lib';
import getRevenueColumns from './lib/getRevenueColumns.lib';
import getSupplierColumns from './lib/getSupplierColumns.lib';
import getUsedTechnologiesColumns from './lib/getUsedTechnologiesColumns.lib';
import getValuationColumns from './lib/getValuationColumns.lib';
// import getRankingAndTrendingColumns from './lib/getRankingAndTrendingColumns.lib';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const ExtendedPrivateCompanyFinancialsTable = () => {
  const {company} = useSelector((state) => state.company);

  const tableData = extractTableData({company});

  return (
    <Fragment>
      <Row margin>
        {!!company?.publicParentCompany && (
          <Column size={1 / 4} noPrintBreak>
            <StatementBlock title="Parent Company">
              <Card noPadding>
                <Table
                  columns={getParentCompanyColumns()}
                  dataSource={tableData}
                  pagination={false}
                  rowKey="section"
                  size="small"
                />
              </Card>
            </StatementBlock>
          </Column>
        )}
        <Column
          size={!!company?.publicParentCompany ? 2 / 4 : 2 / 3}
          noPrintBreak
        >
          <StatementBlock title="Credit Score (Data-Axle)">
            <Card noPadding>
              <Table
                columns={getCreditScoreColumns()}
                dataSource={tableData}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column
          size={!!company?.publicParentCompany ? 1 / 4 : 1 / 3}
          noPrintBreak
        >
          <StatementBlock title="Innovation">
            <Card noPadding>
              <Table
                columns={getInnovationColumns()}
                dataSource={tableData}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
      </Row>
      <Row margin>
        <Column noPrintBreak>
          <StatementBlock title="Supplier Info">
            <Space direction="vertical" size="middle" style={{width: '100%'}}>
              <Card noPadding>
                <Table
                  columns={getSupplierColumns()}
                  dataSource={tableData}
                  pagination={false}
                  rowKey="section"
                  size="small"
                />
              </Card>
              <Card noPadding>
                <Table
                  columns={getAdditionalSupplierColumns()}
                  dataSource={tableData}
                  pagination={false}
                  rowKey="section"
                  size="small"
                />
              </Card>
            </Space>
          </StatementBlock>
        </Column>
      </Row>
      <Row margin>
        <Column noPrintBreak>
          <StatementBlock title="Funding">
            <Card noPadding>
              <Table
                columns={getFundingColumns()}
                dataSource={tableData}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
      </Row>
      <Row margin>
        <Column noPrintBreak>
          <StatementBlock title="Revenue">
            <Card noPadding>
              <Table
                columns={getRevenueColumns()}
                dataSource={tableData}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
      </Row>
      <Row>
        <Column size={2 / 3} noPrintBreak>
          <StatementBlock title="Valuation">
            <Card noPadding>
              <Table
                columns={getValuationColumns()}
                dataSource={tableData}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column size={1 / 3} noPrintBreak>
          <StatementBlock title="Used Technologies">
            <Card noPadding>
              <Table
                columns={getUsedTechnologiesColumns()}
                dataSource={tableData}
                pagination={false}
                rowKey="section"
                size="small"
              />
            </Card>
          </StatementBlock>
        </Column>
      </Row>
      {/* <Row>
        <Column noPrintBreak>
          <Title>Ranking And Trending</Title>
          <Card noPadding>
            <Table
              columns={getRankingAndTrendingColumns()}
              dataSource={tableData}
              pagination={false}
              rowKey="section"
              size="small"
            />
          </Card>
        </Column>
      </Row> */}
    </Fragment>
  );
};

export default ExtendedPrivateCompanyFinancialsTable;
