import styled from 'styled-components';

const Label = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
`;

export default Label;
