// chart components
// import KeySuppliersChartCard from '../../../chart/components/KeySuppliersChartCard/KeySuppliersChartCard';
import KeySuppliersMapChartCard from '../../../chart/components/KeySuppliersMapChartCard/KeySuppliersMapChartCard';

// geopolitical containers
import CountrySuppliersContainer from '../../../geopolitical/containers/CountrySuppliersContainer/CountrySuppliersContainer';

// layout components
import {Column, Row} from '../../../layout/components/Grid/Grid';

// local lib
// import extractKeySuppliers from './lib/extractKeySuppliers.lib';
import extractMapData from './lib/extractMapData.lib';
import renderKeySuppliersSection from './lib/renderKeySuppliersSection.lib';

// map hooks
import useGetWorldAndUsaMapHook from '../../../map/hooks/useGetWorldAndUsaMap.hook.map';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const CompanyKeySuppliersSection = () => {
  const {company, globalResilienceIndexes} = useSelector((state) => ({
    company: state.company.company,
    globalResilienceIndexes: state.dashboard.globalResilienceIndexes,
  }));

  const {loadingMaps, worldMap} = useGetWorldAndUsaMapHook();

  return (
    renderKeySuppliersSection({company}) &&
    !loadingMaps && (
      <Fragment>
        <Row margin>
          <Column>
            <KeySuppliersMapChartCard
              companyName={company.CompanyName}
              worldMap={worldMap}
              worldData={extractMapData({company, globalResilienceIndexes})}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <CountrySuppliersContainer />
          </Column>
        </Row>
      </Fragment>
    )
  );
};

export default CompanyKeySuppliersSection;
