import styled from 'styled-components';

// layout colors
import whiteColor from '../../../../layout/colors/white.color.layout';

const Page = styled.div`
  width: 100%;
  min-height: calc(100vh - 61px);
  background: ${whiteColor};
  padding: 30px;
`;

export default Page;
