import styled from 'styled-components';

// layout colors
import whiteColor from '../../../colors/white.color.layout';

// local lib
import subPageHeight from '../constants/subPageHeight.constant';

const SubNavigation = styled.div`
  width: 100%;
  height: ${subPageHeight}px;
  background-color: ${whiteColor};
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 61px;
  left: 0px;
  z-index: 400;
  display: flex;
  align-items: center;
  padding-right: 30px;
`;

export default SubNavigation;
