// propTypes
import PropTypes from 'prop-types';

// react
// import React from 'react';

const Column = ({category, columnIndex, entry, entryIndex}) => {
  const value = entry[category];
  return [null, undefined, ''].includes(value) ? '—' : value || '-';
};

Column.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default Column;
