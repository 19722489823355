import styled from 'styled-components';

// layout colors
import grey800Color from '../../../../layout/colors/grey800.color.layout';

const Date = styled.div`
  font-size: 12px;
  color: ${grey800Color};
`;

export default Date;
