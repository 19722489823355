// error lib
import parseError from '../../../error/lib/parse.lib.error';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// ant components
import {message as messageApi} from 'antd';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// tag api
import getTagCompaniesApi from '../../api/getTagCompanies.api.tag';

// tag components
import TagEditorModal from '../../components/TagEditorModal/TagEditorModal';

// tag events
import showUpdateModalEvent from '../../events/showUpdateModal.event.tag';

// tag services
import deleteTagService from '../../services/delete.service.tag';
import updateTagService from '../../services/update.service.tag';

class UpdateTagContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    companies: [],
    date: '',
    description: '',
    loading: false,
    name: '',
    originalTag: null,
    previousCompanyIds: [],
    tag: '',
    tagId: null,
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showUpdateModalEvent.subscribe(this.show));
  }

  show = async ({tag}) => {
    this.setState({
      ...this.constructor.DEFAULT_STATE,
      loading: true,
      visible: true,
    });
    const {data: companies} = await getTagCompaniesApi(tag.id);
    const companyIds = [...companies].map(({id}) => id.replace('company_', ''));
    this.setState({
      companies: [...companyIds],
      date: tag?.date || '',
      description: tag?.description || '',
      loading: false,
      name: tag?.name || '',
      originalTag: {...tag},
      previousCompanyIds: [...companyIds],
      tag: tag?.tag || '',
      tagId: tag?.id || '',
      visible: true,
    });
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  change = (key) => (e) => {
    if (this.state.loading) return;
    const value = !!e?.target ? e.target.value : e;
    this.setState({[key]: value});
  };

  changeSelection = (key) => (value) => {
    const {loading} = this.state;
    if (loading) return;
    const previousValues = [...this.state[key]];
    const updateValues = previousValues.includes(value)
      ? previousValues.filter((v) => v !== value)
      : [...previousValues, value];
    this.setState({[key]: updateValues});
  };

  save = async () => {
    const {
      companies,
      // date,
      description,
      loading,
      name,
      previousCompanyIds,
      // tag,
      tagId,
    } = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      await updateTagService({
        tag: {
          // date,
          description,
          name,
          // tag,
        },
        companies,
        previousCompanyIds,
        tagId,
      });
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      messageApi.error(message);
      this.setState({loading: false});
    }
  };

  delete = async () => {
    const {loading, originalTag} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      await deleteTagService({tag: originalTag});
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      messageApi.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {companies, date, description, loading, name, tag, visible} =
      this.state;
    return (
      <TagEditorModal
        companies={companies}
        date={date}
        description={description}
        loading={loading}
        name={name}
        onChange={this.change}
        onChangeSelection={this.changeSelection}
        onClose={this.hide}
        onDelete={this.delete}
        onSave={this.save}
        tag={tag}
        title="Update Tag"
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(UpdateTagContainer);
