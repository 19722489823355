// ant components
import {Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialOfficersCard = ({printView}) => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const officers = company.financials.officers;

  const columns = generateColumns();

  return (
    <StatementBlock title="Officers">
      <Table
        columns={columns}
        dataSource={officers}
        pagination={false}
        rowKey="id"
        size="small"
      />
    </StatementBlock>
  );
};

FinancialOfficersCard.propTypes = {
  printView: PropTypes.bool,
};

export default FinancialOfficersCard;
