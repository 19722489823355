// layout colors
import green300Color from '../../../layout/colors/green300.color.layout';
import violet100Color from '../../../layout/colors/violet100.color.layout';

// local components
import Line from './components/Line';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const LineScore = ({
  score,
  maxScore = 100,
  barColor = green300Color,
  backgroundColor = violet100Color,
}) => (
  <Line width="100%" color={backgroundColor}>
    <Line width={`${(score / maxScore) * 100}%`} color={barColor} />
  </Line>
);

LineScore.propTypes = {
  backgroundColor: PropTypes.string,
  barColor: PropTypes.string,
  maxScore: PropTypes.number,
  score: PropTypes.number,
};

export default LineScore;
