// company components
import CompanyStrategicScoreColumn from '../../../components/CompanyStrategicScoreColumn/CompanyStrategicScoreColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Strategic Score',
  Component: CompanyStrategicScoreColumn,
  sorter: (a, b) => {
    return (
      Number(a?.HaloScores?.Strategic_Area?.Value || 0) -
      Number(b?.HaloScores?.Strategic_Area?.Value || 0)
    );
  },
});
