// carbonEmissions components
import CarbonEmissionsLayout from '../../components/CarbonEmissionsLayout/CarbonEmissionsLayout';

// company api
import loadAndAttachData from '../../../company/api/mock/lib/loadAndAttachData';

// company redux actions
import {setGeneralCompanyData as setGeneralCompanyDataAction} from '../../../company/redux/reducer.redux.company';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// propType
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class CompanyCarbonEmissionsContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const {company, dispatch} = this.props;

    if (!!company?.carbonEmissions || company?.carbonEmissionsLoaded)
      return this.setState({loading: false});

    try {
      const companyId = company.BlackKiteID || company.BlackKiteId || company.CompanyId;
      const companyWithLoadedData = await loadAndAttachData({
        company,
        dataList: [
          {
            filePath: `/mock/ditchCarbon/${companyId}.json`,
            dataKey: 'carbonEmissions',
          },
        ],
      });

      const combinedCompany = {
        ...company,
        ...companyWithLoadedData,
        carbonEmissionsLoaded: true,
      };

      dispatch(
        setGeneralCompanyDataAction({loading: false, company: combinedCompany})
      );
      this.setState({loading: false});
    } catch (error) {
      this.setState({loading: false});
    }
  };

  render() {
    const {loading} = this.state;
    return loading ? <FullScreenLoader /> : <CarbonEmissionsLayout />;
  }
}

export default connect((state) => ({company: state.company.company}))(
  CompanyCarbonEmissionsContainer
);
