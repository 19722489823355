// notification components
import Notifications from '../../components/Notifications/Notifications';

// notification lib
import groupNotificationsByCompany from '../../lib/groupNotificationsByCompany.lib.notification';

// notification redux reducer
import {setGeneralNotificationData as setGeneralNotificationDataAction} from '../../redux/reducer.redux.notification';

// notification services
import markNotificationsAsReadService from '../../services/markAsRead.service.notification';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class NotificationsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    notifications: PropTypes.array,
  };

  state = {
    notifications: [],
  };

  componentDidMount() {
    this.prepareNotifications();
  }

  componentWillUnmount() {
    this.markNotificationsAsRead();
  }

  prepareNotifications = () => {
    const {notifications} = this.props;
    const preparedNotifications = groupNotificationsByCompany({notifications});
    this.setState({notifications: preparedNotifications});
  };

  markNotificationsAsRead = async () => {
    const {notifications} = this.props;
    const updatedNotifications = await markNotificationsAsReadService({
      notifications,
    });
    this.props.dispatch(
      setGeneralNotificationDataAction({notifications: updatedNotifications})
    );
  };

  render() {
    const {notifications} = this.state;
    return <Notifications notifications={notifications} />;
  }
}

export default connect((state) => ({
  notifications: state.notification.notifications,
}))(NotificationsContainer);
