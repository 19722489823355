import styled, {css} from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';
import green400Color from '../../../../layout/colors/green400.color.layout';

const Message = styled.div`
  width: 100%;
  ${({isRead}) =>
    isRead
      ? css`
          color: ${blackColor};
          font-weight: 400;
        `
      : css`
          color: ${green400Color};
          font-weight: 700;
        `}
`;

export default Message;
