const SCORE_DISTRIBUTION_RISK_AREA_DESCRIPTIONS = {
  Financial:
    "The percentage contribution of this supplier to your company's Financial stability based on their Halo Intrinsic Score",
  Compliance:
    "The percentage contribution of this supplier to your company's Compliance controls based on their Halo Intrinsic Score",
  Strategic:
    "The percentage contribution of this supplier to your company's Strategic posture based on their Halo Intrinsic Score",
  Operational:
    "The percentage contribution of this supplier to your company's Operations based on their Halo Intrinsic Score",
  Reputational:
    "The percentage contribution of this supplier to your company's Reputation based on their Halo Intrinsic Score",
};

export default SCORE_DISTRIBUTION_RISK_AREA_DESCRIPTIONS;
