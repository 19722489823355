// number lib
import isNumber from 'is-number';
import convertFromMillions from '../../../../number/lib/convertFromMillions.lib.number';
import twoDecimals from '../../../../number/lib/twoDecimals.lib.number';

const formatEntries =
  ({
    millions = [],
    percentages = [],
    decimals = [],
    formatDecimals = true,
  } = {}) =>
  ({value, row}) => {
    if (!isNumber(value)) return value;

    return millions.includes(row)
      ? convertFromMillions(value)
      : percentages.includes(row)
      ? `${twoDecimals(value)}%`
      : decimals.includes(row)
      ? twoDecimals(value)
      : formatDecimals
      ? twoDecimals(value)
      : value;
  };

export default formatEntries;
