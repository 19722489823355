// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralLayoutDataAction from './actions/setGeneralData.action.redux.layout';
import setLayoutPageAction from './actions/setPage.action.redux.layout';
import setLayoutSubPageAction from './actions/setSubPage.action.redux.layout';

// style redux constants
import DEFAULT_LAYOUT_REDUX_STATE from './constants/defaultState.constant.redux.layout';

const layoutSlice = createSlice({
  name: 'layout',
  initialState: DEFAULT_LAYOUT_REDUX_STATE,
  reducers: {
    setGeneralLayoutData: setGeneralLayoutDataAction,
    setLayoutPage: setLayoutPageAction,
    setLayoutSubPage: setLayoutSubPageAction,
  },
});

export const {setGeneralLayoutData, setLayoutPage, setLayoutSubPage} =
  layoutSlice.actions;

export default layoutSlice.reducer;
