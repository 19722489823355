// local components
import GrowthColumn from '../components/GrowthColumn';

// local lib
import generateColumn from './generateColumn.lib';

const getColumns = ({years = []}) => ({
  yearlyTableWithGrowth: [
    generateColumn({category: 'label', title: ''}),
    ...[...years].map((year) =>
      generateColumn({category: year, title: year, Component: GrowthColumn})
    ),
  ],
  summaryRevenue: [
    generateColumn({category: 'year', title: ''}),
    generateColumn({category: 'value', title: ''}),
  ],
  employeeInformation: [
    generateColumn({category: 'year', title: ''}),
    generateColumn({category: 'employees', title: ''}),
  ],
  headlessKeyValue: [
    generateColumn({category: 'label', title: ''}),
    generateColumn({category: 'value', title: ''}),
  ],
  legalEvents: [
    generateColumn({category: 'key', title: 'Date'}),
    generateColumn({category: 'value', title: 'Description'}),
  ],
  ultimateHoldingCompany: [
    generateColumn({category: 'country', title: 'Country'}),
    generateColumn({category: 'companyName', title: 'Company Name'}),
    generateColumn({category: 'safeNumber', title: 'Safe Number'}),
    generateColumn({category: 'companyNumber', title: 'Company Number'}),
    generateColumn({category: 'status', title: 'Status'}),
  ],
  previousNames: [
    generateColumn({category: 'name', title: 'Name'}),
    generateColumn({category: 'date', title: 'Date'}),
  ],
  legalContracts: [
    generateColumn({category: 'date', title: 'Date'}),
    generateColumn({category: 'relationshipType', title: 'Relationship Type'}),
    generateColumn({category: 'between', title: 'Between'}),
    generateColumn({category: 'and', title: 'And'}),
    generateColumn({category: 'details', title: 'Details'}),
  ],
  auditorDetails: [
    generateColumn({category: 'auditor', title: 'Auditor'}),
    generateColumn({category: 'description', title: 'Description'}),
    generateColumn({category: 'date', title: 'Date'}),
  ],
  bankerDetails: [
    generateColumn({category: 'bankCode', title: 'Bank Code'}),
    generateColumn({category: 'nameOfBank', title: 'Name of Bank'}),
    generateColumn({category: 'BIC', title: 'BIC'}),
    generateColumn({category: 'address', title: 'Address'}),
  ],
});

export default getColumns;
