import store from './store';

const data = (storeName, defaultData = {}) => {
  store.setData(storeName, defaultData);
  return {
    setData: (data = {}) => store.setData(storeName, data),
    getData: () => store.getData(storeName),
  };
};

export default data;
