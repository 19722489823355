const parseDescription = ({description, numberOfCharacters = 180}) => {
  const shortDescription = description
    .split(' ')
    .reduce(
      (combined, word) => {
        if (combined.characterCount >= numberOfCharacters) return combined;
        return {
          characterCount: combined.characterCount + word.length,
          words: [...combined.words, word],
        };
      },
      {characterCount: 0, words: []}
    )
    .words.join(' ');
  return {shortDescription, wasShortened: description !== shortDescription};
};

export default parseDescription;
