import styled from 'styled-components';

// layout colors
import brightRed300Color from '../../../../layout/colors/brightRed300.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

const Badge = styled.div`
  padding: 3px 10px;
  background: ${whiteColor};
  color: ${brightRed300Color};
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
`;

export default Badge;
