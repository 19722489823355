// charts
import Highcharts from 'highcharts';

// esg constants
import SELF_REPORTED_DATA_DESCRIPTION from '../../../esg/constants/selfReportedDataDescription.constant.esg';

// layout components
import Card from '../../../layout/components/Card/Card';
import InformationTooltip from '../../../layout/components/InformationTooltip/InformationTooltip';
import MissingDataRow from '../../../layout/components/MissingDataRow/MissingDataRow';
import {Row, Column} from '../../../layout/components/Grid/Grid';

// local lib
import shouldDisplayChart from './lib/shouldDisplayChart.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const EsgDistributionChartCard = ({
  data = [],
  categories = [],
  descriptions = {},
}) => {
  const id = v4();

  const displayChart = shouldDisplayChart({data});

  useEffect(() => {
    if (!displayChart) return;
    categories.map((category) =>
      Highcharts.chart(`${id}${category}`, {
        chart: {
          type: 'column',
          marginTop: 50,
          height: '460px',
        },

        title: {
          text: category,
        },

        xAxis: {
          type: 'category',
          crosshair: true,
          // categories,
        },

        yAxis: {
          allowDecimals: true,
          min: 0,
          max: 100,
          title: {
            text: 'Score',
          },
        },

        // tooltip: {
        //   formatter: function () {
        //     return `<b>${this.x}</b><br/>${this.series.name}: ${this.y}%<br/>Total: ${this.point.stackTotal}%`;
        //   },
        // },

        plotOptions: {
          column: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '16px',
                fontWeight: 400,
              },
              formatter: function () {
                return !!this.point.y ? `${this.point.y}` : '';
              },
            },
          },
        },

        series: [...data].filter(
          ({data: dataList}) => dataList?.[0]?.name === category
        ),
      })
    );
    // eslint-disable-next-line
  }, [data, categories, displayChart]);
  return (
    <Row>
      <Column margin>
        <InformationTooltip tooltip={SELF_REPORTED_DATA_DESCRIPTION}>
          Based on Self-reported & Public Data
        </InformationTooltip>
      </Column>
      {displayChart ? (
        categories.map((category) => (
          <Column key={category} size={1 / categories.length}>
            <Card infoTooltip={descriptions?.[category]} noPadding>
              <div id={`${id}${category}`} />
            </Card>
          </Column>
        ))
      ) : (
        <Column>
          <MissingDataRow>No ESG data</MissingDataRow>
        </Column>
      )}
    </Row>
  );
};

EsgDistributionChartCard.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.array,
  descriptions: PropTypes.object,
};

export default EsgDistributionChartCard;
