// company containers
import WorkflowsContainer from '../../containers/WorkflowsContainer/WorkflowsContainer';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local page
import page from './page';

// react
import React from 'react';

const WorkflowsPage = () => (
  <Page page={page}>
    <WorkflowsContainer />
  </Page>
);

export default WorkflowsPage;
