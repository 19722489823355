// company components
import CompanyFinancialScoreColumn from '../../../components/CompanyFinancialScoreColumn/CompanyFinancialScoreColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Financial Score',
  Component: CompanyFinancialScoreColumn,
  sorter: (a, b) => {
    return (
      Number(a?.HaloScores?.Financial_Area?.Value || 0) -
      Number(b?.HaloScores?.Financial_Area?.Value || 0)
    );
  },
});
