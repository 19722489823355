// layout components
import FormattedNumber from '../../../../layout/components/FormattedNumber/FormattedNumber';

// local constants
import DIRECTIONS_COLUMN from '../constants/directionsColumn.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Column = ({category, columnIndex, entry, entryIndex}) => {
  const value = entry[category];
  return !columnIndex || category === DIRECTIONS_COLUMN ? (
    value
  ) : (
    <FormattedNumber number={value} />
  );
};

Column.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default Column;
