// company containers
import CompanyEsgContainer from '../../containers/CompanyEsgContainer/CompanyEsgContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const EsgPage = () => (
  <SubPage page={page}>
    <CompanyEsgContainer />
  </SubPage>
);

export default EsgPage;
