import PropTypes from 'prop-types';

// Styles
import styled, {css} from 'styled-components';
import {clearFix} from 'polished';
import {media} from '../../../style/media.style.layout';

const Column = styled.div`
  ${clearFix()};
  float: left;
  width: ${({size}) => `${size * 100}%`};
  max-width: ${({size}) => `${size * 100}%`};
  min-width: ${({size}) => `${size * 100}%`};
  margin-left: ${({offset}) => (!!offset ? `${offset * 100}%` : '0px')};
  margin-bottom: ${({margin}) => (margin ? 20 : 0)}px;
  padding: 0px 10px;

  ${({noPrintBreak}) =>
    noPrintBreak
      ? css`
          break-inside: avoid;
        `
      : ''}

  ${media.l`
    ${({l}) =>
      l > 0
        ? `width: ${l * 100}%; min-width: ${l * 100}%; max-width: ${l * 100}%;`
        : ''}
    ${({offset_l}) =>
      !!offset_l || offset_l === 0 ? `margin-left: ${offset_l * 100}%;` : ''}
  `};
  ${media.m`
    ${({m}) =>
      m > 0
        ? `width: ${m * 100}%; min-width: ${m * 100}%; max-width: ${m * 100}%;`
        : ''}
    ${({offset_m}) =>
      !!offset_m || offset_m === 0 ? `margin-left: ${offset_m * 100}%;` : ''}
  `};
  ${media.s`
    padding: 0px 20px;
    ${({s}) =>
      s > 0
        ? `width: ${s * 100}%; min-width: ${s * 100}%; max-width: ${s * 100}%;`
        : ''}
    ${({offset_s}) =>
      !!offset_s || offset_s === 0 ? `margin-left: ${offset_s * 100}%;` : ''}
  `};
`;

Column.propTypes = {
  size: PropTypes.number.isRequired,
  offset: PropTypes.number,
  l: PropTypes.number,
  m: PropTypes.number,
  s: PropTypes.number,
  offset_l: PropTypes.number,
  offset_m: PropTypes.number,
  offset_s: PropTypes.number,
  margin: PropTypes.bool,
};

Column.defaultProps = {
  size: 1,
  offset: 0,
  l: 0,
  m: 0,
  s: 0,
  margin: false,
};

export default Column;
