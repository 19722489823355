const extractSources = ({company}) =>
  [...company.esgInfo.dataSourcesData.datasourcesall].reduce(
    (sources, dataSource) =>
      !dataSource?.elements?.length || sources.includes(dataSource.name)
        ? sources
        : [...sources, dataSource.name],
    []
  );

export default extractSources;
