// jquery
import $ from 'jquery';

// local components
// import Line from './components/Line';
import Section from './components/Section/Section';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

class SectionScroller extends Component {
  static propTypes = {
    children: PropTypes.func,
    offset: PropTypes.number,
    onVisibilityChange: PropTypes.func,
  };

  static defaultProps = {
    offset: 0,
    onVisibilityChange: () => {},
  };

  static SCROLL_DURATION = 500;

  ignoreUpdatingVisibility = false;
  visibleSections = [];
  visibleSection = null;

  componentDidMount() {
    $(window).on('scroll', this.scrolled);
    this.scrolled();
  }

  componentWillUnmount() {
    $(window).off('scroll', this.scrolled);
  }

  navigationHeight = () => {
    const {offset} = this.props;
    return $('nav').height() + offset;
  };

  scrolled = () => {
    const navigationHeight = this.navigationHeight();
    const {offset} = this.props;
    const scrollStart = $(window).scrollTop();
    const scrollEnd = scrollStart + $(window).height();
    const contentHeight = $(document).height() - $(window).height();
    const scrollPercentage = scrollStart / contentHeight;
    const scrollPosition =
      ($(window).height() - navigationHeight) * scrollPercentage +
      navigationHeight;
    // $('#scrollLine').css({top: `${scrollPosition}px`});
    const visibleSections = $('[data-scroll-section]')
      .filter(function () {
        const start = this.offsetTop;
        const end = start + this.scrollHeight;
        return start < scrollStart ? end > scrollStart : start < scrollEnd;
      })
      .map(function () {
        return this.getAttribute('data-scroll-section');
      })
      .toArray();
    const visibleSection =
      scrollStart <= offset
        ? $('[data-scroll-section]').first().attr('data-scroll-section')
        : scrollPercentage >= 1
        ? $('[data-scroll-section]').last().attr('data-scroll-section')
        : $('[data-scroll-section]')
            .filter(function () {
              const start = this.offsetTop + navigationHeight - scrollStart;
              const end = start + this.offsetHeight;
              return scrollPosition >= start && scrollPosition <= end;
            })
            .map(function () {
              return this.getAttribute('data-scroll-section');
            })
            .toArray()?.[0] || null;
    const update =
      this.visibleSection !== visibleSection ||
      visibleSections.length !== this.visibleSections.length ||
      [...visibleSections].some(
        (section) => ![...this.visibleSections].includes(section)
      ) ||
      [...this.visibleSections].some(
        (section) => ![...visibleSections].includes(section)
      );
    if (!update) return;
    if (!this.ignoreUpdatingVisibility) {
      this.props.onVisibilityChange(visibleSection);
    }
    this.visibleSection = visibleSection;
    this.visibleSections = visibleSections;
  };

  goToSection = (section) => {
    const {SCROLL_DURATION} = this.constructor;
    const sectionDom = $(`[data-scroll-section=${section}]`);
    if (!sectionDom.length) return;
    this.ignoreUpdatingVisibility = true;
    this.visibleSection = section;
    this.props.onVisibilityChange(section);
    $('html, body')
      .stop()
      .animate(
        {scrollTop: sectionDom.offset().top - this.navigationHeight()},
        SCROLL_DURATION,
        'swing'
      );

    setTimeout(() => {
      this.ignoreUpdatingVisibility = false;
    }, SCROLL_DURATION + 100);
  };

  render() {
    const {children} = this.props;
    return (
      <Fragment>
        {children({Section, goToSection: this.goToSection})}
        {/* <Line id="scrollLine" /> */}
      </Fragment>
    );
  }
}

export default SectionScroller;
