import {debounce} from 'lodash';
import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';

import {updateDimensions} from '../store/reducer.redux.dimensions';

// const getScrollbarWidth = () => {
//   const element = document.createElement('div');
//   element.style.width = '100px';
//   element.style.height = '100px';
//   element.style.overflow = 'scroll';
//   element.style.position = 'absolute';
//   element.style.top = '-9999px';

//   document.body.appendChild(element);
//   const scrollbarWidth = element.offsetWidth - element.clientWidth;
//   document.body.removeChild(element);

//   return scrollbarWidth;
// };

// const isScrollbarVisible = document.documentElement.scrollHeight > window.innerHeight;
// const scrollbarWidth = getScrollbarWidth();
// window.dispatchEvent(new Event('resize'));

const getDimensions = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
});

const useDimensions = () => {
  const dispatch = useDispatch();

  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    const handleResize = debounce(() => setDimensions(getDimensions()), 100);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch(updateDimensions(dimensions));
    // eslint-disable-next-line
  }, [dimensions]);
};

export default useDimensions;
