// workflow events
import workflowCreatedEvent from '../events/created.event.workflow';

// workflow repositories
import createWorkflowRepository from '../repositories/create.repository.workflow';

// workflow validators
import createWorkflowValidator from '../validators/create.validator.workflow';

const createWorkflowService = async ({
  createWorkflow = createWorkflowRepository,
  tenantWide,
  validateWorkflow = createWorkflowValidator,
  workflow,
  workflowCreated = workflowCreatedEvent.publish,
}) => {
  validateWorkflow({workflow});
  workflow.recipients = tenantWide ? [] : workflow.recipients;
  const createdWorkflow = await createWorkflow({workflow});
  workflowCreated({workflow: createdWorkflow});
  return createdWorkflow;
};

export default createWorkflowService;
