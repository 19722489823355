import styled from 'styled-components';

const MessageInput = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  gap: 4px;
  align-items: center;
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
  flex-wrap: wrap;
`;

export default MessageInput;
