// company containers
import CompanySideMenuContainer from '../../../company/containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../../company/lib/generateSubPages.lib.company';

// company routes
import suppliersDashboardRoute from '../../../company/pages/SuppliersDashboardPage/route';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

// tenant components
import CustomData from '../CustomData/CustomData';

const CustomDataLayout = () => {
  const {company, subPage} = useSelector((state) => ({
    company: state.company.company,
    subPage: state.layout.subPage,
  }));

  return (
    <PageLayout
      alerts={company?.alerts || []}
      previousPageLabel="Back to Dashboard"
      previousPageUrl={suppliersDashboardRoute(company.CompanyId)}
      sideContent={({goToSection}) => (
        <CompanySideMenuContainer goToSection={goToSection} />
      )}
      subPage={subPage}
      subPages={generateSubPages(company)}
      sectionName="CustomData"
      sections={[
        {
          section: 'Custom Data',
          component: <CustomData />,
        },
      ].filter((section) => !!section)}
    />
  );
};

export default CustomDataLayout;
