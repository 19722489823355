import styled from 'styled-components';

const Entities = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export default Entities;
