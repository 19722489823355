// charts
import Highcharts from 'highcharts';

// layout components
import Card from '../../../layout/components/Card/Card';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const HaloHistoricKeyAreaScoresChartCard = ({data = [], info = ''}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'line',
        height: '280px',
      },
      title: {
        text: 'Key Risk Area Halo Scores Trend',
      },
      xAxis: {
        crosshair: true,
        type: 'category',
        tickInterval: 2,
      },
      yAxis: {
        title: {
          enabled: false,
          text: 'Halo Scores',
        },
      },
      tooltip: {
        shared: true,
      },
      series: data,
    });
    // eslint-disable-next-line
  }, [data]);
  return (
    <Card infoTooltip={info} noPadding>
      <div id={id} />
    </Card>
  );
};

HaloHistoricKeyAreaScoresChartCard.propTypes = {
  data: PropTypes.array,
  info: PropTypes.node,
};

export default HaloHistoricKeyAreaScoresChartCard;
