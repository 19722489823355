const extractCyberRiskTags = (companies) =>
  [...companies]
    .map((company) => (company?.CyberSecurity?.CyberAlerts || '').split(', '))
    .flat()
    .reduce((cyberRiskTags, cyberRiskTag) => {
      return !cyberRiskTag || cyberRiskTags.includes(cyberRiskTag)
        ? cyberRiskTags
        : [...cyberRiskTags, cyberRiskTag];
    }, [])
    .map((cyberRiskTag) => ({value: cyberRiskTag, label: cyberRiskTag}));

export default extractCyberRiskTags;
