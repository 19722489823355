// company storages
import companyCustomColumnsStorage from '../storages/customColumns.storage.company';

// redux store
import reduxStore from '../../redux/store';

const saveUserColumnsService = ({
  customColumns,
  user = reduxStore.getState().auth.user,
} = {}) => {
  const allCustomColumns = JSON.parse(
    companyCustomColumnsStorage.get() || '{}'
  );
  const updatedCustomColumns = {
    ...allCustomColumns,
    [user.email]: customColumns,
  };
  companyCustomColumnsStorage.add(JSON.stringify(updatedCustomColumns));
};

export default saveUserColumnsService;
