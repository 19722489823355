// financial lib
import getFiscalYear from '../../../../financial/lib/getFiscalYear.lib.financial';

// local lib
import generateColumn from './generateColumn.lib';

// table lib
import checkAndRemoveLTM from '../../../../table/lib/checkAndRemoveLTM.lib.table';
import removeEmptyTableColumns from '../../../../table/lib/removeEmptyColumns.lib.table';

const extractBalanceSheet = ({company}) => {
  if (!company?.historicalFinancials?.balanceSheet)
    return {categories: [], entries: []};
  // const currency = company?.historicalFinancials?.currency;
  const categories = [
    'section',
    ...company.historicalFinancials.balanceSheet.periods,
  ];
  return removeEmptyTableColumns(
    checkAndRemoveLTM({
      categories: [...categories].map((category, index) =>
        generateColumn({
          title: !index ? `USD Millions` : null,
          category,
          columnIndex: index,
          fiscalYear: getFiscalYear({
            years: company?.historicalFinancials?.fiscalYears?.fiscalQuarter,
            category,
          }),
        })
      ),
      entries: [
        ...Object.entries(company.historicalFinancials.balanceSheet.data),
      ].map(([label, values]) => ({
        key: label,
        section: label,
        ...[...values].reduce((combined, current, index) => {
          const category = categories?.[index + 1];
          return !!category ? {...combined, [category]: current} : combined;
        }, {}),
      })),
    })
  );
};

export default extractBalanceSheet;
