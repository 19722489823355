const getSubPercentileValue = ({ratings = [], attribute, attributeValue}) =>
  ([...ratings].find((rating) => {
    const attributes = Object.keys(rating);
    return (
      attributes.length === 2 &&
      attributes.includes('value') &&
      attributes.includes(attribute) &&
      rating[attribute] === attributeValue
    );
  })?.value || 0) * 100;

export default getSubPercentileValue;
