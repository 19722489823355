// collection events
import collectionCreatedEvent from '../../events/created.event.collection';
import collectionDeletedEvent from '../../events/deleted.event.collection';
import collectionUpdatedEvent from '../../events/updated.event.collection';

// collection redux events
import {storeCollection as storeCollectionAction} from '../../redux/reducer.redux.collection';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class CollectionEventHandlerContainer extends Component {
  static propTypes = {
    collection: PropTypes.object,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.props.subscribe(
      collectionCreatedEvent.subscribe(this.collectionCreated),
      collectionDeletedEvent.subscribe(this.collectionDeleted),
      collectionUpdatedEvent.subscribe(this.collectionUpdated)
    );
  }

  collectionCreated = (collection) => {
    const {collections, dispatch} = this.props;
    const updatedCollections = [...collections, collection];
    dispatch(storeCollectionAction({collections: updatedCollections}));
  };

  collectionDeleted = (collection) => {
    const {collections, dispatch} = this.props;
    const updatedCollections = collections.filter(
      (c) => c.id !== collection.id
    );
    dispatch(storeCollectionAction({collections: updatedCollections}));
  };

  collectionUpdated = (collection) => {
    const {collections, dispatch} = this.props;
    const updatedCollections = collections.map((c) =>
      c.id === collection.id ? collection : c
    );
    dispatch(storeCollectionAction({collections: updatedCollections}));
  };

  render() {
    return null;
  }
}

export default connect((state) => ({
  collections: state.collection.collections,
}))(subscriptionHOC(CollectionEventHandlerContainer));
