import styled from 'styled-components';

// layout colors
import green400Color from '../../../../layout/colors/green400.color.layout';

const More = styled.div`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  flex: 1;
  cursor: pointer;
  color: ${green400Color};
`;

export default More;
