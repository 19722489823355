// ant components
import {Col as Column, Row, Table} from 'antd';

// date lib
import formatISODate from '../../../date/formatters/formatISO.formatter.date';

// financial components
import IndustryBreakdownChart from '../IndustryBreakdownChart/IndustryBreakdownChart';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const HistoricalTradeInformationCard = ({
  currency,
  valueCategories,
  entries,
}) => {
  const columns = generateColumns({currency, valueCategories});

  const chartCategories = [...entries]
    .reduce(
      (combined, {date}) =>
        combined.includes(date?.value) ? combined : [...combined, date.value],
      []
    )
    .map((date) => formatISODate({date, dateFormat: 'MMMM yyyy'}));
  const statuses = [...entries].reduce(
    (combined, {status}) =>
      !combined.includes(status) ? [...combined, status] : combined,
    []
  );

  return (
    <StatementBlock title="Historical Trade Information">
      <Row gutter={[0, 10]}>
        <Column span={24}>
          <Table
            columns={columns.valueCategories}
            dataSource={entries}
            pagination={false}
            rowKey={(record) => `${record.date.value}${record.status}`}
            size="small"
          />
        </Column>
        <Column span={24}>
          <Row gutter={[10, 10]}>
            {statuses.map((status) => (
              <Column span={24 / statuses.length}>
                <IndustryBreakdownChart
                  categories={chartCategories}
                  entries={[
                    {
                      name: status,
                      data: [...entries]
                        .filter((entry) => entry.status === status)
                        .map(({total}) => total?.value),
                    },
                  ]}
                />
              </Column>
            ))}
          </Row>
        </Column>
      </Row>
    </StatementBlock>
  );
};

HistoricalTradeInformationCard.propTypes = {
  currency: PropTypes.string,
  valueCategories: PropTypes.array,
  entries: PropTypes.array,
};

export default HistoricalTradeInformationCard;
