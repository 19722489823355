// charts
import Highcharts from 'highcharts';

// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// risk lib
import getRiskColor from '../../../risk/lib/getRiskColor.lib.risk';

// uuid
import {v4} from 'uuid';

const MiniPrimaryScoreChart = ({score = 0}) => {
  const id = v4();

  const {primary, secondary} = getRiskColor(score);

  useEffect(() => {
    Highcharts.chart(id, {
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      chart: {
        type: 'solidgauge',
        height: '40px',
        spacing: [0, 0, 0, 0],
        // backgroundColor: 'transparent',
        style: {
          borderRadius: '50%',
        },
      },
      tooltip: {
        enabled: false,
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        size: '100%',
        background: [
          {
            outerRadius: '100%',
            innerRadius: '65%',
            backgroundColor: secondary,
            borderWidth: 0,
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: true,
            borderWidth: 0,
            padding: 0,
            y: -9,
            style: {
              fontSize: '16px',
            },
            format: '{point.y:.0f}',
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },
      series: [
        {
          name: 'Halo Intrinsic Score',
          data: [
            {
              color: primary,
              radius: '100%',
              innerRadius: '65%',
              y: score,
            },
          ],
        },
      ],
    });
    // eslint-disable-next-line
  }, [score]);

  return (
    <Container>
      <div id={id} />
    </Container>
  );
};

MiniPrimaryScoreChart.propTypes = {
  score: PropTypes.number,
};

export default MiniPrimaryScoreChart;
