import styled from 'styled-components';

// layout colors
import grey300Color from '../../../../layout/colors/grey300.color.layout';

// constants
const SIZE = '320px';

const MetaSection = styled.div`
  width: ${SIZE};
  max-width: ${SIZE};
  min-width: ${SIZE};
  padding: 0px 20px;
  border-left: 1px solid ${grey300Color};
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export default MetaSection;
