import styled from 'styled-components';

const Containers = styled.div`
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
  color: ${({color}) => color};
  cursor: ${({onClick}) => (!!onClick ? 'pointer' : 'initial')};
`;

export default Containers;
