// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';
import CompanyFilterColumn from '../../../components/CompanyFilterColumn/CompanyFilterColumn';

// company filters
import industriesFilter from '../../../filters/industries.filter.company';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Primary Industry',
  componentProps: ({company}) => ({
    children: (
      <CompanyFilterColumn
        filter={industriesFilter.filter}
        filterValue={company?.Firmographic?.PrimaryIndustry || null}
      >
        {company?.Firmographic?.PrimaryIndustry || '-'}
      </CompanyFilterColumn>
    ),
  }),
  Component: CompanyDefaultDataColumn,
});
