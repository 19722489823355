// financial components
import FiscalYearTableHeader from '../../../../financial/components/FiscalYearTableHeader/FiscalYearTableHeader';

// local components
import Column from '../components/Column';

const generateColumn = ({category, columnIndex, fiscalYear, title = null}) => {
  return {
    title:
      title ||
      (!!fiscalYear ? (
        <FiscalYearTableHeader {...fiscalYear} />
      ) : !columnIndex ? (
        ''
      ) : (
        category
      )),
    key: category,
    dataIndex: category,
    align: !columnIndex ? 'left' : 'center',
    render: (_, entry, entryIndex) => (
      <Column
        category={category}
        columnIndex={columnIndex}
        entry={entry}
        entryIndex={entryIndex}
      />
    ),
  };
};

export default generateColumn;
