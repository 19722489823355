import styled from 'styled-components';

// colors
import blueGrey500Color from '../../../../../../layout/colors/blueGrey500.color.layout';

const Title = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: ${blueGrey500Color};
  gap: 15px;
  white-space: nowrap;
`;

export default Title;
