const cyberFactorIndexes = {
  DigitalFootprint: 0,
  DNSHealth: 1,
  EmailSecurity: 2,
  SSLStrength: 3,
  ApplicationSecurity: 4,
  DDoSResiliency: 5,
  NetworkSecurity: 6,
  FraudulentDomains: 7,
  FraudulentApps: 8,
  CredentialManagement: 9,
  IPReputation: 10,
  HacktivistShares: 11,
  SocialNetwork: 12,
  AttackSurface: 13,
  BrandMonitoring: 14,
  PatchManagement: 15,
  WebRanking: 16,
  InformationDisclosure: 17,
  CDNSecurity: 18,
  WebsiteSecurity: 19,
};

const getCyberFactorIndex = (cyberFactor) =>
  cyberFactorIndexes?.[cyberFactor] || 0;

export default getCyberFactorIndex;
