// table components
import DynamicColumn from '../../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const generateColumns = ({
  currency,
  summary,
  activeSuits,
  activeJudgments,
}) => ({
  suitsSummary: [...summary.suits.categories].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      props: {currency},
      title: label,
    })
  ),
  judgmentsSummary: [...summary.judgments.categories].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      props: {currency},
      title: label,
    })
  ),
  activeSuits: [...activeSuits.categories].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      props: {currency},
      title: label,
    })
  ),
  activeJudgments: [...activeJudgments.categories].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      props: {currency},
      title: label,
    })
  ),
});

export default generateColumns;
