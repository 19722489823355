// ant components
import {List} from 'antd';

// local components
import Container from './Container';
import Date from './Date';
import Message from './Message';
import NotificationRow from './NotificationRow';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const AlertsColumns = ({company}) => (
  <Container>
    <List
      dataSource={company.notifications}
      locale={{emptyText: 'No notifications to display'}}
      size="small"
      renderItem={(notification) => (
        <List.Item key={notification.id}>
          <NotificationRow>
            <Message
              isRead={notification.read}
              dangerouslySetInnerHTML={{__html: notification.message}}
            />
            <Date>{notification.date}</Date>
          </NotificationRow>
        </List.Item>
      )}
    />
  </Container>
);

AlertsColumns.propTypes = {
  company: PropTypes.object,
};

export default AlertsColumns;
