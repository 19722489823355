// local columns
import countriesOfOperationColumn from '../columns/countriesOfOperation.column';
import numberOfLinkedSpecialInterestEntitiesColumn from '../columns/numberOfLinkedSpecialInterestEntities.column';
import numberOfPoliticallyExposedPersonsColumn from '../columns/numberOfPoliticallyExposedPersons.column';
import numberOfRelativesAndCloseAssociatesColumn from '../columns/numberOfRelativesAndCloseAssociates.column';
import numberOfSpecialInterestPersonsColumn from '../columns/numberOfSpecialInterestPersons.column';
import operatesInSanctionedCountryColumn from '../columns/operatesInSanctionedCountry.column';
import sanctionedCountryColumn from '../columns/sanctionedCountry.column';
import specialInterestEntityColumn from '../columns/specialInterestEntity.column';

// local lib
import generateColumnGroup from '../lib/generateColumnGroup.lib';

const sanctionsColumnGroup = generateColumnGroup({
  name: 'Sanctions',
  size: {x: 2400},
  columns: [
    countriesOfOperationColumn,
    operatesInSanctionedCountryColumn,
    sanctionedCountryColumn,
    specialInterestEntityColumn,
    numberOfLinkedSpecialInterestEntitiesColumn,
    numberOfPoliticallyExposedPersonsColumn,
    numberOfRelativesAndCloseAssociatesColumn,
    numberOfSpecialInterestPersonsColumn,
  ],
});

export default sanctionsColumnGroup;
