// charts
import Highcharts from 'highcharts';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// risk colors
import getScoreColor from '../../../risk/lib/getScoreColor.lib.risk';
import getSecondaryScoreColor from '../../../risk/lib/getSecondaryScoreColor.lib.risk';

// uuid
import {v4} from 'uuid';

const CyberSecurityComplianceChart = ({
  compliance = 0,
  completeness = 0,
  height = '337px',
}) => {
  const id = v4();

  const completenessPrimaryColor = getScoreColor(completeness);
  const completenessSecondaryColor = getSecondaryScoreColor(completeness);

  const compliancePrimaryColor = getScoreColor(compliance);
  const complianceSecondaryColor = getSecondaryScoreColor(compliance);

  useEffect(() => {
    Highcharts.chart(id, {
      title: {
        text: 'Compliance Rating',
      },
      chart: {
        type: 'solidgauge',
        height,
      },
      tooltip: {
        // enabled: false,
        valueSuffix: '%',
        valueDecimals: 0,
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        size: '90%',
        background: [
          {
            outerRadius: '100%',
            innerRadius: '80%',
            backgroundColor: complianceSecondaryColor,
            borderWidth: 0,
          },
          {
            outerRadius: '80%',
            innerRadius: '60%',
            backgroundColor: completenessSecondaryColor,
            borderWidth: 0,
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: true,
            borderWidth: 0,
            padding: 0,
            y: -24,
            style: {
              fontSize: '40px',
            },
            format: '{point.y:,.0f}%',
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },
      series: [
        {
          name: 'Compliance',
          data: [
            {
              color: compliancePrimaryColor,
              radius: '100%',
              innerRadius: '80%',
              y: compliance,
            },
          ],
        },
        {
          name: 'Completeness',
          data: [
            {
              color: completenessPrimaryColor,
              radius: '80%',
              innerRadius: '60%',
              y: completeness,
            },
          ],
          opacity: 0.35,
        },
      ],
    });
    // eslint-disable-next-line
  }, [compliance, completeness, height]);

  return <div id={id} />;
};

CyberSecurityComplianceChart.propTypes = {
  height: PropTypes.string,
  score: PropTypes.number,
  title: PropTypes.string,
};

export default CyberSecurityComplianceChart;
