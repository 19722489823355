import styled from 'styled-components';

// layout colors
import grey200Color from '../../../../layout/colors/grey200.color.layout';

// constants
const SIZE = '36px';

const Header = styled.div`
  width: 100%;
  height: ${SIZE};
  min-height: ${SIZE};
  max-height: ${SIZE};
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${grey200Color};
  font-size: 14px;
`;

export default Header;
