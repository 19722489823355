import styled from 'styled-components';

const SummaryRow = styled.div`
  width: 100%;
  height: ${({small}) => (small ? '60px' : '90px')};
  display: flex;
  align-items: stretch;
  padding: 0px 20px;
  gap: 4px;
`;

export default SummaryRow;
