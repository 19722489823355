// company components
import HighlightedScore from '../HighlightedScore/HighlightedScore';

// company filters
import operationalRiskLevelFilter from '../../filters/operationalRiskLevel.filter.company';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CompanyOperationalScoreColumn = ({company}) => (
  <HighlightedScore
    score={Number(company?.HaloScores?.Operational_Area?.Value)}
    filter={operationalRiskLevelFilter.filter}
  />
);

CompanyOperationalScoreColumn.propTypes = {
  company: PropTypes.object,
};

export default CompanyOperationalScoreColumn;
