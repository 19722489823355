const extractInnovationScore = ({company}) => ({
  categories: (company?.Innovation?.VersusIndustry?.period || []).filter(
    (data) => data !== null
  ),
  average: (company?.Innovation?.VersusIndustry?.IndustryAverage || []).filter(
    (data) => data !== null
  ),
  innovationScore: (
    company?.Innovation?.VersusIndustry?.InnovationScore || []
  ).filter((data) => data !== null),
});

export default extractInnovationScore;
