// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// number lib
import formattedInteger from '../../../../number/lib/formattedInteger.lib.number';

export default generateColumn({
  title: 'No. of Employees',
  componentProps: ({company}) => ({
    children: !!company?.Firmographic?.['No.ofEmployees']
      ? formattedInteger(company?.Firmographic?.['No.ofEmployees'])
      : '-',
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    return (
      Number(a?.Firmographic?.['No.ofEmployees'] || 0) -
      Number(b?.Firmographic?.['No.ofEmployees'] || 0)
    );
  },
});
