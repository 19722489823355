// date lib
import formatISODate from '../../date/formatters/formatISO.formatter.date';

const capitalStructureDataMap = (company) => {
  const localFinancialsType = [
    ...(company?.financials?.report?.localFinancialStatements || []),
  ]?.[0]?.type;

  const {categories, entries} = [
    ...(company?.financials?.report?.localFinancialStatements || []),
  ]
    .filter(({type}) => type === localFinancialsType)
    .reduce(
      (combined, current) => {
        const updatedCategories = [
          ...combined.categories,
          {
            key: current.yearEndDate,
            label: formatISODate({
              date: current.yearEndDate,
              dateFormat: 'MM/dd/yy',
            }),
          },
        ];

        const entries = Object.entries(current?.ratios || {}).reduce(
          (combinedEntries, [entryKey, entryValue]) => {
            if (
              ![
                'financialLeverage',
                'debtToCapitalAtBook',
                'payoutRatio',
                'retentionRatio',
                'zScore',
              ].includes(entryKey)
            )
              return combinedEntries;
            const entry = [...combined.entries].find(
              (existingEntry) => existingEntry.entry.value === entryKey
            ) || {
              entry: {
                value: entryKey,
                type: 'key',
              },
            };
            entry[current.yearEndDate] = {
              value: entryValue,
              type: 'ratio',
            };
            return [...combinedEntries, entry];
          },
          []
        );

        return {
          categories: updatedCategories,
          entries,
        };
      },
      {
        categories: [],
        entries: [],
      }
    );

  const updatedCategories = [
    {
      key: 'entry',
      label: '',
    },
    ...categories.reverse(),
  ];
  const updatedEntries = [...entries].filter((entry) =>
    Object.entries(entry).every(
      ([key, value]) => key === 'entry' || !!value?.value
    )
  );
  return {
    categories: updatedCategories,
    entries: updatedEntries,
  };
};

export default capitalStructureDataMap;
