// ant components
import {Tooltip} from 'antd';

// local components
import Container from './components/Container';
import Content from './components/Content';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const StatementBlock = ({children, title, tooltip, padding = false}) => (
  <Container>
    {!!title ? (
      !!tooltip ? (
        <Tooltip title={tooltip}>
          <Title>
            {title} <i className="mdi mdi-information" />
          </Title>
        </Tooltip>
      ) : (
        <Title>{title}</Title>
      )
    ) : null}
    {!!children && <Content padding={padding}>{children}</Content>}
  </Container>
);

StatementBlock.propTypes = {
  children: PropTypes.node,
  padding: PropTypes.bool,
  title: PropTypes.node,
  tooltip: PropTypes.node,
};

export default StatementBlock;
