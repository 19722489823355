// local components
import IFrame from './components/IFrame';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialsPdfSection = () => {
  const {company} = useSelector((state) => state.company);

  return <IFrame src={company.financialsPdf} />;
};

export default FinancialsPdfSection;
