const getExternalCss = () =>
  [].slice.call(document.styleSheets).reduce((prev, styleSheet) => {
    try {
      const styles = !!styleSheet.cssRules
        ? prev +
          [].slice
            .call(styleSheet.cssRules)
            .reduce((prev, cssRule) => `${prev}${cssRule.cssText}`, '')
        : prev;
      return styles;
    } catch (error) {
      return prev;
    }
  }, '');

export default getExternalCss;
