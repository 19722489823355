// table constants
import LTM_LABEL from '../constants/ltmLabel.constant.table';

const checkAndRemoveLTM = ({categories, entries, ...props}) => {
  const lastTwoCategories = [...categories].slice(-2);
  const lastCategory = lastTwoCategories[1];
  const penultimateCategory = lastTwoCategories[0];

  if (lastCategory?.key !== LTM_LABEL)
    return {
      categories,
      entries,
      ...props,
    };

  const shouldRemoveLTM = [...entries].every(
    (entry) =>
      (entry[penultimateCategory?.key] !== null &&
        entry[lastCategory?.key] === null) ||
      entry[lastCategory?.key] === entry[penultimateCategory?.key]
  );

  const updatedCategories = shouldRemoveLTM
    ? [...categories].filter((category) => category?.key !== LTM_LABEL)
    : categories;

  return {
    categories: updatedCategories,
    entries,
    ...props,
  };
};

export default checkAndRemoveLTM;
