// ant components
import {Modal} from 'antd';

// layout components
import Column from '../../../layout/components/Grid/Column/Column';
import Row from '../../../layout/components/Grid/Row/Row';

// local components
import ArrowRight from './components/ArrowRight';
import ArrowUp from './components/ArrowUp';
import Content from './components/Content';
import Highlight from './components/Highlight';
import LabelX from './components/LabelX';
import LabelY from './components/LabelY';
import Matrix from './components/Matrix';
import MatrixCompanyName from './components/MatrixCompanyName';
import MatrixQuadrant from './components/MatrixQuadrant';
import SectionTitle from './components/SectionTitle';
import SubTitle from './components/SubTitle';
import Table from './components/Table';
import TableCol from './components/TableCol';
import TableRow from './components/TableRow';
import Title from './components/Title';
import TitleContainer from './components/TitleContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const VSIModal = ({visible, company, onClose}) => (
  <Modal
    title={
      <TitleContainer>
        <Title>Vulnerable Software Identifier (VSI)</Title>
        <SubTitle color={company?.VSI?.styling?.color}>
          {company?.VSI?.vsiTag}
        </SubTitle>
      </TitleContainer>
    }
    centered
    open={visible}
    onOk={onClose}
    onCancel={onClose}
    width="75vw"
  >
    {!!company?.VSI && (
      <Content>
        <Row>
          <Column size={1 / 3}>
            <SectionTitle>Probability</SectionTitle>
            <Table>
              <TableRow>
                <TableCol>Probability</TableCol>
                <TableCol>
                  <Highlight color={company?.VSI?.styling?.color}>
                    {company?.VSI?.probability?.probability}
                  </Highlight>
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>Ransomware Susceptibility Index (RSI)</TableCol>
                <TableCol>
                  {company?.VSI?.probability?.ransomwareSusceptibilityIndex}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>Loss Event Frequency (LEF)</TableCol>
                <TableCol>
                  {company?.VSI?.probability?.lossEventFrequency}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>Average Vulnerability Severity</TableCol>
                <TableCol>
                  {company?.VSI?.probability?.averageVulnerabilitySeverity}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>The Number of Known Exploits</TableCol>
                <TableCol>
                  {company?.VSI?.probability?.numberOfKnownExploits}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>Known Exploits per Vulnerability</TableCol>
                <TableCol>
                  {company?.VSI?.probability?.knownExploitsPerVulnerability}
                </TableCol>
              </TableRow>
            </Table>
          </Column>
          <Column size={1 / 3}>
            <SectionTitle>Impact Matrix</SectionTitle>
            <Matrix>
              {[
                {
                  color: '#D14527',
                  label: 'High Impact - Low Probability (Black Swan)',
                },
                {
                  color: '#800F15',
                  label: 'High Impact - High Probability (Gray Rhino)',
                },
                {
                  color: '#73AE42',
                  label: 'Low Impact - Low Probability',
                },
                {
                  color: '#FBB415',
                  label: 'Low Impact - High Probability',
                },
              ].map(({color, label}) => (
                <MatrixQuadrant key={label} color={color}>
                  {label}
                  {label === company?.VSI?.vsiTag && (
                    <MatrixCompanyName>{company.CompanyName}</MatrixCompanyName>
                  )}
                </MatrixQuadrant>
              ))}
              <ArrowUp />
              <ArrowRight />
              <LabelX>Probability</LabelX>
              <LabelY>Impact</LabelY>
            </Matrix>
          </Column>
          <Column size={1 / 3}>
            <SectionTitle>Impact</SectionTitle>
            <Table>
              <TableRow>
                <TableCol>Impact Level</TableCol>
                <TableCol>
                  <Highlight color={company?.VSI?.styling?.color}>
                    {company?.VSI?.impact?.impactLevel}
                  </Highlight>
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>Loss Magnitude (LM)</TableCol>
                <TableCol>{company?.VSI?.impact?.lossMagnitude}</TableCol>
              </TableRow>
              <TableRow>
                <TableCol>Internal Access</TableCol>
                <TableCol>{company?.VSI?.impact?.internalAccess}</TableCol>
              </TableRow>
            </Table>
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

VSIModal.propTypes = {
  company: PropTypes.object,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default VSIModal;
