const extractPaydexScoreChartData = ({company}) => {
  const paydex = company.testFinancials.supplierViability.find(
    ({label}) => label === 'Paydex Score'
  );
  if (!paydex) return {categories: [], data: []};
  const months = company.testFinancials.supplierViability.find(
    ({label}) => label === 'Months'
  );
  const daysBeyondTerm = company.testFinancials.supplierViability.find(
    ({label}) => label === 'Days beyond term'
  );
  const dataKeys = [
    'unavailable',
    'over120',
    '120',
    '90',
    '60',
    '30',
    '22',
    '15',
    'prompt',
    'discount',
    'anticipate',
    'last',
  ];
  const categories = [...dataKeys].map(
    (dataKey) => `${months[dataKey]} ${daysBeyondTerm[dataKey]}`
  );
  return {
    categories,
    data: [
      {
        name: 'Paydex Score',
        data: [...dataKeys].map((dataKey) => paydex[dataKey]),
      },
    ],
  };
};

export default extractPaydexScoreChartData;
