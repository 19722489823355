// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// notification events
import generateNotificationsEvent from '../../events/generateNotifications.event.notification';

// notification redux reducer
import {setGeneralNotificationData as setGeneralNotificationDataAction} from '../../redux/reducer.redux.notification';

// notification services
import generateNotificationsFromAlertsService from '../../services/generateNotificationsFromAlerts.service.notification';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class NotificationGeneratorContainer extends Component {
  static propTypes = {
    alerts: PropTypes.array,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.generateNotifications();
    this.props.subscribe(
      generateNotificationsEvent.subscribe(this.generateNotifications)
    );
  }

  generateNotifications = async ({alerts = this.props.alerts} = {}) => {
    const {dispatch} = this.props;

    const notifications = await generateNotificationsFromAlertsService({
      alerts,
    });

    dispatch(setGeneralNotificationDataAction({notifications}));
  };

  render() {
    return null;
  }
}

export default connect((state) => ({alerts: state.alert.alerts}))(
  subscriptionHOC(NotificationGeneratorContainer)
);
