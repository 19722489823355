// charts
import Highcharts from 'highcharts';

// layout colors
import blackColor from '../../../layout/colors/black.color.layout';
import blueGrey300Color from '../../../layout/colors/blueGrey300.color.layout';
import green300Color from '../../../layout/colors/green300.color.layout';
import grey300Color from '../../../layout/colors/grey300.color.layout';
import grey600Color from '../../../layout/colors/grey600.color.layout';
import red300Color from '../../../layout/colors/red300.color.layout';
import violet300Color from '../../../layout/colors/violet300.color.layout';
import yellow300Color from '../../../layout/colors/yellow300.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const EmissionsChart = ({categories = [], data = []}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      title: {
        text: '',
        enabled: false,
      },

      colors: [
        blueGrey300Color,
        green300Color,
        violet300Color,
        yellow300Color,
        red300Color,
        grey300Color,
        grey600Color,
        blackColor,
      ],

      chart: {
        zoomType: 'xy',
      },

      xAxis: [
        {
          categories,
          crosshair: true,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value}',
          },
          title: {
            text: '',
          },
          opposite: true,
        },
        {
          // Secondary yAxis
          gridLineWidth: 0,
          title: {
            text: '',
          },
          labels: {
            // eslint-disable-next-line
            format: '{value}',
          },
        },
      ],
      tooltip: {
        shared: true,
      },
      series: data,
    });
    // eslint-disable-next-line
  }, [categories, data]);

  return <div id={id} />;
};

EmissionsChart.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.array,
  title: PropTypes.string,
};

export default EmissionsChart;
