// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// local columns
import creditOutlookColumn from './creditOutlook.column';
import spAnalystsRecommendationsColumn from './spAnalystsRecommendations.column';
import spConsensusScoresColumn from './spConsensusScores.column';
import spRatingColumn from './spRating.column';

export default generateColumn({
  title: 'S&P',
  children: [
    spRatingColumn,
    creditOutlookColumn,
    spAnalystsRecommendationsColumn,
    spConsensusScoresColumn,
  ],
});
