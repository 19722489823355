import styled from 'styled-components';

// colors
import blackColor from '../../../../layout/colors/black.color.layout';

const Title = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  margin: 9px 0px 24px 0px;
  color: ${blackColor};
`;

export default Title;
