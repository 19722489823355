import styled from 'styled-components';

const IFrame = styled.iframe`
  width: 100%;
  height: 70vh;
  border: none;
  display: block;
  padding: 0;
  margin: 0;
`;

export default IFrame;
