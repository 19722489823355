// local columns
import economicFactorRatingColumn from '../columns/economicFactorRating.column';
import geopoliticalFactorRatingColumn from '../columns/geopoliticalFactorRating.column';
import hqCountryColumn from '../columns/hqCountry.column';
import hqStateColumn from '../columns/hqState.column';
import resilienceFactorRatingColumn from '../columns/resilienceFactorRating.column';
import riskQualityFactorRatingColumn from '../columns/riskQualityFactorRating.column';
import supplyChainFactorRatingColumn from '../columns/supplyChainFactorRating.column';

// local lib
import generateColumnGroup from '../lib/generateColumnGroup.lib';

const geopoliticalEconomicsColumnGroup = generateColumnGroup({
  name: 'Geopolitical & Economics',
  columns: [
    geopoliticalFactorRatingColumn,
    resilienceFactorRatingColumn,
    hqStateColumn,
    hqCountryColumn,
    economicFactorRatingColumn,
    riskQualityFactorRatingColumn,
    supplyChainFactorRatingColumn,
  ],
});

export default geopoliticalEconomicsColumnGroup;
