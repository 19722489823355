import styled from 'styled-components';

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: ${({rtl}) => (rtl ? '0 0 0 10px' : '0 10px 0 0')};
`;

export default MessageBox;
