import styled, {css} from 'styled-components';

// colors
// import grey300Color from '../../../../layout/colors/grey300.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

const Container = styled.div`
  width: 100%;
  padding: ${({noPadding}) => (noPadding ? '0px' : '40px')};
  background: ${whiteColor};
  border-radius: 10px;
  ${'' /* border: 1px solid ${grey300Color}; */}
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  ${({noPrintBreak}) =>
    noPrintBreak
      ? css`
          break-inside: avoid;
        `
      : ''}
`;

export default Container;
