const extractFinancialSummaryData = ({company}) => {
  return {
    currency: company.financials.currency,
    riskScore: company.financials.riskScore,
    internationalScore: company.financials.internationalScore,
    creditLimit: company.financials.creditLimit,
    judgmentFilings: company.financials.judgmentFilings,
    judgmentSum: company.financials.judgmentSum,
    possibleOfac: company.financials.possibleOfac,
    dbt: company.financials.dbt,
    paymentExperiences: company.financials.paymentExperiences,
    paymentTrend: company.financials.paymentTrend,
    inquiriesTrend: company.financials.inquiriesTrend,
  };
};

export default extractFinancialSummaryData;
