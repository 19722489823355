// lib
import generateKeyFromLabel from '../../lib/generateKeyFromLabel.lib';

const generateColumn = ({
  Component,
  title,
  key,
  align = 'center',
  componentProps = () => ({}),
  ...props
}) => ({
  key: key || generateKeyFromLabel(title),
  title,
  align,
  ...props,
  render: !Component
    ? null
    : (_, company) => (
        <Component company={company} {...componentProps({company})} />
      ),
});

export default generateColumn;
