import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  color: ${({color}) => color};
  font-size: 14px;
  font-weight: bold;
`;

export default Container;
