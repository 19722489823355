import styled from 'styled-components';

// layout colors
import whiteColor from '../../../../layout/colors/white.color.layout';

const Results = styled.div`
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0px;
  background: ${whiteColor};
  border-radius: 4px;
  box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.1);
`;

export default Results;
