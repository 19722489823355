import styled from 'styled-components';

// layout colors
import blueGrey500Color from '../../../../layout/colors/blueGrey500.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

const Matrix = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 250px;
  position: relative;
  padding: 0px 0px 20px 20px;
  background: ${blueGrey500Color};
  color: ${whiteColor};
`;

export default Matrix;
