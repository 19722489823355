// data components
import TypeDisplay from '../../../data/components/TypeDisplay/TypeDisplay';

// object lib
import isObject from 'is-object';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const DynamicColumn = ({
  category,
  entry,
  missingValueReplacement = '',
  ...props
}) => {
  const entryValue = entry?.[category];
  const {value, type, ...attributes} =
    isObject(entryValue) &&
    ['value', 'type'].every((attribute) =>
      Object.keys(entryValue || {}).includes(attribute)
    )
      ? entry?.[category]
      : {value: entryValue, type: 'any'};
  return [null, undefined].includes(value) ? (
    missingValueReplacement
  ) : (
    <TypeDisplay value={value} type={type} {...props} {...attributes} />
  );
};

DynamicColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entry: PropTypes.object,
  missingValueReplacement: PropTypes.node,
};

export default DynamicColumn;
