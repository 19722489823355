// charts
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
import DrillDown from 'highcharts/modules/drilldown';
import ExportData from 'highcharts/modules/export-data';
import Exporting from 'highcharts/modules/exporting';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import Heatmap from 'highcharts/modules/heatmap';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsCustomEvents from 'highcharts-custom-events';
import Map from 'highcharts/modules/map';
import Networkgraph from 'highcharts/modules/networkgraph';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Treegraph from 'highcharts/modules/treegraph';
import Treemap from 'highcharts/modules/treemap';

// layout colors
import blackColor from '../../layout/colors/black.color.layout';
import grey300Color from '../../layout/colors/grey300.color.layout';
import whiteColor from '../../layout/colors/white.color.layout';

// Enable the highcharts accessibility module
require('highcharts/modules/accessibility')(Highcharts);

HighchartsCustomEvents(Highcharts);
Highcharts3D(Highcharts);
ChartModuleMore(Highcharts);
Networkgraph(Highcharts);
HCSoldGauge(Highcharts);
DrillDown(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);
Treemap(Highcharts);
Heatmap(Highcharts);
Treegraph(Highcharts);
Map(Highcharts);

Highcharts.Point.prototype.highlight = function (event) {
  event = this.series.chart.pointer.normalize(event);
  this.onMouseOver(); // Show the hover marker
  this.series.chart.tooltip.refresh(this); // Show the tooltip
  this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
};

Highcharts.setOptions({
  credits: {
    enabled: false,
  },
  chart: {
    backgroundColor: whiteColor,
    style: {
      fontFamily: 'Open Sans',
      borderRadius: '10px',
    },
  },
  title: {
    align: 'left',
    style: {
      fontSize: '16px',
      fontWeight: 700,
      color: blackColor,
    },
    x: 0,
    y: 16,
    text: '',
  },
  subtitle: {
    align: 'left',
    x: 30,
    text: '',
  },
  xAxis: {
    title: {
      style: {
        fontSize: '16px',
        fontWeight: 400,
        color: blackColor,
      },
    },
  },
  yAxis: {
    title: {
      style: {
        fontSize: '16px',
        fontWeight: 400,
        color: blackColor,
      },
    },
  },
  tooltip: {
    style: {
      zIndex: 600,
    },
    shadow: false,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: grey300Color,
  },
});
