// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const SourceColumn = ({
  category,
  columnIndex,
  entry,
  entryIndex,
  missingValueReplacement = '',
}) => {
  const value = entry[category];

  return [null, undefined].includes(value) ? (
    missingValueReplacement
  ) : (
    <a href={value} target="_blank" rel="noreferrer">
      {value}
    </a>
  );
};

SourceColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
  missingValueReplacement: PropTypes.node,
};

export default SourceColumn;
