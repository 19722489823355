// local components
import Page from './components/Page';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const GlobalResilienceIndexLayout = ({children}) => <Page>{children}</Page>;

GlobalResilienceIndexLayout.propTypes = {
  children: PropTypes.node,
};

export default GlobalResilienceIndexLayout;
