// redux store
import reduxStore from '../../redux/store';

// collection lib
import getPrimaryCollection from './getPrimaryCollection.lib.collection';

const getAllCollectionSuppliers = ({
  collections = reduxStore.getState()?.collection?.collections || [],
} = {}) => {
  const collection = getPrimaryCollection(collections);
  const suppliers =
    reduxStore.getState()?.collection?.collectionsData?.[
      collection?.id || 'portfolio'
    ]?.suppliers || [];
  return [...suppliers].map(({id}) => id.replace('company_', ''));
};

export default getAllCollectionSuppliers;
