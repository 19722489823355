// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';
import blueGrey300Color from '../../../../layout/colors/blueGrey300.color.layout';
import green300Color from '../../../../layout/colors/green300.color.layout';
import green400Color from '../../../../layout/colors/green400.color.layout';
import red300Color from '../../../../layout/colors/red300.color.layout';
import yellow300Color from '../../../../layout/colors/yellow300.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// styled
import styled from 'styled-components';

// helper ui
const Container = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${({color}) => color};
`;

// helper constants
const colors = {
  1: red300Color,
  2: yellow300Color,
  3: blueGrey300Color,
  4: green300Color,
  5: green400Color,
  default: blackColor,
};

const HighlightedColumn = ({category, columnIndex, entry, entryIndex}) => {
  const value = entry[category];
  const color = colors[entry.scoreGrade] || colors.default;
  return (
    <Container color={color}>
      {[null, undefined, ''].includes(value) ? '' : value}
    </Container>
  );
};

HighlightedColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default HighlightedColumn;
