// esg lib
import getGeneralPercentileValue from '../../../lib/getGeneralPercentileValue.lib.esg';
import getSubPercentileValue from '../../../lib/getSubPercentileValue.lib.esg';

// number lib
import integer from '../../../../number/lib/integer.lib.number';

const formatData = ({
  data,
  name,
  categories,
  percentileData = [],
  percentileView = false,
  subValue = null,
}) => ({
  name,
  data: [
    {
      name: 'Overall',
      drilldown: name,
      y: integer(
        percentileView
          ? !!subValue
            ? getSubPercentileValue({
                ratings: percentileData.overall,
                attribute: subValue,
                attributeValue: name,
              })
            : getGeneralPercentileValue(percentileData.overall)
          : data.ratings.overall
      ),
    },
    ...[...categories]
      .filter((category) => category !== 'Overall')
      .map((category) => {
        const value = integer(
          percentileView
            ? !!subValue
              ? getSubPercentileValue({
                  ratings: percentileData.categories[category],
                  attribute: subValue,
                  attributeValue: name,
                })
              : getGeneralPercentileValue(percentileData.categories[category])
            : data.ratings.categories[category]
        );
        return {
          name: category,
          drilldown: name,
          y: value,
        };
      }),
  ],
  drillDown: {
    name,
    id: name,
    data: Object.entries(data.ratings.subcategories).map(([key, value]) => ({
      name: key,
      y: integer(
        percentileView
          ? !!subValue
            ? getSubPercentileValue({
                ratings: percentileData.subcategories[key],
                attribute: subValue,
                attributeValue: name,
              })
            : getGeneralPercentileValue(percentileData.subcategories[key])
          : value
      ),
    })),
  },
});

const extractData = ({company, percentileView}) => {
  const categories = [
    'Overall',
    'Community',
    'Employees',
    'Environment',
    'Governance',
  ];

  const industries = [...company.esgInfo.industriesData].map((industry) =>
    formatData({
      name: industry.name,
      data: industry,
      categories,
      percentileView,
      percentileData: company.esgInfo.percentileRatings,
      subValue: 'industry',
    })
  );

  const dataWithDrillDown = [
    formatData({
      name: company.CompanyName,
      data: company.esgInfo,
      categories,
      percentileView,
      percentileData: company.esgInfo.percentileRatings,
    }),
    ...industries,
    formatData({
      name: company.esgInfo.countryData.name,
      data: company.esgInfo.countryData,
      categories,
      percentileView,
      percentileData: company.esgInfo.percentileRatings,
      subValue: 'country',
    }),
    !percentileView &&
      formatData({
        name: 'Average',
        data: company.esgInfo.averageData,
        categories,
        percentileView,
        percentileData: company.esgInfo.percentileRatings,
      }),
  ].filter((dataRow) => !!dataRow);

  const {data, drillDown} = [...dataWithDrillDown].reduce(
    (combined, {drillDown: drillDownPoint, ...dataPoint}) => ({
      data: [...combined.data, dataPoint],
      drillDown: [...combined.drillDown, drillDownPoint],
    }),
    {data: [], drillDown: []}
  );

  return {data, drillDown};
};

export default extractData;
