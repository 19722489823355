// ant components
import {Col as Column, Row, Space} from 'antd';

// chart components
import KeyFinancialsMultiChartCard from '../../../chart/components/KeyFinancialsMultiChartCard/KeyFinancialsMultiChartCard';
import KeyFinancialsRevenueChartCard from '../../../chart/components/KeyFinancialsRevenueChartCard/KeyFinancialsRevenueChartCard';
import PrivateCapitalEfficiencyChartCard from '../../../chart/components/PrivateCapitalEfficiencyChartCard/PrivateCapitalEfficiencyChartCard';
import PrivateRevenueChartCard from '../../../chart/components/PrivateRevenueChartCard/PrivateRevenueChartCard';

// local lib
import extractFinancialData from './lib/extractFinancialData.lib';
import extractMultiFinancialData from './lib/extractMultiFinancialData.lib';
import extractPrivateCapitalEfficiencyData from './lib/extractPrivateCapitalEfficiencyData.lib';
import extractPrivateRevenueData from './lib/extractPrivateRevenueData.lib';
import renderFinancialsChart from './lib/renderFinancialsChart.lib';
import renderRevenueChart from './lib/renderRevenueChart.lib';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const CompanyKeyFinancialsSection = () => {
  const {company} = useSelector((state) => state.company);

  const showRevenueChart = renderRevenueChart({company});
  const showPrivateFinancials = !!company.PrivateFinancialData;

  return (
    <Space direction="vertical" style={{width: '100%'}} size={20}>
      {showRevenueChart && (
        <KeyFinancialsRevenueChartCard {...extractFinancialData({company})} />
      )}
      {renderFinancialsChart({company}) && (
        <KeyFinancialsMultiChartCard
          {...extractMultiFinancialData({company})}
        />
      )}
      {showPrivateFinancials && (
        <Row gutter={20}>
          <Column span={12}>
            <PrivateCapitalEfficiencyChartCard
              data={extractPrivateCapitalEfficiencyData({company})}
            />
          </Column>
          <Column span={12}>
            <PrivateRevenueChartCard
              data={extractPrivateRevenueData({company})}
            />
          </Column>
        </Row>
      )}
    </Space>
  );
};

export default CompanyKeyFinancialsSection;
