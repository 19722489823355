// local components
import Column from '../components/Column';

const generateColumn = ({category, columnIndex}) => {
  return {
    title: !columnIndex ? '' : category,
    key: category,
    dataIndex: category,
    align: !columnIndex ? 'left' : 'center',
    render: (_, entry, entryIndex) => (
      <Column
        category={category}
        columnIndex={columnIndex}
        entry={entry}
        entryIndex={entryIndex}
      />
    ),
  };
};

export default generateColumn;
