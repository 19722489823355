// collection api
import deleteCollectionApi from '../api/delete.api.collection';

// collection events
import collectionDeletedEvent from '../events/deleted.event.collection';

const deleteCollectionService = async ({
  deleteCollection = deleteCollectionApi,
  collection,
  collectionDeleted = collectionDeletedEvent.publish,
}) => {
  await deleteCollection(collection.id);
  collectionDeleted(collection);
  return collection;
};

export default deleteCollectionService;
