const extractCyberIndustryAverageData = ({company}) => {
  const industryAverage =
    company?.cyberSecurityIndustryAverage?.IndustryAverage || [];
  const data = {
    categories: [...industryAverage].map(({date}) => date),
    data: [...industryAverage]
      .map(({score}) => score)
      .filter((score) => score !== null),
    range: [...industryAverage]
      .map(({low, high}) => [low, high])
      .filter(([low, high]) => low !== null && high !== null),
  };
  return !!data.data.length || !!data.range.length ? data : null;
};

export default extractCyberIndustryAverageData;
