// local columns
import customerSentimentFactorRatingColumn from '../columns/customerSentimentFactorRating.column';
import employeeSentimentFactorRatingColumn from '../columns/employeeSentimentFactorRating.column';
// import mediaSentimentFactorRatingColumn from '../columns/mediaSentimentFactorRating.column';
import socialMediaColumn from '../columns/socialMedia.column';

// local lib
import generateColumnGroup from '../lib/generateColumnGroup.lib';

const socialMediaColumnGroup = generateColumnGroup({
  name: 'Social Media',
  columns: [
    // mediaSentimentFactorRatingColumn,
    customerSentimentFactorRatingColumn,
    employeeSentimentFactorRatingColumn,
    socialMediaColumn,
  ],
});

export default socialMediaColumnGroup;
