// country components
import Flag from '../Flag/Flag';

// local components
import Container from './components/Container';
import More from './components/More';

// local constants
import NUMBER_OF_CONDENSED_COUNTRIES from './constants/numberOfCondensedCountries.constant';

// local lib
import parseCountries from './lib/parseCountries.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useState} from 'react';

const Flags = ({
  condense = false,
  countries,
  delimiter = '|',
  emptyLabel = '-',
  fullName,
}) => {
  const [isCondensed, setIsCondensed] = useState(true);
  if (!countries) return emptyLabel;
  const countriesList = parseCountries({countries, fullName, delimiter});
  const visibleCountries =
    condense && isCondensed
      ? [...countriesList].slice(0, NUMBER_OF_CONDENSED_COUNTRIES)
      : countriesList;
  return (
    <Container>
      {visibleCountries.map(({key, country, isoCountry}) => (
        <Flag key={key} isoCountry={isoCountry} country={country} />
      ))}
      {condense && countriesList.length >= NUMBER_OF_CONDENSED_COUNTRIES && (
        <More onClick={() => setIsCondensed(!isCondensed)}>
          {isCondensed ? 'Show more' : 'Show less'}
        </More>
      )}
    </Container>
  );
};

Flags.propTypes = {
  condense: PropTypes.bool,
  countries: PropTypes.string,
  delimiter: PropTypes.string,
  emptyLabel: PropTypes.node,
  fullName: PropTypes.bool,
};

export default Flags;
