const icons = [
  {type: 'Business', icon: 'domain'},
  {type: 'Organization', icon: 'domain'},
  {type: 'Human', icon: 'account'},
];

const getIcon = ({types = []}) =>
  icons.find(({type}) => (types || []).includes(type))?.icon;

export default getIcon;
