// date lib
import {parseISO, format} from 'date-fns';

// table components
import DynamicColumn from '../../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const generateColumns = ({dates}) => [
  generateGeneralColumn({
    category: 'label',
    title: '',
  }),
  ...[...dates].map((date) =>
    generateGeneralColumn({
      category: date,
      Component: DynamicColumn,
      title: format(parseISO(date), 'MMM yy'),
    })
  ),
];

export default generateColumns;
