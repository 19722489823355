// ant
import {ConfigProvider} from 'antd';

// layout colors
import green400Color from '../../colors/green400.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Theme = ({children}) => (
  <ConfigProvider theme={{token: {colorPrimary: green400Color}}}>
    {children}
  </ConfigProvider>
);

Theme.propTypes = {
  children: PropTypes.node,
};

export default Theme;
