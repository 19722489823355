import styled from 'styled-components';

// layout colors
import whiteColor from '../../../colors/white.color.layout';

const Close = styled.div`
  padding: 10px 20px;
  border-radius: 10px;
  color: ${whiteColor};
  border: 2px solid ${whiteColor};
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
`;

export default Close;
