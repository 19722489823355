// table components
import DynamicColumn from '../../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const generateColumns = ({activities, currency, companyInformation}) => ({
  companyInformation: [...companyInformation.categories].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      props: {currency},
      title: label,
    })
  ),
  activities: [...activities.categories].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      props: {currency},
      title: label,
    })
  ),
});

export default generateColumns;
