// number lib
import twoDecimals from './twoDecimals.lib.number';

const millionToBillion = ({number, prefix = '$', withAffix = true}) => {
  const numberWithTwoDecimals = twoDecimals(
    number >= 1000 ? number / 1000 : number
  );
  const formattedNumber = `${numberWithTwoDecimals}`.endsWith('.00')
    ? numberWithTwoDecimals.replace('.00', '')
    : numberWithTwoDecimals;
  const affix = withAffix ? (number >= 1000 ? 'B' : 'M') : '';
  return `${prefix}${formattedNumber}${affix}`;
};

export default millionToBillion;
