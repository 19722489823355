// ant components
import {
  // DatePicker,
  Button,
  Input,
  Modal,
  Select,
  Space,
} from 'antd';

// local components
import Box from './components/Box';
import Label from './components/Label';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// supplier services
import getSupplierOptionsService from '../../../supplier/services/getSuppliersOptions.service.supplier';

const TagEditorModal = ({
  companies,
  date,
  description,
  loading,
  name,
  onChange,
  onChangeSelection,
  onClose,
  onDelete,
  onSave,
  tag,
  title,
  visible,
}) => (
  <Modal
    title={title}
    centered
    open={visible}
    onOk={onSave}
    onCancel={onClose}
    okText="Save"
    width={'800px'}
  >
    <Space direction="vertical" style={{width: '100%'}} size={[16, 16]}>
      <Box>
        <Label>Name</Label>
        <Input value={name} onChange={onChange('name')} disabled={loading} />
      </Box>
      {/* <Box>
        <Label>Tag</Label>
        <Input value={tag} onChange={onChange('tag')} disabled={loading} />
      </Box>
      <Box>
        <Label>Date</Label>
        <DatePicker
          value={date}
          onChange={onChange('date')}
          disabled={loading}
          format="YYYY/MM/DD"
        />
      </Box> */}
      <Box>
        <Label>Description</Label>
        <Input.TextArea
          value={description}
          onChange={onChange('description')}
          disabled={loading}
          rows={6}
        />
      </Box>
      <Box>
        <Label>Companies</Label>
        <Select
          mode="multiple"
          value={companies}
          onSelect={onChangeSelection('companies')}
          onDeselect={onChangeSelection('companies')}
          showSearch
          style={{width: '100%'}}
          placeholder="Select companies"
          options={getSupplierOptionsService({excludeCompanies: companies})}
          disabled={loading}
          optionFilterProp="label"
        />
      </Box>
      {!!onDelete && (
        <Box>
          <Button onClick={onDelete} disabled={loading} danger>
            Delete Tag
          </Button>
        </Box>
      )}
    </Space>
  </Modal>
);

TagEditorModal.propTypes = {
  companies: PropTypes.array,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onChangeSelection: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  supplier: PropTypes.object,
  tag: PropTypes.string,
  title: PropTypes.string,
  visible: PropTypes.bool,
};

export default TagEditorModal;
