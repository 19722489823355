// antd
import {Table} from 'antd';

// layout components
import Card from '../../../layout/components/Card/Card';
// import TableScroll from '../../../layout/components/TableScroll/TableScroll';

// layout constants
import tableSectionHeader from '../../../layout/components/GlobalStyle/constants/tableSectionHeader.constant';
import tableSectionHighlight from '../../../layout/components/GlobalStyle/constants/tableSectionHighlight.constant';

// local components
import Container from './components/Container';

// local constants
import HEADER_ROWS from './constants/headerRows.constant';
import HIGHLIGHTED_ROWS from './constants/highlightedRows.constant';

// local lib
import extractBalanceSheet from './lib/extractBalanceSheet.lib';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialsBalanceSheetSection = () => {
  const {company} = useSelector((state) => state.company);

  const parsedData = extractBalanceSheet({company});

  return (
    <Card noPadding>
      <Container className="smallPrint">
        <Table
          columns={parsedData.categories}
          dataSource={parsedData.entries}
          pagination={false}
          rowKey="section"
          rowClassName={(record) =>
            HEADER_ROWS.includes(record.key)
              ? tableSectionHeader
              : HIGHLIGHTED_ROWS.includes(record.key)
              ? tableSectionHighlight
              : ''
          }
          size="small"
          locale={{emptyText: 'Balance sheet not available'}}
        />
      </Container>
    </Card>
  );
};

export default FinancialsBalanceSheetSection;
