const getGroupedEntities = ({newsArticle}) => {
  return ['Organization', 'People']
    .map((type) => ({
      type,
      entities: [...(newsArticle?.entities || [])]
        .filter((entity) => entity.types.includes(type))
        .sort((a, b) => b.overall_prominence - a.overall_prominence)
        .map((entity) => ({
          id: entity.id,
          types: entity.types,
          label:
            entity?.title?.surface_forms?.[0]?.text ||
            entity?.body?.surface_forms?.[0]?.text ||
            null,
        }))
        .filter(({label}) => !!label)
        .slice(0, 5),
    }))
    .filter(({entities}) => !!entities.length);
};

export default getGroupedEntities;
