const extractTableData = ({company}) =>
  !!company?.extendedPrivateFinancials
    ? [
        {
          parentCompany:
            company?.publicParentCompany?.parentCompanyName || null,
          yearFounded: company?.extendedPrivateFinancials?.['Year Founded'],
          operatingStatus:
            company?.extendedPrivateFinancials?.['Operating Status'],
          numberOfEmployees:
            company?.extendedPrivateFinancials?.['Number of Employees'],
          employeeGrowth:
            company?.extendedPrivateFinancials?.['Employee Growth'],
          ownershipStatus:
            company?.extendedPrivateFinancials?.['Ownership Status'],
          totalEquityFundingAmount:
            company?.extendedPrivateFinancials?.['Total Equity Funding Amount'],
          businessStatus:
            company?.extendedPrivateFinancials?.['Business Status'],
          numberOfFundingRounds:
            company?.extendedPrivateFinancials?.['Number of Funding Rounds'],
          fundingStatus: company?.extendedPrivateFinancials?.['Funding Status'],
          lastFundingAmount:
            company?.extendedPrivateFinancials?.[
              'Last Funding Amount (in millions USD)'
            ],
          lastFundingType:
            company?.extendedPrivateFinancials?.['Last Funding Type'],
          activeInvestors:
            company?.extendedPrivateFinancials?.['Active Investors'],
          estimatedRevenueRange:
            company?.extendedPrivateFinancials?.['Estimated Revenue Range'],
          revenue: company?.extendedPrivateFinancials?.Revenue,
          revenueGrowthPercentage:
            company?.extendedPrivateFinancials?.['Revenue Growth Percentage'],
          growthRatePercentile:
            company?.extendedPrivateFinancials?.['Growth Rate Percentile'],
          lastKnownValuation:
            company?.extendedPrivateFinancials?.['Last Known Valuation'],
          lastKnownValuationDate:
            company?.extendedPrivateFinancials?.['Last Known Valuation Date'],
          predicatedExitClass:
            company?.extendedPrivateFinancials?.['Predicated Exit Class'],
          totalPatentDocuments:
            company?.extendedPrivateFinancials?.['Total Patent Documents'],
          creditRating: company?.extendedPrivateFinancials?.['Credit Rating'],
          creditScore: company?.extendedPrivateFinancials?.['Credit Score'],
          rank: company?.extendedPrivateFinancials?.['Rank (Organization)'],
          trend7: company?.extendedPrivateFinancials?.['Trend Score (7 Days)'],
          trend30:
            company?.extendedPrivateFinancials?.['Trend Score (30 Days)'],
          trend90:
            company?.extendedPrivateFinancials?.['Trend Score (90 Days)'],
          similarCompanies:
            company?.extendedPrivateFinancials?.['Similar Companies'],
          activeTechCount:
            company?.extendedPrivateFinancials?.['Active Tech Count'],
          sbe: company?.extendedPrivateFinancials?.[
            'Small Business Enterprises (SBEs)'
          ],
          mbe: company?.extendedPrivateFinancials?.[
            'Minority-owned Enterprises (MBEs)'
          ],
          wbe: company?.extendedPrivateFinancials?.[
            'Women-owned Business Enterprises (WBEs)'
          ],
        },
      ]
    : [];

export default extractTableData;
