// ant
import {Tooltip} from 'antd';

// company components
import HighlightedScore from '../../../components/HighlightedScore/HighlightedScore';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// local tooltips
import nist80053Tooltip from './tooltips/nist80053.tooltip.company';

export default generateColumn({
  key: 'nist80053r5',
  title: <Tooltip title={nist80053Tooltip}>Nist 800-53 R5</Tooltip>,
  componentProps: ({company}) => ({
    score: Number(company?.Compliance?.['NIST800-53R5Rating']),
    suffix: '%',
  }),
  Component: HighlightedScore,
});
