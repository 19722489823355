const convertToMapData = (globalResilienceIndexes) =>
  [...globalResilienceIndexes].map((globalResilienceIndex) => ({
    value: globalResilienceIndex['Country Score'],
    name: globalResilienceIndex.Country,
    extraData: {
      ...globalResilienceIndex,
    },
  }));

export default convertToMapData;
