// local lib
import generateColumn from './generateColumn.lib';

const getValuationColumns = () => [
  generateColumn({
    category: 'lastKnownValuation',
    title: 'Last Known Valuation (in millions USD)',
  }),
  generateColumn({
    category: 'lastKnownValuationDate',
    title: 'Last Known Valuation Date',
  }),
  generateColumn({
    category: 'predicatedExitClass',
    title: 'Predicted Exit Class',
  }),
];

export default getValuationColumns;
