// layout components
import {Column, Row} from '../../../layout/components/Grid/Grid';

// local lib
import extractCorporateHierarchy from './lib/extractCorporateHierarchy.lib';
import renderCorporateHierarchySection from './lib/renderCorporateHierarchySection.lib';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

// supplier components
import HorizontalCorporateHierarchyChartCard from '../HorizontalCorporateHierarchyChartCard/HorizontalCorporateHierarchyChartCard';

const HorizontalCorporateHierarchySection = () => {
  const {company} = useSelector((state) => state.company);

  return (
    renderCorporateHierarchySection({company}) && (
      <Row margin>
        <Column margin>
          <HorizontalCorporateHierarchyChartCard
            {...extractCorporateHierarchy({company})}
          />
        </Column>
        <Column margin />
      </Row>
    )
  );
};

export default HorizontalCorporateHierarchySection;
