// financial lib
import getFiscalYear from '../../../../financial/lib/getFiscalYear.lib.financial';

// lib
import isRisingOrFalling from '../../../../chart/lib/isRisingOrFalling.lib.chart';

// local constants
import DIRECTIONS_COLUMN from '../constants/directionsColumn.constant';

// local lib
import generateColumn from './generateColumn.lib';

// table lib
import removeEmptyTableColumns from '../../../../table/lib/removeEmptyColumns.lib.table';

const extractBalanceSheet = ({company}) => {
  if (!company?.historicalFinancials?.liquidityModule)
    return {categories: [], entries: []};
  const categories = [
    'section',
    ...company.historicalFinancials.liquidityModule.periods,
    DIRECTIONS_COLUMN,
  ];

  const rawEntries = [
    ...Object.entries(company.historicalFinancials.liquidityModule.data),
  ].map(([label, values]) => ({
    key: label,
    section: label,
    ...[...values].reduce((combined, current, index) => {
      const category = categories?.[index + 1];
      return !!category ? {...combined, [category]: current} : combined;
    }, {}),
  }));

  const lastTwoCategories = [...categories].slice(-3);
  const lastCategory = lastTwoCategories[1];
  const penultimateCategory = lastTwoCategories[0];

  const entries = [...rawEntries].map((entry) => ({
    ...entry,
    [DIRECTIONS_COLUMN]: isRisingOrFalling({
      lastValue: entry[lastCategory],
      penultimateValue: entry[penultimateCategory],
    }),
    lastCategory,
    penultimateCategory,
    lastValue: entry[lastCategory],
    penultimateValue: entry[penultimateCategory],
  }));

  return removeEmptyTableColumns({
    categories: [...categories].map((category, index) =>
      generateColumn({
        category,
        columnIndex: index,
        fiscalYear: getFiscalYear({
          years: company?.historicalFinancials?.fiscalYears?.fiscalYear,
          category,
        }),
      })
    ),
    entries,
  });
};

export default extractBalanceSheet;
