import styled from 'styled-components';

// layout components
import ActualLogo from '../../../../layout/components/Logo/Logo';

const Logo = styled(ActualLogo)`
  height: 30px;
  display: block;
  cursor: pointer;
`;

export default Logo;
