// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Column = ({dataKey, entry, entryIndex}) => {
  const value = entry[dataKey];
  return dataKey === 'URL' ? (
    <a href={value} target="_blank" rel="noreferrer">
      {value}
    </a>
  ) : (
    <div>{value || '-'}</div>
  );
};

Column.propTypes = {
  dataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default Column;
