class Company {
  constructor(companyId, user, arrangements, securityReport) {
    this.id = companyId;
    this.user = user;
    this.arrangements = arrangements || {};
    this.securityReport = securityReport;
  }

  static initCompany(companyId, user, arrangements, securityReport) {
    const company = new Company(companyId, user, arrangements, securityReport);
    user.companies[companyId] = company;
    return company;
  }

  static getCompany(companyId, user) {
    return user.companies[companyId] || Company.initCompany(companyId, user);
  }

  save() {
    this.user.save();
  }

  reset() {
    delete this.user.companies[this.id];
    this.user.save();
  }

  getNewArrangementName() {
    let nextCount = 0;
    const arrangementIds = this.getActiveArrangementIds();
    arrangementIds.forEach((arrangementId) => {
      const existingName = this.arrangements[arrangementId].name;
      const currentCount = parseInt(existingName.split('New Arrangement #')[1]);
      if (existingName === 'New Arrangement' && !nextCount) nextCount = 2;
      else if (currentCount && currentCount >= nextCount)
        nextCount = currentCount + 1;
    });
    const name = `New Arrangement${nextCount ? ` #${nextCount}` : ''}`;
    return name;
  }

  getDraftArrangement() {
    const draftArrangementId = Object.keys(this.arrangements).find(
      (arrangementId) => this.arrangements[arrangementId].isDraft()
    );
    return this.arrangements[draftArrangementId];
  }

  getActiveArrangementIds() {
    const arrangementIds = Object.keys(this.arrangements).filter(
      (arrangementId) =>
        !this.arrangements[arrangementId].closed &&
        !this.arrangements[arrangementId].isDraft()
    );
    return arrangementIds.sort(
      (x, y) =>
        this.arrangements[y].created.getTime() -
        this.arrangements[x].created.getTime()
    );
  }

  isOnboarded() {
    const arrangementIds = Object.keys(this.arrangements);
    const firstArrangement = this.arrangements[arrangementIds[0]];
    return (
      arrangementIds.length > 1 ||
      (firstArrangement && !firstArrangement.isDraft())
    );
  }

  isActive() {
    return this.getActiveArrangementIds().length > 0;
  }
}

export default Company;
