// auth components
import SignInLayout from '../../components/SignInLayout/SignInLayout';

// auth redux reducer
import {
  loadTenantConfig as loadTenantConfigAction,
  unloadTenantConfig as unloadTenantConfigAction,
} from '../../../auth/redux/reducer.redux.auth';

// auth services
import loadTenantConfigService from '../../../auth/services/loadTenantConfig.service.auth';

// aws components
import {
  Authenticator,
  useAuthenticator,
  Button,
  TextField,
} from '@aws-amplify/ui-react';

// react
import React, {useRef, useEffect, useState} from 'react';

// redux
import {useSelector, useDispatch} from 'react-redux';

// validator
import validateEmail from 'validator/lib/isEmail';

const SignInContainer = () => {
  const dispatch = useDispatch();
  const {tenant} = useSelector((state) => state.auth);
  const tenantEmailInputRef = useRef(null);
  const [tenantEmailInputCursorPosition, setTenantEmailInputCursorPosition] =
    useState(0);
  const [emailText, setEmailText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isReady, setReady] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const {route} = useAuthenticator((context) => [context.route]);
  const isSignIn = route === 'signIn';
  const isClearEmailInput = !isSignIn && emailText;
  if (isClearEmailInput) {
    setEmailText('');
  }

  const components = {
    SignIn: {
      Header() {
        const hasError = !!errorMessage;
        return (
          <div style={{padding: '30px 30px 0px 30px'}}>
            <TextField
              id="fte"
              className="custom-halo-ui"
              label="Email"
              name="email"
              placeholder="Enter your Email"
              ref={tenantEmailInputRef}
              hasError={hasError}
              isRequired={true}
              value={emailText}
              errorMessage={errorMessage}
              onChange={handleInputChange}
              onKeyUp={(event) =>
                event.key === 'Enter' && confirmFindTenant(event)
              }
            />
          </div>
        );
      },
      Footer() {
        return (
          <div style={{padding: '0px 30px 30px 30px', marginTop: '-48px'}}>
            <Button
              id="fte_button"
              variation="primary"
              type="submit"
              loadingText="Continue"
              onClick={confirmFindTenant}
              isLoading={isLoading}
              style={{width: '100%'}}
            >
              Continue
            </Button>
          </div>
        );
      },
    }
  };

  const confirmFindTenant = async (event) => {
    event.preventDefault();
    const emailElementId = 'fte';
    const email = document.getElementById(emailElementId).value;
    const isEmail = !!email;
    const isValidEmail = validateEmail(email);
    const preErrorMessage = !isEmail
      ? 'Please fill in your email.'
      : !isValidEmail
      ? 'Sorry, that email is invalid.'
      : '';
    if (preErrorMessage) {
      setErrorMessage(preErrorMessage);
      focusInputById(emailElementId);
      return;
    }
    setLoading(true);
    const error = await loadTenantConfigService({
      email,
      dispatch,
      loadTenantConfigAction,
      unloadTenantConfigAction,
    });
    if (error) {
      const postErrorMessage =
        error?.response.data.message === 'Tenant not found.'
          ? "We couldn't find your team."
          : error?.response.data.message || 'Sorry, please try again.';
      setErrorMessage(postErrorMessage);
      focusInputById(emailElementId);
    }
    setLoading(false);
  };

  const focusInputById = (inputId) => {
    setTimeout(() => document.getElementById(inputId)?.focus(), 0);
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    setErrorMessage('');
    if (event.target === document.getElementById('fte')) {
      setTenantEmailInputCursorPosition(event.target.selectionStart);
      setEmailText(event.target.value);
    }
  };

  useEffect(() => {
    focusInputById('fte');
    tenantEmailInputRef.current?.setSelectionRange(
      tenantEmailInputCursorPosition,
      tenantEmailInputCursorPosition
    );
  }, [tenantEmailInputCursorPosition, emailText]);

  useEffect(() => {
    if (!isReady) return setReady(true);
    const emailElementId = 'fte';
    const inputFieldParents = document.querySelectorAll('.amplify-textfield');
    const defaultPrimaryButton = document.querySelectorAll('.amplify-button--primary')[0];
    inputFieldParents.forEach((inputFieldParent) => {
      const inputField = inputFieldParent.getElementsByTagName('input')[0];
      const inputType = inputField?.getAttribute('type');
      const isAmplifyUI =
        !inputFieldParent.classList.contains('custom-halo-ui');
      const isSignInEmail = isSignIn && inputType === 'email';
      const isAutofillEmail =
        tenant?.email && isSignInEmail;
      const isSignInFocus = isSignInEmail && !tenant?.email;
      inputFieldParent.style.display = isAmplifyUI ? 'none' : 'inherit';
      if (isAutofillEmail) inputField.value = tenant.email;
      if (isSignInFocus) inputField.focus();
    });
    if (defaultPrimaryButton) defaultPrimaryButton.style.display = 'none';
    setEmailText(' ');
    setTimeout(() => {
      setEmailText('');
      focusInputById(emailElementId);
    }, 4);
    // eslint-disable-next-line
  }, [null, isReady, route, tenant]);

  return (
    <SignInLayout>
      <div style={{textAlign: 'center'}}>
        <div style={{fontSize: '46px', fontWeight: '600'}}>
          Log in to Halo Ai
        </div>
      </div>
      <Authenticator
        loginMechanisms={['email']}
        hideSignUp={true}
        components={components}
      />
    </SignInLayout>
  );
};

export default SignInContainer;
