// company components
import DashboardSideMenu from '../../components/DashboardSideMenu/DashboardSideMenu';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const DashboardSideMenuContainer = ({goToSection}) => {
  const {collectionScores} = useSelector((state) => state.dashboard);

  return (
    <DashboardSideMenu
      intrinsicScore={Math.round(
        collectionScores?.HaloScores?.HaloScore?.Value || 0
      )}
    />
  );
};

DashboardSideMenuContainer.propTypes = {
  goToSection: PropTypes.func,
};

export default DashboardSideMenuContainer;
