// ant components
// import {Alert, Space} from 'antd';

// chart components
import HaloHistoricIntrinsicScoreChartCard from '../../../chart/components/HaloHistoricIntrinsicScoreChartCard/HaloHistoricIntrinsicScoreChartCard';
import HaloHistoricKeyAreaScoresChartCard from '../../../chart/components/HaloHistoricKeyAreaScoresChartCard/HaloHistoricKeyAreaScoresChartCard';
import HaloIntrinsicScoreChartCard from '../../../chart/components/HaloIntrinsicScoreChartCard/HaloIntrinsicScoreChartCard';
import RiskAreaChartCard from '../../../chart/components/RiskAreaChartCard/RiskAreaChartCard';
import StrategicScoresChartCard from '../../../chart/components/StrategicScoresChartCard/StrategicScoresChartCard';

// dashboard constants
import HALO_FACTOR_SCORES_FOR_KEY_RISK_AREAS_DESCRIPTION from '../../constants/haloFactorScoresForKeyRiskAreasDescription.constant.dashboard';
import HALO_INFO_SCORE_DESCRIPTION from '../../constants/haloInfoScoreDescription.constant.dashboard';
import HALO_INFO_SCORE_TREND_DESCRIPTION from '../../constants/haloInfoScoreTrendDescription.constant.dashboard';
import HALO_KEY_RISK_AREA_TREND_DESCRIPTION from '../../constants/haloKeyRiskAreaTrendDescription.constant.dashboard';
import HALO_SCORES_FOR_KEY_RISK_AREAS_DESCRIPTION from '../../constants/haloScoresForKeyRiskAreasDescription.constant.dashboard';
import SCORE_DISTRIBUTION_ACROSS_KEY_AREAS_DESCRIPTION from '../../constants/scoreDistributionAcrossKeyAreasDescription.constant.dashboard';

// layout components
import {Column, Row} from '../../../layout/components/Grid/Grid';

// local lib
import extractHistoricIntrinsicScoreData from './lib/extractHistoricIntrinsicScoreData.lib';
import extractHistoricKeyRiskAreaScores from './lib/extractHistoricKeyRiskAreaScores.lib';
import extractRiskAreaData from './lib/extractRiskAreaData.lib';
import extractStrategicScores from './lib/extractStrategicScores.lib';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const DashboardHaloScoresSection = () => {
  const {collectionScores} = useSelector((state) => state.dashboard);

  return (
    <Fragment>
      <Row margin>
        <Column size={1 / 5}>
          <HaloIntrinsicScoreChartCard
            info={HALO_INFO_SCORE_DESCRIPTION}
            score={collectionScores?.HaloScores?.HaloScore?.Value || 0}
            title="Halo Score"
          />
        </Column>
        <Column size={4 / 5}>
          <HaloHistoricIntrinsicScoreChartCard
            data={extractHistoricIntrinsicScoreData({
              data: collectionScores.historicHaloScores,
            })}
            info={HALO_INFO_SCORE_TREND_DESCRIPTION}
            title="Halo Score Trend"
          />
        </Column>
      </Row>
      <Row margin>
        <Column size={1 / 2}>
          <StrategicScoresChartCard
            data={extractStrategicScores({data: collectionScores})}
            info={SCORE_DISTRIBUTION_ACROSS_KEY_AREAS_DESCRIPTION}
          />
        </Column>
        <Column size={1 / 2}>
          <RiskAreaChartCard
            {...extractRiskAreaData({data: collectionScores})}
            ratingInfo={HALO_SCORES_FOR_KEY_RISK_AREAS_DESCRIPTION}
            factorInfo={HALO_FACTOR_SCORES_FOR_KEY_RISK_AREAS_DESCRIPTION}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <HaloHistoricKeyAreaScoresChartCard
            data={extractHistoricKeyRiskAreaScores({
              data: collectionScores.historicHaloScores,
            })}
            info={HALO_KEY_RISK_AREA_TREND_DESCRIPTION}
          />
        </Column>
      </Row>
    </Fragment>
  );
};

export default DashboardHaloScoresSection;
