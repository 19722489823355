import styled from 'styled-components';

const MatrixCompanyName = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 5px;
`;

export default MatrixCompanyName;
