// dashboard routes
import dashboardRoute from '../../../dashboard/pages/DashboardPage/route';

// react
import {useEffect} from 'react';

// router
import {useNavigate} from 'react-router-dom';

const RedirectToHome = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(dashboardRoute());
    // eslint-disable-next-line
  }, []);
  return null;
};

export default RedirectToHome;
