// ant components
import {Input, Modal, Space, Select} from 'antd';

// collection services
import getCompaniesOptionsService from '../../services/getCompaniesOptions.service.collection';

// local components
import Box from './components/Box';
import Label from './components/Label';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CollectionEditorModal = ({
  companies,
  loading,
  name,
  onChange,
  onChangeSelection,
  onClose,
  onDelete,
  onSave,
  title,
  visible,
}) => (
  <Modal
    title={title}
    centered
    open={visible}
    onOk={onSave}
    onCancel={onClose}
    okText="Save"
    width={'800px'}
  >
    <Space direction="vertical" style={{width: '100%'}} size={[16, 16]}>
      <Box>
        <Label>Name</Label>
        <Input value={name} onChange={onChange('name')} disabled={loading} />
      </Box>
      <Box>
        <Label>Companies</Label>
        <Select
          mode="multiple"
          value={companies}
          onSelect={onChangeSelection('companies')}
          onDeselect={onChangeSelection('companies')}
          showSearch
          style={{width: '100%'}}
          placeholder="Select companies"
          options={getCompaniesOptionsService({excludeCompanies: companies})}
          disabled={loading}
          optionFilterProp="label"
        />
      </Box>
      {/* {!!onDelete && (
        <Box>
          <Button onClick={onDelete} disabled={loading} danger>
            Delete Tag
          </Button>
        </Box>
      )} */}
    </Space>
  </Modal>
);

CollectionEditorModal.propTypes = {
  companies: PropTypes.array,
  loading: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string,
  visible: PropTypes.bool,
};

export default CollectionEditorModal;
