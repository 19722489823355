// company routes
import baseRoute from '../CompanyPage/route';

export const dueDiligenceRoute = (id = ':id') =>
  `${baseRoute(id)}/due-diligence`;
export const createArrangementRoute = (id = ':id') =>
  `${baseRoute(id)}/due-diligence/create`;
export const getArrangementRoute = (
  id = ':id',
  arrangementId = ':arrangementId'
) => `${baseRoute(id)}/due-diligence/${arrangementId}`;
export const confirmedArrangementRoute = (
  id = ':id',
  arrangementId = ':arrangementId'
) => `${baseRoute(id)}/due-diligence/${arrangementId}/confirmed`;
