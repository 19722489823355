import styled from 'styled-components';

// layout colors
import brightRed300Color from '../../../../layout/colors/brightRed300.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

// constants
const SIZE = '18px';

const Badge = styled.div`
  width: ${SIZE};
  height: ${SIZE};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  color: ${whiteColor};
  background: ${brightRed300Color};
  position: absolute;
  top: -8px;
  right: -8px;
`;

export default Badge;
