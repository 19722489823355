// reports components
import DueDiligenceSection from '../../../company/components/DueDiligenceSection/DueDiligenceSection';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local page
import page from './page';

// react
import React from 'react';

const SecurityReportPage = () => (
  <Page page={page}>
    <div style={{margin:'40px auto', transform:'translateX(-100px)', width:'996px'}}>
      <DueDiligenceSection />
    </div>
  </Page>
);

export default SecurityReportPage;
