// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// number lib
import formattedInteger from '../../../../number/lib/formattedInteger.lib.number';

export default generateColumn({
  title: 'Enterprise Value (M)',
  componentProps: ({company}) => ({
    children: !!company?.Financials?.['EnterpriseValue(M)']
      ? formattedInteger(company?.Financials?.['EnterpriseValue(M)'])
      : '-',
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    return (
      Number(a?.Financials?.['EnterpriseValue(M)'] || 0) -
      Number(b?.Financials?.['EnterpriseValue(M)'] || 0)
    );
  },
});
