// ant components
import {Col as Column, Row, Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FinancialLegalFilingsSuitsAndJudgmentsCard = ({
  currency,
  suitsAndJudgments,
  printView,
}) => {
  const columns = generateColumns({currency, ...suitsAndJudgments});

  return (
    <Row gutter={[20, 20]}>
      <Column span={24}>
        <StatementBlock title="Summary">
          <Row>
            <Column span={12}>
              <Table
                columns={columns.suitsSummary}
                dataSource={suitsAndJudgments.summary.suits.entries}
                pagination={false}
                rowKey="id"
                size="small"
              />
            </Column>
            <Column span={12}>
              <Table
                columns={columns.judgmentsSummary}
                dataSource={suitsAndJudgments.summary.judgments.entries}
                pagination={false}
                rowKey="id"
                size="small"
              />
            </Column>
          </Row>
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Active Suits">
          <Table
            columns={columns.activeSuits}
            dataSource={suitsAndJudgments.activeSuits.entries}
            pagination={false}
            rowKey="id"
            size="small"
          />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Active Judgments">
          <Table
            columns={columns.activeJudgments}
            dataSource={suitsAndJudgments.activeJudgments.entries}
            pagination={false}
            rowKey="id"
            size="small"
          />
        </StatementBlock>
      </Column>
    </Row>
  );
};

FinancialLegalFilingsSuitsAndJudgmentsCard.propTypes = {
  currency: PropTypes.string,
  printView: PropTypes.bool,
  suitsAndJudgments: PropTypes.bool,
};

export default FinancialLegalFilingsSuitsAndJudgmentsCard;
