// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import authorizeAction from './actions/authorize.action.redux.auth';
import loadTenantConfigAction from './actions/loadTenantConfig.action.redux.auth';
import refreshAuthTokenAction from './actions/refreshToken.action.redux.auth';
import setGeneralAuthDataAction from './actions/setGeneralData.action.redux.auth';
import unauthorizeAction from './actions/unauthorize.action.redux.auth';
import unloadTenantConfigAction from './actions/unloadTenantConfig.action.redux.auth';

// style redux constants
import DEFAULT_AUTH_REDUX_STATE from './constants/defaultState.constant.redux.auth';

const authSlice = createSlice({
  name: 'auth',
  initialState: DEFAULT_AUTH_REDUX_STATE,
  reducers: {
    authorize: authorizeAction,
    loadTenantConfig: loadTenantConfigAction,
    refreshAuthToken: refreshAuthTokenAction,
    setGeneralAuthData: setGeneralAuthDataAction,
    unauthorize: unauthorizeAction,
    unloadTenantConfig: unloadTenantConfigAction,
  },
});

export const {
  authorize,
  loadTenantConfig,
  refreshAuthToken,
  setGeneralAuthData,
  unauthorize,
  unloadTenantConfig,
} = authSlice.actions;

export default authSlice.reducer;
