// chart components
import CategoryRatingChartCard from '../CategoryRatingChartCard/CategoryRatingChartCard';
import EsgRatingChartCard from '../EsgRatingChartCard/EsgRatingChartCard';

// layout components
import {Column, Row} from '../../../layout/components/Grid/Grid';

// local lib
import getCategoriesRating from './lib/getCategoriesRating.lib';
import getOverallRating from './lib/getOverallRating.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const EsgOverview = ({percentileView}) => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  return (
    <Row>
      <Column size={1 / 3}>
        <EsgRatingChartCard
          score={getOverallRating({company, percentileView})}
        />
      </Column>
      <Column size={2 / 3}>
        <Row margin>
          <Column size={1 / 2}>
            <CategoryRatingChartCard
              score={getCategoriesRating({
                company,
                percentileView,
                rating: 'Community',
              })}
              title="Community"
            />
          </Column>
          <Column size={1 / 2}>
            <CategoryRatingChartCard
              score={getCategoriesRating({
                company,
                percentileView,
                rating: 'Employees',
              })}
              title="Employees"
            />
          </Column>
        </Row>
        <Row>
          <Column size={1 / 2}>
            <CategoryRatingChartCard
              score={getCategoriesRating({
                company,
                percentileView,
                rating: 'Environment',
              })}
              title="Environment"
            />
          </Column>
          <Column size={1 / 2}>
            <CategoryRatingChartCard
              score={getCategoriesRating({
                company,
                percentileView,
                rating: 'Governance',
              })}
              title="Governance"
            />
          </Column>
        </Row>
      </Column>
    </Row>
  );
};

EsgOverview.propTypes = {
  percentileView: PropTypes.bool,
};

export default EsgOverview;
