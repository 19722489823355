import styled from 'styled-components';

const InputForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0px;
  padding: 0px;
`;

export default InputForm;
