// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralAlertDataAction from './actions/setGeneralData.action.redux.alert';

// style redux constants
import DEFAULT_DASHBOARD_REDUX_STATE from './constants/defaultState.constant.redux.alert';

const alertSlice = createSlice({
  name: 'alert',
  initialState: DEFAULT_DASHBOARD_REDUX_STATE,
  reducers: {
    setGeneralAlertData: setGeneralAlertDataAction,
  },
});

export const {setGeneralAlertData} = alertSlice.actions;

export default alertSlice.reducer;
