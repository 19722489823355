// local components
import Button from './components/Button';
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const GoToBlackKiteButton = ({children, url}) => (
  <Container>
    <Button href={url} target="_blank">
      {children}
    </Button>
  </Container>
);

GoToBlackKiteButton.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
};

export default GoToBlackKiteButton;
