// auth containers
import SignInContainer from '../../../auth/containers/SignInContainer/SignInContainer';

// auth redux reducer
import {
  loadTenantConfig as loadTenantConfigAction,
  unloadTenantConfig as unloadTenantConfigAction,
} from '../../../auth/redux/reducer.redux.auth';

// auth services
import loadTenantConfigService from '../../../auth/services/loadTenantConfig.service.auth';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';
import GlobalStyle from '../../../layout/components/GlobalStyle/GlobalStyle';
import Theme from '../../../layout/components/Theme/Theme';

// posthog
import {usePostHog} from 'posthog-js/react';

// react
import React, {useEffect} from 'react';

// redux
import {useSelector, useDispatch} from 'react-redux';

// route components
import Routes from '../../../route/components/Routes/Routes';

const App = () => {
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const intercomAppID = process.env.REACT_APP_INTERCOM_APP_ID;
  const {initialized, token, user} = useSelector((state) => state.auth);

  if (!!user) {
    const email = user.email;
    const userId = user.sub;
    const firstName = user.first_name;
    const tenantId = user?.['custom:tenantId'];
    const distinctId = tenantId ? `${userId}_${tenantId}` : userId;
    posthog.identify?.(distinctId, {email, ...(tenantId && {tenantId})});
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: intercomAppID,
      name: (firstName || email),
      email: email,
      user_id: distinctId,
    });
  } else {
    posthog.reset?.();
    window.Intercom('shutdown');
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: intercomAppID,
    });
  }

  useEffect(() => {
    loadTenantConfigService({
      dispatch,
      loadTenantConfigAction,
      unloadTenantConfigAction,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Theme>
      <GlobalStyle />
      {!initialized ? (
        <FullScreenLoader />
      ) : !token ? (
        <SignInContainer />
      ) : (
        <Routes />
      )}
    </Theme>
  );
};

export default App;
