// charts
import Highcharts from 'highcharts';

// local components
import Container from './components/Container';
import Grade from './components/Grade';

// local lib
import sortData from './lib/sortData.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect, Fragment} from 'react';

// risk lib
import getGradeColor from '../../../risk/lib/getGradeColor.lib.risk';

// uuid
import {v4} from 'uuid';

const CyberRatingChart = ({
  data = [],
  grade,
  height = '337px',
  onGradeClick,
  onRatingClick = () => {},
}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'pie',
        height,
      },
      title: {
        text: 'Cyber Rating',
        x: 0,
      },
      tooltip: {
        formatter: function () {
          return `<b>${this.point.name}</b><br/>Score: ${
            this.point.score
          }<br/>Grade: ${this.point.grade}<div>${
            this.point.shortDescription || ''
          }</div>`;
        },
        outside: true,
        useHTML: true,
      },
      plotOptions: {
        pie: {
          innerSize: 148,
          depth: 45,
          format: '{y:,.0f}',
          allowPointSelect: false,
        },
        series: {
          allowPointSelect: true,
          dataLabels: {
            enabled: false,
          },
          cursor: 'pointer',
          point: {
            events: {
              legendItemClick: function (e) {
                e.preventDefault();
                return false;
              },
              click: function (event) {
                event.preventDefault();
                const cyberRating = event?.point?.cyberRating || null;
                if (!cyberRating) return false;
                onRatingClick(cyberRating);
                return false;
              },
            },
          },
        },
      },

      series: [
        {
          name: 'Cyber Rating',
          colorByPoint: true,
          data: sortData(data),
        },
      ],
    });
    // eslint-disable-next-line
  }, [data, height]);
  return (
    <Fragment>
      <Container>
        <div id={id} />
        <Grade color={getGradeColor(grade)} onClick={onGradeClick}>
          {grade}
        </Grade>
      </Container>
    </Fragment>
  );
};

CyberRatingChart.propTypes = {
  blackKiteSupplier: PropTypes.object,
  data: PropTypes.array,
  grade: PropTypes.string,
  height: PropTypes.string,
  onGradeClick: PropTypes.func,
  onRatingClick: PropTypes.func,
};

export default CyberRatingChart;
