const generateSupplierCyberSecurityData = ({supplier, tenantData}) => {
  const financialImpact =
    [...(tenantData?.FinancialImpacts || [])].find(
      ({RiskType}) => RiskType === 'Data Breach'
    ) || null;

  const cyberRatings = Object.entries(
    supplier?.CurrentBlackKiteScores || {}
  ).reduce(
    (combined, [key, value]) =>
      key.endsWith('TechnicalRating')
        ? {...combined, [key.replace('TechnicalRating', '')]: value}
        : combined,
    {}
  );

  const cyberSecurity = {
    Ecosystems: tenantData?.Ecosystems || [],
    RansomwareIndex:
      supplier?.CyberSecurityMetrics?.['RansomwareSusceptibilityIndex(RSI)'] ||
      null,
    BreachIndex:
      supplier?.CyberSecurityMetrics?.['DataBreachIndex(DBI)'] || null,
    Grade: supplier?.CyberSecurityMetrics?.Grade || null,
    CyberRating: supplier?.CyberSecurityMetrics?.CyberRating || null,
    Compliance: {
      Completeness: supplier?.BlackKiteComplianceSummary?.Completeness || null,
      Standards: supplier?.BlackKiteComplianceSummary?.Standards || [],
      Rating: supplier?.BlackKiteComplianceSummary?.Rating || null,
    },
    Financial: {
      RatingMin: financialImpact?.RatingMin,
      RatingMax: financialImpact?.RatingMax,
      Rating: financialImpact?.Rating,
    },
    CyberAlerts: supplier?.CyberSecurityMetrics?.CyberAlerts || '',
    TechnicalCounts: supplier?.CurrentBlackKiteTechincalCounts || {},
    cyberRatings,
  };

  const complianceStandards = [
    ...(cyberSecurity?.Compliance?.Standards || []),
  ].map((standard) => ({
    ...standard,
    displayName: standard.Standard === 'SA22' ? 'SIG-22' : standard.Standard,
  }));

  return !!complianceStandards.length
    ? {
        ...cyberSecurity,
        Compliance: {
          ...cyberSecurity.Compliance,
          Standards: complianceStandards,
        },
      }
    : cyberSecurity;
};

export default generateSupplierCyberSecurityData;
