// tags api
import getCompanyTagsApi from '../api/getCompanyTags.api.tag';

const getCompanyTagsService = async ({
  companyId,
  getCompanyTags = getCompanyTagsApi,
}) => {
  try {
    const tags = await getCompanyTags(companyId);
    return tags;
  } catch (error) {
    return [];
  }
};

export default getCompanyTagsService;
