import styled from 'styled-components';

// layout colors
import grey200Color from '../../../../layout/colors/grey200.color.layout';

// constants
const SIZE = '1px';

const SummaryLine = styled.div`
  width: ${SIZE};
  max-width: ${SIZE};
  min-width: ${SIZE};
  background: ${grey200Color};
`;

export default SummaryLine;
