import styled from 'styled-components';

// layout components
import ActualLogo from '../../Logo/Logo';

const Logo = styled(ActualLogo)`
  height: 50px;
  display: block;
`;

export default Logo;
