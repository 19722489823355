// ant components
import {Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const TradePaymentsByCreditExtendedCard = ({currency, categories, entries}) => {
  const columns = generateColumns({currency, categories});

  return (
    <StatementBlock title="Trade Payments By Credit ExtendedCard">
      <Table
        columns={columns}
        dataSource={entries}
        pagination={false}
        rowKey="status"
        size="small"
      />
    </StatementBlock>
  );
};

TradePaymentsByCreditExtendedCard.propTypes = {
  categories: PropTypes.array,
  currency: PropTypes.string,
  entries: PropTypes.array,
};

export default TradePaymentsByCreditExtendedCard;
