// local components
import Container from './components/Container';
import Line from './components/Line';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const SideMenuItem = ({children, highlight, onClick}) => (
  <Container highlight={highlight} onClick={onClick}>
    {highlight && <Line />}
    {children}
  </Container>
);

SideMenuItem.propTypes = {
  children: PropTypes.node,
  highlight: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SideMenuItem;
