// table components
import DynamicColumn from '../../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const generateColumns = ({aqi, weather, fire, naturalDisaster}) => ({
  aqi: [...aqi].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      title: label,
    })
  ),
  weather: [...weather].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      title: label,
    })
  ),
  fire: [...fire].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      title: label,
    })
  ),
  naturalDisaster: [...naturalDisaster].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      title: label,
    })
  ),
});

export default generateColumns;
