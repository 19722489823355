// date-fns
import {format, parse, isDate} from 'date-fns';

const longDateFormatter = ({date = new Date(), dateFormat = 'yyyyMMdd'} = {}) =>
  format(
    isDate(date) ? date : parse(date, dateFormat, new Date()),
    'LLLL do yyyy'
  );

export default longDateFormatter;
