// local components
import Column from '../components/Column';

const generateColumn = ({field, title = null}) => {
  return {
    title,
    key: field,
    dataIndex: field,
    render: (_, entry, entryIndex) => (
      <Column field={field} entry={entry} entryIndex={entryIndex} />
    ),
  };
};

export default generateColumn;
