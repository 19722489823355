// financial components
import FinancialCompanyDetailCard from '../FinancialCompanyDetailCard/FinancialCompanyDetailCard';

// layout components
import Tabs from '../../../layout/components/Tabs/Tabs';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialCompanyDetail = ({printView}) => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const tabs = [
    {
      key: 'companyDetails',
      label: 'Company Details',
      children: (
        <FinancialCompanyDetailCard
          activities={company.financials.companyDetails.activities}
          currency={company.financials.currency}
          companyInformation={
            company.financials.companyDetails.companyInformation
          }
          printView={printView}
        />
      ),
    },
    // {
    //   key: 'corporateRecords',
    //   label: 'Corporate Records',
    //   children: 'Corporate Records',
    // },
  ].filter((tab) => !!tab);

  return <Tabs tabs={tabs} printView={printView} />;
};

FinancialCompanyDetail.propTypes = {
  printView: PropTypes.bool,
};

export default FinancialCompanyDetail;
