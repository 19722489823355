// numeral
import numeral from 'numeral';

const formattedNumber = ({
  number,
  removeZeroDecimals = true,
  withDecimals = true,
}) =>
  numeral(number).format(
    withDecimals ? (removeZeroDecimals ? '0,0[.]0[0]' : '0,0.00') : '0,0'
  );

export default formattedNumber;
