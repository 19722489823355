import React, {useEffect, useState, Fragment} from 'react';
import styled from 'styled-components';
import {Button, Form} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

import {Search} from './Search';

const ListContainer = styled.div`
  .dynamic-delete-button {
    position: absolute;
    top: 10px;
    margin-left: 8px;
    color: #999;
    cursor: pointer;
    transition: all 0.3s;
  }
  .dynamic-delete-button:hover {
    color: #777;
  }
  .ant-form-item-control-input-content {
    max-width: fit-content;
  }
`;

const List = ({
  disabled = false,
  item = 'Item',
  min = 0,
  value,
  onChange,
  onSubmit,
}) => {
  const getValues = () => {
    const items = [];
    const elements = document.getElementsByClassName(getInputClassName());
    [...Array(elements.length).keys()].forEach((index) => {
      const element = elements.item(index);
      const value = element.value;
      if (value.replace(/\s/g, '')) items.push(value);
    });
    return [...new Set(items)];
  };

  const getInputClassName = () => {
    return item.replace(/\s/g, '');
  };

  const [values] = useState(JSON.parse(value));

  let addListField;

  useEffect(() => {
    values.forEach((item, index) => {
      if (index >= 2) addListField();
    });
    // eslint-disable-next-line
  }, []);

  return (
    <ListContainer>
      <Form name="dynamic_form_item">
        <Form.List name="names" rules={[]}>
          {(fields, {add, remove}, {errors}) => {
            addListField = () => add();
            return (
              <Fragment>
                {[...Array(min).keys()].map((index) => (
                  <Form.Item key={index} style={{marginBottom: '10px'}}>
                    <Search
                      className={getInputClassName()}
                      style={{backgroundColor: 'white'}}
                      placeholder={item + ' #' + (index + 1)}
                      value={values[index]}
                      onChange={() => {
                        if (onChange) onChange(getValues());
                      }}
                      basicSearch
                    />
                  </Form.Item>
                ))}
                {fields.map((field, index) => (
                  <Form.Item key={field.key} style={{marginBottom: '10px'}}>
                    <Search
                      className={getInputClassName()}
                      style={{backgroundColor: 'white'}}
                      placeholder={item + ' #' + (index + min + 1)}
                      value={values[index + min]}
                      onChange={() => {
                        if (onChange) onChange(getValues());
                      }}
                      basicSearch
                    />
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </Form.Item>
                ))}
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  style={{marginRight: '10px'}}
                >
                  {'Add ' + item}
                </Button>
              </Fragment>
            );
          }}
        </Form.List>
        <Button
          disabled={disabled}
          type="primary"
          onClick={() => onSubmit(getValues())}
        >
          Continue
        </Button>
      </Form>
    </ListContainer>
  );
};

export default List;
