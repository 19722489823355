// ID
import {v4} from 'uuid';

// Constants
import KEY from './constants/key.constant.store';

class Store {
  constructor() {
    this.init();
  }

  init() {
    if (!!window[KEY]) return;
    window[KEY] = {
      subscribers: [],
      data: {},
    };
  }

  store() {
    this.init();
    return window[KEY];
  }

  setData(store, data = {}) {
    const currentData = this.store().data[store] || {};
    this.store().data[store] = {...currentData, ...data};
    this.publish(store);
  }

  getData(store) {
    return this.store().data[store] || {};
  }

  subscribe(stores = [], fn) {
    const id = v4();
    this.store().subscribers = [...this.store().subscribers, {id, stores, fn}];
    return this.unsubscribe.bind(this, id);
  }

  unsubscribe(subscriberId) {
    this.store().subscribers = [...this.store().subscribers].filter(
      ({id}) => id !== subscriberId
    );
  }

  publish(store) {
    this.store().subscribers.forEach((subscriber) => {
      if (!subscriber.stores.includes(store)) return;
      subscriber.fn(this.generateData(subscriber.stores));
    });
  }

  generateData(stores) {
    return [...stores].reduce(
      (combined, current) => ({
        ...combined,
        [current]: this.getData(current),
      }),
      {}
    );
  }
}

const store = new Store();

export default store;
