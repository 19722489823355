// propTypes
import PropTypes from 'prop-types';

// react
// import React from 'react';

const Column = ({dataKey, entry, entryIndex}) => {
  const value = entry?.[dataKey];
  return [undefined, null].includes(value) ? '-' : value;
};

Column.propTypes = {
  dataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default Column;
