// react redux store
import store from '../../redux/store';

const canEditWorkflowPermission = ({
  user = store.getState().auth.user,
  workflow,
} = {}) =>
  !workflow?.settings?.createdBy ||
  workflow?.settings?.createdBy === user?.email;

export default canEditWorkflowPermission;
