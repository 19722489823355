// map api
// import getUSAMapApi from '../api/getUSA.api.map';
import getWorldMapApi from '../api/getWorld.api.map';

// react
import {useEffect, useState} from 'react';

const useGetWorldAndUsaMapHook = () => {
  const [loadingMaps, setLoadingMaps] = useState(true);
  // const [maps, setMaps] = useState({worldMap: null, usaMap: null});
  const [maps, setMaps] = useState({worldMap: null});

  useEffect(() => {
    const fetchData = async () => {
      setLoadingMaps(true);
      try {
        const [
          worldMap,
          // usaMap,
        ] = await Promise.all([
          getWorldMapApi(),
          // getUSAMapApi(),
        ]);
        // setMaps({worldMap, usaMap});
        setMaps({worldMap});
      } catch (error) {
        // setMaps({worldMap: null, usaMap: null});
        setMaps({worldMap: null});
      }
      setLoadingMaps(false);
    };
    fetchData();
  }, []);

  return {...maps, loadingMaps};
};

export default useGetWorldAndUsaMapHook;
