// company components
import CompanySupplierColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanySupplierColumn/CompanySupplierColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// string lib
import sortByString from '../../../../string/lib/sortByString.lib.string';

export default generateColumn({
  title: 'Supplier',
  fixed: 'left',
  Component: CompanySupplierColumn,
  align: 'left',
  width: 200,
  defaultSortOrder: 'ascend',
  sorter: (a, b) =>
    sortByString({
      valueA: a?.CompanyName || a?.Firmographic?.LegalName,
      valueB: b?.CompanyName || b?.Firmographic?.LegalName,
    }),
});
