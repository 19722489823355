// api constants
import ADMIN_API_URL from '../../api/constants/url.constant.api.js';

// auth constants
import RESERVED_SUBDOMAINS from '../constants/reservedSubdomains.constant.auth.js';

// auth stores
import authStore from '../stores/store.auth.js';

// halo api
import getHaloData from '../../api/lib/getHaloData.lib.api';

// auth services
import signOutService from '../../auth/services/signOut.service.auth';

// app lib
import isProduction from '../../app/lib/isProduction.lib.app';

// aws lib
import setupAmplify from '../../aws/lib/setupAmplify.lib.aws';
import extractAwsTokenData from '../../aws/lib/extractTokenData.lib.aws';

// aws
import {Auth} from 'aws-amplify';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

// axios
import axios from 'axios';

// Buffer
import {Buffer} from 'buffer';

// tenant store
import tenantStore from '../../tenant/stores/store.tenant';

// CryptoJS
import CryptoJS from 'crypto-js';

const loadTenantConfigService = async ({
  auth = Auth,
  email,
  dispatch,
  loadTenantConfigAction,
  unloadTenantConfigAction,
}) => {
  try {
    let token,
      user = null;
    let tenantBase64 = new URLSearchParams(window.location.search).get('t');
    const authStateBase64 = new URLSearchParams(window.location.search).get(
      'state'
    );
    const authState =
      authStateBase64 &&
      JSON.parse(Buffer.from(authStateBase64, 'base64').toString('utf-8'));
    const currentSubdomain = window.location.hostname.split('.')[0];
    const isRootDomain = window.location.hostname.split('.').length === 1;
    const isWWWSubdomain = window.location.hostname.indexOf(`www.`) === 0;
    const isReservedSubdomain = RESERVED_SUBDOMAINS.includes(currentSubdomain);
    const logoutStateBase64 = localStorage.getItem('logout_state');
    const logoutState = logoutStateBase64 && JSON.parse(Buffer.from(logoutStateBase64, 'base64').toString('utf-8'));
    if (logoutState) {
      localStorage.removeItem('logout_state');
      window.location.href = `http${logoutState.is_production ? 's' : ''}://${RESERVED_SUBDOMAINS.includes(logoutState.subdomain) ? logoutState.subdomain : 'www'}.${logoutState.is_production ? 'gotohalo.com' : 'localhost:3000'}${logoutState.login_email ? `?l=${Buffer.from(JSON.stringify(logoutState.login_email)).toString('base64')}` : ''}`;
      return;
    }
    const isStoreTenant = !!tenantBase64;
    if (isStoreTenant) {
      localStorage.setItem('tenant', tenantBase64);
      let tenant =
        tenantBase64 &&
        JSON.parse(Buffer.from(tenantBase64, 'base64').toString('utf-8'));
      const codeVerifier = CryptoJS.lib.WordArray.random(8).toString();
      const codeVerifierEncoded = CryptoJS.SHA256(codeVerifier)
        .toString(CryptoJS.enc.Base64)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
      const ssoAuthURL = `https://pooledtenant-halosaas-947585184831.auth.${tenant.region}.amazoncognito.com/oauth2/authorize`;
      const responseType = `response_type=code`;
      const clientId = `client_id=${tenant.appClientId}`;
      const redirectURI = `redirect_uri=http${isProduction() ? 's' : ''}://${
        isProduction()
          ? `${isReservedSubdomain ? currentSubdomain : 'www'}.`
          : ''
      }${isProduction() ? 'gotohalo.com' : 'localhost:3000'}`;
      const state = `state=${Buffer.from(
        JSON.stringify({
          subdomain: currentSubdomain,
          code_verifier: codeVerifier,
        })
      ).toString('base64')}`;
      const identityProvider = `identity_provider=auth0`;
      const scope = `scope=email%20openid%20profile`;
      const codeChallengeMethod = `code_challenge_method=S256`;
      const codeChallenge = `code_challenge=${codeVerifierEncoded}`;
      const url = `${ssoAuthURL}?${responseType}&${clientId}&${redirectURI}&${state}&${identityProvider}&${scope}&${codeChallengeMethod}&${codeChallenge}`;
      window.location.href = url;
      return;
    }
    tenantBase64 = localStorage.getItem('tenant');
    let tenant =
      tenantBase64 &&
      JSON.parse(Buffer.from(tenantBase64, 'base64').toString('utf-8'));
    const isRedirectBackToSubdomain =
      authState &&
      ((!isProduction() && isRootDomain) || (isProduction() && isWWWSubdomain));
    const isGetUserToken =
      authState && authState.code_verifier && !isRedirectBackToSubdomain;
    if (isRedirectBackToSubdomain) {
      const subdomain = authState.subdomain;
      if (authState.code_verifier) {
        window.location.href = `http${isProduction() ? 's' : ''}://${subdomain}.${isProduction() ? 'gotohalo.com' : 'localhost:3000'}${window.location.search}`;
      }
      else if (authState.logout_redirect_uri) {
        localStorage.setItem('logout_state', authStateBase64);
        window.location.href = authState.logout_redirect_uri;
      }
      return;
    } else if (isGetUserToken) {
      try {
        const authError = new URLSearchParams(window.location.search).get(
          'error_description'
        );
        if (authError) return;

        const authCode = new URLSearchParams(window.location.search).get(
          'code'
        );
        const codeVerifier = authState.code_verifier;
        const ssoTokenURL = `https://pooledtenant-halosaas-947585184831.auth.${tenant.region}.amazoncognito.com/oauth2/token`;
        const ssoTokenParameters = new URLSearchParams();
        ssoTokenParameters.append('grant_type', 'authorization_code');
        ssoTokenParameters.append('client_id', tenant.appClientId);
        ssoTokenParameters.append(
          'redirect_uri',
          `http${isProduction() ? 's' : ''}://${
            isProduction()
              ? `${isReservedSubdomain ? currentSubdomain : 'www'}.`
              : ''
          }${isProduction() ? 'gotohalo.com' : 'localhost:3000'}`
        );
        ssoTokenParameters.append('code', authCode);
        ssoTokenParameters.append('code_verifier', codeVerifier);
        const ssoTokenConfig = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        const ssoTokenResponse = await axios.post(
          ssoTokenURL,
          ssoTokenParameters,
          ssoTokenConfig
        );
        const data = ssoTokenResponse?.data;
        /******************************************************************************************
         * Injects an access token, id token, and refresh token into AWS Amplify for idenity and access
         * management. Cognito will store these tokens in memory and they will persist upon requesting
         * additional pages from the same domain.
         *
         * Calling this method should have the same effect as signing in with Auth.signIn(). When an
         * id or access token expires, Cognito will automatically retrieve new ones using the refresh
         * token passed.
         ******************************************************************************************/
        const session = new AmazonCognitoIdentity.CognitoUserSession({
          AccessToken: new AmazonCognitoIdentity.CognitoAccessToken({
            AccessToken: data.access_token,
          }),
          IdToken: new AmazonCognitoIdentity.CognitoIdToken({
            IdToken: data.id_token,
          }),
          RefreshToken: new AmazonCognitoIdentity.CognitoRefreshToken({
            RefreshToken: data.refresh_token,
          }),
        });
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: session.accessToken.payload.username,
          Pool: new AmazonCognitoIdentity.CognitoUserPool({
            UserPoolId: tenant.userPoolId,
            ClientId: tenant.appClientId,
          }),
        });
        cognitoUser.setSignInUserSession(session);
        /*****************************************************************************************/
        window.location.href = window.location.href.split('?')[0];
        return;
      } catch (error) {}
    }
    const loginEmailBase64 = new URLSearchParams(window.location.search).get('l');
    const loginEmail = email || (loginEmailBase64 && JSON.parse(Buffer.from(loginEmailBase64, 'base64').toString('utf-8')));
    const isFindTenant = !!loginEmail;
    if (isFindTenant) {
      const tenantConfigResponse = await axios.get(
        `${ADMIN_API_URL}/tenant/init/${loginEmail}`
      );
      const data = tenantConfigResponse?.data;
      if (!data) dispatch(unloadTenantConfigAction());
      tenant = {
        id: data.tenantId,
        email: loginEmail,
        region: data.userPoolId.split('_')[0],
        domain: loginEmail.split('@')[1],
        subdomain: isReservedSubdomain
          ? currentSubdomain
          : data.tenantSubdomain,
        name: data.tenantName,
        userPoolId: data.userPoolId,
        appClientId: data.appClientId,
        apiURL: data.apiGatewayUrl
          .replace(/\/$/, '')
          .replace(/\/prod$/, '/prod'),
      };
      tenantBase64 = Buffer.from(JSON.stringify(tenant)).toString('base64');
    }
    tenantStore.setData({tenant});
    setupAmplify(tenant);
    const isLoadUser = !loginEmail && !!tenant;
    if (isLoadUser) {
      try {
        const userData = await auth.currentAuthenticatedUser();
        if (userData) {
          token = extractAwsTokenData(userData);
          user = {
            sub: token.idToken.data.sub,
            email: token.idToken.data.email,
            email_verified: token.idToken.data.email_verified,
            first_name: token.idToken.data.given_name,
            last_name: token.idToken.data.family_name,
            name: token.idToken.data.name,
            'custom:tenantId': token.idToken.data['custom:tenantId'],
            'custom:userRole': token.idToken.data['custom:userRole'],
          };
          const secondsSinceSignIn = Math.floor(
            (Date.now() - new Date(token.accessToken.data.auth_time * 1000)) /
              1000
          );
          const isTenantValid = tenant.id === user?.['custom:tenantId'];
          const isUserValid =
            isTenantValid &&
            (tenant.email === user?.email || secondsSinceSignIn < 10);
          if (isUserValid) {
            tenant.email = user.email;
            tenantBase64 = Buffer.from(JSON.stringify(tenant)).toString(
              'base64'
            );
            localStorage.setItem('tenant', tenantBase64);
          } else
            signOutService({dispatch, tenant, isFindTenant:true});
        }
      } catch (error) {}
    }
    const isRedirectUnknownTenant =
      !tenant && !isReservedSubdomain && !isWWWSubdomain;
    const isRedirectFoundTenant = tenant && isFindTenant;
    const isRedirect = isRedirectUnknownTenant || isRedirectFoundTenant;
    if (isRedirect) {
      window.location.href = `http${isProduction() ? 's' : ''}://${
        isReservedSubdomain
          ? currentSubdomain
          : tenant
          ? tenant.subdomain
          : 'www'
      }.${isProduction() ? 'gotohalo.com' : 'localhost:3000'}${
        tenant ? `?t=${tenantBase64}` : ''
      }`;
      return;
    }
    if (tenant) getHaloData(`/tenant/${tenant.id}/logo.png`, tenant); // preload
    authStore.setData({token, user});
    dispatch(loadTenantConfigAction({tenant, token, user}));
  } catch (error) {
    dispatch(unloadTenantConfigAction());
    return error;
  }
};

export default loadTenantConfigService;
