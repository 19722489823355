// charts
import Highcharts from 'highcharts';

// layout colors
import blackColor from '../../../layout/colors/black.color.layout';
import blueGrey300Color from '../../../layout/colors/blueGrey300.color.layout';
import green300Color from '../../../layout/colors/green300.color.layout';
import grey300Color from '../../../layout/colors/grey300.color.layout';
import grey600Color from '../../../layout/colors/grey600.color.layout';
import red300Color from '../../../layout/colors/red300.color.layout';
import violet300Color from '../../../layout/colors/violet300.color.layout';
import yellow300Color from '../../../layout/colors/yellow300.color.layout';

// layout components
import Card from '../../../layout/components/Card/Card';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const EsgComparisonChartCard = ({data = [], drillDown = []}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'column',
        height: '400px',
      },
      colors: [
        blueGrey300Color,
        green300Color,
        violet300Color,
        yellow300Color,
        red300Color,
        grey600Color,
        grey300Color,
        blackColor,
      ],
      title: {
        text: 'ESG Comparison',
      },
      xAxis: {
        crosshair: true,
        type: 'category',
      },
      yAxis: {
        title: {
          text: 'Score',
        },
        min: 0,
        max: 100,
      },
      tooltip: {
        shared: true,
      },

      series: data,

      drilldown: {
        breadcrumbs: {
          position: {
            align: 'right',
          },
          showFullPath: false,
          format: 'Back',
        },
        series: drillDown,
        activeDataLabelStyle: {
          textDecoration: 'none',
          color: blackColor,
        },
        activeAxisLabelStyle: {
          textDecoration: 'none',
          color: blackColor,
        },
      },
    });
    // eslint-disable-next-line
  }, [data, drillDown]);

  return (
    <Card noPadding>
      <div id={id} />
    </Card>
  );
};

EsgComparisonChartCard.propTypes = {
  data: PropTypes.array,
  drillDown: PropTypes.array,
};

export default EsgComparisonChartCard;
