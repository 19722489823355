// charts
import Highcharts from 'highcharts';

// layout colors
import blueGrey300Color from '../../../layout/colors/blueGrey300.color.layout';

// layout components
import Card from '../../../layout/components/Card/Card';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const EsgHistoricChartCard = ({data = []}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'area',
        zoomType: 'x',
      },
      colors: [blueGrey300Color],
      title: {
        text: 'ESG Trend',
      },
      subtitle: {
        text: 'Click and drag to zoom',
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Score',
        },
        min: 0,
        max: 100,
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: 'Score',
          data,
        },
      ],
    });
    // eslint-disable-next-line
  }, [data]);

  return (
    <Card noPadding>
      <div id={id} />
    </Card>
  );
};

EsgHistoricChartCard.propTypes = {
  data: PropTypes.array,
  drillDown: PropTypes.array,
};

export default EsgHistoricChartCard;
