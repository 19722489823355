// charts
import Highcharts from 'highcharts';

// layout colors
import blackColor from '../../../layout/colors/black.color.layout';
import blueGrey300Color from '../../../layout/colors/blueGrey300.color.layout';
import green300Color from '../../../layout/colors/green300.color.layout';
import grey300Color from '../../../layout/colors/grey300.color.layout';
import grey600Color from '../../../layout/colors/grey600.color.layout';
import red300Color from '../../../layout/colors/red300.color.layout';
import violet300Color from '../../../layout/colors/violet300.color.layout';
import yellow300Color from '../../../layout/colors/yellow300.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const HighCreditBalancesChart = ({categories, entries}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'column',
      },

      colors: [
        blueGrey300Color,
        green300Color,
        violet300Color,
        yellow300Color,
        red300Color,
        grey300Color,
        grey600Color,
        blackColor,
      ],

      xAxis: {
        categories,
      },

      yAxis: {
        title: {
          text: '',
        },
      },

      plotOptions: {
        series: {
          categories,
        },
      },

      series: entries,
    });
    // eslint-disable-next-line
  }, [entries, categories]);
  return <div id={id} />;
};

HighCreditBalancesChart.propTypes = {
  categories: PropTypes.array,
  entries: PropTypes.array,
};

export default HighCreditBalancesChart;
