// alert api
import updateAlertApi from '../../alert/api/update.api.alert';

// workflow userSettings
// import workflowsUserSettings from '../userSettings/userSettings.workflow';

const updateWorkflowRepository = async ({workflow}) => {
  const updatedWorkflow = await updateAlertApi(workflow.id, workflow);
  return updatedWorkflow;
  // const workflowSettings = await workflowsUserSettings.getSettings();
  // const existingWorkflows = workflowSettings?.workflows || [];
  // const workflows = [...existingWorkflows].map((existingWorkflow) =>
  //   existingWorkflow.id === workflow.id ? workflow : existingWorkflow
  // );
  // await workflowsUserSettings.saveSettings({...workflowSettings, workflows});
  // return workflow;
};

export default updateWorkflowRepository;
