const setupIntercomService = () => {
  const intercom = window.Intercom;

  if (typeof intercom === 'function') {
    intercom('reattach_activator');
    intercom('update', window.intercomSettings);
    return;
  }

  const i = function () {
    i.c(arguments);
  };

  i.q = [];
  i.c = function (args) {
    i.q.push(args);
  };
  window.Intercom = i;
  var l = function () {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}`;
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  };
  if (document.readyState === 'complete') {
    l();
  } else if (window.attachEvent) {
    window.attachEvent('onload', l);
  } else {
    window.addEventListener('load', l, false);
  }
};

export default setupIntercomService;
