// financial components
import FinancialCompanyDetail from '../FinancialCompanyDetail/FinancialCompanyDetail';
import FinancialLegalFilings from '../FinancialLegalFilings/FinancialLegalFilings';
import FinancialOfficersCard from '../FinancialOfficersCard/FinancialOfficersCard';
import FinancialPaymentData from '../FinancialPaymentData/FinancialPaymentData';
import FinancialsSummary from '../FinancialsSummary/FinancialsSummary';
import FinancialStatements from '../FinancialStatements/FinancialStatements';

// layout components
import Tabs from '../../../layout/components/Tabs/Tabs';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FinancialsTabs = ({printView}) => {
  const tabs = [
    {
      key: 'summary',
      label: 'Executive Summary',
      children: <FinancialsSummary printView={printView} />,
    },
    {
      key: 'paymentData',
      label: 'Payment Data',
      children: <FinancialPaymentData printView={printView} />,
    },
    {
      key: 'financialStatements',
      label: 'Financial Statements',
      children: <FinancialStatements printView={printView} />,
    },
    // {
    //   key: 'businessSpend',
    //   label: 'Business Spend',
    //   children: 'Business Spend',
    // },
    {
      key: 'legalFilings',
      label: 'Legal Filings',
      children: <FinancialLegalFilings printView={printView} />,
    },
    // {
    //   key: 'corporateStructure_group',
    //   label: 'Corporate Structure Financials',
    //   children: 'Corporate Structure Financials',
    // },
    {
      key: 'officers',
      label: 'Officers',
      children: <FinancialOfficersCard printView={printView} />,
    },
    {
      key: 'companyDetails',
      label: 'Company Details',
      children: <FinancialCompanyDetail printView={printView} />,
    },
  ].filter((tab) => !!tab);

  return <Tabs tabs={tabs} printView={printView} />;
};

FinancialsTabs.propTypes = {
  printView: PropTypes.bool,
};

export default FinancialsTabs;
