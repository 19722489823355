// components
import {createGlobalStyle} from 'styled-components';

// layout colors
import blackColor from '../../colors/black.color.layout';
import blueGrey200Color from '../../colors/blueGrey200.color.layout';
import grey200Color from '../../colors/grey200.color.layout';
import grey300Color from '../../colors/grey300.color.layout';
import green400Color from '../../colors/green400.color.layout';

// local constants
import tableSectionHeader from './constants/tableSectionHeader.constant';
import tableSectionHighlight from './constants/tableSectionHighlight.constant';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    font-family: 'Open Sans';
    margin: 0;
    padding: 0;
    color: ${blackColor};
    background: ${grey200Color};
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
  }

  body {
    min-height: inherit;
    background: ${grey200Color};
  }

  a {
    color: ${green400Color};

    &:hover {
      color: ${blackColor};
    }
  }

  .${tableSectionHeader} {
    background: ${grey300Color};
    font-weight: 700;
  }

  .${tableSectionHighlight} {
    background: ${blueGrey200Color};
    font-weight: 700;
  }

  .highcharts-container {
    z-index: auto !important;
  }

  .highcharts-contextmenu  {
    z-index: 10000000 !important;
  }

  .highchartHoverLabel {
    &:hover {
      color: ${green400Color};
    }
  }

  .highchartLogoLabel {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;

    & > .hcLogoLabelContainer {
      width: 50px;
      height: 20px;
      text-align: right;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .table-row-aligned-top table tr {
    vertical-align: top;
  }

  @media print {
    @page {
      size: A4; /* DIN A4 standard, Europe */
      margin: 10mm 0mm;
    }

    html, body {
      width: 100%;
      ${
        '' /* width: 210mm;
      height: 297mm; */
      }
      font-size: 11px;
      background: #FFF !important;
      overflow: visible;
      margin: auto;
    }

    .hide-on-print {
      display: none;
    }

    table td, table th {
      font-size: 10px !important;
    }

    .smallPrint table td, table th {
      font-size: 8px !important;
    }

    table { page-break-inside: auto }
    tr    { page-break-inside: avoid; page-break-after: auto; }
    thead { display: table-header-group; }
    tfoot { display: table-footer-group; }

    .highcharts-exporting-group {
      display: none;
    }
  }
`;

export default GlobalStyle;
