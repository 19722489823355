// local lib
import renderFinancialsChart from './renderFinancialsChart.lib';
import renderPrivateCompanyData from './renderPrivateCompanyData.lib';
import renderRevenueChart from './renderRevenueChart.lib';

const renderFinancialSection = ({company}) =>
  renderFinancialsChart({company}) ||
  renderRevenueChart({company}) ||
  renderPrivateCompanyData({company});

export default renderFinancialSection;
