// layout colors
import blueGrey300Color from '../../../../layout/colors/blueGrey300.color.layout';
import green300Color from '../../../../layout/colors/green300.color.layout';
import red300Color from '../../../../layout/colors/red300.color.layout';

// local components
import Circle from '../../PrivateCompanyFinancialsTable/components/Circle';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// helper constants
const colors = {
  decreasing: red300Color,
  neutral: blueGrey300Color,
  increasing: green300Color,
};

const icons = {
  decreasing: 'arrow-down-circle',
  neutral: 'arrow-right-circle',
  increasing: 'arrow-up-circle',
};

const DirectionColumn = ({category, columnIndex, entry, entryIndex}) => {
  const value = entry[category];
  const isDirection = ['decreasing', 'neutral', 'increasing'].includes(value);
  const color = colors[value] || colors.neutral;
  const icon = icons[value] || icons.neutral;
  return isDirection ? (
    <Circle theme={color}>
      <i className={`mdi mdi-${icon}`} />
    </Circle>
  ) : (
    value
  );
};

DirectionColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default DirectionColumn;
