// ant components
import {Tag} from 'antd';

// local components
import NameContent from './NameContent';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const NameColumn = ({entry}) => {
  const name = entry?.settings?.name || null;
  const recipients = entry?.settings?.recipients || [];
  return !!name ? (
    <NameContent>
      {name}
      {!recipients.length && <Tag>Tenant-wide</Tag>}
    </NameContent>
  ) : (
    '-'
  );
};

NameColumn.propTypes = {
  dataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entry: PropTypes.object,
};

export default NameColumn;
