// ant components
import {Col as Column, Row, Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FinancialLegalFilingSummaryCard = ({currency, legal, printView}) => {
  const columns = generateColumns({currency, ...legal});

  return (
    <Row gutter={[20, 20]}>
      <Column span={24}>
        <StatementBlock title="Legal Filings Summary">
          <Table
            columns={columns.filingsSummary}
            dataSource={legal.filingsSummary.entries}
            pagination={false}
            rowKey="id"
            size="small"
          />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Most Recent Filings">
          <Table
            columns={columns.mostRecentFilings}
            dataSource={legal.mostRecentFilings.entries}
            pagination={false}
            rowKey="id"
            size="small"
          />
        </StatementBlock>
      </Column>
    </Row>
  );
};

FinancialLegalFilingSummaryCard.propTypes = {
  currency: PropTypes.string,
  legal: PropTypes.bool,
  printView: PropTypes.bool,
};

export default FinancialLegalFilingSummaryCard;
