// data constants
import HALO_FACTOR_RATING_DESCRIPTIONS from '../../../../data/constants/haloFactorRatingDescriptions.constant.data';

// data lib
import generateAreaScoreKey from '../../../../data/lib/generateAreaScoreKey.lib.data';

// lib
import generateLabelFromKeys from '../../../../lib/generateLabelFromKeys.lib';

// number lib
import integer from '../../../../number/lib/integer.lib.number';

// risk lib
import convertScoreToGrade from '../../../../risk/lib/convertScoreToGrade.lib.risk';
import getRiskColor from '../../../../risk/lib/getRiskColor.lib.risk';

const extractRiskAreaBreakdownData = ({company, area = 'Financial'}) =>
  Object.entries(company.originalHaloScores.HaloScores)
    .filter(
      ([key, {Value}]) =>
        generateAreaScoreKey(area) !== key &&
        key.startsWith(`${area}_`) &&
        Value !== null
    )
    .map(([key, {Value}]) => {
      const areaKey = key.split('_')[1];
      const grade = convertScoreToGrade(Value);
      const label = generateLabelFromKeys(areaKey);
      return {
        color: getRiskColor(integer(Value))?.primary,
        description: HALO_FACTOR_RATING_DESCRIPTIONS?.[areaKey] || null,
        grade,
        name:
          label === 'Financial'
            ? 'Financial Metric'
            : generateLabelFromKeys(areaKey),
        y: Value,
      };
    });

export default extractRiskAreaBreakdownData;
