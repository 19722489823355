// company components
import CompanySocialMediaColumn from '../../../components/CompaniesTableColumns/dynamicColumns/CompanySocialMediaColumn/CompanySocialMediaColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Social Media',
  componentProps: ({company}) => ({
    facebook: company?.Firmographic?.Facebook,
    instagram: company?.Firmographic?.Instagram,
    linkedIn: company?.Firmographic?.LinkedIn,
    twitter: company?.Firmographic?.Twitter,
    youtube: company?.Firmographic?.YouTube,
  }),
  Component: CompanySocialMediaColumn,
});
