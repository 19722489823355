// ant components
import {Tooltip} from 'antd';

// layout components
import Card from '../../../layout/components/Card/Card';

// local components
import Container from './components/Container';
import DataSource from './components/DataSource';
import DataSourceLink from './components/DataSourceLink';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const EsgActiveSources = () => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const dataSources = company.esgInfo.dataSourcesData.datasource;

  return (
    <Card title={`${dataSources.length} Active Sources`}>
      <Container>
        {dataSources.map((dataSource) => {
          const content = !!dataSource?.description ? (
            <Tooltip
              title={
                <div
                  dangerouslySetInnerHTML={{__html: dataSource?.description}}
                />
              }
            >
              {dataSource.name}
            </Tooltip>
          ) : (
            dataSource.name
          );
          return !!dataSource?.dslink ? (
            <DataSourceLink
              key={dataSource.id}
              href={dataSource.dslink}
              target="_blank"
            >
              {content}
            </DataSourceLink>
          ) : (
            <DataSource key={dataSource.id}>{content}</DataSource>
          );
        })}
      </Container>
    </Card>
  );
};

export default EsgActiveSources;
