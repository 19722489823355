// ant components
import {Button} from 'antd';

// date format
import longDateFormatter from '../../../date/formatters/longDate.formatter.date';

// local components
import A4 from './components/A4';
import Container from './components/Container';
import FirstPage from './components/FirstPage';
import Logo from './components/Logo';
import Overlay from './components/Overlay';
import PrintContainer from './components/PrintContainer';
import SubTitle from './components/SubTitle';
import Title from './components/Title';

// local services
import printService from './services/print.service';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment, useRef} from 'react';

const Print = ({
  trigger,
  children,
  responsiveView = false,
  subtitle,
  title,
}) => {
  const contentRef = useRef();
  const print = () =>
    printService({dom: contentRef, responsive: responsiveView});
  return (
    <Fragment>
      {trigger({print})}
      <Container ref={contentRef}>
        {!!title && (
          <FirstPage>
            <Logo />
            <Title>{title}</Title>
            {!!subtitle && <SubTitle>{subtitle}</SubTitle>}
            <div>{longDateFormatter()}</div>
          </FirstPage>
        )}
        <A4>{children}</A4>
        <PrintContainer>
          <Overlay className="printOverlay">
            <Button type="primary" id="closePrintWindowButton">
              CLOSE
            </Button>
          </Overlay>
        </PrintContainer>
      </Container>
    </Fragment>
  );
};

Print.propTypes = {
  children: PropTypes.node.isRequired,
  responsiveView: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  trigger: PropTypes.func.isRequired,
};

export default Print;
