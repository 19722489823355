import styled from 'styled-components';

const Tabs = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`;

export default Tabs;
