// antd
import {Table} from 'antd';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// local lib
import getColumns from './lib/getColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Notifications = ({notifications}) => {
  return (
    <PageLayout>
      {({Content}) => (
        <Content title="Notifications">
          <Table
            columns={getColumns()}
            dataSource={notifications}
            pagination={false}
            rowKey="CompanyId"
          />
        </Content>
      )}
    </PageLayout>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array,
};

export default Notifications;
