// local components
import Column from '../components/Column/Column';

const generateGeneralColumn = ({
  category,
  columnIndex,
  Component = Column,
  missingValueReplacement = '—',
  props = {},
  title = null,
  ...columnProps
}) => {
  return {
    title: title || (!columnIndex ? '' : category),
    key: category,
    dataIndex: category,
    align: !columnIndex ? 'left' : 'center',
    render: (_, entry, entryIndex) => (
      <Component
        category={category}
        columnIndex={columnIndex}
        entry={entry}
        entryIndex={entryIndex}
        missingValueReplacement={missingValueReplacement}
        {...props}
      />
    ),
    ...columnProps,
  };
};

export default generateGeneralColumn;
