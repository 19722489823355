import styled from 'styled-components';

// colors
import blueGrey500Color from '../../../../layout/colors/blueGrey500.color.layout';

const Title = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: ${blueGrey500Color};
`;

export default Title;
