// supplier lib
import getSupplierLogoUrl from '../../../../supplier/lib/getLogoUrl.lib.supplier';

const extractHighRiskSuppliers = ({data}) => {
  const sortedList = Object.entries(data?.TopRiskSuppliers || {})
    .map(([company, {CompanyId, name, score, domain}]) => ({
      CompanyId,
      name: name || company,
      score,
      domain,
    }))
    .sort((a, b) => b.score - a.score)
    .slice(0, 5);

  return {
    categories: [...sortedList].map(({CompanyId}) => CompanyId),
    names: [...sortedList].reduce(
      (combined, {CompanyId, name}) => ({...combined, [CompanyId]: name}),
      {}
    ),
    logos: [...sortedList].reduce(
      (combined, {CompanyId, domain}) => ({
        ...combined,
        [CompanyId]: getSupplierLogoUrl({path: domain}),
      }),
      {}
    ),
    data: [
      {
        name: `Rating`,
        data: [...sortedList].map(({score}) => score),
      },
    ],
  };
};

export default extractHighRiskSuppliers;
