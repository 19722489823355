// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';
import green300Color from '../../../../layout/colors/green300.color.layout';
import red300Color from '../../../../layout/colors/red300.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// styled components
import styled from 'styled-components';

// helper components
const Container = styled.div`
  display: inline-block;
  color: ${({color}) => color};
  margin-left: 5px;
`;

const Growth = ({value}) => {
  const color = value.startsWith('-')
    ? red300Color
    : value === '0%'
    ? blackColor
    : green300Color;
  return <Container color={color}>{value}</Container>;
};

Growth.propTypes = {
  value: PropTypes.string,
};

export default Growth;
