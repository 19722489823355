// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Analyst Score',
  componentProps: ({company}) => ({
    children: company?.CreditPosture?.AnalystsScore || '-',
  }),
  Component: CompanyDefaultDataColumn,
});
