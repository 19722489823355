// alert api
import deleteAlertApi from '../../alert/api/delete.api.alert';

// workflow userSettings
// import workflowsUserSettings from '../userSettings/userSettings.workflow';

const deleteWorkflowRepository = async ({workflow}) => {
  await deleteAlertApi(workflow.id);
  return workflow;
  // const workflowSettings = await workflowsUserSettings.getSettings();
  // const existingWorkflows = workflowSettings?.workflows || [];
  // const workflows = [...existingWorkflows].filter(
  //   (existingWorkflow) => existingWorkflow.id !== workflow.id
  // );
  // await workflowsUserSettings.saveSettings({...workflowSettings, workflows});
  // return workflow;
};

export default deleteWorkflowRepository;
