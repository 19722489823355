// ant components
import {Col as Column, Row, Table} from 'antd';

// carbonEmissions components
import EmissionsChart from '../EmissionsChart/EmissionsChart';

// chart components
import HaloIntrinsicScoreChartCard from '../../../chart/components/HaloIntrinsicScoreChartCard/HaloIntrinsicScoreChartCard';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';
import parseCarbonEmissionsData from './lib/parseData.lib';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const CarbonEmissionsData = () => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const data = parseCarbonEmissionsData({company});
  const columns = generateColumns({
    scienceBasedTargets: data.scienceBasedTargets.categories,
    unitedNationsGlobalCompact: data.unitedNationsGlobalCompact.categories,
    emissions: data.emissions.table.categories,
  });

  return (
    <Row gutter={[20, 20]}>
      <Column span={24 / 3}>
        <HaloIntrinsicScoreChartCard
          score={data.carbonEmissionsScore}
          title="Score"
          height="320px"
        />
      </Column>
      <Column span={(24 / 3) * 2} />
      <Column span={24 / 2}>
        <StatementBlock title="Science Based Targets">
          <Table
            columns={columns.scienceBasedTargets}
            dataSource={data.scienceBasedTargets.data}
            pagination={false}
            rowKey="entry"
            size="small"
            showHeader={false}
          />
        </StatementBlock>
      </Column>
      <Column span={24 / 2}>
        <StatementBlock title="United Nations Global Compact">
          <Table
            columns={columns.unitedNationsGlobalCompact}
            dataSource={data.unitedNationsGlobalCompact.data}
            pagination={false}
            rowKey="entry"
            size="small"
            showHeader={false}
          />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Annual reported emissions by scope (kg CO2e)">
          <Table
            columns={columns.emissions}
            dataSource={data.emissions.table.entries}
            pagination={false}
            rowKey="entry"
            size="small"
          />
          <EmissionsChart
            categories={data.emissions.chart.categories}
            data={data.emissions.chart.entries}
          />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Sources">
          <Table
            columns={columns.sources}
            dataSource={data.sources}
            pagination={false}
            rowKey="url"
            size="small"
            showHeader={false}
          />
        </StatementBlock>
      </Column>
    </Row>
  );
};

CarbonEmissionsData.propTypes = {};

export default CarbonEmissionsData;
