// tag api
import updateTagApi from '../api/update.api.tag';

// tag events
import tagUpdatedEvent from '../events/updated.event.tag';

// tag validators
import updateTagValidator from '../validators/update.validator.tag';

const updateTagService = async ({
  companies,
  previousCompanyIds,
  tag,
  tagId,
  tagUpdated = tagUpdatedEvent.publish,
  updateTag = updateTagApi,
  validateTag = updateTagValidator,
}) => {
  validateTag({...tag, company_ids: companies});
  const companyIdsToAdd = [...companies].filter(
    (companyId) => !previousCompanyIds.includes(companyId)
  );
  const companyIdsToRemove = [...previousCompanyIds].filter(
    (companyId) => !companies.includes(companyId)
  );
  const formattedTag = {
    ...tag,
    company_ids_to_add: companyIdsToAdd,
    company_ids_to_remove: companyIdsToRemove,
  };
  const updatedTag = await updateTag(tagId, formattedTag);
  tagUpdated({...updatedTag, companyIdsToAdd, companyIdsToRemove});
  return updatedTag;
};

export default updateTagService;
