import styled from 'styled-components';

// colors
import green400Color from '../../../../layout/colors/green400.color.layout';

const Button = styled.a`
  border: 2px solid ${green400Color};
  background-color: white;
  color: ${green400Color};
  padding: 6px 28px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;

  &:hover {
    color: ${green400Color};
  }
`;

export default Button;
