// auth constants
import DEMO_SUBDOMAINS from '../../../../auth/constants/demoSubdomains.constant.auth.js';

// collection pages
import collectionsPage from '../../../../collection/pages/CollectionsPage/page';

// collection routes
import collectionsRoute from '../../../../collection/pages/CollectionsPage/route';

// company pages
import companiesPage from '../../../../company/pages/CompaniesPage/page';
import companyPage from '../../../../company/pages/CompanyPage/page';

// company routes
import companiesRoute from '../../../../company/pages/CompaniesPage/route';

// dashboard pages
import dashboardPage from '../../../../dashboard/pages/DashboardPage/page';

// dashboard routes
import dashboardRoute from '../../../../dashboard/pages/DashboardPage/route';

// reports pages
import securityReportPage from '../../../../reports/pages/SecurityReportPage/page';

// reports routes
import securityReportRoute from '../../../../reports/pages/SecurityReportPage/route';

// globalResilience pages
import globalResilienceIndexPage from '../../../../globalResilience/pages/GlobalResilienceIndexPage/page';

// globalResilience routes
import globalResilienceIndexRoute from '../../../../globalResilience/pages/GlobalResilienceIndexPage/route';

// resource pages
// import resourcesPage from '../../../../resource/pages/ResourcesPage/page';

// resource routes
// import resourcesRoute from '../../../../resource/pages/ResourcesPage/route';

const generatePages = ({page}) => {
  const currentSubdomain = window.location.hostname.split('.')[0];
  const isDemoSubdomain = DEMO_SUBDOMAINS.includes(currentSubdomain);
  return [
    {
      page: 'Portfolio / Collections',
      route: dashboardRoute(),
      selected: page === dashboardPage,
    },
    {
      page: 'Suppliers',
      route: companiesRoute(),
      selected: [companiesPage, companyPage].includes(page),
    },
    {
      page: 'Collections',
      route: collectionsRoute(),
      selected: [collectionsPage].includes(page),
    },
    isDemoSubdomain && {
      page: 'Reports',
      route: securityReportRoute(),
      selected: [securityReportPage].includes(page),
    },
    {
      page: 'Global Resilience Index',
      route: globalResilienceIndexRoute(),
      selected: page === globalResilienceIndexPage,
    },
    // {
    //   page: 'Resource Center',
    //   route: resourcesRoute(),
    //   selected: page === resourcesPage,
    // },
  ];
};

export default generatePages;
