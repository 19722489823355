// notification repositories
import saveAllNotificationsRepository from '../repositories/saveAll.repository.notification';

const markNotificationsAsReadService = async ({
  notifications,
  saveNotifications = saveAllNotificationsRepository,
} = {}) => {
  const updatedNotifications = [...notifications].map((notification) => ({
    ...notification,
    read: true,
  }));

  await saveNotifications({notifications: updatedNotifications});

  return updatedNotifications;
};

export default markNotificationsAsReadService;
