const generateAlertId = ({alert, supplier}) =>
  Object.entries({
    Type: alert.Type,
    PreviousValue: alert.PreviousValue,
    CurrentValue: alert.CurrentValue,
    Period: alert.Period,
    Threshold: alert.Threshold,
    Supplier: supplier.supplierId,
  })
    .map(([key, value]) => `${key}:${value}`)
    .join('|');

export default generateAlertId;
