// financial haloGpt
import assetsHaloGptPrompt from './assets.haloGpt.financial';
import assetUtilizationHaloGptPrompt from './assetUtilization.haloGpt.financial';
import capitalStructureHaloGptPrompt from './capitalStructure.haloGpt.financial';
import cashFlowHaloGptPrompt from './cashFlow.haloGpt.financial';
import growthRatesHaloGptPrompt from './growthRates.haloGpt.financial';
import incomeStatementHaloGptPrompt from './incomeStatement.haloGpt.financial';
import keyPerformanceIndicatorsHaloGptPrompt from './keyPerformanceIndicators.haloGpt.financial';
import liabilitiesHaloGptPrompt from './liabilities.haloGpt.financial';
import liquidityRatiosHaloGptPrompt from './liquidityRatios.haloGpt.financial';
import profitabilityRatiosHaloGptPrompt from './profitabilityRatios.haloGpt.financial';
import strategicRiskItemsHaloGptPrompt from './strategicRiskItems.haloGpt.financial';

const chatHaloGptPrompt = ({ data }) => ({
  welcomeMessage: 'Welcome to HaloGPT. What can I help you with?',
  model: 'ft:gpt-3.5-turbo-1106:halo-ai::8IsOhg3F',
  messages: [
    incomeStatementHaloGptPrompt({ data }),
    assetsHaloGptPrompt({ data }),
    liabilitiesHaloGptPrompt({ data }),
    cashFlowHaloGptPrompt({ data }),
    profitabilityRatiosHaloGptPrompt({ data }),
    assetUtilizationHaloGptPrompt({ data }),
    liquidityRatiosHaloGptPrompt({ data }),
    capitalStructureHaloGptPrompt({ data }),
    strategicRiskItemsHaloGptPrompt({ data }),
    growthRatesHaloGptPrompt({ data }),
    keyPerformanceIndicatorsHaloGptPrompt({ data }),
  ],
});

export default chatHaloGptPrompt;
