// esg components
import EsgActiveFlags from '../../components/EsgActiveFlags/EsgActiveFlags';
import EsgActiveSources from '../../components/EsgActiveSources/EsgActiveSources';
import EsgComparison from '../../components/EsgComparison/EsgComparison';
import EsgHistory from '../../components/EsgHistory/EsgHistory';
import EsgOverview from '../../components/EsgOverview/EsgOverview';
import EsgSourceDataValuesTable from '../../components/EsgSourceDataValuesTable/EsgSourceDataValuesTable';
import EsgSpecialIssues from '../../components/EsgSpecialIssues/EsgSpecialIssues';
import ViewSelection from '../../components/ViewSelection/ViewSelection';

// esg constants
import NORMALIZED_DATA_DESCRIPTION from '../../constants/normalizedDataDescription.constant.esg';

// layout components
import InformationTooltip from '../../../layout/components/InformationTooltip/InformationTooltip';
import {Column, Row} from '../../../layout/components/Grid/Grid';

// react
import React, {Fragment, useState} from 'react';

// redux
import {useSelector} from 'react-redux';

const NormalizedEsgSection = () => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const [percentileView, setPercentileView] = useState(false);

  return (
    <Fragment>
      <Row margin>
        <Column>
          <InformationTooltip tooltip={NORMALIZED_DATA_DESCRIPTION}>
            Based on Normalized & Aggregated Data from All Sources
          </InformationTooltip>
        </Column>
      </Row>
      <Row margin>
        <Column>
          <ViewSelection
            onSelect={(view) => setPercentileView(view)}
            percentileView={percentileView}
          />
        </Column>
      </Row>
      <Row margin>
        <Column>
          <EsgOverview percentileView={percentileView} />
        </Column>
      </Row>
      <Row margin>
        <Column>
          <ViewSelection
            onSelect={(view) => setPercentileView(view)}
            percentileView={percentileView}
          />
        </Column>
      </Row>
      <Row margin>
        <Column>
          <EsgComparison percentileView={percentileView} />
        </Column>
      </Row>
      <Row margin>
        <Column>
          <ViewSelection
            onSelect={(view) => setPercentileView(view)}
            percentileView={percentileView}
          />
        </Column>
      </Row>
      <Row margin>
        <Column>
          <EsgHistory percentileView={percentileView} />
        </Column>
      </Row>
      {!!company?.esgInfo?.specialissues?.length && (
        <Row margin>
          <Column>
            <EsgSpecialIssues />
          </Column>
        </Row>
      )}
      <Row margin>
        <Column>
          <EsgSourceDataValuesTable />
        </Column>
      </Row>
      <Row margin>
        <Column>
          <EsgActiveFlags />
        </Column>
      </Row>
      <Row>
        <Column>
          <EsgActiveSources />
        </Column>
      </Row>
    </Fragment>
  );
};

export default NormalizedEsgSection;
