// notification lib
import getNumberOfUnreadNotifications from '../../lib/getNumberOfUnreadNotifications.lib.notification';

// notification routes
import notificationsRoute from '../../pages/NotificationsPage/route';

// propTypes
import PropTypes from 'prop-types';

// redux
import {useSelector} from 'react-redux';

// router
import {useNavigate} from 'react-router-dom';

const NotificationBadgeContainer = ({children}) => {
  const navigate = useNavigate();
  const {notifications} = useSelector((state) => state.notification);

  const numberOfUnreadNotifications =
    getNumberOfUnreadNotifications(notifications);
  const notificationsUrl = notificationsRoute();

  return children({
    formattedNotificationCount:
      numberOfUnreadNotifications > 9 ? '9+' : numberOfUnreadNotifications,
    numberOfUnreadNotifications,
    onClick: () => navigate(notificationsUrl),
    url: notificationsUrl,
  });
};

NotificationBadgeContainer.propTypes = {
  children: PropTypes.func,
  notifications: PropTypes.array,
};

export default NotificationBadgeContainer;
