import styled from 'styled-components';

// layoutColors
import blackColor from '../../../../layout/colors/black.color.layout';
import brightGreen300Color from '../../../../layout/colors/brightGreen300.color.layout';
import brightRed300Color from '../../../../layout/colors/brightRed300.color.layout';

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: ${({direction}) =>
    direction === 'positive'
      ? brightGreen300Color
      : direction === 'negative'
      ? brightRed300Color
      : blackColor};

  & > i {
    font-size: 1.8rem;
    line-height: 1;
  }
`;

export default Container;
