// validator lib
import isEmail from 'validator/lib/isEmail';

// workflow lib
import isPeriodRequired from '../lib/isPeriodRequired.lib.workflow';
import isThresholdRequired from '../lib/isThresholdRequired.lib.workflow';

const createWorkflowValidator = ({workflow}) => {
  if (!workflow?.createdBy)
    throw new Error('specify the creator of the workflow');
  if (!workflow?.name) throw new Error('Please enter a name');
  if (!workflow?.trigger?.length) throw new Error('Please select a trigger');
  if (isPeriodRequired({trigger: workflow.trigger}) && !workflow?.period)
    throw new Error('Please select a period');
  if (isThresholdRequired({trigger: workflow.trigger}) && !workflow?.threshold)
    throw new Error('Please select a threshold');

  const invalidRecipients = [...(workflow?.recipients || [])].filter(
    (email) => !isEmail(email)
  );
  if (invalidRecipients.length)
    throw new Error(
      `Please correct the following email address: ${invalidRecipients.join(
        ', '
      )}`
    );
  // if (!workflow?.actions?.length) throw new Error('Please select actions');
  // if (!workflow?.recipients?.length)
  //   throw new Error('Please insert at least one recipient');
  // if (!workflow?.actionsPerCompany)
  //   throw new Error('Please select a frequency of actions per company');
};

export default createWorkflowValidator;
