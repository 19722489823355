const getElements = ({company, sourceFilter}) => {
  const dataSources = [...company.esgInfo.dataSourcesData.datasourcesall];
  const filteredDataSources = !!sourceFilter
    ? dataSources.filter((dataSource) => dataSource.name === sourceFilter)
    : dataSources;

  return filteredDataSources
    .map((dataSource) =>
      [...dataSource.elements].map((element) => ({
        ...element,
        id: `${dataSource.id}-${element.name}`,
        dataSource: dataSource.name,
      }))
    )
    .flat();
};

export default getElements;
