// tag api
import updateTagApi from '../api/update.api.tag';

// tag events
import tagDeletedEvent from '../events/deleted.event.tag';

const deleteTagService = async ({
  updateTag = updateTagApi,
  tag,
  tagDeleted = tagDeletedEvent.publish,
}) => {
  tag.is_active = false;
  const updatedTag = await updateTag(tag.id, tag);
  tagDeleted(updatedTag);
  return updatedTag;
};

export default deleteTagService;
