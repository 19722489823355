// collection api
import createCollectionApi from '../api/create.api.collection';

// collection events
import collectionCreatedEvent from '../events/created.event.collection';

// collection validators
import createCollectionValidator from '../validators/create.validator.collection';

const createCollectionService = async ({
  createCollection = createCollectionApi,
  collection,
  collectionCreated = collectionCreatedEvent.publish,
  validateCollection = createCollectionValidator,
}) => {
  const formattedCollection = {
    ...collection,
  };
  validateCollection(formattedCollection);
  const createdCollection = await createCollection(formattedCollection);
  collectionCreated(createdCollection);
  return createdCollection;
};

export default createCollectionService;
