const extractUniqueObjectEntries = ({array, identifier}) =>
  [...array].reduce(
    (combined, current) =>
      combined.identifiers.includes(current?.[identifier])
        ? combined
        : {
            list: [...combined.list, current],
            identifiers: [...combined.identifiers, current?.[identifier]],
          },
    {list: [], identifiers: []}
  ).list;

export default extractUniqueObjectEntries;
