// ant components
import {
  Modal,
  // Tag
} from 'antd';

// local components
import Content from './components/Content';
// import DataRow from './components/DataRow';
// import DataSource from './components/DataSource';
import Description from './components/Description';
import Header from './components/Header';
// import TagContainer from './components/TagContainer';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// tag constants
// import TAG_BACKGROUND_COLOR from '../../constants/backgroundColor.constant.tag';
const TagDetailModal = ({visible, tag, onClose, onEdit}) => {
  const description = (tag?.title ? (tag?.title + '\n\n') : '') + (tag?.subtitle ? (tag?.subtitle + '\n\n') : '') + (tag?.description ? (tag?.description + '\n\n') : '');
  return (
    <Modal
      title={
        <Header>
          <Title>{tag?.name || '-'}</Title>
          {/* <TagContainer>
            <Tag color={TAG_BACKGROUND_COLOR}>{tag?.tag}</Tag>
          </TagContainer> */}
        </Header>
      }
      centered
      open={visible}
      onOk={onEdit}
      okText="Edit"
      onCancel={onClose}
      width={'800px'}
    >
      {!!tag && (
        <Content>
          {!!description && (
            <Description dangerouslySetInnerHTML={{__html: description}} />
          )}
          {/* <DataRow>
            <DataSource>Date</DataSource> {tag.date}
          </DataRow> */}
        </Content>
      )}
    </Modal>
  )
};

TagDetailModal.propTypes = {
  tag: PropTypes.object,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default TagDetailModal;
