const flattenQuestionFlowMessages = ({
  messages,
  flatObject = {},
  parents = [],
}) =>
  [...messages].reduce((combined, message) => {
    const combinedFlatMessages = !!message?.messages?.length
      ? flattenQuestionFlowMessages({
          messages: message.messages,
          flatObject: combined,
          parents: [...parents, message],
        })
      : combined;
    return {
      ...combinedFlatMessages,
      [message.id]: {
        ...message,
        parents,
      },
    };
  }, flatObject);

export default flattenQuestionFlowMessages;
