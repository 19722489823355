import styled from 'styled-components';

const AlertsDropDown = styled.div`
  width: 400px;
  height: calc(100vh - 122px);
  position: fixed;
  top: 122px;
  right: 0px;
  padding: 10px;
`;

export default AlertsDropDown;
