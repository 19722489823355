import {css} from 'styled-components';
import $ from 'jquery';

export const breakingpoints = {
  l: 1500,
  m: 1200,
  s: 768,
};

export const isDeviceSize = (size) =>
  !!breakingpoints[size] && $(window).width() <= breakingpoints[size];

export const media = Object.keys(breakingpoints).reduce(
  (combined, point) => ({
    ...combined,
    [point]: (...args) => css`
      @media (max-width: ${breakingpoints[point]}px) {
        ${css(...args)};
      }
    `,
  }),
  {}
);
