import styled from 'styled-components';

const Container = styled.div`
  width: 60px;
  max-width: 60px;
  min-width: 60px;
  height: 60px;
  max-height: 60px;
  min-height: 60px;
  cursor: pointer;
  user-select: none;
  display: inline-block;
`;

export default Container;
