import styled from 'styled-components';

const A4 = styled.div`
  width: 100%;
  max-width: 210mm;
  padding: 0mm 10mm;
  margin: auto;
`;

export default A4;
