// dashboard containers
import DashboardContainer from '../../containers/DashboardContainer/DashboardContainer';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local page
import page from './page';

// react
import React from 'react';

const DashboardPage = () => (
  <Page page={page}>
    <DashboardContainer />
  </Page>
);

export default DashboardPage;
