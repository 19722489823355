import styled from 'styled-components';

// layout colors
import green200Color from '../../../../layout/colors/green200.color.layout';

const Highlight = styled.span`
  background: ${green200Color};
`;

export default Highlight;
