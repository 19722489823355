// alert events
import showCreditSafeAlertsEvent from '../../../alert/events/showCreditSafeAlerts.event.alert';

// company components
import CompanySideMenu from '../../components/CompanySideMenu/CompanySideMenu';

// layout colors
import blackColor from '../../../layout/colors/black.color.layout';
import violet400Color from '../../../layout/colors/violet400.color.layout';

// local lib
import colorFormat from '../../../layout/lib/formatHexColor.lib.layout';

// number
import integer from '../../../number/lib/integer.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

// tag events
import shoeCreateTagModalEvent from '../../../tag/events/showCreateModal.event.tag';
import showTagDetailModalEvent from '../../../tag/events/showDetailModal.event.tag';
import showBlackKiteTagDetailModalEvent from '../../../tag/events/showBlackKiteDetailModal.event.tag';

// tag permissions
import canManageTags from '../../../tag/permissions/canManage.permission.tag';

// tag lib
import orderTagsByName from '../../../tag/lib/orderTagsByName.lib.tag';

const CompanySideMenuContainer = ({goToSection}) => {
  const {company} = useSelector((state) => state.company);

  return (
    <CompanySideMenu
      intrinsicScore={integer(company?.HaloScores?.HaloScore?.Value || 0)}
      logo={company.logo}
      name={company.CompanyName}
      // stockExchange="NYSE: ZUO"
      tags={[
        !!company?.creditSafeAlerts?.length && {
          color: violet400Color,
          key: 'creditSafeAlerts',
          label: 'Financial Alerts',
          onClick: () => showCreditSafeAlertsEvent.publish({company}),
        },
        ...[
          ...(company?.Tags || []),
          ...(company?.customTags || []),
          ...(company?.CustomTags || []),
        ]
          .map((tag) => ({
            color:
              colorFormat(tag?.BackgroundColor || tag?.Color) || blackColor,
            key: `${tag.TagId}`,
            label: tag.TagName,
            onClick: () => showBlackKiteTagDetailModalEvent.publish({tag}),
          }))
          .sort((a, b) => (a.color > b.color ? 1 : a.color < b.color ? -1 : 0)),
        ...orderTagsByName({tags: company?.tags || []}).map((tag) => ({
          color: tag?.json?.background_color?.startsWith('#')
            ? tag.json.background_color
            : tag.json.background_color
            ? `#${tag.json.background_color}`
            : blackColor,
          key: tag.id,
          label: tag.name,
          onClick: () => showTagDetailModalEvent.publish({tag}),
        })),
        canManageTags() && {
          color: 'rgba(255, 255, 255, 0.14)',
          key: 'createTag',
          label: '+ Create Custom Tag',
          onClick: () => shoeCreateTagModalEvent.publish({supplier: company}),
        },
      ].filter((tag) => !!tag)}
    />
  );
};

CompanySideMenuContainer.propTypes = {
  goToSection: PropTypes.func,
};

export default CompanySideMenuContainer;
