// local columns
import industryColumn from '../columns/industry.column';
// import marketCapColumn from '../columns/marketCap.column';
import nameColumn from '../columns/name.column';
import relationshipTypeColumn from '../columns/relationshipType.column';
import sourceColumn from '../columns/source.column';
import stateColumn from '../columns/state.column';

const getSupplierColumn = ({withState = false} = {}) =>
  [
    nameColumn,
    industryColumn,
    sourceColumn,
    relationshipTypeColumn,
    // marketCapColumn,
    withState && stateColumn,
  ].filter((column) => !!column);

export default getSupplierColumn;
