// lib
import generateKeyFromLabel from '../../lib/generateKeyFromLabel.lib';

const generateColumnGroup = ({
  key,
  name,
  columns = [],
  preColumns = [],
  postColumns = [],
  dynamicColumns = () => [],
  size = null,
}) => ({
  key: key || generateKeyFromLabel(name),
  name,
  columns: [...preColumns, ...columns, ...postColumns],
  dynamicColumns,
  size,
});

export default generateColumnGroup;
