// charts
import Highcharts from 'highcharts';

// layout components
import Card from '../../../layout/components/Card/Card';

// number lib
import integer from '../../../number/lib/integer.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const RiskAreaBreakdownChartCard = ({
  data = [],
  height = '360px',
  info,
  title = 'Key Risk Area Ratings',
  xTitle = 'Risk Factors',
  yTitle = 'Halo Ratings',
}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'column',
        height,
      },
      title: {
        text: title,
      },
      xAxis: {
        type: 'category',
        title: {
          text: xTitle,
        },
      },
      yAxis: {
        max: 130,
        title: {
          text: yTitle,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '16px',
              fontWeight: 700,
              zIndex: 1,
            },
            useHTML: true,
            formatter: function () {
              if (!!this?.point?.options?.color) {
                return `<span style="color: ${this?.point?.options?.color};">${this?.point?.options?.grade}</span>`;
              }
              return `<span>${
                this?.point?.options?.grade || integer(this.point.y)
              }</span>`;
            },
          },
        },
      },
      tooltip: {
        useHTML: true,
        outside: true,
        formatter: function () {
          return `<div><b>${this.point.name}</b><br/>${integer(
            this.point.y
          )}<div>${this?.point?.options?.description || ''}</div></div>`;
        },
        style: {
          zIndex: 10000,
        },
      },

      series: [
        {
          name: 'Back',
          colorByPoint: true,
          data,
        },
      ],

      accessibility: {
        enabled: false,
      },
    });
    // eslint-disable-next-line
  }, [data]);
  return (
    <Card infoTooltip={info} noPadding>
      <div id={id} />
    </Card>
  );
};

RiskAreaBreakdownChartCard.propTypes = {
  data: PropTypes.array,
  height: PropTypes.string,
  info: PropTypes.node,
  title: PropTypes.string,
  xTitle: PropTypes.string,
  yTitle: PropTypes.string,
};

export default RiskAreaBreakdownChartCard;
