// esg components
import EsgComparisonChartCard from '../EsgComparisonChartCard/EsgComparisonChartCard';

// local lib
import extractData from './lib/extractData.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const EsgComparison = ({percentileView}) => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  return <EsgComparisonChartCard {...extractData({company, percentileView})} />;
};

EsgComparison.propTypes = {
  percentileView: PropTypes.bool,
};

export default EsgComparison;
