// financial lib
import getFiscalYear from '../../../../financial/lib/getFiscalYear.lib.financial';

// local constants
import PERCENTAGE_ROWS from '../constants/percentageRows.constant';

// local lib
import convertToPercentage from './convertToPercentage.lib';
import generateColumn from './generateColumn.lib';

// table lib
import checkAndRemoveLTM from '../../../../table/lib/checkAndRemoveLTM.lib.table';
import removeEmptyTableColumns from '../../../../table/lib/removeEmptyColumns.lib.table';

const extractKeyDataAndRatios = ({company}) => {
  if (!company?.historicalFinancials?.keyDataAndRatios)
    return {categories: [], entries: [], currency: null};
  // const currency = company?.historicalFinancials?.currency;
  const categories = [
    'section',
    ...company.historicalFinancials.keyDataAndRatios.periods,
  ];

  return removeEmptyTableColumns(
    checkAndRemoveLTM({
      categories: [...categories].map((category, index) =>
        generateColumn({
          title: !index ? `USD Millions` : null,
          category,
          columnIndex: index,
          fiscalYear: getFiscalYear({
            years: company?.historicalFinancials?.fiscalYears?.fiscalYear,
            category,
          }),
        })
      ),
      entries: [
        ...Object.entries(company.historicalFinancials.keyDataAndRatios.data),
      ].map(([label, values]) => {
        return {
          key: label,
          section: label,
          ...[...values].reduce((combined, current, index) => {
            const category = categories?.[index + 1];
            return !!category
              ? {
                  ...combined,
                  [category]: PERCENTAGE_ROWS.includes(label)
                    ? convertToPercentage(current)
                    : current,
                }
              : combined;
          }, {}),
        };
      }),
    })
  );
};

export default extractKeyDataAndRatios;
