import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';

const Label = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${blackColor};
`;

export default Label;
