import styled from 'styled-components';

// layout components
import Logo from '../../../../layout/components/Logo/Logo';

const LogoContainer = styled(Logo)`
  width: 200px;
`;

export default LogoContainer;
