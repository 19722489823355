// lib
import isObject from 'is-object';

const parseHistoricalFinancials = (historicalFinancials) =>
  isObject(historicalFinancials)
    ? Object.entries(historicalFinancials).reduce(
        (combined, [key, value]) =>
          !['fiscalYears'].includes(key) &&
          isObject(value) &&
          !!value?.data &&
          !!value?.periods
            ? !!value.periods.length &&
              Object.values(value.data).some((data) => !!data?.length)
              ? {...combined, [key]: value}
              : combined
            : {...combined, [key]: value},
        {}
      )
    : historicalFinancials;

export default parseHistoricalFinancials;
