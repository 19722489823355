// array lib
import extractUniqueObjectEntries from '../../../../array/lib/extractUniqueObjectEntries.lib.array';

// countries
import {countryToAlpha2} from 'country-to-iso';

const extractMapData = ({company, globalResilienceIndexes}) => {
  const keySuppliers = Object.entries(
    (company?.KeySuppliers || []).reduce((combined, current) => {
      const country = current.country || null;
      if (!country) return combined;
      const countryData = combined?.[country] || [];
      const updatedCountryData = [
        ...countryData,
        {
          id: `${current.companyName}${current.primaryIndustry}${current.source}`,
          name: current.companyName,
          value: current.marketCap || 1,
          industry: current.primaryIndustry,
          relationshipType: current.relationshipType,
          source: current.source,
          marketCap: current?.marketCap || null,
          displayMarketCap: !!current.marketCap,
        },
      ];
      return {...combined, [country]: updatedCountryData};
    }, {})
  ).map(([country, data]) => ({name: country, data, value: data.length}));
  const {worldData, usaData} = [...keySuppliers].reduce(
    (combined, country) => {
      const key = !!countryToAlpha2(country.name) ? 'worldData' : 'usaData';
      return {...combined, [key]: [...(combined?.[key] || []), country]};
    },
    {worldData: [], usaData: []}
  );

  const usaStateData = extractUniqueObjectEntries({
    array: [...usaData]
      .map(({name, data}) =>
        [...data].map((dataObject) => ({
          ...dataObject,
          state: name,
          id: `${dataObject.id}${name}`,
        }))
      )
      .flat(),
    identifier: 'id',
  });

  const usaCountry = {
    data: usaStateData,
    name: 'United States of America',
    stateBreakdown: true,
    states: usaData,
    value: usaStateData.length,
  };
  const worldDataWithUsa = (
    !!usaData.length ? [...worldData, usaCountry] : worldData
  ).map((country) => ({
    ...country,
    globalResilienceIndex:
      globalResilienceIndexes.find(({Country}) => Country === country.name) ||
      null,
  }));
  return worldDataWithUsa;
};

export default extractMapData;
