// collection api
import getCollectionCompaniesApi from '../../api/getCompanies.api.collection';

// error lib
import parseError from '../../../error/lib/parse.lib.error';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// ant components
import {message as messageApi} from 'antd';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// collection components
import CollectionEditorModal from '../../components/CollectionEditorModal/CollectionEditorModal';

// collection events
import showUpdateModalEvent from '../../events/showUpdateModal.event.collection';

// collection services
import deleteCollectionService from '../../services/delete.service.collection';
import updateCollectionService from '../../services/update.service.collection';

class UpdateCollectionContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    companies: [],
    loading: false,
    name: '',
    collection: null,
    collectionId: null,
    previousCompanyIds: [],
    saving: false,
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showUpdateModalEvent.subscribe(this.show));
  }

  show = async ({collection}) => {
    this.setState({
      ...this.constructor.DEFAULT_STATE,
      visible: true,
      loading: true,
    });

    try {
      const {data: companies} = await getCollectionCompaniesApi(collection.id);
      const companyIds = [...companies].map(({id}) =>
        id.replace('company_', '')
      );
      this.setState({
        collection: {...collection},
        collectionId: collection.id,
        companies: [...companyIds],
        loading: false,
        name: collection.name,
        previousCompanyIds: [...companyIds],
      });
    } catch (error) {
      messageApi.error('Could not load collection details');
      this.setState({visible: false, loading: false});
    }
  };

  hide = () => {
    const {loading, saving} = this.state;
    if (loading || saving) return;
    this.setState({visible: false});
  };

  change = (key) => (e) => {
    const {loading, saving} = this.state;
    if (loading || saving) return;
    const value = !!e?.target ? e.target.value : e;
    this.setState({[key]: value});
  };

  save = async () => {
    const {companies, loading, name, collectionId, previousCompanyIds, saving} =
      this.state;

    if (loading || saving) return;

    this.setState({saving: true});

    try {
      await updateCollectionService({
        collection: {
          name,
        },
        companyIds: [...companies],
        previousCompanyIds,
        companies,
        collectionId,
      });
      this.setState({saving: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      messageApi.error(message);
      this.setState({saving: false});
    }
  };

  changeSelection = (key) => (value) => {
    const previousValues = [...this.state[key]];
    const updateValues = previousValues.includes(value)
      ? previousValues.filter((v) => v !== value)
      : [...previousValues, value];
    this.setState({[key]: updateValues});
  };

  delete = async () => {
    const {loading, collection} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      await deleteCollectionService({collection});
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      messageApi.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {companies, loading, name, saving, visible} = this.state;
    return (
      <CollectionEditorModal
        companies={companies}
        loading={loading || saving}
        name={name}
        onChange={this.change}
        onChangeSelection={this.changeSelection}
        onClose={this.hide}
        onDelete={this.delete}
        onSave={this.save}
        saving={saving}
        title="Update Collection"
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(UpdateCollectionContainer);
