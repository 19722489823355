// charts
import Highcharts from 'highcharts';

// layout components
import Card from '../../../layout/components/Card/Card';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const ViabilityChartCard = ({
  categories = [],
  data = [],
  viabilityScore = 9,
}) => {
  const id = v4();

  const plotBandValue = categories.length - viabilityScore;

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: 'Viability Chart',
      },
      xAxis: [
        {
          categories,
          crosshair: true,
          plotBands: [
            {
              from: plotBandValue - 0.5,
              to: plotBandValue + 0.5,
              // color: '#EEFFF4',
            },
          ],
          title: {
            text: 'Viability score',
          },
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value}%',
          },
          title: {
            text: 'Percent of Total',
          },
          opposite: true,
        },
        {
          // Secondary yAxis
          gridLineWidth: 0,
          title: {
            text: 'Out of Business (Bad) Rate',
          },
          labels: {
            // eslint-disable-next-line
            format: '{value}%',
          },
        },
      ],
      tooltip: {
        shared: true,
      },
      series: data,
    });
    // eslint-disable-next-line
  }, [categories, data, plotBandValue]);
  return (
    <Card noPadding>
      <div id={id} />
    </Card>
  );
};

ViabilityChartCard.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.array,
  viabilityScore: PropTypes.number,
};

export default ViabilityChartCard;
