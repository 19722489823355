import {createSlice} from '@reduxjs/toolkit';

const dimensionsSlice = createSlice({
  name: 'dimensions',
  initialState: {
    dimensions: {},
  },
  reducers: {
    updateDimensions(state, action) {
      const dimensions = action.payload;
      state.dimensions = dimensions;
    },
  },
});

export const {updateDimensions} = dimensionsSlice.actions;

export default dimensionsSlice.reducer;
