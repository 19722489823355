// charts
import Highcharts from 'highcharts';

// layout colors
import blueGrey200Color from '../../../layout/colors/blueGrey200.color.layout';

// layout components
import Card from '../../../layout/components/Card/Card';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// risk lib
import getGradeColor from '../../../risk/lib/getGradeColor.lib.risk';

// uuid
import {v4} from 'uuid';

const InnovationConsolidatedChartCard = ({categories = [], data = []}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'column',
      },
      colors: [blueGrey200Color],

      xAxis: {
        categories,
      },

      yAxis: {
        title: {
          enabled: false,
        },
        crosshair: true,
      },

      legend: {
        enabled: false,
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '16px',
              fontWeight: 700,
            },
            useHTML: true,
            formatter: function () {
              const gradeColor = getGradeColor(this?.point?.options?.grade);
              return `<span style="color: ${gradeColor};">${this?.point?.options?.grade}</span>`;
            },
          },
        },
      },

      tooltip: {
        useHTML: true,
        pointFormat: '{point.y:,.0f}',
        outside: true,
        style: {
          zIndex: 10000,
        },
      },

      title: {
        text: '24 Months View - Projected',
      },
      series: data,
    });
    // eslint-disable-next-line
  }, [categories, data]);
  return (
    <Card noPadding>
      <div id={id} />
    </Card>
  );
};

InnovationConsolidatedChartCard.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.array,
};

export default InnovationConsolidatedChartCard;
