// local lib
import generateColumn from './generateColumn.lib';

const getRevenueColumns = () => [
  generateColumn({
    category: 'estimatedRevenueRange',
    title: 'Estimated Revenue Range',
  }),
  generateColumn({category: 'revenue', title: 'Revenue (in millions USD)'}),
  generateColumn({
    category: 'revenueGrowthPercentage',
    title: 'Revenue Growth Percentage',
  }),
  generateColumn({
    category: 'growthRatePercentile',
    title: 'Growth Rate Percentile',
  }),
];

export default getRevenueColumns;
