import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

// constants
const SIZE = '50%';

const MatrixQuadrant = styled.div`
  width: ${SIZE};
  min-width: ${SIZE};
  max-width: ${SIZE};
  height: ${SIZE};
  min-height: ${SIZE};
  max-height: ${SIZE};
  padding: 5px;
  background: ${({color}) => color || blackColor};
  color: ${whiteColor};
  text-align: center;
  font-size: 12px;
  position: relative;
`;

export default MatrixQuadrant;
