// local components
import Description from '../components/Description';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Column = ({field, entry, entryIndex}) => {
  const value = entry[field];
  return field === 'description' ? (
    <Description>{value}</Description>
  ) : (
    <span>{value}</span>
  );
};

Column.propTypes = {
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default Column;
