// local components
import Container from './components/Containers';

// number lib
import integer from '../../../number/lib/integer.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// risk lib
import convertScoreToGrade from '../../../risk/lib/convertScoreToGrade.lib.risk';
import getGradeColor from '../../../risk/lib/getGradeColor.lib.risk';

const HighlightedGrade = ({grade, score, showScore = false}) => {
  const actualGrade = grade || convertScoreToGrade(score);
  const color = getGradeColor(actualGrade);

  return !grade && !score ? (
    'Insufficient Data'
  ) : (
    <Container color={color}>
      {showScore ? `${integer(score)} / ${actualGrade}` : actualGrade}
    </Container>
  );
};

HighlightedGrade.propTypes = {
  grade: PropTypes.string,
  score: PropTypes.number,
  showScore: PropTypes.bool,
};

export default HighlightedGrade;
