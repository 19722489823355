// ant components
import {Modal, Tag} from 'antd';

// local components
import Content from './components/Content';
import DataRow from './components/DataRow';
import DataSource from './components/DataSource';
import Description from './components/Description';
import Header from './components/Header';
import SubTitle from './components/SubTitle';
import TagContainer from './components/TagContainer';
import Title from './components/Title';

// layout colors
import blackColor from '../../../layout/colors/black.color.layout';

// layout lib
import formatHexColor from '../../../layout/lib/formatHexColor.lib.layout';

// lib
import generateLabelFromKeys from '../../../lib/generateLabelFromKeys.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const BlackKiteTagDetailModal = ({visible, tag, onClose}) => (
  <Modal
    title={
      <Header>
        <Title>{tag?.Title || '-'}</Title>
        <TagContainer>
          <Tag color={formatHexColor(tag?.BackgroundColor) || blackColor}>
            {tag?.TagName}
          </Tag>
        </TagContainer>
        {!!tag?.Subtitle && (
          <SubTitle dangerouslySetInnerHTML={{__html: tag.Subtitle}} />
        )}
      </Header>
    }
    centered
    open={visible}
    onOk={onClose}
    onCancel={onClose}
    width={'800px'}
  >
    {!!tag && (
      <Content>
        {!!tag.ContentHeader && (
          <Description dangerouslySetInnerHTML={{__html: tag.ContentHeader}} />
        )}
        {(tag?.Content || []).map((dataRow) => (
          <DataRow key={dataRow.Name}>
            <DataSource>
              {generateLabelFromKeys(dataRow.Name).split('_').join(' ')}:
            </DataSource>{' '}
            {dataRow.Value}
          </DataRow>
        ))}
      </Content>
    )}
  </Modal>
);

BlackKiteTagDetailModal.propTypes = {
  tag: PropTypes.object,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default BlackKiteTagDetailModal;
