// local components
import Container from './components/Container';

// number lib
import currency from '../../../number/lib/currency.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FormattedNumber = ({number, empty = '-', prefix = '', affix = ''}) => {
  const numericNumber = Number(number) || 0;
  const formattedNumber = currency(Math.abs(numericNumber));
  return (
    <Container negative={number < 0}>
      {[undefined, null, 0].includes(number)
        ? empty
        : numericNumber < 0
        ? `${prefix}(${formattedNumber})${affix}`
        : `${prefix}${formattedNumber}${affix}`}
    </Container>
  );
};

FormattedNumber.propTypes = {
  affix: PropTypes.string,
  empty: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  prefix: PropTypes.string,
};

export default FormattedNumber;
