// chart components
import EsgDistributionChartCard from '../../../chart/components/EsgDistributionChartCard/EsgDistributionChartCard';

// layout components
import {Column, Row} from '../../../layout/components/Grid/Grid';

// local lib
import extractEsgData from './lib/extractEsgData.lib';
import renderEsgSection from './lib/renderEsgSection.lib';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const CompanyEsgDistributionSection = () => {
  const {company} = useSelector((state) => state.company);

  return (
    renderEsgSection({company}) && (
      <Fragment>
        <Row>
          <Column>
            <EsgDistributionChartCard {...extractEsgData({company})} />
          </Column>
        </Row>
      </Fragment>
    )
  );
};

export default CompanyEsgDistributionSection;
