// ant
import {Tooltip} from 'antd';

// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const InformationTooltip = ({children, tooltip}) => (
  <Tooltip title={tooltip}>
    <Container>
      <i className="mdi mdi-information" />
      {children}
    </Container>
  </Tooltip>
);

InformationTooltip.propTypes = {
  children: PropTypes.node,
  tooltip: PropTypes.node,
};

export default InformationTooltip;
