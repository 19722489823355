import styled from 'styled-components';

const LabelX = styled.div`
  position: absolute;
  bottom: 0px;
  left: calc(50% + 10px);
  transform: translateX(-50%);
  font-weight: 500;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

export default LabelX;
