import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';

// router
import {Link} from 'react-router-dom';

const Tab = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  color: ${blackColor};
`;

export default Tab;
