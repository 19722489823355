// ant components
import {Button} from 'antd';

// haloGpt events
import answerHaloGptQueryEvent from '../../events/answerQuery.event.haloGpt';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class AnswerWithHaloGptContainer extends Component {
  static propTypes = {
    message: PropTypes.string,
    queries: PropTypes.array,
    scope: PropTypes.string,
    text: PropTypes.node,
  };

  static defaultProps = {
    text: 'Explain',
  };

  requestAnswer = () => {
    const {message, queries, scope} = this.props;
    answerHaloGptQueryEvent.publish({query: {message, queries}, scope});
  };

  render() {
    const {text} = this.props;
    return (
      <Button
        onClick={this.requestAnswer}
        shape="round"
        size="small"
        type="default"
      >
        {text}
      </Button>
    );
  }
}

export default AnswerWithHaloGptContainer;
