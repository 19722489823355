// ant components
import {Tooltip} from 'antd';

// local components
import Container from './components/Container';
import Content from './components/Content';
import Info from './components/Info';
import Title from './components/Title';
import Underline from './components/Underline';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Card = ({
  children,
  indentTitle,
  infoTooltip,
  noPadding,
  noPrintBreak = false,
  resetInfoTooltipPosition = false,
  title,
  titleTooltip,
  underline,
}) => (
  <Container noPadding={noPadding} noPrintBreak={noPrintBreak}>
    {!!title && (
      <Title indent={indentTitle}>
        {!!titleTooltip ? (
          <Tooltip title={titleTooltip}>
            {title} <i className="mdi mdi-information" />
          </Tooltip>
        ) : (
          title
        )}
        {!!underline && <Underline color={underline} />}
      </Title>
    )}
    <Content>{children}</Content>
    {!!infoTooltip && (
      <Info
        noPadding={noPadding}
        resetInfoTooltipPosition={resetInfoTooltipPosition}
      >
        <Tooltip title={infoTooltip}>
          <i className="mdi mdi-information" />
        </Tooltip>
      </Info>
    )}
  </Container>
);

Card.propTypes = {
  children: PropTypes.node,
  indentTitle: PropTypes.bool,
  infoTooltip: PropTypes.node,
  noPadding: PropTypes.bool,
  noPrintBreak: PropTypes.bool,
  resetInfoTooltipPosition: PropTypes.bool,
  title: PropTypes.node,
  titleTooltip: PropTypes.node,
  underline: PropTypes.string,
};

export default Card;
