// company lib
import generateCompanyFilter from '../lib/generateFilter.lib.company';

// risk constants
import GRADES from '../../risk/constants/grades.constant.risk';

// risk lib
import convertScoreToGrade from '../../risk/lib/convertScoreToGrade.lib.risk';

export default generateCompanyFilter({
  label: 'Financial Metrics Rating',
  options: Object.keys(GRADES).map((grade) => ({
    value: grade,
    label: grade,
  })),
  fn: ({entry, filterValue}) =>
    (filterValue || []).includes(
      convertScoreToGrade(Number(entry?.Financials?.HaloFinancialFactorRating))
    ),
  props: {
    mode: 'multiple',
  },
});
