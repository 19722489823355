// auth redux reducer
import {refreshAuthToken as refreshAuthTokenAction} from '../../auth/redux/reducer.redux.auth';

// auth stores
import authStore from '../../auth/stores/store.auth.js';

// aws
import {Auth} from 'aws-amplify';

// aws lib
import extractAwsTokenData from '../../aws/lib/extractTokenData.lib.aws';

// redux store
import store from '../../redux/store';

const refreshAwsTokenService = async ({
  auth = Auth,
  dispatch = store.dispatch,
  refreshAuthToken = refreshAuthTokenAction,
} = {}) => {
  try {
    const userData = await auth.currentSession();
    const token = extractAwsTokenData(userData);
    authStore.setData({token});
    dispatch(refreshAuthToken({token}));
    return token;
  } catch (error) {
    return null;
  }
};

export default refreshAwsTokenService;
