import styled from 'styled-components';

// colors
import blueGrey400Color from '../../../../layout/colors/blueGrey400.color.layout';

const Info = styled.div`
  position: absolute;
  top: ${({noPadding}) => `${(noPadding ? 0 : 40) + 9}px`};
  right: ${({noPadding, resetInfoTooltipPosition}) =>
    `${(noPadding || resetInfoTooltipPosition ? 0 : 40) + 40}px`};
  font-size: 17px;
  color: ${blueGrey400Color};
`;

export default Info;
