const DEFAULT_DASHBOARD_REDUX_STATE = {
  collectionScores: null,
  tenantData: null,
  data: null,
  loading: true,
  alerts: [],
  globalResilienceIndexes: [],
};

export default DEFAULT_DASHBOARD_REDUX_STATE;
