import styled from 'styled-components';

// layout colors
import grey400Color from '../../../../layout/colors/grey400.color.layout';
import grey800Color from '../../../../layout/colors/grey800.color.layout';

const Container = styled.div`
  display: inline-block;
  padding: 6px 12px;
  padding-left: 36px;
  position: relative;
  border-radius: 10px;
  color: ${grey800Color};
  background: ${grey400Color};
  font-size: 14px;
  ${
    '' /* background: linear-gradient(135.12deg, #153443 16.73%, #63d57e 209.5%); */
  }
  position: relative;
  overflow: hidden;

  & > i {
    display: block;
    position: absolute;
    top: -12px;
    left: -4px;
    transform: rotate(25deg);
    font-size: 30px;
    opacity: 0.2;
  }
`;

export default Container;
