// ant components
import {Modal} from 'antd';

// local components
import Categories from './components/Categories';
import Category from './components/Category';
import Container from './components/Container';
import Content from './components/Content';
import ContentRow from './components/ContentRow';
import Entities from './components/Entities';
import Image from './components/Image';
import MetaSection from './components/MetaSection';
import Paragraph from './components/Paragraph';
import Section from './components/Section';
import SectionTitle from './components/SectionTitle';

// news components
import NewsAttributes from '../NewsAttributes/NewsAttributes';
import NewsEntity from '../NewsEntity/NewsEntity';

// news lib
import getNewsBody from '../../lib/getBody.lib.news';
import getNewsCategories from '../../lib/getCategories.lib.news';
import getNewsGroupedEntities from '../../lib/getGroupedEntities.lib.news';
import getNewsImage from '../../lib/getImage.lib.news';
import getNewsTitle from '../../lib/getTitle.lib.news';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const NewsArticleModal = ({newsArticle, onClose, visible}) => {
  const categories = getNewsCategories({newsArticle});
  const image = getNewsImage({newsArticle});
  const groupedEntities = getNewsGroupedEntities({newsArticle});

  return (
    <Modal
      title={getNewsTitle({newsArticle})}
      centered
      open={visible}
      onOk={onClose}
      onCancel={onClose}
      width={'1200px'}
    >
      {!!newsArticle && (
        <Container>
          <NewsAttributes newsArticle={newsArticle} />
          <ContentRow>
            <Content>
              {getNewsBody({newsArticle}).map((paragraph) => (
                <Paragraph key={paragraph}>{paragraph}</Paragraph>
              ))}
            </Content>
            <MetaSection>
              {!!image && <Image src={image} />}
              {groupedEntities.map(({type, entities}) => (
                <Section key={type}>
                  <SectionTitle>{type}</SectionTitle>
                  <Entities>
                    {entities.map((entity) => (
                      <NewsEntity key={entity.id} entity={entity} />
                    ))}
                  </Entities>
                </Section>
              ))}
              {!!categories.length && (
                <Section>
                  <SectionTitle>Categories</SectionTitle>
                  <Categories>
                    {categories.map((category) => (
                      <Category key={category.id}>{category.label}</Category>
                    ))}
                  </Categories>
                </Section>
              )}
            </MetaSection>
          </ContentRow>
        </Container>
      )}
    </Modal>
  );
};

NewsArticleModal.propTypes = {
  newsArticle: PropTypes.object,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default NewsArticleModal;
