// tag api
import createTagApi from '../api/create.api.tag';

// tag events
import tagCreatedEvent from '../events/created.event.tag';

// tag validators
import createTagValidator from '../validators/create.validator.tag';

const createTagService = async ({
  createTag = createTagApi,
  supplier,
  tag,
  tagCreated = tagCreatedEvent.publish,
  validateTag = createTagValidator,
}) => {
  const formattedTag = {
    ...tag,
  };
  validateTag(formattedTag);
  const createdTag = await createTag(formattedTag);
  tagCreated(createdTag);
  return createdTag;
};

export default createTagService;
