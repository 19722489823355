// css
import getExternalCSS from '../../../lib/getExternalCss.lib.print';

const print = ({dom, responsive}) => {
  const content = dom.current.innerHTML;
  const head = [
    '<meta charset="utf-8" />',
    responsive &&
      '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, minimum-scale=1, viewport-fit=cover" />',
    '<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap" />',
    '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@7.1.96/css/materialdesignicons.min.css" />',
  ]
    .filter((headLink) => !!headLink)
    .join('');
  const css = getExternalCSS();
  const html = `<html><head>${head}<style>${css}</style></head><body>${content}</body></html>`;
  const a = window.open('', '_blank');
  a.document.write(html);
  a.document.getElementById('closePrintWindowButton').addEventListener(
    'click',
    () => {
      a.close();
    },
    false
  );
  a.document.close();
  a.print();
  const close = () => {
    a.close();
  };
  a.onafterprint = close;
};

export default print;
