// company components
import DueDiligenceLayout from '../../components/DueDiligenceLayout/DueDiligenceLayout';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const DueDiligencePage = () => (
  <SubPage page={page}>
    <DueDiligenceLayout />
  </SubPage>
);

export default DueDiligencePage;
