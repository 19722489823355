// local components
import SourceColumn from '../components/SourceColumn';

// table components
import DynamicColumn from '../../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const generateColumns = ({
  scienceBasedTargets,
  unitedNationsGlobalCompact,
  emissions,
}) => ({
  scienceBasedTargets: [...scienceBasedTargets].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      title: label,
    })
  ),
  unitedNationsGlobalCompact: [...unitedNationsGlobalCompact].map(
    ({key, label}) =>
      generateGeneralColumn({
        category: key,
        Component: DynamicColumn,
        title: label,
      })
  ),
  sources: [
    generateGeneralColumn({
      category: 'url',
      Component: SourceColumn,
      title: '',
    }),
  ],
  emissions: [...emissions].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      title: label,
    })
  ),
  emissionsChart: [...emissions]
    .filter(({key, label}) => key !== 'entry' && !!label)
    .map(({label}) => label),
});

export default generateColumns;
