// number lib
import millionToBillion from '../../../../number/lib/millionToBillion.lib.number';

const extractPrivateCapitalEfficiencyData = ({company}) => {
  const totalRaised = company?.PrivateFinancialData?.TotalRaised || 0;
  const revenue = company?.PrivateFinancialData?.Revenue || 0;
  const lastKnownValuation =
    company?.PrivateFinancialData?.LastKnownValuation || 0;
  return [
    {
      label: 'Total Raised',
      value: totalRaised,
      formattedValue: millionToBillion({number: totalRaised}),
    },
    {
      label: 'Revenue',
      value: revenue,
      formattedValue: millionToBillion({number: revenue}),
    },
    {
      label: 'Last Known Valuation',
      value: lastKnownValuation,
      formattedValue: millionToBillion({number: lastKnownValuation}),
    },
  ];
};

export default extractPrivateCapitalEfficiencyData;
