// auth reducers
import {unauthorize as unauthorizeAction, unloadTenantConfig as unloadTenantConfigAction} from '../../auth/redux/reducer.redux.auth';

// app lib
import isProduction from '../../app/lib/isProduction.lib.app';

// aws
import {Auth} from 'aws-amplify';

// Buffer
import {Buffer} from 'buffer';

const signOutService = async ({auth = Auth, dispatch, tenant, isFindTenant}) => {
  try {
    await auth.signOut();
    dispatch(unauthorizeAction());
    dispatch(unloadTenantConfigAction());
    
    const currentSubdomain = window.location.hostname.split('.')[0];
    const sloURL = `https://pooledtenant-halosaas-947585184831.auth.${tenant.region}.amazoncognito.com/logout`;
    const clientId = `client_id=${tenant.appClientId}`;
    const logoutURI = `logout_uri=https://www.gotohalo.com`;
    const logoutRedirectURI = `${sloURL}?${clientId}&${logoutURI}`;
    const state = `state=${Buffer.from(
      JSON.stringify({
        subdomain: currentSubdomain,
        is_production: isProduction(),
        logout_redirect_uri: logoutRedirectURI,
        login_email: isFindTenant && tenant.email
      })
    ).toString('base64')}`;
    window.location.href = `https://www.gotohalo.com?${state}`;

  } catch (error) {
    // do nothing
  }
};

export default signOutService;
