// redux store
import reduxStore from '../../redux/store';

// userSettings storages
import userSettingsStorage from '../storages/settings.storage.userSettings';

const getUserSettingsService = async ({
  domain = '',
  settings,
  user = reduxStore.getState().auth.user,
} = {}) => {
  const allSettings = JSON.parse(userSettingsStorage.get() || '{}');
  const userSettings = allSettings?.[user?.email] || {};
  const updatedSettings = {
    ...allSettings,
    [user.email]: {
      ...userSettings,
      [domain]: settings,
    },
  };
  userSettingsStorage.add(JSON.stringify(updatedSettings));
};

export default getUserSettingsService;
