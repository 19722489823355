const HIGHLIGHTED_ROWS = [
  'Total Current Assets',
  'Total Term Assets',
  'Total Assets',
  'Total Current Liabilities',
  'Total Term Liabilities',
  'Total Liabilities',
  "Total Shareholders' Equity",
];

export default HIGHLIGHTED_ROWS;
