const prepareDataForHaloGptExplain = (supplier) => ({
  default: {
    'Environment Score': supplier?.ESG?.Environment,
    'Environment Emissions': supplier?.ESG?.EnvironmentEmissions,
    'Environment Resource use': supplier?.ESG?.EnvironmentResourceUse,
    'Environment Innovation': supplier?.ESG?.EnvionmentInnovation,
    'Social Score': supplier?.ESG?.Social,
    'Social Human Rights': supplier?.ESG?.SocialHumanRights,
    'Social Product Responsibility': supplier?.ESG?.SocialProductReponsibility,
    'Social Workforce': supplier?.ESG?.SocialWorkforce,
    'Social Community': supplier?.ESG?.SocialCommunity,
    'Governance Score': supplier?.ESG?.Governance,
    'Governance Management': supplier?.ESG?.GovernanceManagement,
    'Governance Shareholders': supplier?.ESG?.GovernanceShareholders,
    'CSR Strategy': supplier?.ESG?.CSRStrategy,
  },
});

export default prepareDataForHaloGptExplain;
