import CryptoJS from 'crypto-js';
import axios from 'axios';

const fetchedDataMap = new Map();

// path:    (Required) (relative path inside S3 bucket)
// tenant:  (Required) (state.auth.tenant)
// token:   (Optional) (state.auth.token) (include if path requires authentication) (include to return data and exclude to return URL)
const getHaloData = async (path, tenant, token) => {
  const isBinary =
    token && ['png', 'jpg', 'jpeg'].includes(path.split('.').reverse()[0]);
  path = path.substring(path.charAt(0) === '/' ? 1 : 0);
  if (!isDataFetched(path)) {
    const dataPromise = getData(path, tenant, token, isBinary);
    fetchedDataMap.set(path, dataPromise);
    const data = await dataPromise;
    return data;
  } else {
    const dataPromise = fetchedDataMap.get(path);
    const data = await dataPromise;
    return data;
  }
};

const isDataFetched = (path) => {
  return fetchedDataMap.has(path);
};

async function getData(path, tenant, token, isBinary) {
  try {
    const requestConfig = {};
    if (token)
      requestConfig.headers = {Authorization: 'Bearer ' + token.idToken.token};
    if (isBinary) requestConfig.responseType = 'blob';
    const url = `${tenant.apiURL}/${token ? 'data' : 'public'}/${
      token ? path : CryptoJS.SHA3(path).toString()
    }`;
    const response = token ? await axios.get(url, requestConfig) : {data: url};
    return new Promise((resolve) => {
      if (!isBinary) resolve(response.data);
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(response.data);
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

export default getHaloData;
