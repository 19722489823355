// company components
import CompanyOperationalScoreColumn from '../../../components/CompanyOperationalScoreColumn/CompanyOperationalScoreColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Operational Score',
  Component: CompanyOperationalScoreColumn,
  sorter: (a, b) => {
    return (
      Number(a?.HaloScores?.Operational_Area?.Value || 0) -
      Number(b?.HaloScores?.Operational_Area?.Value || 0)
    );
  },
});
