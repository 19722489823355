// cyberSecurity events
import showCyberRatingDetailEvent from '../../../../cyberSecurity/events/showRatingDetail.event.cyberSecurity';

const cyberRatingClickHandler =
  ({company}) =>
  (cyberRating) => {
    showCyberRatingDetailEvent.publish({cyberRating, company});
  };

export default cyberRatingClickHandler;
