// chart components
import ComplianceBreakdownChart from '../ComplianceBreakdownChart/ComplianceBreakdownChart';
import CyberIndustryAverageChart from '../CyberIndustryAverageChart/CyberIndustryAverageChart';
import CyberRatingChart from '../CyberRatingChart/CyberRatingChart';
import CyberSecurityComplianceChart from '../CyberSecurityComplianceChart/CyberSecurityComplianceChart';
import CyberSecurityRiskDataCard from '../CyberSecurityRiskDataCard/CyberSecurityRiskDataCard';

// cyberSecurity components
import GoToBlackKiteButton from '../../../cyberSecurity/components/GoToBlackKiteButton/GoToBlackKiteButton';

// layout components
import Card from '../../../layout/components/Card/Card';
import {Column, Row} from '../../../layout/components/Grid/Grid';
import ScoreLine from '../../../layout/components/ScoreLine/ScoreLine';

// local lib
import formatValue from './lib/formatValue.lib';

// number lib
import threeDecimals from '../../../number/lib/threeDecimals.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const CyberSecurityChartData = ({
  blackKiteLinks,
  bottomIndexLabel,
  bottomIndexValue,
  companyName,
  complianceBreakdown = [],
  complianceCompleteness,
  complianceRating,
  complianceRatingDescription,
  condensedView = false,
  cyberRatingChartData,
  cyberRatingDescription,
  grade,
  industryAverageData,
  max,
  min,
  onClick,
  onComplianceClick,
  onCyberRatingClick,
  onCyberSecurityGradeClick,
  print,
  probableFinancialImpactDescription,
  topIndexLabel,
  topIndexValue,
  value,
}) => (
  <Fragment>
    <Row margin>
      <Column>
        <Card>
          <ScoreLine
            bottomScore={threeDecimals(bottomIndexValue)}
            bottomScoreLabel={bottomIndexLabel}
            bottomTooltip="The Ransomware Susceptibility Index is an early warning system for ransomware attacks."
            topScore={threeDecimals(topIndexValue)}
            topScoreLabel={topIndexLabel}
            topTooltip="The Breach Index is a key performance index that tracks data breaches and measures their severity based on the number of records compromised and when the data was breached. By assigning a severity score to each breach, the Breach Index provides a historical performance score of a company."
          />
        </Card>
      </Column>
    </Row>
    <Row margin={!!industryAverageData}>
      <Column>
        <Row>
          <Column
            size={print ? 1 : 1 / 3}
            l={condensedView || print ? 1 : 1 / 3}
            margin
            m={1}
          >
            <Card infoTooltip={cyberRatingDescription}>
              <CyberRatingChart
                data={cyberRatingChartData}
                grade={grade}
                onGradeClick={onCyberSecurityGradeClick}
                onRatingClick={onCyberRatingClick}
              />
              {!!blackKiteLinks?.linkDetails?.TechRepLink && (
                <GoToBlackKiteButton
                  url={blackKiteLinks.linkDetails.TechRepLink}
                >
                  Technical Report
                </GoToBlackKiteButton>
              )}
            </Card>
          </Column>
          <Column
            size={print ? 1 : 1 / 3}
            l={condensedView || print ? 1 : 1 / 3}
            margin
            m={1}
          >
            <Card
              infoTooltip={probableFinancialImpactDescription}
              resetInfoTooltipPosition
            >
              <CyberSecurityRiskDataCard
                value={formatValue(value)}
                min={formatValue(min)}
                max={formatValue(max)}
                onClick={onClick}
              />
              {!!blackKiteLinks?.linkDetails?.FairRepLink && (
                <GoToBlackKiteButton
                  url={blackKiteLinks.linkDetails.FairRepLink}
                >
                  FAIR Report
                </GoToBlackKiteButton>
              )}
            </Card>
          </Column>
          <Column
            size={print ? 1 : 1 / 3}
            l={condensedView || print ? 1 : 1 / 3}
            margin
            m={1}
          >
            <Card infoTooltip={complianceRatingDescription}>
              {!!complianceBreakdown.length ? (
                <ComplianceBreakdownChart
                  compliance={complianceRating}
                  data={complianceBreakdown}
                  onSectionClick={onComplianceClick}
                />
              ) : (
                <CyberSecurityComplianceChart
                  completeness={complianceCompleteness}
                  compliance={complianceRating}
                />
              )}
              {!!blackKiteLinks?.linkDetails?.ComplianceRepLink && (
                <GoToBlackKiteButton
                  url={blackKiteLinks.linkDetails.ComplianceRepLink}
                >
                  Compliance Report
                </GoToBlackKiteButton>
              )}
            </Card>
          </Column>
        </Row>
        <Row>
          <Column>
            <div style={{textAlign: 'right', fontSize: '12px'}}>
              Powered by Black Kite
            </div>
          </Column>
        </Row>
      </Column>
    </Row>
    {!!industryAverageData && (
      <Row>
        <Column>
          <CyberIndustryAverageChart
            {...industryAverageData}
            companyName={companyName}
          />
        </Column>
      </Row>
    )}
  </Fragment>
);

CyberSecurityChartData.propTypes = {
  blackKiteLinks: PropTypes.object,
  bottomIndexLabel: PropTypes.string,
  bottomIndexValue: PropTypes.number,
  companyName: PropTypes.string,
  complianceBreakdown: PropTypes.array,
  complianceCompleteness: PropTypes.number,
  complianceRating: PropTypes.number,
  complianceRatingDescription: PropTypes.node,
  condensedView: PropTypes.bool,
  cyberRatingChartData: PropTypes.array,
  grade: PropTypes.string,
  industryAverageData: PropTypes.object,
  max: PropTypes.number,
  min: PropTypes.number,
  onClick: PropTypes.func,
  onComplianceClick: PropTypes.func,
  onCyberRatingClick: PropTypes.func,
  onCyberSecurityGradeClick: PropTypes.func,
  print: PropTypes.bool,
  probableFinancialImpactDescription: PropTypes.node,
  topIndexLabel: PropTypes.string,
  topIndexValue: PropTypes.number,
  value: PropTypes.number,
};

export default CyberSecurityChartData;
