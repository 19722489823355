// table components
import DynamicColumn from '../../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const generateColumns = ({currency, valueCategories}) => ({
  valueCategories: [...valueCategories].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      props: {currency},
      title: label,
      onCell: (_, index) =>
        key === 'industry' ? {rowSpan: !(index % 2) ? 2 : 0} : {},
    })
  ),
});

export default generateColumns;
