// data constants
import HALO_FACTOR_RATING_DESCRIPTIONS from '../../../../data/constants/haloFactorRatingDescriptions.constant.data';
import riskAreaDescriptionsGenerator from '../../../../data/constants/riskAreaDescriptions.constant.data';

// data lib
import generateAreaScoreKey from '../../../../data/lib/generateAreaScoreKey.lib.data';

// lib
import generateLabelFromKeys from '../../../../lib/generateLabelFromKeys.lib';

// local components
import RISK_AREA_KEYS from '../constants/riskAreaKeys.constant';

// risk constants
import KEY_RISK_AREAS from '../../../../risk/constants/keyRiskAreas.constant.risk';

// risk lib
import convertScoreToGrade from '../../../../risk/lib/convertScoreToGrade.lib.risk';

// helpers
const RISK_AREA_DESCRIPTIONS = riskAreaDescriptionsGenerator();

const extractData = (dataObject, area) =>
  Object.entries(dataObject)
    .filter(
      ([key, {Value}]) =>
        generateAreaScoreKey(area) !== key &&
        key.startsWith(`${area}_`) &&
        Value !== null
    )
    .map(([key, {Value}]) => {
      const areaKey = key.split('_')[1];
      return {
        name: RISK_AREA_KEYS?.[areaKey] || generateLabelFromKeys(areaKey),
        y: Value,
        grade: convertScoreToGrade(Value),
        description: HALO_FACTOR_RATING_DESCRIPTIONS?.[areaKey] || null,
      };
    });

const extractRiskAreaData = ({company}) => ({
  data: [...KEY_RISK_AREAS].map((riskArea) => ({
    name: `${riskArea.label} Score`,
    y: company?.HaloScores?.[generateAreaScoreKey(riskArea.label)]?.Value,
    drilldown: riskArea.label,
    color: riskArea.color,
    description: RISK_AREA_DESCRIPTIONS?.[riskArea.label.toLowerCase()],
  })),
  drillDown: [...KEY_RISK_AREAS].map((riskArea) => ({
    name: riskArea.label,
    id: riskArea.label,
    data: extractData(company.originalHaloScores.HaloScores, riskArea.label),
  })),
});

export default extractRiskAreaData;
