// local components
import Container from './components/Container';
import Icon from './components/Icon';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CompanySocialMediaColumn = ({
  facebook,
  instagram,
  linkedIn,
  twitter,
  youtube,
}) => (
  <Container>
    {!!facebook && (
      <Icon>
        <a href={facebook} target="_blank" rel="noreferrer">
          <i className="mdi mdi-facebook" />
        </a>
      </Icon>
    )}
    {!!instagram && (
      <Icon>
        <a href={instagram} target="_blank" rel="noreferrer">
          <i className="mdi mdi-instagram" />
        </a>
      </Icon>
    )}
    {!!linkedIn && (
      <Icon>
        <a href={linkedIn} target="_blank" rel="noreferrer">
          <i className="mdi mdi-linkedIn" />
        </a>
      </Icon>
    )}
    {!!twitter && (
      <Icon>
        <a href={twitter} target="_blank" rel="noreferrer">
          <i className="mdi mdi-twitter" />
        </a>
      </Icon>
    )}
    {!!youtube && (
      <Icon>
        <a href={youtube} target="_blank" rel="noreferrer">
          <i className="mdi mdi-youtube" />
        </a>
      </Icon>
    )}
  </Container>
);

CompanySocialMediaColumn.propTypes = {
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  linkedIn: PropTypes.string,
  twitter: PropTypes.string,
  youtube: PropTypes.string,
};

export default CompanySocialMediaColumn;
