// company components
import CompanyActionsColumn from '../../../../company/components/CompaniesTableColumns/staticColumns/CompanyActionsColumn/CompanyActionsColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Actions',
  fixed: 'right',
  width: 130,
  Component: CompanyActionsColumn,
});
