// company components
import CompanyFullIntegrationColumn from '../../../components/CompaniesTableColumns/dynamicColumns/CompanyFullIntegrationColumn/CompanyFullIntegrationColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Inherent Risk',
  onCell: () => ({
    colSpan: 6,
  }),
  Component: CompanyFullIntegrationColumn,
});
