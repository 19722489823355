// local lib
import generateColumn from './generateColumn.lib';

const getAdditionalSupplierColumns = () => [
  generateColumn({category: 'sbe', title: 'Small Business Enterprises (SBEs)'}),
  generateColumn({category: 'mbe', title: 'Minority-owned Enterprises (MBEs)'}),
  generateColumn({
    category: 'wbe',
    title: 'Women-owned Business Enterprises (WBEs)',
  }),
];

export default getAdditionalSupplierColumns;
