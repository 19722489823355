import React from 'react';
import {ImArrowDown, ImArrowUp} from 'react-icons/im';
import theme, {palette} from '../constants/theme';

import styled from 'styled-components';
import {Button as AntButton, Table as AntTable, Typography, Menu} from 'antd';
import {AiOutlineFileText, AiOutlinePoweroff} from 'react-icons/ai';
import {
  IoPeopleOutline as BsPeopleFill,
  IoPersonOutline as BsPersonFill,
  IoPersonAddOutline,
  IoCheckmark,
  IoCheckmarkCircleOutline,
  IoCloseOutline,
} from 'react-icons/io5';
import {capitalize} from '../lib/strings';

const {Text: AntText, Title: AntTitle} = Typography;

const LOW_RISK_COLOR = palette.greenHaze;
const HIGH_RISK_COLOR = palette.alizarinCrimson;

export const Title = styled(AntTitle)`
  &.ant-typography {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: ${theme.commonList.title};
  }
`;
export const Table = styled(AntTable)`
  .ant-table-thead > tr > th {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: ${theme.commonList.tableTH};
  }
`;

export const Text = styled(AntText)`
  font-family: 'Open Sans', sans-serif;
  font-weight: ${({color}) => (color ? 700 : 400)};
  font-size: 12px;
  ${({color}) =>
    color && {
      color: color,
    }}
`;
export const Button = styled(AntButton)`
  &.ant-btn {
    border: 1px solid ${theme.commonList.button.border};
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif;
    background: ${theme.commonList.button.background};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${theme.commonList.button.color};
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
`;

export const TableContainer = styled.div`
  background: ${theme.commonList.tableContainer.background};

  border: 1px solid ${theme.commonList.tableContainer.border};
  box-sizing: border-box;

  box-shadow: 0px 8px 16px rgba(20, 40, 60, 0.03);
  border-radius: 4px;
  padding: 12px;
`;
export const columnTextRenderer = (value, color) =>
  textRenderer(capitalize(value), color);

export const textRenderer = (value, color) => (
  <Text color={color}>{capitalize(value)}</Text>
);

export const VarianceIcon = ({variance, onClick}) => {
  switch (variance) {
    case 'increase':
      return (
        <ImArrowUp
          onClick={onClick}
          style={{color: HIGH_RISK_COLOR, cursor: 'pointer', fontSize: '1.1em'}}
        />
      );
    case 'decrease':
      return (
        <ImArrowDown
          onClick={onClick}
          style={{color: LOW_RISK_COLOR, cursor: 'pointer', fontSize: '1.1em'}}
        />
      );
    default:
      return null;
  }
};

export const RelationshipIcon = ({relationship}) => {
  switch (relationship) {
    case 'single':
      return <BsPersonFill style={{cursor: 'default', fontSize: '1.3em'}} />;
    case 'multiple':
      return <BsPeopleFill style={{cursor: 'default', fontSize: '1.3em'}} />;
    case 1:
      return <BsPersonFill style={{cursor: 'default', fontSize: '1.3em'}} />;
    case 2:
      return <BsPeopleFill style={{cursor: 'default', fontSize: '1.3em'}} />;
    default:
      return relationship;
  }
};

export const bubblePreventionEventHandler = ({domEvent}) => {
  domEvent.stopPropagation(); // For demo purposes. else you'll need to implement this
};

export const DropDownMenu = () => {
  const items = [];
  items.push({
    key: '1',
    label: 'Add to EDD',
    icon: <IoPersonAddOutline style={{color: '#88ab9e', cursor: 'pointer'}} />,
    onClick: bubblePreventionEventHandler,
  });
  items.push({
    key: '2',
    label: 'Create SAR report',
    icon: <AiOutlineFileText style={{color: '#84a5ca', cursor: 'pointer'}} />,
    onClick: bubblePreventionEventHandler,
  });
  items.push({
    key: '3',
    label: 'Offboard client',
    icon: <AiOutlinePoweroff style={{color: '#c34b36', cursor: 'pointer'}} />,
    onClick: bubblePreventionEventHandler,
  });
  return <Menu items={items} onClick={bubblePreventionEventHandler} />;
};

export const DropDownMenuPendingApplications = () => {
  const items = [];
  items.push({
    key: '1',
    label: 'Approve',
    icon: <IoCheckmark style={{color: '#88ab9e', cursor: 'pointer'}} />,
    onClick: bubblePreventionEventHandler,
  });
  items.push({
    key: '2',
    label: 'Approve with EDD',
    icon: (
      <IoCheckmarkCircleOutline style={{color: '#84a5ca', cursor: 'pointer'}} />
    ),
    onClick: bubblePreventionEventHandler,
  });
  items.push({
    key: '3',
    label: 'Decline',
    icon: <IoCloseOutline style={{color: '#c34b36', cursor: 'pointer'}} />,
    onClick: bubblePreventionEventHandler,
  });
  return <Menu items={items} onClick={bubblePreventionEventHandler} />;
};
