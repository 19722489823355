// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralCompanyDataAction from './actions/setGeneralData.action.redux.company';

// style redux constants
import DEFAULT_DASHBOARD_REDUX_STATE from './constants/defaultState.constant.redux.company';

const companySlice = createSlice({
  name: 'company',
  initialState: DEFAULT_DASHBOARD_REDUX_STATE,
  reducers: {
    setGeneralCompanyData: setGeneralCompanyDataAction,
  },
});

export const {setGeneralCompanyData} = companySlice.actions;

export default companySlice.reducer;
