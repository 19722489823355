// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// layout components
import FormattedNumber from '../../../../layout/components/FormattedNumber/FormattedNumber';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'EBITDA (M)',
  componentProps: ({company}) => ({
    children: <FormattedNumber number={company?.Financials?.EBITDA} />,
  }),
  Component: CompanyDefaultDataColumn,
  sorter: (a, b) => {
    return (
      Number(a?.Financials?.EBITDA || 0) - Number(b?.Financials?.EBITDA || 0)
    );
  },
});
