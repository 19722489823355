// local components
import Container from './components/Container';
import LogoContainer from './components/LogoContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const SignInLayout = ({children}) => (
  <Container>
    <LogoContainer />
    {children}
  </Container>
);

SignInLayout.propTypes = {
  children: PropTypes.node,
};

export default SignInLayout;
