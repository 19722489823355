import styled from 'styled-components';

// layout colors
import blueGrey500Color from '../../../../layout/colors/blueGrey500.color.layout';

const Paragraph = styled.div`
  width: 100%;
  color: ${blueGrey500Color};
  font-size: 15px;
`;

export default Paragraph;
