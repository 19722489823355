// ant components
import {Table} from 'antd';

// date lib
import {parseISO, format} from 'date-fns';

// financial components
import HistoricDbtTradeInformationChart from '../HistoricDbtTradeInformationChart/HistoricDbtTradeInformationChart';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const HistoricDbtTradeInformationCard = ({dates, sections}) => {
  const columns = generateColumns({dates});

  const categories = [...dates].map((date) => format(parseISO(date), 'MMM yy'));
  const entries = [...sections].map((section) => ({
    name: section.label,
    data: [...dates].map((date) => section?.[date]?.value || null),
  }));

  return (
    <StatementBlock title="Historic Trade Information - Monthly DBT Trends">
      <Table
        columns={columns}
        dataSource={sections}
        pagination={false}
        rowKey="status"
        size="small"
      />
      <HistoricDbtTradeInformationChart
        categories={categories}
        entries={entries}
      />
    </StatementBlock>
  );
};

HistoricDbtTradeInformationCard.propTypes = {
  dates: PropTypes.array,
  sections: PropTypes.array,
};

export default HistoricDbtTradeInformationCard;
