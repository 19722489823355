// charts
import Highcharts from 'highcharts';

// geopolitical events
import showCountrySuppliersModalEvent from '../../../geopolitical/events/showCountrySuppliersModal.event.geopolitical';

// layout colors
import whiteColor from '../../../layout/colors/white.color.layout';
import brightYellow300Color from '../../../layout/colors/brightYellow300.color.layout';

// layout components
import Card from '../../../layout/components/Card/Card';

// local constants
import COLOR_STOPS from './constants/colorStops.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const KeySuppliersMapChartCard = ({
  companyName,
  height = '500px',
  worldData = [],
  worldMap,
}) => {
  const worldMapId = v4();

  const title = `Key Suppliers of ${companyName}`;

  useEffect(() => {
    const chartSettings = {
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          align: 'right',
          verticalAlign: 'bottom',
          alignTo: 'spacingBox',
        },
      },

      colorAxis: {
        min: 0,
        stops: COLOR_STOPS,
      },

      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'bottom',
      },
    };

    const onCountryClick = function () {
      const country = this?.options;
      if (!country?.data?.length) return;
      showCountrySuppliersModalEvent.publish({companyName, country});
    };

    Highcharts.mapChart(worldMapId, {
      chart: {
        map: worldMap,
        height,
      },

      title: {
        text: title,
        x: 0,
      },

      ...chartSettings,

      tooltip: {
        useHTML: true,
        formatter: function () {
          const data = this?.point?.options?.globalResilienceIndex;
          return !!data
            ? `<div>
                <div><b>${data.Country}</b></div>
                <div># of Companies: ${this?.point?.options?.value || 0}</div>
                <div>Country Rank: #${data['Country Rank']}</div>
                <div>Country Score: ${data['Country Score']}</div>
                <div>Economic Score: ${data['Economic Score']}</div>
                <div>Risk Quality Score: ${data['Risk Quality Score']}</div>
                <div>Supply Chain Score: ${data['Supply Chain Score']}</div>
              </div>`
            : `<b>${this.series.name}</b>`;
        },
      },

      series: [
        {
          name: 'Companies',
          data: worldData,
          joinBy: ['name', 'name'],
          borderColor: whiteColor,
          borderWidth: 1,
          nullColor: '#eee',
          states: {
            hover: {
              color: brightYellow300Color,
            },
          },
          point: {
            events: {
              click: onCountryClick,
            },
          },
        },
      ],
    });
    // eslint-disable-next-line
  }, [companyName, height, worldData]);

  return (
    <Card>
      <div id={worldMapId} />
    </Card>
  );
};

KeySuppliersMapChartCard.propTypes = {
  companyName: PropTypes.string,
  height: PropTypes.string,
  worldData: PropTypes.array,
  worldMap: PropTypes.object,
};

export default KeySuppliersMapChartCard;
