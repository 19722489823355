const flattenTriggerOptions = (triggers) =>
  [...triggers].reduce(
    (combined, trigger) =>
      !!trigger?.children?.length
        ? [...combined, ...flattenTriggerOptions(trigger.children)]
        : [...combined, trigger],
    []
  );

export default flattenTriggerOptions;
