// data lib
import parseValueByType from '../../lib/parseValueByType.lib.data';

// number components
import PercentageTrend from '../../../number/components/PercentageTrend/PercentageTrend';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// helper constants
const FORMATTERS = {
  default: (...attributes) => parseValueByType(...attributes),
  trendInPercentage: ({value, reverse}) => (
    <PercentageTrend percentage={value} reverse={reverse} />
  ),
};

const TypeDisplay = ({value, type, ...props}) =>
  (FORMATTERS?.[type] || FORMATTERS.default)({value, type, ...props});

TypeDisplay.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
};

export default TypeDisplay;
