import styled, {css} from 'styled-components';

// colors
import whiteColor from '../../../../layout/colors/white.color.layout';

// constants
const SIZE = '130px';
const WIDTH = '220px';

const Card = styled.div`
  height: ${SIZE};
  min-height: ${SIZE};
  max-height: ${SIZE};
  padding: 10px 4px;
  flex: 1;
  background: ${whiteColor};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  break-inside: avoid;
  overflow: hidden;
  ${({fixedWidth}) =>
    fixedWidth &&
    css`
      width: ${WIDTH};
      min-width: ${WIDTH};
      max-width: ${WIDTH};
    `}
`;

export default Card;
