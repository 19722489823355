import styled from 'styled-components';

// layout colors
import brightGreen300Color from '../../../../layout/colors/brightGreen300.color.layout';

const Value = styled.div`
  width: 100%;
  text-align: center;
  font-size: 40px;
  color: ${brightGreen300Color};
  font-weight: 700;
  position: relative;
`;

export default Value;
