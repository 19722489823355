// local lib
import extractKeySuppliers from './extractKeySuppliers.lib';

const renderKeySuppliersSection = ({company}) => {
  try {
    return !!extractKeySuppliers({company})?.length;
  } catch (error) {
    return false;
  }
};

export default renderKeySuppliersSection;
