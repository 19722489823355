const CRUNCH_BASE_NEWS_ALERT_TRIGGERS = [
  {
    value: 'cb_News',
    title: 'News',
    provider: 'cb',
    type: 'boolean',
  },
  {
    value: 'cb_Acquisition',
    title: 'Acquisition',
    provider: 'cb',
    type: 'boolean',
  },
  {
    value: 'cb_Investments',
    title: 'Investments',
    provider: 'cb',
    type: 'boolean',
  },
  {
    value: 'cb_Funds Raised',
    title: 'Funds Raised',
    provider: 'cb',
    type: 'boolean',
  },
  {
    value: 'cb_IPO',
    title: 'IPO',
    provider: 'cb',
    type: 'boolean',
  },
];

export default CRUNCH_BASE_NEWS_ALERT_TRIGGERS;
