const CREDIT_SAFE_DIRECTORS_ALERT_TRIGGERS = [
  {
    value: 'cs_directors',
    title: 'Director(s)',
    provider: 'cs',
    type: 'boolean',
  },
  // {
  //   value: 'cs_changeInOfficerOfCompany',
  //   title: 'Change in Officer of Company',
  //   provider: 'cs',
  //   type: 'boolean',
  // },
  // {
  //   value: 'cs_changeInFirstNameOfOfficerOfCompany',
  //   title: 'Change in First Name of Officer of Company',
  //   provider: 'cs',
  //   type: 'boolean',
  // },
  // {
  //   value: 'cs_changeInLastNameOfOfficerOfCompany',
  //   title: 'Change in Last Name of Officer of Company',
  //   provider: 'cs',
  //   type: 'boolean',
  // },
].filter((option) => !!option);

export default CREDIT_SAFE_DIRECTORS_ALERT_TRIGGERS;
