const getNewsBody = ({
  newsArticle,
  language = 'en',
  missingBody = '-',
  splitToArray = true,
}) => {
  if (!newsArticle) return [missingBody];
  const body =
    (!language || language === newsArticle.language
      ? newsArticle.body
      : newsArticle?.translations?.[language]?.body) ||
    newsArticle.body ||
    missingBody;
  return splitToArray
    ? body
        .split('\n\n')
        .map((paragraph) => paragraph.split('\n'))
        .flat()
    : body;
};

export default getNewsBody;
