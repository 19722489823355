// company containers
import CompanySanctionsContainer from '../../containers/CompanySanctionsContainer/CompanySanctionsContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const SanctionsPage = () => (
  <SubPage page={page}>
    <CompanySanctionsContainer />
  </SubPage>
);

export default SanctionsPage;
