// collection lib
import getPrimaryCollection from '../../../collection/lib/getPrimaryCollection.lib.collection';

// collection redux events
import {storeCollection as storeCollectionAction} from '../../../collection/redux/reducer.redux.collection';

// collection services
import loadCollectionsService from '../../../collection/services/loadCollections.service.collection';
import getCollectionDataService from '../../../collection/services/getData.service.collection';

// company redux actions
import {setGeneralDashboardData as setGeneralDashboardDataAction} from '../../redux/reducer.redux.dashboard';

// date lib
import {formatISO} from 'date-fns';

// dashboard services
import loadDashboardDataService from '../../services/loadDashboardData.service.data';

// dashboard storages
import dashboardPortfolioUpdateDateStorage from '../../storages/portfolioUpdateDate.storage.dashboard';

// globalResilience api
import listGlobalResilienceFactorsApi from '../../../globalResilience/api/listGlobalResilienceFactors.api.globalResilience';

// globalResilience lib
import combinedGlobalResilienceData from '../../../globalResilience/lib/combineData.lib.globalResilience';

// react
import {Component} from 'react';

// propType
import PropTypes from 'prop-types';

// redux
import {connect} from 'react-redux';

class LoadDashboardDataContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    this.loadDashboard();
  }

  loadDashboard = async () => {
    const {dispatch} = this.props;

    dispatch(setGeneralDashboardDataAction({loading: true}));

    try {
      const [tenantData, fetchedGlobalResilienceIndexes, {data: collections}] =
        await Promise.all([
          loadDashboardDataService(),
          listGlobalResilienceFactorsApi(),
          loadCollectionsService({is_active: true}),
        ]);
      dashboardPortfolioUpdateDateStorage.add(formatISO(new Date()));
      const globalResilienceIndexes = combinedGlobalResilienceData(
        fetchedGlobalResilienceIndexes
      );

      const collection = getPrimaryCollection(collections);
      const collectionScores = await getCollectionDataService({collection});
      dispatch(
        storeCollectionAction({
          collection,
          collectionData: collectionScores,
          collections,
        })
      );

      dispatch(
        setGeneralDashboardDataAction({
          tenantData,
          collectionScores,
          globalResilienceIndexes,
          loading: false,
        })
      );
    } catch (error) {
      dispatch(setGeneralDashboardDataAction({loading: false}));
    }
  };

  render() {
    return null;
  }
}

export default connect()(LoadDashboardDataContainer);
