import styled from 'styled-components';

// layout colors
import grey400Color from '../../../../layout/colors/grey400.color.layout';

// constants
const SIZE = '5px';

const SummaryValueLine = styled.div`
  width: 100%;
  height: ${SIZE};
  min-height: ${SIZE};
  max-height: ${SIZE};
  background: ${({color}) => color || grey400Color};
`;

export default SummaryValueLine;
