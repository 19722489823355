// local lib
import generateColumn from './generateColumn.lib';

const getSupplierColumns = () => [
  generateColumn({category: 'yearFounded', title: 'Year Founded'}),
  generateColumn({category: 'operatingStatus', title: 'Operating Status'}),
  generateColumn({category: 'businessStatus', title: 'Business Status'}),
  generateColumn({category: 'numberOfEmployees', title: 'Number of Employees'}),
  generateColumn({category: 'employeeGrowth', title: 'Employee Growth'}),
  generateColumn({category: 'ownershipStatus', title: 'Ownership Status'}),
];

export default getSupplierColumns;
