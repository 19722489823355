// ant components
import {Col as Column, Row} from 'antd';

// financial components
import GroupIndustryBreakdownCard from '../GroupIndustryBreakdownCard/GroupIndustryBreakdownCard';
import GroupTradePaymentInformationCard from '../GroupTradePaymentInformationCard/GroupTradePaymentInformationCard';
import HighCreditBalancesCard from '../HighCreditBalancesCard/HighCreditBalancesCard';
import HistoricalTradeInformationCard from '../HistoricalTradeInformationCard/HistoricalTradeInformationCard';
import HistoricDbtTradeInformationCard from '../HistoricDbtTradeInformationCard/HistoricDbtTradeInformationCard';
import IndustryBreakdownCard from '../IndustryBreakdownCard/IndustryBreakdownCard';
import LifetimeCreditBalancesCard from '../LifetimeCreditBalancesCard/LifetimeCreditBalancesCard';
import PastDueBalancesCard from '../PastDueBalancesCard/PastDueBalancesCard';
import TradePaymentInformationCard from '../TradePaymentInformationCard/TradePaymentInformationCard';
import TradePaymentsByCreditExtendedCard from '../TradePaymentsByCreditExtendedCard/TradePaymentsByCreditExtendedCard';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialPaymentData = ({printView}) => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  return (
    <Row gutter={[20, 20]}>
      <Column span={24}>
        <TradePaymentInformationCard
          currency={company.financials.currency}
          totalTradeLines={company.financials.tradePayment.totalTradeLines}
          valueCategories={company.financials.tradePayment.values.categories}
          statuses={company.financials.tradePayment.values.statuses}
        />
      </Column>
      <Column span={24}>
        <HistoricDbtTradeInformationCard
          dates={company?.financials?.dbtHistory?.dates || []}
          sections={company?.financials?.dbtHistory?.sections || []}
        />
      </Column>
      <Column span={24}>
        <IndustryBreakdownCard
          currency={company.financials.currency}
          entries={company.financials.industryBreakdown.values.entries}
          valueCategories={
            company.financials.industryBreakdown.values.categories
          }
        />
      </Column>
      <Column span={24}>
        <HistoricalTradeInformationCard
          currency={company.financials.currency}
          entries={company.financials.historicalTradeInformation.values.entries}
          valueCategories={
            company.financials.historicalTradeInformation.values.categories
          }
        />
      </Column>
      <Column span={24}>
        <TradePaymentsByCreditExtendedCard
          categories={
            company.financials.tradePaymentsByCreditExtended.categories
          }
          currency={company.financials.currency}
          entries={company.financials.tradePaymentsByCreditExtended.entries}
        />
      </Column>
      <Column span={24}>
        <HighCreditBalancesCard
          currency={company.financials.currency}
          entries={company.financials.highCreditBalances.values.entries}
          valueCategories={
            company.financials.highCreditBalances.values.categories
          }
        />
      </Column>
      <Column span={24}>
        <LifetimeCreditBalancesCard
          currency={company.financials.currency}
          entries={company.financials.lifetimeCreditBalances.values.entries}
          valueCategories={
            company.financials.lifetimeCreditBalances.values.categories
          }
        />
      </Column>
      <Column span={24}>
        <PastDueBalancesCard
          categories={company.financials.pastDueBalances.categories}
          currency={company.financials.currency}
          entries={company.financials.pastDueBalances.entries}
        />
      </Column>
      <Column span={24}>
        <GroupTradePaymentInformationCard
          currency={company.financials.currency}
          totalTradeLines={company.financials.groupTradePayment.totalTradeLines}
          valueCategories={
            company.financials.groupTradePayment.values.categories
          }
          statuses={company.financials.groupTradePayment.values.statuses}
        />
      </Column>
      <Column span={24}>
        <GroupIndustryBreakdownCard
          currency={company.financials.currency}
          entries={company.financials.groupIndustryBreakdown.values.entries}
          valueCategories={
            company.financials.groupIndustryBreakdown.values.categories
          }
        />
      </Column>
    </Row>
  );
};

FinancialPaymentData.propTypes = {
  printView: PropTypes.bool,
};

export default FinancialPaymentData;
