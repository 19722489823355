import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  & > * {
    cursor: pointer;
  }
`;

export default Container;
