// local columns
import actionsColumn from '../columns/actions.column';
import commentColumn from '../columns/comment.column';
import nameColumn from '../columns/name.column';
import periodColumn from '../columns/period.column';
// import scopeColumn from '../columns/scope.column';
import thresholdColumn from '../columns/threshold.column';

const generateColumns = () => [
  nameColumn,
  // scopeColumn,
  periodColumn,
  thresholdColumn,
  commentColumn,
  actionsColumn,
];

export default generateColumns;
