const extractCountries = (companies) =>
  [...companies]
    .reduce((countries, company) => {
      const country = company?.Firmographic?.HQCountry || null;
      return !country || countries.includes(country)
        ? countries
        : [...countries, country];
    }, [])
    .map((country) => ({value: country, label: country}));

export default extractCountries;
