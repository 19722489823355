import styled from 'styled-components';

// layout colors
import blueGrey500Color from '../../../../layout/colors/blueGrey500.color.layout';

const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid ${blueGrey500Color};
  position: absolute;
  bottom: 0px;
  left: 100%;
`;

export default ArrowRight;
