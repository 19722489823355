import styled from 'styled-components';

// colors
import whiteColor from '../../../../layout/colors/white.color.layout';

const Side = styled.div`
  width: 200px;
  height: calc(100vh - ${({withSubPages}) => (withSubPages ? 122 : 60)}px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 27px 18px;
  position: fixed;
  top: ${({withSubPages}) => (withSubPages ? 122 : 60)}px;
  left: 0px;
  z-index: 300;
  color: ${whiteColor};
  background: linear-gradient(135.12deg, #153443 16.73%, #63d57e 209.5%);
`;

export default Side;
