// cyberSecurity constants
import CYBER_SECURITY_RISK_DESCRIPTIONS from '../../../../cyberSecurity/constants/cyberSecurityRiskDescriptions.constant.cyberSecurity';

// lib
import generateLabelFromKeys from '../../../../lib/generateLabelFromKeys.lib';

// local constants
import MANUAL_LABEL_OVERRIDES from '../constants/manualLabelOverrides.constant';

// risk lib
import getGradeColor from '../../../../risk/lib/getGradeColor.lib.risk';
import getCyberWeight from '../../../../risk/lib/getCyberWeight.lib.risk';
import getCyberFactorIndex from '../../../../risk/lib/getCyberFactorIndex.lib.risk';
import convertCyberScoreToGrade from '../../../../risk/lib/convertCyberScoreToGrade.lib.risk';

const extractCyberRatingData = ({company}) => {
  const data = Object.entries(company?.CyberSecurity?.cyberRatings || {}).map(
    ([key, {Value: value}]) => ({
      name: generateLabelFromKeys(key, {
        manualOverrides: MANUAL_LABEL_OVERRIDES,
      }),
      y: getCyberWeight(key) * 100,
      score: value,
      grade: convertCyberScoreToGrade(value),
      color: getGradeColor(convertCyberScoreToGrade(value)),
      shortDescription: CYBER_SECURITY_RISK_DESCRIPTIONS?.[key] || '',
      index: getCyberFactorIndex(key),
      cyberRating: {
        tag: key,
        tagName: generateLabelFromKeys(key, {
          manualOverrides: MANUAL_LABEL_OVERRIDES,
        }),
        shortDescription: CYBER_SECURITY_RISK_DESCRIPTIONS?.[key] || '',
        value,
        grade: convertCyberScoreToGrade(value),
      },
    })
  );
  return data;
};

export default extractCyberRatingData;
