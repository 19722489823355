// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// redux
import {connect} from 'react-redux';

// supplier api
import getSuppliersForSearchApi from '../../api/getSuppliersForSearch.api.supplier';

// supplier redux actions
import {setGeneralSupplierData as setGeneralSupplierDataAction} from '../../redux/reducer.redux.supplier';

class LoadSuppliersForSearchContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    tenantData: PropTypes.object,
  };

  componentDidMount() {
    if (!this.props.tenantData) return;
    this.loadSuppliers();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.tenantData && !!this.props.tenantData) this.loadSuppliers();
  }

  loadSuppliers = async () => {
    const {tenantData, dispatch} = this.props;
    dispatch(setGeneralSupplierDataAction({loadingSuppliersForSearch: true}));

    try {
      const suppliers = await getSuppliersForSearchApi();
      const suppliersForSearch = [...Object.values(suppliers)].map(
        (supplier) => {
          const dashboardDataInfo =
            tenantData?.BlackKiteSupplierTenantData?.[supplier.CompanyId] || {};
          return {
            ...dashboardDataInfo,
            ...supplier,
          };
        }
      );
      dispatch(
        setGeneralSupplierDataAction({
          loadingSuppliersForSearch: false,
          suppliersForSearch,
        })
      );
    } catch (error) {
      dispatch(
        setGeneralSupplierDataAction({loadingSuppliersForSearch: false})
      );
    }
  };

  render() {
    return null;
  }
}

export default connect((state) => ({
  tenantData: state.dashboard.tenantData,
}))(LoadSuppliersForSearchContainer);
