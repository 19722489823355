// compliance events
import showComplianceDetailEvent from '../../../../compliance/events/showComplianceDetail.event.compliance';

const complianceClickHandler =
  ({company}) =>
  (compliance) => {
    showComplianceDetailEvent.publish({compliance, company});
  };

export default complianceClickHandler;
