import styled from 'styled-components';

const SubValuesLabel = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  padding: 10px;
`;

export default SubValuesLabel;
