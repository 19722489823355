import styled from 'styled-components';

// local lib
import subPageHeight from '../constants/subPageHeight.constant';

const ContentContainer = styled.div`
  width: 100%;
  padding-left: ${({withSideContent}) => (withSideContent ? '200px' : '0px')};
  padding-right: ${({withChat}) => (withChat ? '320px' : '0px')};
  margin: auto;
  padding-top: ${({withSubPages}) =>
    withSubPages ? `${subPageHeight}px` : '0px'};
  position: relative;
`;

export default ContentContainer;
