const prepareForGPT = ({
  alternativeFormatting = false,
  data = [],
  ignoredParameters = ['key', 'LTM'],
  key = 'section',
  formatData = ({value}) => value,
} = {}) => {
  const formattedData = [...data]
    .map((entry) =>
      Object.entries(entry).reduce(
        (combined, [dataKey, value]) =>
          dataKey !== key && ignoredParameters.includes(dataKey)
            ? combined
            : {
                ...combined,
                [dataKey]: formatData({value, dataKey, row: entry[key]}),
              },
        {}
      )
    )
    .filter((entry) =>
      Object.entries(entry).some(
        ([dataKey, value]) => dataKey !== key && !!value
      )
    );

  return alternativeFormatting
    ? [...formattedData].reduce(
        (combined, entry) => ({
          ...combined,
          [entry[key]]: [...Object.entries(entry)].reduce(
            (combineEntry, [dataKey, value]) =>
              dataKey === key
                ? combineEntry
                : {
                    ...combineEntry,
                    [dataKey]: value,
                  },
            {}
          ),
        }),
        {}
      )
    : formattedData;
};

export default prepareForGPT;
