import {useState, useMemo} from 'react';

import Fuse from 'fuse.js';

/**
 * Written as a hook that connects to redux. ideally it will hit the api
 * just change the implementation of the hook and if interfaces are respected it should work
 *
 */
const fuseInstanceConfiguration = {
  keys: ['name'],
  threshold: 0.1,
};
export const useSearchTerm = (includeCompanies = true) => {
  const [searchTerm, setSearchTerm] = useState('');
  // eslint-disable-next-line
  const companiesInHalo = [];
  // eslint-disable-next-line
  const companiesInLocal = [];

  const fuseInstanceCompaniesHalo = useMemo(
    () => new Fuse(companiesInHalo, fuseInstanceConfiguration),
    [companiesInHalo]
  );
  const fuseInstanceCompaniesLocal = useMemo(
    () => new Fuse(companiesInLocal, fuseInstanceConfiguration),
    [companiesInLocal]
  );

  const companiesInHaloResults = useMemo(
    () =>
      includeCompanies ? fuseInstanceCompaniesHalo.search(searchTerm) : [],
    [searchTerm, fuseInstanceCompaniesHalo, includeCompanies]
  );
  const companiesInLocalResults = useMemo(
    () =>
      includeCompanies ? fuseInstanceCompaniesLocal.search(searchTerm) : [],
    [searchTerm, fuseInstanceCompaniesLocal, includeCompanies]
  );

  return {
    isSearching: false,
    searchResults: {
      companiesInHaloResults,
      companiesInLocalResults,
    },
    searchTerm,
    setSearchTerm,
  };
};
