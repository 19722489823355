// antd
import {Table} from 'antd';

// layout components
import Card from '../../../layout/components/Card/Card';

// local lib
import extractKeyDataAndRatios from './lib/extractKeyDataAndRatios.lib';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialsKeyDataAndRatiosSection = () => {
  const {company} = useSelector((state) => state.company);

  const parsedData = extractKeyDataAndRatios({company});

  return (
    <Card noPadding>
      <Table
        columns={parsedData.categories}
        dataSource={parsedData.entries}
        pagination={false}
        rowKey="section"
        size="small"
        locale={{emptyText: 'No key data and ratios'}}
      />
    </Card>
  );
};

export default FinancialsKeyDataAndRatiosSection;
