// collection api
import updateCollectionApi from '../api/update.api.collection';

// collection events
import collectionUpdatedEvent from '../events/updated.event.collection';

// collection validators
import updateCollectionValidator from '../validators/update.validator.collection';

const updateCollectionService = async ({
  collection,
  collectionId,
  collectionUpdated = collectionUpdatedEvent.publish,
  companyIds,
  previousCompanyIds,
  updateCollection = updateCollectionApi,
  validateCollection = updateCollectionValidator,
}) => {
  validateCollection({collection, companyIds});
  const companyIdsToAdd = [...companyIds].filter(
    (companyId) => !previousCompanyIds.includes(companyId)
  );
  const companyIdsToRemove = [...previousCompanyIds].filter(
    (companyId) => !companyIds.includes(companyId)
  );
  const formattedCollection = {
    ...collection,
    company_ids_to_add: companyIdsToAdd,
    company_ids_to_remove: companyIdsToRemove,
  };
  const updatedCollection = await updateCollection(
    collectionId,
    formattedCollection
  );
  collectionUpdated(updatedCollection);
  return updatedCollection;
};

export default updateCollectionService;
