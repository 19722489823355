import styled from 'styled-components';

// layout colors
import blueGrey100Color from '../../../../layout/colors/blueGrey100.color.layout';

const Container = styled.div`
  display: flex;
  height: 36px;
  padding: 0px 6px;
  cursor: ${({onClick}) => (!!onClick ? 'pointer' : 'default')};
  font-size: 12px;
  background: ${blueGrey100Color};
  display: flex;
  align-items: center;
  gap: 4px;

  & i {
    font-size: 1.2rem;
  }
`;

export default Container;
