// ant
import {Dropdown, Button} from 'antd';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// workflow events
import showWorkflowCreatorModalEvent from '../../../events/showCreatorModal.event.workflow';
import showWorkflowEditorModalEvent from '../../../events/showEditorModal.event.workflow';
import showWorkflowDeleteModalEvent from '../../../events/showDeleteModal.event.workflow';

// helpers
const actions = {
  edit: ({workflow}) => showWorkflowEditorModalEvent.publish({workflow}),
  duplicate: ({workflow}) => showWorkflowCreatorModalEvent.publish(workflow),
  delete: ({workflow}) => showWorkflowDeleteModalEvent.publish({workflow}),
};

const ActionsButton = ({workflow}) => (
  <Dropdown
    trigger={['click']}
    menu={{
      onClick: (option) => {
        const action = actions?.[option?.keyPath];
        if (!action) return;
        action({workflow});
      },
      items: [
        {
          label: 'Edit',
          key: 'edit',
        },
        {
          label: 'Duplicate',
          key: 'duplicate',
        },
        {
          label: 'Delete',
          key: 'delete',
        },
      ],
    }}
  >
    <Button>Actions</Button>
  </Dropdown>
);

ActionsButton.propTypes = {
  workflow: PropTypes.object,
};

export default ActionsButton;
