// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// country components
import Flags from '../../../../country/components/Flags/Flags';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Countries of Operation',
  width: 180,
  componentProps: ({company}) => ({
    children: (
      <Flags countries={company?.Firmographic?.CountriesofOperation} condense />
    ),
  }),
  Component: CompanyDefaultDataColumn,
});
