// company containers
import CompanyContainer from '../../containers/CompanyContainer/CompanyContainer';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local page
import page from './page';

// react
import React from 'react';

// router
import {useParams} from 'react-router-dom';

const CompanyPage = () => {
  const {id} = useParams();
  return (
    <Page page={page}>
      <CompanyContainer companyId={id} />
    </Page>
  );
};

export default CompanyPage;
