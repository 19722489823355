import styled from 'styled-components';

// colors
import blackColor from '../../../../layout/colors/black.color.layout';
import grey200Color from '../../../../layout/colors/grey200.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

const Result = styled.div`
  width: 100%;
  padding: 8px 10px;
  color: ${blackColor};
  cursor: pointer;
  user-select: none;
  background: ${whiteColor};
  border-style: ${({withBorder}) =>
    withBorder ? 'solid none none none' : 'none'};
  border-width: 1px;
  border-color: ${grey200Color};

  &:hover {
    background: ${grey200Color};
  }
`;

export default Result;
