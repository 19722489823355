import styled from 'styled-components';

const Container = styled.div`
  width: 310px;
  height: calc(100vh - 142px);
  position: fixed;
  z-index: 400;
  right: 10px;
  top: 132px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
`;

export default Container;
