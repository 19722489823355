// financial constants
import FINANCIAL_CREDIT_LIMIT_DEFINITION from '../../constants/creditLimitDefinition.constant.financial';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';
import Title from '../../../layout/components/Title/Title';

// number lib
import shortenCurrency from '../../../number/lib/shortenCurrency.lib.number';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// score components
import HighlightedScore from '../../../score/components/HighlightedScore/HighlightedScore';

const FinancialCreditLimitCard = ({currency, creditLimit}) => {
  return (
    <StatementBlock
      padding
      title="Credit Limit"
      tooltip={FINANCIAL_CREDIT_LIMIT_DEFINITION}
    >
      <Title heading="H4">Today's Credit Limit</Title>
      <HighlightedScore>
        {shortenCurrency({
          number: creditLimit,
          currency,
        })}
      </HighlightedScore>
    </StatementBlock>
  );
};

FinancialCreditLimitCard.propTypes = {
  currency: PropTypes.string,
  creditLimit: PropTypes.number,
};

export default FinancialCreditLimitCard;
