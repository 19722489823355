// ant
import {Tooltip} from 'antd';

// company components
import HighlightedScore from '../../../components/HighlightedScore/HighlightedScore';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// local tooltips
import nist800171Tooltip from './tooltips/nist800171.tooltip.company';

export default generateColumn({
  key: 'nist800171',
  title: <Tooltip title={nist800171Tooltip}>NIST 800-171</Tooltip>,
  componentProps: ({company}) => ({
    score: Number(company?.Compliance?.['NIST800-171Rating']),
    suffix: '%',
  }),
  Component: HighlightedScore,
});
