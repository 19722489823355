import styled from 'styled-components';

// layout colors
import blueGrey400Color from '../../../../layout/colors/blueGrey400.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

// constants
const SIZE = '26px';

const IconButton = styled.div`
  width: ${SIZE};
  max-width: ${SIZE};
  min-width: ${SIZE};
  height: ${SIZE};
  max-height: ${SIZE};
  min-height: ${SIZE};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  font-size: 18px;
  color: ${blueGrey400Color};
  background: ${whiteColor};
  position: relative;
`;

export default IconButton;
