import styled from 'styled-components';

// layout colors
import grey500Color from '../../../../layout/colors/grey500.color.layout';

const NoResults = styled.div`
  width: 100%;
  padding: 8px 10px;
  color: ${grey500Color};
`;

export default NoResults;
