// company containers
import GlobalResilienceIndexContainer from '../../containers/GlobalResilienceIndexContainer/GlobalResilienceIndexContainer';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local page
import page from './page';

// react
import React from 'react';

const GlobalResilienceIndexPage = () => (
  <Page page={page}>
    <GlobalResilienceIndexContainer />
  </Page>
);

export default GlobalResilienceIndexPage;
