import styled from 'styled-components';

const SubValuesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 10px;
  margin: 20px 0px 10px 0px;
  position: relative;
`;

export default SubValuesContainer;
