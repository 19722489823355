// company routes
import companyRoute from '../../../company/pages/CompanyPage/route';

// navigation components
import NavigationSearch from '../../components/NavigationSearch/NavigationSearch';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// route HOCs
import withNavigationHOC from '../../../route/hocs/withNavigation.hoc.route';

// supplier lib
import isSupplierAvailable from '../../../supplier/lib/isSupplierAvailable.lib.supplier';

class NavigationSearchContainer extends Component {
  static propTypes = {
    navigate: PropTypes.func,
    suppliers: PropTypes.array,
  };

  state = {
    results: [],
    resultsVisible: false,
    search: '',
    searching: false,
  };

  MIN_SEARCH_CHARS = 1;

  search = async (search) => {
    const {suppliers} = this.props;
    const resultsVisible = search.length >= this.MIN_SEARCH_CHARS;
    if (!resultsVisible)
      return this.setState({search, resultsVisible, results: []});

    this.setState({searching: true, resultsVisible, search});

    try {
      const results = [...suppliers]
        .filter(
          (supplier) =>
            isSupplierAvailable({supplier}) &&
            supplier.CompanyName.toLowerCase().includes(search.toLowerCase())
        )
        .slice(0, 8);
      this.setState({results, searching: false});
    } catch (error) {
      this.setState({results: [], searching: false});
    }
  };

  goToResult = (result) => () => {
    this.props.navigate(companyRoute(result.CompanyId));
    this.setState({
      results: [],
      resultsVisible: false,
      search: '',
      searching: false,
    });
  };

  showResults = () => {
    if (this.state.search.length < this.MIN_SEARCH_CHARS) return;
    this.setState({resultsVisible: true});
  };

  hideResults = () => this.setState({resultsVisible: false});

  selectFirst = () => {
    const {results} = this.state;
    const [company] = results;
    if (!company) return;
    this.goToResult(company)();
  };

  render() {
    const {results, resultsVisible, search, searching} = this.state;
    return (
      <NavigationSearch
        hideResults={this.hideResults}
        onEnter={this.selectFirst}
        onResultClick={this.goToResult}
        onSearch={this.search}
        results={results}
        resultsVisible={resultsVisible}
        search={search}
        searching={searching}
        showResults={this.showResults}
      />
    );
  }
}

export default connect((state) => ({
  suppliers: state.supplier.suppliersForSearch,
}))(withNavigationHOC(NavigationSearchContainer));
