import styled from 'styled-components';

// layout colors
import grey400Color from '../../../../layout/colors/grey400.color.layout';
import blackColor from '../../../../layout/colors/black.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';
import green400Color from '../../../../layout/colors/green400.color.layout';

const Option = styled.div`
  border-radius: 16px;
  padding: 5px 10px;
  font-size: 10px;
  cursor: pointer;
  background: ${grey400Color};
  color: ${blackColor};

  &:hover {
    background: ${green400Color};
    color: ${whiteColor};
  }
`;

export default Option;
