import styled from 'styled-components';

// layout colors
import grey400Color from '../../../../layout/colors/grey400.color.layout';

// constants
const SIZE = '30px';

const SubValueLine = styled.div`
  width: ${SIZE};
  max-width: ${SIZE};
  min-width: ${SIZE};
  height: 1px;
  background: ${grey400Color};
`;

export default SubValueLine;
