// redux
import {createSlice} from '@reduxjs/toolkit';

// style redux actions
import setGeneralTagDataAction from './actions/setGeneralData.action.redux.tag';

// style redux constants
import DEFAULT_TAG_REDUX_STATE from './constants/defaultState.constant.redux.tag';

const tagSlice = createSlice({
  name: 'tag',
  initialState: DEFAULT_TAG_REDUX_STATE,
  reducers: {
    setGeneralTagData: setGeneralTagDataAction,
  },
});

export const {setGeneralTagData} = tagSlice.actions;

export default tagSlice.reducer;
