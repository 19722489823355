// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Due Diligence Percent Complete',
  componentProps: ({company}) => ({
    children:
      company?.ManagementDueDiligence?.DueDiligencePercentComplete || '-',
  }),
  onCell: () => ({
    colSpan: 0,
  }),
  Component: CompanyDefaultDataColumn,
});
