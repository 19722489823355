// ant components
import {Table} from 'antd';

// financial components
import PastDueBalancesChart from '../PastDueBalancesChart/PastDueBalancesChart';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const PastDueBalancesCard = ({currency, categories, entries}) => {
  const columns = generateColumns({currency, categories});

  const chartCategories = [...categories].filter(({key}) => key !== 'entry');
  const chartData = [...entries].map((entry) => ({
    name: entry.entry,
    data: [...chartCategories].map(({key}) => entry?.[key]?.value || null),
  }));

  return (
    <StatementBlock title="Past Due Balances">
      <Table
        columns={columns}
        dataSource={entries}
        pagination={false}
        rowKey="status"
        size="small"
      />
      <PastDueBalancesChart
        categories={[...chartCategories].map(({label}) => label)}
        entries={chartData}
      />
    </StatementBlock>
  );
};

PastDueBalancesCard.propTypes = {
  categories: PropTypes.array,
  currency: PropTypes.string,
  entries: PropTypes.array,
};

export default PastDueBalancesCard;
