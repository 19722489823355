const CREDIT_SAFE_FINANCIAL_ALERT_TRIGGERS = [
  // {
  //   value: 'cs_localDelinquencyRating',
  //   title: 'Local Delinquency Rating',
  //   provider: 'cs',
  //   type: 'percentage',
  // },
  {
    value: 'cs_internationalDelinquencyRating',
    title: 'Delinquency Rating',
    provider: 'cs',
    type: 'percentage',
  },
  {
    value: 'cs_dbt',
    title: 'DBT (Days Beyond Term)',
    provider: 'cs',
    type: 'percentage',
    format: 'integer',
  },
  {
    value: 'cs_derogatoryFilingCount',
    title: 'Derogatory Filing Count',
    provider: 'cs',
    type: 'percentage',
    format: 'integer',
  },
  {
    value: 'cs_derogatoryFilingOutstandingAmount',
    title: 'Derogatory Filing Outstanding Amount',
    provider: 'cs',
    type: 'percentage',
    format: 'currency',
  },
  // {
  //   value: 'cs_paymentBehaviour',
  //   title: 'Payment Behaviour',
  //   provider: 'cs',
  //   type: 'boolean',
  // },
  // {
  //   value: 'cs_paymentExpectation',
  //   title: 'Payment Expectation',
  //   provider: 'cs',
  //   type: 'boolean',
  // },
].filter((option) => !!option);

export default CREDIT_SAFE_FINANCIAL_ALERT_TRIGGERS;
