const parseWeatherData = ({company}) => {
  const aqi =
    company?.Firmographic?.HQState === 'California'
      ? [...(company?.weather?.aqi?.stations || [])]?.[0] || null
      : null;
  const weather =
    company?.Firmographic?.HQState === 'California'
      ? company?.weather?.weather?.data || null
      : null;
  const fire =
    company?.Firmographic?.HQCountry === 'United States'
      ? [...(company?.weather?.fire?.data || [])]
      : [];
  const naturalDisaster =
    company?.Firmographic?.HQCountry === 'United States'
      ? [...(company?.weather?.naturalDisaster?.result || [])]
      : [];

  return {
    aqi: {
      categories: [
        {
          key: 'entry',
          label: '',
        },
        {
          key: 'value',
          label: '',
        },
      ],
      data: !!aqi
        ? [
            {
              entry: 'Updated At',
              value: !!aqi?.updatedAt
                ? {
                    value: (aqi?.updatedAt || '')?.trim().split(' ').join(''),
                    type: 'date',
                    format: 'MMM dd, yyyy HH:mm',
                  }
                : null,
            },
            {
              entry: 'CO',
              value: aqi?.CO,
            },
            {
              entry: 'NO2',
              value: aqi?.NO2,
            },
            {
              entry: 'OZONE',
              value: aqi?.OZONE,
            },
            {
              entry: 'PM10',
              value: aqi?.PM10,
            },
            {
              entry: 'PM25',
              value: aqi?.PM25,
            },
            {
              entry: 'SO2',
              value: aqi?.SO2,
            },
            {
              entry: 'AQI',
              value: aqi?.AQI,
            },
            {
              entry: 'Pollutant',
              value: aqi?.aqiInfo?.pollutant,
            },
            {
              entry: 'Concentration',
              value: aqi?.aqiInfo?.concentration,
            },
            {
              entry: 'Category',
              value: aqi?.aqiInfo?.category,
            },
          ]
        : [],
    },
    weather: {
      categories: [
        {
          key: 'entry',
          label: '',
        },
        {
          key: 'value',
          label: '',
        },
      ],
      data: !!weather
        ? [
            {
              entry: 'Updated At',
              value: {
                value: !!weather?.updatedAt
                  ? (weather?.updatedAt || '')?.trim().split(' ').join('')
                  : null,
                type: 'date',
                format: 'MMM dd, yyyy HH:mm',
              },
            },
            {
              entry: 'Apparent Temperature',
              value: weather?.apparentTemperature,
            },
            {
              entry: 'Cloud Cover',
              value: weather?.cloudCover,
            },
            {
              entry: 'Dew Point',
              value: weather?.dewPoint,
            },
            {
              entry: 'Humidity',
              value: weather?.humidity,
            },
            {
              entry: 'Pressure',
              value: weather?.pressure,
            },
            {
              entry: 'Precip Intensity',
              value: weather?.precipIntensity,
            },
            {
              entry: 'Temperature',
              value: weather?.temperature,
            },
            {
              entry: 'Visibility',
              value: weather?.visibility,
            },
            {
              entry: 'Wind Gust',
              value: weather?.windGust,
            },
            {
              entry: 'OZONE',
              value: weather?.ozone,
            },
            {
              entry: 'UV Index',
              value: weather?.uvIndex,
            },
            {
              entry: 'Wind Speed',
              value: weather?.windSpeed,
            },
            {
              entry: 'Wind Bearing',
              value: weather?.windBearing,
            },
            {
              entry: 'Summary',
              value: weather?.summary,
            },
          ]
        : [],
    },
    fire: {
      categories: [
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'location',
          label: 'Location',
        },
        {
          key: 'confidence',
          label: 'Confidence',
        },
        {
          key: 'frp',
          label: 'FRP',
        },
        {
          key: 'fwi',
          label: 'FWI',
        },
        {
          key: 'fireType',
          label: 'Fire Type',
        },
      ],
      data: fire.map((entry) => ({
        date: {
          value: (entry?.detectedAt || '')?.trim().split(' ').join(''),
          type: 'date',
          format: 'MMM dd, yyyy HH:mm',
        },
        location: `${entry.lat}, ${entry.lng}`,
        confidence: entry.confidence,
        frp: entry.frp,
        fwi: entry.fwi,
        fireType: entry.fireType,
      })),
    },
    naturalDisaster: {
      categories: [
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'event',
          label: 'Event',
        },
        {
          key: 'level',
          label: 'Level',
        },
        {
          key: 'location',
          label: 'Location',
        },
        {
          key: 'severity',
          label: 'Severity',
        },
        {
          key: 'duration',
          label: 'Duration (days)',
        },
        {
          key: 'peopleAffected',
          label: 'People Affected',
        },
      ],
      data: naturalDisaster.map((entry) => ({
        date: {
          value: `${(entry?.start_date || '')
            .split(': ')
            .join(':')
            .split(' ')
            .join('T')}Z`,
          type: 'date',
          format: 'MMM dd, yyyy HH:mm',
        },
        event: entry?.name,
        level: entry?.alert_level,
        location: entry?.country,
        severity: entry?.severity_description,
        duration: entry?.details?.['Duration (days)'],
        peopleAffected: entry?.details?.['People affected'],
      })),
    },
  };
};

export default parseWeatherData;
