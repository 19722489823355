// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// local columns
import riskGaugeRatingColumn from './riskGaugeRating.column';
import creditRiskScoreColumn from './creditRiskScore.column';
import rgRiskLevelColumn from './rgRiskLevel.column';

export default generateColumn({
  title: 'RiskGauge',
  children: [riskGaugeRatingColumn, creditRiskScoreColumn, rgRiskLevelColumn],
});
