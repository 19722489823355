// charts
import Highcharts from 'highcharts';

// company routes
import companyRoute from '../../../company/pages/CompanyPage/route';

// layout colors
import blueGrey300Color from '../../../layout/colors/blueGrey300.color.layout';
import orange300Color from '../../../layout/colors/orange300.color.layout';
import violet300Color from '../../../layout/colors/violet300.color.layout';

// layout components
import Card from '../../../layout/components/Card/Card';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// router
import {useNavigate} from 'react-router-dom';

// uuid
import {v4} from 'uuid';

const HighRiskSuppliersChartCard = ({
  categories = [],
  data = [],
  info,
  logos = {},
  names = {},
}) => {
  const id = v4();

  const navigate = useNavigate();

  useEffect(() => {
    Highcharts.chart(id, {
      colors: [violet300Color, orange300Color, blueGrey300Color],
      chart: {
        type: 'column',
        inverted: true,
        // polar: true,
        height: '260px',
      },
      title: {
        text: 'Top 5 Lowest Halo Scores',
      },
      tooltip: {
        outside: true,
      },
      // pane: {
      //   size: '85%',
      //   innerSize: '20%',
      //   endAngle: 270,
      // },
      legend: {
        enabled: false,
      },
      xAxis: {
        tickInterval: 1,
        labels: {
          align: 'right',
          useHTML: true,
          allowOverlap: true,
          step: 1,
          y: 3,
          style: {
            fontSize: '13px',
          },
          formatter: function () {
            const name = names?.[this.value] || null;
            const logo = logos?.[this.value] || null;
            return !!logo
              ? `<div class="highchartHoverLabel highchartLogoLabel"><div class="hcLogoLabelContainer"><img src="${logo}" /></div>${name}</div>`
              : name;
          },
          events: {
            click: function () {
              const company = categories?.[this.pos] || null;
              navigate(companyRoute(company));
            },
          },
        },
        lineWidth: 0,
        categories,
      },
      yAxis: {
        crosshair: {
          enabled: true,
          color: '#333',
        },
        lineWidth: 0,
        tickInterval: 25,
        reversedStacks: false,
        endOnTick: true,
        showLastLabel: true,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          borderWidth: 0,
          pointPadding: 0,
          groupPadding: 0.15,
          tooltip: {
            pointFormat: '{point.y:,.0f}',
          },
        },
      },
      series: data,
    });
    // eslint-disable-next-line
  }, [data, categories, names, logos]);
  return (
    <Card infoTooltip={info} noPadding>
      <div id={id} />
    </Card>
  );
};

HighRiskSuppliersChartCard.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.array,
  info: PropTypes.node,
  logos: PropTypes.object,
  names: PropTypes.object,
};

export default HighRiskSuppliersChartCard;
