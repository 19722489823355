// company containers
import CompaniesTableContainer from '../../containers/CompaniesTableContainer/CompaniesTableContainer';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local page
import page from './page';

// react
import React from 'react';

const CompaniesPage = () => (
  <Page page={page}>
    <CompaniesTableContainer />
  </Page>
);

export default CompaniesPage;
