// redux store
import reduxStore from '../../redux/store';

// userSettings storages
import userSettingsStorage from '../storages/settings.storage.userSettings';

const getUserSettingsService = async ({
  defaultSettings = {},
  domain = '',
  user = reduxStore.getState().auth.user,
} = {}) => {
  const settings = JSON.parse(userSettingsStorage.get() || '{}');
  const userSettings = settings?.[user?.email] || {};
  return userSettings?.[domain] || defaultSettings;
};

export default getUserSettingsService;
