// local components
import Growth from './Growth';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const GrowthColumn = ({category, columnIndex, entry, entryIndex}) => {
  const {value, growth} = entry[category];
  const parsedValue = value === null ? '-' : value;
  return growth === null ? (
    parsedValue
  ) : (
    <Fragment>
      {parsedValue} <Growth value={growth} />
    </Fragment>
  );
};

GrowthColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default GrowthColumn;
