import styled from 'styled-components';

// colors
import grey400Color from '../../../../../../layout/colors/grey400.color.layout';

const Container = styled.div`
  width: 100%;
  padding: ${({withTitle}) => (withTitle ? '0px 30px 30px 30px' : '30px')};
  border-top: ${({withBorder}) =>
    withBorder ? `1px solid ${grey400Color}` : 'none'};
`;

export default Container;
