// outsideClick
import onClickOutside from 'react-onclickoutside';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// styledComponents
import styled from 'styled-components';

// constants
const SIZE = '280px';

// helper components
const Content = styled.div`
  width: ${SIZE};
  min-width: ${SIZE};
  max-width: ${SIZE};
  height: 32px;
  position: relative;
`;
class Container extends Component {
  static propTypes = {children: PropTypes.node, onClose: PropTypes.func};

  handleClickOutside = () => this.props.onClose();

  render() {
    const {children} = this.props;
    return <Content>{children}</Content>;
  }
}

export default onClickOutside(Container);
