import styled from 'styled-components';

const Grade = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  color: ${({color}) => color};
  font-size: 60px;
  font-weight: 700;
  transform: translate(-50%, calc(-50% + 12px));
  cursor: ${({onClick}) => (!!onClick ? 'pointer' : 'default')};
`;

export default Grade;
