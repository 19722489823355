// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const HighlightedScore = ({children, color = 'inherit', ...props}) => (
  <Container color={color}>{children}</Container>
);

HighlightedScore.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
};

export default HighlightedScore;
