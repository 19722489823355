import styled from 'styled-components';

const Image = styled.img`
  display: block;
  margin-bottom: 4px;
  max-width: 80px;
  max-height: 40px;
`;

export default Image;
