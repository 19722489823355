// layout components
import Card from '../../../layout/components/Card/Card';

// local components
import Container from './components/Container';
import Issue from './components/Issue';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const EsgSpecialIssues = () => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const issues = company.esgInfo.specialissues;

  return (
    <Card
      title={`${issues.length} Special Issue${
        issues.length !== 1 ? 's' : ''
      } affecting ${company.CompanyName}`}
    >
      <Container>
        {issues.map((issue) => (
          <Issue key={issue}>{issue}</Issue>
        ))}
      </Container>
    </Card>
  );
};

export default EsgSpecialIssues;
