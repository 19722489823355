import styled from 'styled-components';

// layout colors
import blackColor from '../../../colors/black.color.layout';
import blueGrey300Color from '../../../colors/blueGrey300.color.layout';
import blueGrey400Color from '../../../colors/blueGrey400.color.layout';
import whiteColor from '../../../colors/white.color.layout';

// router
import {Link} from 'react-router-dom';

const NavigationTab = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 6px;
  padding: 0 12px;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  color: ${({selected}) => (selected ? whiteColor : blueGrey300Color)};
  background: ${({selected}) => (selected ? blueGrey400Color : 'transparent')};
  &:hover {
    color: ${({selected}) => (selected ? whiteColor : blackColor)};
  }
`;

export default NavigationTab;
