import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: ${({multiRows}) => (multiRows ? 'wrap' : 'nowrap')};
`;

export default Container;
