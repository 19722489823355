// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// tag components
import TagDetailModal from '../../components/TagDetailModal/TagDetailModal';

// tag events
import showDetailModalEvent from '../../events/showDetailModal.event.tag';
import showUpdateModalEvent from '../../events/showUpdateModal.event.tag';

class TagDetailContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    tag: null,
  };

  componentDidMount() {
    this.props.subscribe(showDetailModalEvent.subscribe(this.show));
  }

  show = ({tag}) => {
    this.setState({visible: true, tag});
  };

  hide = () => {
    this.setState({visible: false});
  };

  edit = () => {
    const {tag} = this.state;
    this.setState({visible: false});
    showUpdateModalEvent.publish({tag});
  };

  render() {
    const {tag, visible} = this.state;
    return (
      <TagDetailModal
        onClose={this.hide}
        onEdit={this.edit}
        tag={tag}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(TagDetailContainer);
