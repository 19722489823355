// sentry
import * as Sentry from '@sentry/react';

// sentry constants
import DSN from '../constants/dsn.constant.sentry';
import REPLAYS_ON_ERROR_SAMPLE_RATE from '../constants/replaysOnErrorSampleRate.constant.sentry';
import REPLAYS_SESSION_SAMPLE_RATE from '../constants/replaysSessionSampleRate.constant.sentry';
import TRACES_SAMPLE_RATE from '../constants/tracesSampleRate.constant.sentry';

const initializeSentryService = ({
  dsn = DSN,
  replaysOnErrorSampleRate = REPLAYS_ON_ERROR_SAMPLE_RATE(),
  replaysSessionSampleRate = REPLAYS_SESSION_SAMPLE_RATE(),
  tracesSampleRate = TRACES_SAMPLE_RATE(),
} = {}) => {
  if (!dsn) return null;
  return Sentry.init({
    dsn,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    replaysOnErrorSampleRate,
    replaysSessionSampleRate,
    tracesSampleRate,
  });
};

export default initializeSentryService;
