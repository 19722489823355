import styled from 'styled-components';

// layout colors
import brightRed300Color from '../../../colors/brightRed300.color.layout';

const Container = styled.span`
  color: ${({negative}) => (negative ? brightRed300Color : 'inherit')};
`;

export default Container;
