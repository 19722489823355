// ant components
import {Col as Column, Row, Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';
import Title from '../../../layout/components/Title/Title';

// local lib
import generateColumns from './lib/generateColumns.lib';

// financial components
import TradePaymentInformationChart from '../TradePaymentInformationChart/TradePaymentInformationChart';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// score components
import HighlightedScore from '../../../score/components/HighlightedScore/HighlightedScore';

const TradePaymentInformationCard = ({
  currency,
  totalTradeLines,
  valueCategories,
  statuses,
}) => {
  const columns = generateColumns({currency, valueCategories});

  const chartCategories = [...valueCategories].filter(
    ({key}) => key !== 'status'
  );
  const chartData = [...statuses].map((status) => ({
    name: status.status,
    data: [...chartCategories].map(({key}) => status?.[key]?.value || null),
  }));

  return (
    <StatementBlock padding title="Trade Payment Information">
      <Row gutter={[10, 10]}>
        <Column span={5}>
          <Title heading="H4">Total Trade Lines</Title>
          <HighlightedScore>{totalTradeLines}</HighlightedScore>
        </Column>
        <Column span={19}>
          <Table
            columns={columns.valueCategories}
            dataSource={statuses}
            pagination={false}
            rowKey="status"
            size="small"
          />
        </Column>
        <Column span={24}>
          <TradePaymentInformationChart
            categories={[...chartCategories].map(({label}) => label)}
            statuses={chartData}
          />
        </Column>
      </Row>
    </StatementBlock>
  );
};

TradePaymentInformationCard.propTypes = {
  currency: PropTypes.string,
  totalTradeLines: PropTypes.number,
  valueCategories: PropTypes.array,
  values: PropTypes.array,
};

export default TradePaymentInformationCard;
