// chart components
import HighRiskSuppliersChartCard from '../../../chart/components/HighRiskSuppliersChartCard/HighRiskSuppliersChartCard';

// layout components
import {Column, Row} from '../../../layout/components/Grid/Grid';

// local lib
import extractHighRiskSuppliers from './lib/extractHighRiskSuppliers.lib';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const DashboardHighRiskSuppliersSection = () => {
  const {collectionScores} = useSelector((state) => state.dashboard);

  return (
    <Fragment>
      <Row>
        <Column>
          <HighRiskSuppliersChartCard
            {...extractHighRiskSuppliers({data: collectionScores})}
          />
        </Column>
      </Row>
    </Fragment>
  );
};

export default DashboardHighRiskSuppliersSection;
