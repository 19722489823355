// ant components
import {Alert} from 'antd';

// ant icons
import {DownOutlined, UpOutlined} from '@ant-design/icons';

// local components
import Alerts from './components/Alerts';
import AlertsDropDown from './components/AlertsDropDown';
import Badge from './components/Badge';
import Container from './components/Container';
import HeaderRow from './components/HeaderRow';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useState} from 'react';

const AlertsSlider = ({alerts = [], defaultVisible = false}) => {
  const [visible, setVisible] = useState(defaultVisible);

  return (
    !!alerts.length && (
      <Container>
        <HeaderRow
          highlight={!!alerts.length}
          onClick={() => setVisible(!visible)}
        >
          <Badge>{alerts.length}</Badge> Alert{alerts.length > 1 ? 's' : ''}{' '}
          {visible ? <UpOutlined /> : <DownOutlined />}
        </HeaderRow>
        {visible && (
          <AlertsDropDown>
            <Alerts>
              {alerts.map((alertMessage) => (
                <Alert
                  key={alertMessage.id}
                  description={alertMessage.message}
                  type="error"
                  showIcon
                />
              ))}
            </Alerts>
          </AlertsDropDown>
        )}
      </Container>
    )
  );
};

AlertsSlider.propTypes = {
  alerts: PropTypes.array,
  defaultVisible: PropTypes.bool,
};

export default AlertsSlider;
