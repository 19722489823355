// lib
import generateKeyFromLabel from '../../lib/generateKeyFromLabel.lib';

const generateFilter = ({
  filter,
  label,
  options = [],
  fn = () => false,
  props = {},
}) => ({
  filter: filter || generateKeyFromLabel(label),
  label,
  options,
  fn,
  props,
});

export default generateFilter;
