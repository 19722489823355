// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

// tenant containers
import CustomDataLayout from '../../components/CustomDataLayout/CustomDataLayout';

const CustomDataPage = () => (
  <SubPage page={page}>
    <CustomDataLayout />
  </SubPage>
);

export default CustomDataPage;
