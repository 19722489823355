// layout colors
import blueGrey300Color from '../../../../layout/colors/blueGrey300.color.layout';
import orange300Color from '../../../../layout/colors/orange300.color.layout';

const extractViabilityChartData = ({company}) => {
  const categories = [...company.testFinancials.viabilityScoreRanking].map(
    ({score}) => `${score}`
  );
  return {
    viabilityScore: company.testFinancials.viabilityScore,
    categories,
    data: [
      {
        name: 'Out of Business (Bad) Rate',
        type: 'area',
        color: blueGrey300Color,
        yAxis: 1,
        data: [...company.testFinancials.viabilityScoreRanking].map(
          ({outOfBusinessRate}) => outOfBusinessRate
        ),
        tooltip: {
          valueSuffix: '%',
        },
      },
      {
        name: 'Percent of Total',
        type: 'line',
        color: orange300Color,
        data: [...company.testFinancials.viabilityScoreRanking].map(
          ({totalPercent}) => totalPercent
        ),
        tooltip: {
          valueSuffix: '%',
        },
      },
    ],
  };
};

export default extractViabilityChartData;
