// ant components
import {message} from 'antd';

// dashboard redux actions
import {setGeneralDashboardData as setGeneralDashboardDataAction} from '../../../dashboard/redux/reducer.redux.dashboard';

// collection events
import collectionSelectedEvent from '../../events/selected.event.collection';

// collection redux events
import {
  setGeneralCollectionData as setGeneralCollectionDataAction,
  storeCollection as storeCollectionAction,
} from '../../redux/reducer.redux.collection';

// collection services
import getCollectionDataService from '../../services/getData.service.collection';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class CollectionsDataContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    collection: PropTypes.object,
    collectionsData: PropTypes.object,
    loadedCollections: PropTypes.array,
    loadingCollection: PropTypes.bool,
    subscribe: PropTypes.func,
    tenantData: PropTypes.object,
  };

  componentDidMount() {
    this.props.subscribe(
      collectionSelectedEvent.subscribe(this.setupNewCollection)
    );
  }

  setupNewCollection = async (collection) => {
    const {
      dispatch,
      collection: currentCollection,
      loadingCollection,
    } = this.props;

    if (loadingCollection || currentCollection?.id === collection?.id) return;

    dispatch(setGeneralCollectionDataAction({loadingCollection: true}));

    try {
      const collectionScores = await this.loadCollection(collection);
      dispatch(
        storeCollectionAction({
          collection,
          collectionData: collectionScores,
        })
      );
      dispatch(setGeneralDashboardDataAction({collectionScores}));
      dispatch(setGeneralCollectionDataAction({loadingCollection: false}));
    } catch (error) {
      message.warning('Collection data is still being compiled.');
      dispatch(setGeneralCollectionDataAction({loadingCollection: false}));
    }
  };

  loadCollection = async (collection) => {
    const {collectionsData} = this.props;

    const collectionData = collectionsData?.[collection?.id] || null;

    return !!collectionData
      ? collectionData
      : getCollectionDataService({collection});
  };

  render() {
    return null;
  }
}

export default subscriptionHOC(
  connect((state) => ({
    collection: state.collection.collection,
    collectionsData: state.collection.collectionsData,
    loadedCollections: state.collection.loadedCollections,
    loadingCollection: state.collection.loadingCollection,
    tenantData: state.dashboard.tenantData,
  }))(CollectionsDataContainer)
);
