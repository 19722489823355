const LEVELS = [
  [35, 'low'],
  [75, 'medium'],
  [101, 'high'],
];

const getRiskLevel = (score) => {
  const minLevel = [...LEVELS][0];
  const maxLevel = [...LEVELS].slice(-1);
  return score > maxLevel[0]
    ? maxLevel[1]
    : [...LEVELS].find(([maxScore]) => score < maxScore)?.[1] || minLevel[1];
};

export default getRiskLevel;
