import styled from 'styled-components';

const Section = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
`;

export default Section;
