// date lib
import convertDateToUnix from '../../../../date/lib/convertDateToUnix.lib.date';
import orderByDate from '../../../../date/lib/orderByDate.lib.date';

// number lib
import integer from '../../../../number/lib/integer.lib.number';

const extractData = ({company, percentileView}) => {
  return {
    data: percentileView
      ? orderByDate({
          list: company.esgInfo.scores.company.ratios.overall,
          dateAttribute: 'date',
        }).map(({date, value}) => [
          convertDateToUnix({date}),
          integer(value * 100),
        ])
      : orderByDate({
          list: company.esgInfo.scores.company.ratings.overall,
          dateAttribute: 'date',
        }).map(({date, value}) => [convertDateToUnix({date}), integer(value)]),
  };
};

export default extractData;
