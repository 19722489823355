const loadTenantConfigAction = (state, {payload} = {}) => {
  return {
    ...state,
    initialized: true,
    tenant: payload.tenant,
    token: payload.token,
    user: payload.user,
  };
};

export default loadTenantConfigAction;
