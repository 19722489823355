// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';
import CompanyDescriptionColumn from '../../../components/CompaniesTableColumns/dynamicColumns/CompanyDescriptionColumn/CompanyDescriptionColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Long Description',
  width: 500,
  componentProps: ({company}) => ({
    children: (
      <CompanyDescriptionColumn
        description={company?.Firmographic?.LongDescription}
      />
    ),
  }),
  Component: CompanyDefaultDataColumn,
});
