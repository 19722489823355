// layout components
import FormattedNumber from '../../../../layout/components/FormattedNumber/FormattedNumber';

// local constants
import HEADER_ROWS from '../constants/headerRows.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Column = ({category, columnIndex, entry, entryIndex}) => {
  const value = entry[category];
  return !columnIndex ? (
    value
  ) : HEADER_ROWS.includes(entry.key) ? (
    ''
  ) : (
    <FormattedNumber number={value} />
  );
};

Column.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default Column;
