// company lib
import generateCompanyFilter from '../lib/generateFilter.lib.company';

export default generateCompanyFilter({
  filter: 'collection',
  label: 'Collection',
  fn: ({entry, filterValue, suppliers}) => suppliers.includes(entry.CompanyId),
  props: {
    allowClear: false,
  },
});
