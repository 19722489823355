// ant components
import {Col, Row, Select} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local components
import Activities from './components/Activities';
import Activity from './components/Activity';
import Container from './components/Container';
import Date from './components/Date';

// local constants
import ACTIVITY_TYPES from './constants/activityTypes.constant';

// local lib
// import getColumns from './lib/getColumns.lib';

// react
import React, {useState} from 'react';

// redux
import {useSelector} from 'react-redux';

const CrunchbaseNewsList = () => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));
  const [display, setDisplay] = useState('all');

  const availableActivityTypes = [...ACTIVITY_TYPES].reduce(
    (combined, activityType) => {
      const activities = [...(company?.crunchbaseNews || [])].filter(
        (activity) => activity.activity === activityType.key
      );
      return [...combined, {...activityType, activities}];
    },
    []
  );

  return (
    <Container>
      <Row gutter={[20, 20]}>
        {availableActivityTypes.length > 1 && (
          <Col span={24} className="hide-on-print">
            <Select
              value={display}
              style={{width: '200px'}}
              onChange={(value) => setDisplay(value)}
              options={[
                {
                  value: 'all',
                  label: (
                    <div>
                      <i className="mdi mdi-view-list-outline" /> All Activities
                    </div>
                  ),
                },
                ...[...availableActivityTypes].map((activityType) => ({
                  value: activityType.key,
                  label: (
                    <div>
                      <i className={`mdi mdi-${activityType.icon}`} />{' '}
                      {activityType.label}
                    </div>
                  ),
                })),
              ]}
            />
          </Col>
        )}
        {availableActivityTypes.map((activityType) => {
          const displayActivities =
            display === 'all' || display === activityType.key;
          return (
            displayActivities && (
              <Col key={activityType.key} span={24}>
                <StatementBlock
                  title={
                    <div>
                      <i className={`mdi mdi-${activityType.icon}`} />{' '}
                      {activityType.label}
                    </div>
                  }
                >
                  <Activities>
                    {!activityType.activities.length && (
                      <Activity>
                        <div>No Recent {activityType.label}</div>
                      </Activity>
                    )}
                    {activityType.activities.map((activity) => (
                      <Activity
                        key={activity.url}
                        href={activity.url}
                        target="_blank"
                      >
                        <div>{activity.title}</div>
                        <Date>{activity.publishDate}</Date>
                      </Activity>
                    ))}
                  </Activities>
                </StatementBlock>
              </Col>
            )
          );
        })}
      </Row>
      {/* <Table
        columns={getColumns()}
        dataSource={company?.crunchbaseNews || []}
        pagination={false}
        rowKey="URL"
        size="small"
        locale={{emptyText: 'No news'}}
      /> */}
    </Container>
  );
};

export default CrunchbaseNewsList;
