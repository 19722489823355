const GRADES = {
  F: 0,
  'E-': 50,
  E: 53,
  'E+': 57,
  'D-': 60,
  D: 63,
  'D+': 67,
  'C-': 70,
  C: 73,
  'C+': 77,
  'B-': 80,
  B: 83,
  'B+': 87,
  'A-': 90,
  A: 93,
  'A+': 97,
};

export default GRADES;
