import styled from 'styled-components';

// layout colors
import blackColor from '../../../../../../layout/colors/black.color.layout';

// router
import {Link as LinkElement} from 'react-router-dom';

const Link = styled(LinkElement)`
  display: block;
  color: ${blackColor};
`;

export default Link;
