// ant components
import {Col as Column, Row, Table} from 'antd';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const FinancialCompanyDetailCard = ({
  activities,
  currency,
  companyInformation,
  printView,
}) => {
  const columns = generateColumns({activities, currency, companyInformation});

  return (
    <Row gutter={[20, 20]}>
      <Column span={24}>
        <StatementBlock title="Summary">
          <Table
            columns={columns.companyInformation}
            dataSource={companyInformation.entries}
            pagination={false}
            rowKey="total"
            size="small"
          />
        </StatementBlock>
      </Column>
      <Column span={24}>
        <StatementBlock title="Activities">
          <Table
            columns={columns.activities}
            dataSource={activities.entries}
            pagination={false}
            rowKey="total"
            size="small"
          />
        </StatementBlock>
      </Column>
    </Row>
  );
};

FinancialCompanyDetailCard.propTypes = {
  currency: PropTypes.string,
  printView: PropTypes.bool,
  companyInformation: PropTypes.object,
  activities: PropTypes.object,
};

export default FinancialCompanyDetailCard;
