// supplier api
import loadJsonAwsApiGenerator from './loadJsonAwsApiGenerator.api.supplier';

const getSupplierBlackKiteComplianceFindingsApi = async (companyId) => {
  try {
    const data = await loadJsonAwsApiGenerator(
      'black-kite-compliance-findings'
    )(companyId);
    return data;
  } catch (error) {
    return {};
  }
};

export default getSupplierBlackKiteComplianceFindingsApi;
