// charts
import Highcharts from 'highcharts';

// layout colors
import blueGrey300Color from '../../../layout/colors/blueGrey300.color.layout';
import blueGrey500Color from '../../../layout/colors/blueGrey500.color.layout';

// layout components
import Card from '../../../layout/components/Card/Card';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const InnovationChartCard = ({
  categories = [],
  average = [],
  innovationScore = [],
  companyName = '',
}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: `${companyName} Innovation vs. Industry Average`,
      },
      xAxis: [
        {
          categories: categories,
          crosshair: true,
        },
      ],
      yAxis: {
        labels: {
          // eslint-disable-next-line
          format: '{value}',
        },
        title: {
          enabled: false,
        },
      },
      tooltip: {
        shared: true,
        pointFormat:
          // eslint-disable-next-line
          '<div>{series.name}: <b>{point.y:,.0f}</b><br /><div>',
      },
      legend: {},

      series: [
        {
          name: 'Industry Average',
          type: 'column',
          color: blueGrey300Color,
          data: average,
        },
        {
          name: `${companyName} Innovation`,
          type: 'spline',
          data: innovationScore,
          color: blueGrey500Color,
        },
      ],
    });
    // eslint-disable-next-line
  }, [categories, average, innovationScore]);
  return (
    <Card noPadding>
      <div id={id} />
    </Card>
  );
};

InnovationChartCard.propTypes = {
  categories: PropTypes.array,
  companyName: PropTypes.string,
  average: PropTypes.array,
  innovationScore: PropTypes.array,
};

export default InnovationChartCard;
