// workflow lib
import flattenTriggerOptions from './flattenTriggerOptions.lib.workflow';

// workflow services
import getTriggerOptionsService from '../services/getTriggerOptions.service.workflow';

const generateTriggerObjects = ({
  trigger,
  triggers = getTriggerOptionsService(),
}) => {
  const triggerOptions = flattenTriggerOptions(triggers);
  return [...trigger]
    .map(
      (triggerKey) =>
        [...triggerOptions].find(({value}) => value === triggerKey) || null
    )
    .filter((triggerOption) => !!triggerOption);
};

export default generateTriggerObjects;
