import styled from 'styled-components';

// colors
import whiteColor from '../../../../layout/colors/white.color.layout';

const Container = styled.div`
  width: 100%;
  min-height: 24px;
  padding: 2px 0px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-weight: ${({highlight}) => (highlight ? 700 : 400)};
  color: ${whiteColor};
  padding-left: 12px;
  user-select: none;
  cursor: pointer;
`;

export default Container;
