const keyPerformanceIndicatorsDataMap = (company) => {
  const localFinancialsType = [
    ...(company?.financials?.report?.localFinancialStatements || []),
  ]?.[0]?.type;

  return Object.values(
    [...(company?.financials?.report?.localFinancialStatements || [])]
      .filter(({type}) => type === localFinancialsType)
      .reduce(
        (combined, current) => {
          const totalLiabilitiesAndDebt =
            current?.balanceSheet?.totalLiabilitiesAndDebt || 0;
          const totalShareholdersEquity =
            current?.balanceSheet?.totalShareholdersEquity || 0;
          const debtToEquityRatioValue =
            totalLiabilitiesAndDebt / totalShareholdersEquity;
          const debtToEquityRatio = {
            ...combined.debtToEquityRatio,
            table: {
              ...combined.debtToEquityRatio.table,
              entries: [
                {
                  year: {
                    value: current.yearEndDate,
                    type: 'date',
                    format: 'yyyy',
                  },
                  entryData: {
                    value: debtToEquityRatioValue,
                    type: 'ratio',
                  },
                },
                ...combined.debtToEquityRatio.table.entries,
              ],
            },
            chart: [
              {
                category: current.yearEndDate,
                data: [
                  {
                    label: 'Total Liabilities and Debt',
                    value: totalLiabilitiesAndDebt,
                  },
                  {
                    label: 'Total Shareholders Equity',
                    value: totalShareholdersEquity,
                  },
                  {
                    label: 'Debt to Equity Ratio',
                    value: debtToEquityRatioValue,
                  },
                ],
              },
              ...combined.debtToEquityRatio.chart,
            ],
          };

          const currentAssets = current?.balanceSheet?.currentAssets || 0;
          const currentLiabilities =
            current?.balanceSheet?.totalCurrentLiabilities || 0;
          const currentRatioValue = currentAssets / currentLiabilities;
          const currentRatio = {
            ...combined.currentRatio,
            table: {
              ...combined.currentRatio.table,
              entries: [
                {
                  year: {
                    value: current.yearEndDate,
                    type: 'date',
                    format: 'yyyy',
                  },
                  entryData: {
                    value: currentRatioValue,
                    type: 'ratio',
                  },
                },
                ...combined.currentRatio.table.entries,
              ],
            },
            chart: [
              {
                category: current.yearEndDate,
                data: [
                  {
                    label: 'Current Assets',
                    value: currentAssets,
                  },
                  {
                    label: 'Current Liabilities',
                    value: currentLiabilities,
                  },
                  {
                    label: 'Current Ratio',
                    value: currentRatioValue,
                  },
                ],
              },
              ...combined.currentRatio.chart,
            ],
          };

          const netIncome = current?.cashFlow?.netIncome || 0;
          const returnOnEquityValue = current?.ratios?.returnOnEquity || 0;
          const returnOnEquity = {
            ...combined.returnOnEquity,
            table: {
              ...combined.returnOnEquity.table,
              entries: [
                {
                  year: {
                    value: current.yearEndDate,
                    type: 'date',
                    format: 'yyyy',
                  },
                  entryData: {
                    value: returnOnEquityValue,
                    type: 'ratio',
                  },
                },
                ...combined.returnOnEquity.table.entries,
              ],
            },
            chart: [
              {
                category: current.yearEndDate,
                data: [
                  {
                    label: 'Total Shareholders Equity',
                    value: totalShareholdersEquity,
                  },
                  {
                    label: 'Net Income',
                    value: netIncome,
                  },
                  {
                    label: 'Return on Equity',
                    value: returnOnEquityValue,
                  },
                ],
              },
              ...combined.returnOnEquity.chart,
            ],
          };

          return {
            ...combined,
            debtToEquityRatio,
            currentRatio,
            returnOnEquity,
          };
        },
        {
          debtToEquityRatio: {
            indicator: 'Debt to Equity Ratio',
            definition:
              'The debt to equity ratio is a financial, liquidity ratio that compares a company’s total debt to total equity. The debt to equity ratio shows the percentage of company financing that comes from creditors and investors. A higher debt to equity ratio indicates that more creditor financing (bank loans) is used than investor financing (shareholders).',
            table: {
              categories: [
                {
                  key: 'year',
                  label: 'Year',
                },
                {
                  key: 'entryData',
                  label: 'Debt to Equity Ratio',
                },
              ],
              entries: [],
            },
            chart: [],
          },
          currentRatio: {
            indicator: 'Current Ratio',
            definition:
              'The current ratio is a liquidity and efficiency ratio that measures a firm’s ability to pay off its short-term liabilities with its current assets. The current ratio is an important measure of liquidity because short-term liabilities are due within the next year.',
            table: {
              categories: [
                {
                  key: 'year',
                  label: 'Year',
                },
                {
                  key: 'entryData',
                  label: 'Current Ratio',
                },
              ],
              entries: [],
            },
            chart: [],
          },
          returnOnEquity: {
            indicator: 'Return on Equity',
            definition:
              'The return on equity ratio or ROE is a profitability ratio that measures the ability of a firm to generate profits from its shareholders investments in the company. In other words, the return on equity ratio shows how much profit each dollar of common stockholders’ equity generates.',
            table: {
              categories: [
                {
                  key: 'year',
                  label: 'Year',
                },
                {
                  key: 'entryData',
                  label: 'Return on Equity',
                },
              ],
              entries: [],
            },
            chart: [],
          },
        }
      )
  );
};

export default keyPerformanceIndicatorsDataMap;
