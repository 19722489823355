// local components
import AmountInMillionsColumn from '../components/AmountInMillionsColumn';
import BoardAssociationColumn from '../components/BoardAssociationColumn';
import BusinessPartnerRolesColumn from '../components/BusinessPartnerRolesColumn';
import BusinessPartnersColumn from '../components/BusinessPartnersColumn';
import InvestorsColumn from '../components/InvestorsColumn';
import SourcesColumn from '../components/SourcesColumn';
import WorkExperienceColumn from '../components/WorkExperienceColumn';

// local lib
import generateColumn from './generateColumn.lib';

const getColumns = ({organizationId = null} = {}) => ({
  funding: [
    generateColumn({category: 'dealDate', title: 'Date'}),
    generateColumn({category: 'fundingRound', title: 'Round'}),
    generateColumn({
      category: 'dealSizeInMillions',
      title: 'Amount',
      Component: AmountInMillionsColumn,
    }),
    generateColumn({
      category: 'investors',
      title: 'Investors',
      Component: InvestorsColumn,
    }),
    generateColumn({
      category: 'valuationInMillions',
      title: 'Valuation',
      Component: AmountInMillionsColumn,
    }),
    generateColumn({
      category: 'valuationSourceUrls',
      title: 'Sources',
      Component: SourcesColumn,
    }),
  ],
  investors: [generateColumn({category: 'orgName', title: 'Investor'})],
  competitors: [generateColumn({category: 'orgName', title: 'Competitors'})],
  businessRelationships: [
    generateColumn({category: 'startDate', title: 'Date'}),
    generateColumn({
      category: 'businessPartners',
      title: 'Partners',
      Component: BusinessPartnersColumn,
    }),
    generateColumn({
      category: 'businessPartners',
      title: 'Role',
      Component: BusinessPartnerRolesColumn,
    }),
  ],
  people: [
    generateColumn({category: 'name', title: 'Name'}),
    generateColumn({
      category: 'workExperience',
      title: 'Title',
      props: {organizationId},
      Component: WorkExperienceColumn,
    }),
    generateColumn({
      category: 'boardAssocations',
      title: 'Board Association',
      props: {organizationId},
      Component: BoardAssociationColumn,
    }),
  ],
});

export default getColumns;
