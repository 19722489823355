import styled from 'styled-components';

// layout colors
import blueGrey500Color from '../../../../layout/colors/blueGrey500.color.layout';

const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${blueGrey500Color};
  position: absolute;
  bottom: 100%;
  left: 0px;
`;

export default ArrowUp;
