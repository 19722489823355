// company api
import getCompanyByIdApi from '../../api/getById.api.company';

// company lib
import prepareSupplierDashboardData from '../../lib/prepareSupplierDashboardData.lib.company';

// company redux actions
import {setGeneralCompanyData as setGeneralCompanyDataAction} from '../../redux/reducer.redux.company';

// company routes
import companiesRoute from '../../pages/CompaniesPage/route';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// router
import {Navigate, Outlet} from 'react-router-dom';

// tag events
import createdTagEvent from '../../../tag/events/created.event.tag';
import deletedTagEvent from '../../../tag/events/deleted.event.tag';
import updatedTagEvent from '../../../tag/events/updated.event.tag';

class CompanyContainer extends Component {
  static propTypes = {
    alerts: PropTypes.array,
    company: PropTypes.object,
    companyId: PropTypes.string,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    subscribe: PropTypes.func,
  };

  state = {
    redirect: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.loadCompany();
    this.props.subscribe(
      createdTagEvent.subscribe(this.tagCreated),
      deletedTagEvent.subscribe(this.tagDeleted),
      updatedTagEvent.subscribe(this.tagUpdated)
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.companyId !== this.props?.companyId) this.loadCompany();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadCompany = async () => {
    const {alerts, companyId, tenantData, dispatch} = this.props;

    dispatch(setGeneralCompanyDataAction({loading: true}));

    try {
      const companyWithoutHaloScores = await getCompanyByIdApi({companyId});
      const company = prepareSupplierDashboardData({
        alerts,
        company: companyWithoutHaloScores,
        supplierScores: tenantData?.SupplierHaloScores,
        tenantData: tenantData?.BlackKiteSupplierTenantData,
      });
      if (!this.mounted || this.props.companyId !== companyId) return;
      dispatch(setGeneralCompanyDataAction({loading: false, company}));
    } catch (error) {
      if (!this.mounted || this.props.companyId !== companyId) return;
      this.setState({redirect: true});
    }
  };

  tagCreated = (tag) => {
    const {company, dispatch} = this.props;
    const tags = [...(company?.tags || []), tag];
    dispatch(setGeneralCompanyDataAction({company: {...company, tags}}));
  };

  tagDeleted = (tag) => {
    const {company, dispatch} = this.props;
    const tags = [...(company?.tags || [])]?.filter(({id}) => id !== tag.id);
    dispatch(setGeneralCompanyDataAction({company: {...company, tags}}));
  };

  tagUpdated = (tag) => {
    const {company, dispatch} = this.props;
    if (tag?.companyIdsToAdd?.includes(company?.CompanyId))
      return this.tagCreated(tag);
    if (tag?.companyIdsToRemove?.includes(company?.CompanyId))
      return this.tagDeleted(tag);
    const tags = [...(company?.tags || [])]?.map((t) =>
      t.id === tag.id ? tag : t
    );
    dispatch(setGeneralCompanyDataAction({company: {...company, tags}}));
  };

  render() {
    const {company, loading} = this.props;
    const {redirect} = this.state;
    return redirect ? (
      <Navigate to={companiesRoute()} replace />
    ) : loading || !company ? (
      <FullScreenLoader />
    ) : (
      <Outlet />
    );
  }
}

export default connect((state) => ({
  alerts: state.alert.alerts,
  company: state.company.company,
  tenantData: state.dashboard.tenantData,
  loading: state.company.loading,
}))(subscriptionHOC(CompanyContainer));
