import styled from 'styled-components';
import {transitions} from 'polished';

// layout colors
import whiteColor from '../../../colors/white.color.layout';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(135.12deg, #153443 16.73%, #63d57e 209.5%);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100000000000000;
  color: ${whiteColor};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  ${transitions(
    ['opacity'].map((element) => `${element} .2s ease-in-out`).join(', ')
  )};
`;

export default Container;
