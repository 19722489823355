// company components
import CompanyReputationalScoreColumn from '../../../components/CompanyReputationalScoreColumn/CompanyReputationalScoreColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Reputational Score',
  Component: CompanyReputationalScoreColumn,
  sorter: (a, b) => {
    return (
      Number(a?.HaloScores?.Reputational_Area?.Value || 0) -
      Number(b?.HaloScores?.Reputational_Area?.Value || 0)
    );
  },
});
