// company components
import HighlightedScore from '../HighlightedScore/HighlightedScore';

// company filters
import reputationalRiskLevelFilter from '../../filters/reputationalRiskLevel.filter.company';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CompanyReputationalScoreColumn = ({company}) => (
  <HighlightedScore
    score={Number(company?.HaloScores?.Reputational_Area?.Value)}
    filter={reputationalRiskLevelFilter.filter}
  />
);

CompanyReputationalScoreColumn.propTypes = {
  company: PropTypes.object,
};

export default CompanyReputationalScoreColumn;
