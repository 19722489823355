import styled from 'styled-components';

// colors
import blueGrey300Color from '../../../../layout/colors/blueGrey300.color.layout';

const SectionTitle = styled.div`
  width: 100%;
  text-align: center;
  color: ${blueGrey300Color};
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
`;

export default SectionTitle;
