// company components
import CompanyDefaultDataColumn from '../../../components/CompaniesTableColumns/staticColumns/CompanyDefaultDataColumn/CompanyDefaultDataColumn';

// layout components
import TableCellWidth from '../../../../layout/components/TableCellWidth/TableCellWidth';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Credit Trend',
  componentProps: ({company}) => ({
    children: (
      <TableCellWidth width="100px">
        {company?.CreditPosture?.daCreditTrend || '-'}
      </TableCellWidth>
    ),
  }),
  Component: CompanyDefaultDataColumn,
});
