// ant
import {Tooltip} from 'antd';

// company components
import HighlightedScore from '../../../components/HighlightedScore/HighlightedScore';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

// local tooltips
import sa2022Tooltip from './tooltips/sa2022.tooltip.company';

export default generateColumn({
  key: 'sa2022',
  title: <Tooltip title={sa2022Tooltip}>SA-2022</Tooltip>,
  componentProps: ({company}) => ({
    score: Number(company?.Compliance?.['SA-2022Rating']),
    suffix: '%',
  }),
  Component: HighlightedScore,
});
