// ant components
import {Tooltip} from 'antd';

// layout components
import Card from '../../../layout/components/Card/Card';

// local components
import Container from './components/Container';
import DataSource from './components/DataSource';
import DataSourceLink from './components/DataSourceLink';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const EsgActiveFlags = () => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const flags = company.esgInfo.flagsData.flags;

  return (
    <Card title={`${flags.length} Active Flags`}>
      <Container>
        {flags.map((flag) => {
          const content = !!flag?.description ? (
            <Tooltip
              title={
                <div dangerouslySetInnerHTML={{__html: flag?.description}} />
              }
            >
              {flag.name}
            </Tooltip>
          ) : (
            flag.name
          );
          return !!flag?.dslink ? (
            <DataSourceLink key={flag.id} href={flag.dslink} target="_blank">
              {content}
            </DataSourceLink>
          ) : (
            <DataSource key={flag.id}>{content}</DataSource>
          );
        })}
      </Container>
    </Card>
  );
};

export default EsgActiveFlags;
