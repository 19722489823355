// ant components
import Input from 'antd/es/input/Input';

// ant icons
import {SearchOutlined} from '@ant-design/icons';

// highlighter components
import Highlighter from 'react-highlight-words';

// layout components
// import AsyncInput from '../../../layout/components/AsyncInput/AsyncInput';
import Loader from '../../../layout/components/Loader/Loader';

// local components
// import Button from './components/Button';
import Container from './components/Container';
import Highlight from './components/Highlight';
// import Input from './components/Input';
import InputForm from './components/InputForm';
import Loading from './components/Loading';
import NoResults from './components/NoResults';
import Result from './components/Result';
import Results from './components/Results';
import ResultsList from './components/ResultsList';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const NavigationSearch = ({
  hideResults,
  onEnter,
  onResultClick = () => {},
  onSearch,
  results = [],
  resultsVisible,
  search,
  searching,
  showResults,
}) => (
  <Container onClose={hideResults}>
    <InputForm>
      <Input
        onChange={(e) => onSearch(e.target.value)}
        onFocus={showResults}
        onPressEnter={onEnter}
        placeholder="Search Suppliers..."
        prefix={<SearchOutlined />}
        value={search}
      />
    </InputForm>
    {resultsVisible && (
      <Results>
        {searching ? (
          <Loading>
            <Loader />
          </Loading>
        ) : !results.length ? (
          <NoResults>No results found</NoResults>
        ) : (
          <ResultsList>
            {results.map((result) => (
              <Result key={result.CompanyId} onClick={onResultClick(result)}>
                <Highlighter
                  searchWords={[search]}
                  autoEscape={true}
                  textToHighlight={result.CompanyName}
                  highlightTag={Highlight}
                />
              </Result>
            ))}
          </ResultsList>
        )}
        {/* {!searching && <Result withBorder>+ Add Company</Result>} */}
      </Results>
    )}
  </Container>
);

NavigationSearch.propTypes = {
  hideResults: PropTypes.func,
  onEnter: PropTypes.func,
  onResultClick: PropTypes.func,
  onSearch: PropTypes.func,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node,
    })
  ),
  resultsVisible: PropTypes.bool,
  search: PropTypes.string,
  searching: PropTypes.bool,
  showResults: PropTypes.func,
};

export default NavigationSearch;
