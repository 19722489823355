// company components
import SuppliersDashboard from '../../components/SuppliersDashboard/SuppliersDashboard';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const SuppliersDashboardPage = () => (
  <SubPage page={page}>
    <SuppliersDashboard />
  </SubPage>
);

export default SuppliersDashboardPage;
