// table components
import DynamicColumn from '../../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const generateColumns = () => [
  generateGeneralColumn({
    category: 'name',
    Component: DynamicColumn,
    title: 'Contact Name',
  }),
  generateGeneralColumn({
    category: 'position',
    Component: DynamicColumn,
    title: 'Contact Title',
  }),
  generateGeneralColumn({
    category: 'addressStreet',
    Component: DynamicColumn,
    title: 'Address',
  }),
  generateGeneralColumn({
    category: 'addressCity',
    Component: DynamicColumn,
    title: 'City',
  }),
  generateGeneralColumn({
    category: 'addressState',
    Component: DynamicColumn,
    title: 'State',
  }),
  generateGeneralColumn({
    category: 'addressZip',
    Component: DynamicColumn,
    title: 'ZIP',
  }),
];

export default generateColumns;
