// ant components
import {message as messageApi} from 'antd';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// error lib
import parseError from '../../../error/lib/parse.lib.error';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// workflow components
import WorkflowEditorModal from '../../components/WorkflowEditorModal/WorkflowEditorModal';

// workflow events
import showWorkflowCreatorModalEvent from '../../events/showCreatorModal.event.workflow';

// workflow permissions
import canSetupAlertsForOthersPermission from '../../permissions/canSetupAlertsForOthers.permission.workflow';

// workflow services
import createWorkflowService from '../../services/create.service.workflow';

class CreateWorkflowContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
    user: PropTypes.object,
  };

  static DEFAULT_STATE = {
    actions: [],
    actionsPerCompany: '',
    comment: '',
    excludeCompanies: [],
    name: '',
    period: '',
    recipients: [],
    saving: false,
    scope: [],
    tenantWide: false,
    threshold: '',
    trigger: [],
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showWorkflowCreatorModalEvent.subscribe(this.show));
  }

  show = ({
    settings: {
      actions,
      actionsPerCompany,
      comment,
      excludeCompanies,
      period,
      recipients = [],
      scope,
      threshold,
      trigger,
    } = this.constructor.DEFAULT_STATE,
  } = {}) => {
    const {user} = this.props;

    this.setState({
      actions,
      actionsPerCompany,
      comment,
      excludeCompanies,
      name: '',
      period,
      recipients: !!recipients?.length ? recipients : [user.email],
      scope,
      tenantWide: false,
      threshold,
      trigger,
      visible: true,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  change = (key) => (e) => {
    const value = !!e?.target ? e.target.value : e;
    this.setState({[key]: value});
  };

  changeSelection = (key) => (value) => {
    const previousValues = [...this.state[key]];
    const updateValues = previousValues.includes(value)
      ? previousValues.filter((v) => v !== value)
      : [...previousValues, value];
    this.setState({[key]: updateValues});
  };

  clearSelection = (key) => () => {
    this.setState({[key]: []});
  };

  reset = () => {
    this.setState({...this.constructor.DEFAULT_STATE});
  };

  save = async () => {
    const {user} = this.props;
    const {saving, visible, tenantWide, ...workflowAttributes} = this.state;

    if (saving) return;

    this.setState({saving: true});

    try {
      const workflow = {
        ...workflowAttributes,
        createdBy: user.email,
      };
      await createWorkflowService({workflow, tenantWide});
      this.setState({saving: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      messageApi.error(message);
      this.setState({saving: false});
    }
  };

  render() {
    const {...inputs} = this.state;
    return (
      <WorkflowEditorModal
        {...inputs}
        canEditRecipients={canSetupAlertsForOthersPermission()}
        onChange={this.change}
        onChangeSelection={this.changeSelection}
        onClearSelection={this.clearSelection}
        onClose={this.hide}
        onReset={this.reset}
        onSave={this.save}
      />
    );
  }
}

export default connect((state) => ({user: state.auth.user}))(
  subscriptionHOC(CreateWorkflowContainer)
);
