import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';

const SubTitle = styled.div`
  color: ${({color}) => color || blackColor};
`;

export default SubTitle;
