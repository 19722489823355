// posthog
import posthog from 'posthog-js';

// posthog constants
import API_KEY from '../constants/apiKey.service.posthog';
import API_HOST from '../constants/apiHost.service.posthog';

const initializePosthogService = ({
  apiKey = API_KEY,
  apiHost = API_HOST,
} = {}) => {
  posthog.init(apiKey, {
    api_host: apiHost,
    persistence: 'localStorage',
  });
  return posthog;
};

export default initializePosthogService;
