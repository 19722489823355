// charts
import Highcharts from 'highcharts';

// layout colors
import grey300Color from '../../../layout/colors/grey300.color.layout';
import grey600Color from '../../../layout/colors/grey600.color.layout';

// layout components
import Card from '../../../layout/components/Card/Card';
import {Row, Column} from '../../../layout/components/Grid/Grid';

// local components
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const PrivateCapitalEfficiencyChartCard = ({data = [], height = '240px'}) => {
  const id = v4();

  useEffect(() => {
    data.map((category) =>
      Highcharts.chart(`${id}${category.label}`, {
        title: {
          text: category.label,
          style: {
            fontSize: '14px',
            fontWeight: 700,
          },
          floating: true,
          x: 0,
          y: 16,
        },
        chart: {
          type: 'solidgauge',
          height,
          marginTop: 80,
        },
        exporting: {enabled: false},
        tooltip: {
          enabled: false,
        },
        pane: {
          startAngle: 0,
          endAngle: 360,
          // size: '80%',
          background: [
            {
              outerRadius: '100%',
              innerRadius: '80%',
              backgroundColor: grey300Color,
              borderWidth: 0,
            },
          ],
        },
        yAxis: {
          min: 0,
          max: category.value || 1,
          lineWidth: 0,
          tickPositions: [],
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: true,
              borderWidth: 0,
              padding: 0,
              y: -14,
              style: {
                fontSize: '15px',
              },
              // eslint-disable-next-line
              // format: '{point.formattedValue}',
              formatter: function () {
                return !this.point.y
                  ? '—'
                  : `<span>${this?.point?.options?.formattedValue}</span>`;
              },
            },
            linecap: 'round',
            stickyTracking: false,
            rounded: true,
          },
        },
        series: [
          {
            name: category.label,
            data: [
              {
                color: grey600Color,
                radius: '100%',
                innerRadius: '80%',
                y: category.value,
                formattedValue: category.formattedValue,
              },
            ],
          },
        ],
      })
    );
    // eslint-disable-next-line
  }, [data, height]);
  return (
    <Card noPadding>
      <Row>
        <Column>
          <Title>Capital Efficiency</Title>
        </Column>
      </Row>
      <Row>
        {data.map((category) => (
          <Column key={category.label} size={1 / data.length}>
            <div id={`${id}${category.label}`} />
          </Column>
        ))}
      </Row>
    </Card>
  );
};

PrivateCapitalEfficiencyChartCard.propTypes = {
  data: PropTypes.array,
  categories: PropTypes.array,
};

export default PrivateCapitalEfficiencyChartCard;
