import styled from 'styled-components';

// layout colors
import grey300Color from '../../../../layout/colors/grey300.color.layout';
import grey600Color from '../../../../layout/colors/grey600.color.layout';
import whiteColor from '../../../../layout//colors/white.color.layout';

const Container = styled.div`
  width: 100%;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  background: ${whiteColor};
  border: 1px solid ${grey300Color};
  color: ${grey600Color};
`;

export default Container;
