// local components
import Line from './components/Line';
import Tab from './components/Tab';
import Tabs from './components/Tabs';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const NavigationTabs = ({pages = []}) => (
  <Tabs>
    {pages.map((page) => (
      page && <Tab key={page.route} to={page.route} selected={page.selected}>
        {page.page}
        {page.selected && <Line />}
      </Tab>
    ))}
  </Tabs>
);

NavigationTabs.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      page: PropTypes.string,
      route: PropTypes.string,
      selected: PropTypes.bool,
    })
  ),
};

export default NavigationTabs;
