const HALO_FACTOR_RATING_DESCRIPTIONS = {
  CustomerSentiment:
    'This quantifies the degree to which customers of this supplier are satisfied with the products & services they receive.',
  GovernanceMetrics:
    'This quantifies the degree to which this supplier meets acceptable governance standards at the board level and beyond.',
  ESG: 'This quantifies the degree to which this supplier meets acceptable ESG, CSR, & DE&I standards.',
  JurisdictionalSanctions:
    'This quantifies the degree to which this supplier meets their regulatory obligations around operating in sanctioned countries.',
  RegulatoryActions:
    'This quantifies the degree to which this supplier has been the subject of Regulatory Actions.',
  LegalActions:
    'This quantifies the degree to which this supplier has been the subject of Legal Actions.',
  EthicalRisk:
    'This quantifies the degree to which this supplier has been the subject of Ethical Conduct Actions or Investigations.',
  Crime:
    'This quantifies the degree to which this supplier has been the subject of Criminal Conduct Actions or Investigations.',
  SpecialInterestIndividuals:
    "This quantifies the degree to which this supplier's executive team and/or board members includes individuals designated as Special Interest Persons such as Politically Exposed Individuals.",
  CyberSecurity:
    'This quantifies the degree to which this supplier meets acceptable cyber security standards across 20 categories.',
  Compliance:
    'This quantifies the degree to which this supplier meets acceptable info-sec, privacy, and TPRM compliance standards across 14 categories.',
  EmployeeSentiment:
    'This quantifies the degree to which Employees of this suppliers are satisfies with their employer.',
  GeopoliticalFactorRating:
    'This quantifies the degree to which this supplier meets acceptable geopolitical standards.',
  ResilienceFactorRating:
    "This quantifies the degree to which this supplier's operation meets -among other things- acceptable economic and supply-chain standards.",
  Financial:
    'This quantifies the degree to which this supplier meets appropraite financial  measures. ',
  Innovation:
    'This quantifies the degree to which this supplier is always on the lookout for advancements.',
};

export default HALO_FACTOR_RATING_DESCRIPTIONS;
