import styled from 'styled-components';

// layout colors
import grey400Color from '../../../../layout/colors/grey400.color.layout';
import grey800Color from '../../../../layout/colors/grey800.color.layout';
import brightRed300Color from '../../../../layout/colors/brightRed300.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

const HeaderRow = styled.div`
  height: 40px;
  background: ${({highlight}) =>
    highlight ? brightRed300Color : grey400Color};
  color: ${({highlight}) => (highlight ? whiteColor : grey800Color)};
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
  padding: 0px 10px;
  font-weight: 700;
  font-size: 16px;
  user-select: none;
`;

export default HeaderRow;
