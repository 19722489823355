import styled from 'styled-components';

// layout colors
import blueGrey300Color from '../../../../layout/colors/blueGrey300.color.layout';

const Update = styled.div`
  display: inline-block;
  font-size: 20px;
  color: ${blueGrey300Color};
  font-weight: 500;
`;

export default Update;
