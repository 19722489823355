import styled from 'styled-components';

const FilterLabel = styled.div`
  width: 100%;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 700;
`;

export default FilterLabel;
