// data check
import isBoolean from 'is-boolean';
import isNumber from 'is-number';

const prepareSingleCustomTenantDataForTable = (value) => ({
  value,
  type: isBoolean(value) ? 'yesNo' : isNumber(value) ? 'number' : 'default',
});

export default prepareSingleCustomTenantDataForTable;
