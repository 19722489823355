const RESOURCES = [
  {
    section: 'GeoPolitical Risk',
    resources: [
      {
        title:
          'The Impact of the current Middle East conflict on the global suppliers',
        url: 'mock/resources/The Impact of the current Middle East conflict on the global suppliers.pdf',
      },
    ],
  },
];

export default RESOURCES;
