// antd components
import {Tabs as TabsComponent} from 'antd';

// local components
import Section from './components/Section/Section';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment, useState} from 'react';

const Tabs = ({tabProps = {}, tabs, printView}) => {
  const [activeTab, setActiveTab] = useState(tabs?.[0]?.key || null);

  return printView ? (
    <Fragment>
      {tabs.map(({key, label, children}) => (
        <Section key={key} title={label}>
          {children}
        </Section>
      ))}
    </Fragment>
  ) : (
    <TabsComponent
      {...tabProps}
      items={tabs}
      defaultActiveKey={activeTab}
      onChange={(key) => setActiveTab(key)}
    />
  );
};

Tabs.propTypes = {
  tabProps: PropTypes.object,
  tabs: PropTypes.array,
  printView: PropTypes.bool,
};

export default Tabs;
