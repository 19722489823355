import styled from 'styled-components';

const PrintContainer = styled.div`
  width: 100%;

  @media print {
    & .printOverlay {
      display: none;
    }
  }
}
`;

export default PrintContainer;
