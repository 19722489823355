// alert components
import CreditSafeAlertsModal from '../../components/CreditSafeAlertsModal/CreditSafeAlertsModal';

// alert events
import showCreditSafeAlertsEvent from '../../events/showCreditSafeAlerts.event.alert';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class CreditSafeAlertsContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    alerts: [],
    company: null,
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showCreditSafeAlertsEvent.subscribe(this.show));
  }

  show = ({company}) => {
    this.setState({
      alerts: [...(company?.creditSafeAlerts || [])],
      company,
      visible: true,
    });
  };

  hide = () => {
    this.setState({visible: false});
  };

  render() {
    const {alerts, company, visible} = this.state;
    return (
      <CreditSafeAlertsModal
        alerts={alerts}
        company={company}
        onClose={this.hide}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(CreditSafeAlertsContainer);
