// haloGpt lib
import attachMessageIdsToQuestionFlow from './attachMessageIdsToQuestionFlow.lib.haloGpt';
import flattenQuestionFlowMessages from './flattenQuestionFlowMessages.lib.haloGpt';

const prepareQuestionFlow = (originalQuestionFlow) => {
  const questionFlowWithMessageIds = {
    ...originalQuestionFlow,
    messages: attachMessageIdsToQuestionFlow(
      originalQuestionFlow?.messages || []
    ),
  };
  const flatMessages = flattenQuestionFlowMessages({
    messages: questionFlowWithMessageIds?.messages || [],
  });
  return {
    three: questionFlowWithMessageIds,
    flat: {...originalQuestionFlow, messages: flatMessages},
  };
};

export default prepareQuestionFlow;
