// collection components
import CollectionsTable from '../../components/CollectionsTable/CollectionsTable';

// collection events
import showCreateCollectionModalEvent from '../../events/showCreateModal.event.collection';
import showDeleteCollectionModalEvent from '../../events/showDeleteModal.event.collection';
import showUpdateCollectionModalEvent from '../../events/showUpdateModal.event.collection';

// local data
import columns from './data/columns.data';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class CollectionsContainer extends Component {
  static propTypes = {
    collections: PropTypes.array,
  };

  openNewCollectionModal = () => {
    showCreateCollectionModalEvent.publish();
  };

  openUpdateCollectionModal = (collection) => () => {
    showUpdateCollectionModalEvent.publish({collection});
  };

  openRemoveCollectionModal = (collection) => () => {
    showDeleteCollectionModalEvent.publish({collection});
  };

  render() {
    const {collections} = this.props;
    return (
      <CollectionsTable
        columns={columns()}
        dataSource={collections}
        onEntry={this.openUpdateCollectionModal}
        onNewCollection={this.openNewCollectionModal}
      />
    );
  }
}

export default connect((state) => ({
  collections: state.collection.collections,
}))(CollectionsContainer);
