// data lib
import generateAreaPercentageKey from '../../../../data/lib/generateAreaPercentageKey.lib.data';

// risk constants
import KEY_RISK_AREAS from '../../../../risk/constants/keyRiskAreas.constant.risk';

const extractStrategicScores = ({company}) =>
  [...KEY_RISK_AREAS].map((area) => ({
    name: area.label,
    color: area.color,
    y: company?.HaloScores?.[generateAreaPercentageKey(area.label)]?.Value || 0,
  }));

export default extractStrategicScores;
