// local components
import Column from '../components/Column';

const generateColumn = ({
  key,
  title = null,
  ColumnComponent = Column,
  ...props
}) => {
  return {
    title: title || key,
    key: key || title,
    dataIndex: key,
    ...props,
    render: (_, entry, entryIndex) => (
      <ColumnComponent dataKey={key} entry={entry} entryIndex={entryIndex} />
    ),
  };
};

export default generateColumn;
