// collection containers
import CollectionsContainer from '../../containers/CollectionsContainer/CollectionsContainer';

// layout containers
import Page from '../../../layout/containers/Page/Page';

// local page
import page from './page';

// react
import React from 'react';

const CollectionsPage = () => (
  <Page page={page}>
    <CollectionsContainer />
  </Page>
);

export default CollectionsPage;
