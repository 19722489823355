// ant components
import {Col as Column, Row, Table} from 'antd';

// date lib
import formatISODate from '../../../date/formatters/formatISO.formatter.date';

// financial components
import FinancialStatementsKeyPerformanceIndicatorChart from '../FinancialStatementsKeyPerformanceIndicatorChart/FinancialStatementsKeyPerformanceIndicatorChart';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialStatementsKeyPerformanceIndicatorsCard = ({printView}) => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  return (
    <Row gutter={[20, 20]}>
      {company.financials.financials.keyPerformanceIndicators.map((section) => {
        const columns = generateColumns({
          currency: company.financials.currency,
          categories: section.table.categories,
        });
        const chartData = {
          categories: section.chart.map(({category}) =>
            formatISODate({date: category, dateFormat: 'MM/dd/yyyy'})
          ),
          data: Object.entries(
            [...section.chart].reduce((combined, {category, data}) => {
              const formattedData = [...data].reduce(
                (combinedData, entry) => {
                  const existingEntry = combined[entry.label] || [];
                  const updatedEntry = [...existingEntry, entry.value];
                  return {
                    ...combinedData,
                    [entry.label]: updatedEntry,
                  };
                },
                {...combined}
              );
              return formattedData;
            }, {})
          ).map(([key, value]) => {
            const alternativeChart =
              key.endsWith('Ratio') || key.startsWith('Return');
            return {
              name: key,
              data: value,
              type: alternativeChart ? 'spline' : 'column',
              yAxis: alternativeChart ? undefined : 1,
            };
          }),
        };
        return (
          <Column span={24} key={section.indicator}>
            <StatementBlock
              padding
              title={section.indicator}
              tooltip={section.definition}
            >
              <Row gutter={[10, 10]}>
                <Column span={16}>
                  <FinancialStatementsKeyPerformanceIndicatorChart
                    {...chartData}
                  />
                </Column>
                <Column span={8}>
                  <Table
                    columns={columns}
                    dataSource={section.table.entries}
                    pagination={false}
                    rowKey={(record) => `${record.year.value}`}
                    size="small"
                  />
                </Column>
              </Row>
            </StatementBlock>
          </Column>
        );
      })}
    </Row>
  );
};

FinancialStatementsKeyPerformanceIndicatorsCard.propTypes = {
  printView: PropTypes.bool,
};

export default FinancialStatementsKeyPerformanceIndicatorsCard;
