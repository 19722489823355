import styled from 'styled-components';

// layout colors
import whiteColor from '../../../../layout/colors/white.color.layout';

const Alerts = styled.div`
  width: 100%;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  -webkit-overflow-scrolling: touch;
  background: ${whiteColor};
  box-shadow: 0px 4px 12px rgba(20, 40, 60, 0.1);
  border-radius: 8px;
`;

export default Alerts;
