// antd
import {Table, Button, Space} from 'antd';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// local components
import Container from './components/Container';
import TableContainer from './components/TableContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const CollectionsTable = ({columns, dataSource, onEntry, onNewCollection}) => (
  <PageLayout>
    {({Content}) => (
      <Content
        title="Collections"
        moreButton={
          <Space>
            <Button onClick={onNewCollection}>New Collection</Button>
          </Space>
        }
      >
        <Container>
          <TableContainer>
            <Table
              columns={columns}
              dataSource={dataSource}
              rowKey="CompanyId"
              onRow={(record) => ({
                onClick: onEntry(record),
              })}
            />
          </TableContainer>
        </Container>
      </Content>
    )}
  </PageLayout>
);

CollectionsTable.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  onEntry: PropTypes.func,
  onNewCollection: PropTypes.func,
};

export default CollectionsTable;
