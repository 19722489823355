// risk lib
import getScoreColor from './getScoreColor.lib.risk';

const getGradeColor = (grade) => {
  const formattedGrade = `${grade}`.toUpperCase();
  const score = formattedGrade.includes('A')
    ? 100
    : formattedGrade.includes('B')
    ? 80
    : formattedGrade.includes('C')
    ? 60
    : formattedGrade.includes('D')
    ? 50
    : formattedGrade.includes('E')
    ? 30
    : 0;
  return getScoreColor(score);
};

export default getGradeColor;
