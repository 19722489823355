// table components
import DynamicColumn from '../../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const generateColumns = ({currency, summary, cautionaryUCCs, uccs}) => ({
  summary: [...summary.categories].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      props: {currency},
      title: label,
    })
  ),
  cautionaryUCCs: [...cautionaryUCCs.categories].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      props: {currency},
      title: label,
    })
  ),
  uccs: [...uccs.categories].map(({key, label}) =>
    generateGeneralColumn({
      category: key,
      Component: DynamicColumn,
      props: {currency},
      title: label,
    })
  ),
});

export default generateColumns;
