// ant components
import {Table} from 'antd';

// charts
import Highcharts from 'highcharts';

// layout colors
import whiteColor from '../../../layout/colors/white.color.layout';
import brightYellow300Color from '../../../layout/colors/brightYellow300.color.layout';

// layout components
import Card from '../../../layout/components/Card/Card';
import {Column, Row} from '../../../layout/components/Grid/Grid';

// local constants
import COLOR_STOPS from './constants/colorStops.constant';

// local components
import Title from './components/Title';

// local lib
import getSupplierColumns from './lib/getSupplierColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment, useEffect} from 'react';

const CountrySuppliersView = ({
  companyName,
  countryName,
  displayState,
  globalResilienceIndex,
  loading,
  map,
  states,
  suppliers,
}) => {
  const mapId = 'countrySuppliersMapChart';

  useEffect(() => {
    if (!!map) {
      Highcharts.mapChart(mapId, {
        chart: {
          map,
          height: '400px',
        },

        mapNavigation: {
          enabled: true,
          buttonOptions: {
            align: 'right',
            verticalAlign: 'bottom',
            alignTo: 'spacingBox',
          },
        },

        colorAxis: {
          min: 0,
          stops: COLOR_STOPS,
        },

        legend: {
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'bottom',
        },

        series: [
          {
            name: 'Companies',
            data: states,
            joinBy: ['name', 'name'],
            borderColor: whiteColor,
            borderWidth: 1,
            nullColor: '#eee',
            states: {
              hover: {
                color: brightYellow300Color,
              },
            },
          },
        ],
      });
    }

    // eslint-disable-next-line
  }, [companyName, countryName, mapId, loading]);

  return (
    <Card title={`${companyName} Suppliers in ${countryName}`}>
      {!!map && (
        <Row margin>
          <Column>
            <Title>{countryName}</Title>
            <div id={mapId} />
          </Column>
        </Row>
      )}
      {loading ? (
        <Fragment>Loading ...</Fragment>
      ) : (
        <Fragment>
          <Row margin>
            <Column>
              <Title>Global Resilience Index of {countryName}</Title>
              <div>
                Country Rank: #{globalResilienceIndex?.['Country Rank']}
              </div>
              <div>
                Country Score: {globalResilienceIndex?.['Country Score']}
              </div>
              <div>
                Economic Score: {globalResilienceIndex?.['Economic Score']}
              </div>
              <div>
                Risk Quality Score:{' '}
                {globalResilienceIndex?.['Risk Quality Score']}
              </div>
              <div>
                Supply Chain Score:{' '}
                {globalResilienceIndex?.['Supply Chain Score']}
              </div>
            </Column>
          </Row>
          <Title>Suppliers</Title>
          <Table
            columns={getSupplierColumns({withState: displayState})}
            dataSource={suppliers}
            pagination={false}
            rowKey="id"
            size="small"
          />
        </Fragment>
      )}
    </Card>
  );
};

CountrySuppliersView.propTypes = {
  companyName: PropTypes.string,
  countryName: PropTypes.string,
  displayState: PropTypes.bool,
  globalResilienceIndex: PropTypes.object,
  loading: PropTypes.bool,
  map: PropTypes.object,
  states: PropTypes.array,
  suppliers: PropTypes.array,
};

export default CountrySuppliersView;
