// local lib
import extractForensicData from './extractForensicData.lib';

const renderForensicSection = ({company}) => {
  const data = extractForensicData({company});
  const values = Object.values(data);
  return (
    !!values.length && values.some((dataPoints) => !!dataPoints.scores.length)
  );
};

export default renderForensicSection;
