import styled from 'styled-components';

// layout colors
import blackColor from '../../../../layout/colors/black.color.layout';

const TooltipIcon = styled.i`
  color: ${blackColor};
`;

export default TooltipIcon;
