// aws
import {Amplify} from 'aws-amplify';

// aws lib
import generateAwsConfig from './generateConfig.lib.aws';

const setupAmplify = (tenant) =>
  tenant && Amplify.configure(generateAwsConfig(tenant));

export default setupAmplify;
