// company components
import CompanyHaloIntrinsicScoreColumn from '../../../../company/components/CompaniesTableColumns/staticColumns/CompanyHaloIntrinsicScoreColumn/CompanyHaloIntrinsicScoreColumn';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Halo Intrinsic Score',
  Component: CompanyHaloIntrinsicScoreColumn,
  width: 150,
  // sorter: (a, b) => {
  //   return (
  //     Number(a?.HaloScores?.HaloScore?.Value || 0) -
  //     Number(b?.HaloScores?.HaloScore?.Value || 0)
  //   );
  // },
});
