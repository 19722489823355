// event HOC
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// news components
import NewsArticleModal from '../../components/NewsArticleModal/NewsArticleModal';

// news events
import showNewsArticleEvent from '../../events/showArticle.event.news';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class NewsArticleContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    newsArticle: null,
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showNewsArticleEvent.subscribe(this.show));
  }

  show = ({newsArticle}) => {
    this.setState({newsArticle, visible: true});
  };

  hide = () => {
    this.setState({visible: false});
  };

  render() {
    const {newsArticle, visible} = this.state;
    return (
      <NewsArticleModal
        newsArticle={newsArticle}
        onClose={this.hide}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(NewsArticleContainer);
