// creditSafe constants
import CREDIT_SAFE_DIRECTORS_ALERT_TRIGGERS from '../../creditSafe/constants/directorsAlertTriggers.constant.creditSafe';
import CREDIT_SAFE_FINANCIAL_ALERT_TRIGGERS from '../../creditSafe/constants/financialAlertTriggers.constant.creditSafe';
import CREDIT_SAFE_FIRMOGRAPHIC_ALERT_TRIGGERS from '../../creditSafe/constants/firmographicAlertTriggers.constant.creditSafe';
import CREDIT_SAFE_STATUS_ALERT_TRIGGERS from '../../creditSafe/constants/statusAlertTriggers.constant.creditSafe';

// crunchBase constants
import CRUNCH_BASE_NEWS_ALERT_TRIGGERS from '../../crunchBase/constants/newsAlertTriggers.constant.crunchBase';

// lib
import generateLabelFromKeys from '../../lib/generateLabelFromKeys.lib';

// redux store
import reduxStore from '../../redux/store';

// risk constants
import KEY_RISK_AREAS from '../../risk/constants/keyRiskAreas.constant.risk';

const getTriggerOptionsService = ({
  dataObject = reduxStore.getState()?.dashboard?.collectionScores?.HaloScores,
} = {}) => [
  {
    value: 'HaloScore',
    title: 'Halo Intrinsic Score',
    provider: 'halo',
    type: 'percentage',
  },
  ...KEY_RISK_AREAS.map(({label}) => ({
    value: `${label}`,
    title: `Halo ${label}`,
    provider: 'halo',
    children: [
      {value: `${label}_Area`, title: `${label} Risk Area Score`},
      ...Object.keys(dataObject)
        .filter(
          (key) =>
            key.startsWith(`${label}_`) &&
            !key.includes('AreaDistribution') &&
            !key.endsWith('_Area') &&
            !['Date'].includes(key)
        )
        .map((key) => {
          const isFinancialMetric =
            label === 'Financial' && key === 'Financial_Financial';
          const optionAttributes = isFinancialMetric
            ? {
                value: `${key}_Group`,
                children: [
                  {
                    value: key,
                    title: `${generateLabelFromKeys(
                      key.replace(`${label}_`, '')
                    )} Metrics Factor Rating (${label})`,
                  },
                  ...CREDIT_SAFE_FINANCIAL_ALERT_TRIGGERS,
                ],
              }
            : {
                value: key,
                provider: 'halo',
                type: 'percentage',
              };
          return {
            ...optionAttributes,
            title: `${generateLabelFromKeys(
              key.replace(`${label}_`, '')
            )} Metrics Factor Rating (${label})`,
          };
        }),
    ],
  })),
  {
    value: 'status',
    title: 'Status',
    provider: 'cs',
    children: [...CREDIT_SAFE_STATUS_ALERT_TRIGGERS],
  },
  {
    value: 'firmographic',
    title: 'Firmographic',
    provider: 'cs',
    children: [...CREDIT_SAFE_FIRMOGRAPHIC_ALERT_TRIGGERS],
  },
  {
    value: 'directors',
    title: 'Officers / Directors',
    provider: 'cs',
    children: [...CREDIT_SAFE_DIRECTORS_ALERT_TRIGGERS],
  },
  {
    value: 'news',
    title: 'News',
    provider: 'cb',
    children: [...CRUNCH_BASE_NEWS_ALERT_TRIGGERS],
  },
];

export default getTriggerOptionsService;
