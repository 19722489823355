// company containers
import CompanyNewsContainer from '../../containers/CompanyNewsContainer/CompanyNewsContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const NewsPage = () => (
  <SubPage page={page}>
    <CompanyNewsContainer />
  </SubPage>
);

export default NewsPage;
