import styled from 'styled-components';

// layout colors
import orange300Color from '../../../../layout/colors/orange300.color.layout';

const TooltipContainer = styled.div`
  display: inline-block;
  color: ${orange300Color};
  font-size: 16px;
  transform: translateY(-30px);
`;

export default TooltipContainer;
