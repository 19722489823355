// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const MissingDataRow = ({children = 'No available data'}) => (
  <Container>{children}</Container>
);

MissingDataRow.propTypes = {
  children: PropTypes.node,
};

export default MissingDataRow;
