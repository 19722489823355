// qs
import qs from 'qs';

const parseRoute = ({path, query = {}, hash = null} = {}) => {
  const queryString = qs.stringify(query);
  const queryStringWithPrefix = !!queryString ? `?${queryString}` : '';
  const hashString = !!hash ? `#${hash}` : '';
  return `${path}${queryStringWithPrefix}${hashString}`;
};

export default parseRoute;
