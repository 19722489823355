// react
import {Component} from 'react';

// propType
import PropTypes from 'prop-types';

// redux
import {connect} from 'react-redux';

// tenant api
import getCustomTenantDataApi from '../../api/getCustomData.api.tenant';

// tenant redux actions
import {setGeneralTenantData as setGeneralTenantDataAction} from '../../redux/reducer.redux.tenant';

class LoadCustomDataContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    this.loadCustomData();
  }

  loadCustomData = async () => {
    const {dispatch} = this.props;

    dispatch(setGeneralTenantDataAction({loadingCustomData: true}));

    try {
      const customData = await getCustomTenantDataApi();
      dispatch(
        setGeneralTenantDataAction({
          loadingCustomData: false,
          customDataLoaded: true,
          customData,
        })
      );
    } catch (error) {
      dispatch(
        setGeneralTenantDataAction({
          loadingCustomData: false,
          customDataLoaded: true,
        })
      );
    }
  };

  render() {
    return null;
  }
}

export default connect()(LoadCustomDataContainer);
