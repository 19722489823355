// workflow events
import workflowDeletedEvent from '../events/deleted.event.workflow';

// workflow repositories
import deleteWorkflowRepository from '../repositories/delete.repository.workflow';

const deleteWorkflowService = async ({
  deleteWorkflow = deleteWorkflowRepository,
  workflow,
  workflowDeleted = workflowDeletedEvent.publish,
}) => {
  const deletedWorkflow = await deleteWorkflow({workflow});
  workflowDeleted({workflow: deletedWorkflow});
  return deletedWorkflow;
};

export default deleteWorkflowService;
