// notification userSettings
import notificationsUserSettings from '../userSettings/userSettings.notification';

const saveAllNotificationsRepository = async ({notifications}) => {
  await notificationsUserSettings.saveSettings({
    notifications,
  });
  return notifications;
};

export default saveAllNotificationsRepository;
