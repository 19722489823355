// ant components
import {Tag} from 'antd';

// company routes
import companyRoute from '../../../../pages/CompanyPage/route';

// layout colors
import blueGrey300Color from '../../../../../layout/colors/blueGrey300.color.layout';

// local components
import Image from './components/Image';
import Link from './components/Link';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// supplier lib
import getSupplierLogoUrl from '../../../../../supplier/lib/getLogoUrl.lib.supplier';

const CompanySupplierColumn = ({company}) => {
  const logoURL = getSupplierLogoUrl({path: company.Domain});
  return (
    <Link to={companyRoute(company.CompanyId)}>
      {!!logoURL && <Image src={logoURL} />}
      <div>{company.CompanyName || company.Firmographic.LegalName}</div>
      <div>{company.Ticker || 'Private'}</div>
      <Tag
        color={blueGrey300Color}
        icon={<i className="mdi mdi-arrow-right" />}
      >
        Go to dashboard
      </Tag>
    </Link>
  );
};

CompanySupplierColumn.propTypes = {
  company: PropTypes.object,
};

export default CompanySupplierColumn;
