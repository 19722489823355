// layout colors
import blackColor from '../../colors/black.color.layout';
import green300Color from '../../colors/green300.color.layout';

// loaders
import {ProgressBar} from 'react-loader-spinner';

// local components
import Container from './components/Container';
import Logo from './components/Logo';

// react
import React from 'react';

const FullScreenLoader = () => (
  <Container>
    <Logo />
    <ProgressBar
      height="80"
      borderColor={blackColor}
      barColor={green300Color}
    />
  </Container>
);

export default FullScreenLoader;
