// financial lib
import displayUpdatedFinancials from '../../../../financial/lib/displayUpdatedFinancials.lib.financial';

// layout colors
import blueGrey300Color from '../../../../layout/colors/blueGrey300.color.layout';
import blueGrey500Color from '../../../../layout/colors/blueGrey500.color.layout';
import orange300Color from '../../../../layout/colors/orange300.color.layout';
// import red300Color from '../../../../layout/colors/red300.color.layout';
// import surfGreen300Color from '../../../../layout/colors/surfGreen300.color.layout';

// number lib
import integer from '../../../../number/lib/integer.lib.number';
import twoDecimals from '../../../../number/lib/twoDecimals.lib.number';

const renderChart = (data) =>
  !!data && data.some((dataPoint) => dataPoint !== null);

const generateColumn = ({name, color, data}) =>
  renderChart(data)
    ? {
        name,
        type: 'column',
        yAxis: 1,
        color,
        data: [...(data || [])].map((value) =>
          value === null ? null : twoDecimals(value)
        ),
        tooltip: {
          valuePrefix: '$',
          valueSuffix: 'M',
        },
      }
    : null;

const generateSpline = ({name, data, color}) =>
  renderChart(data)
    ? {
        name,
        type: 'spline',
        data: [...(data || [])].map((value) =>
          value === null ? null : integer(value)
        ),
        color,
        tooltip: {
          valueSuffix: '%',
        },
      }
    : null;

const extractFinancialData = ({company}) => {
  const updatedFinancials = displayUpdatedFinancials(company);

  const data = updatedFinancials
    ? [
        generateColumn({
          color: blueGrey300Color,
          data: company.KeyFinancials.totalRevenue,
          name: 'Total Revenue ($)',
        }),
        generateColumn({
          color: blueGrey500Color,
          data: company?.KeyFinancials?.['EBITDA(non-GAAP)'],
          name: 'EBITDA',
        }),
        // generateSpline({
        //   color: surfGreen300Color,
        //   data: company?.KeyFinancials?.['totalRevenue/totalAssetsPercentage'],
        //   name: 'Total Rev / Total Assets (%)',
        // }),
        generateSpline({
          color: orange300Color,
          data: company?.KeyFinancials?.[
            'operatingProfit/totalRevenuePercentage'
          ],
          name: 'Operating Profit / Total Rev (%)',
        }),
        // generateSpline({
        //   color: red300Color,
        //   data: company?.KeyFinancials?.['operatingProfit/interestExp'],
        //   name: 'Operating Profit / Interest Exp',
        // }),
      ]
    : [
        generateColumn({
          color: blueGrey300Color,
          data: company.KeyFinancials.totalRevenue,
          name: 'Total Revenue ($)',
        }),
        generateSpline({
          color: blueGrey500Color,
          data: company?.KeyFinancials?.TotalRevenuesOneYearGrowthPercentage,
          name: 'Total Revenue, 1 Year Growth (%)',
        }),
      ];

  const availableCategories = [...(company.KeyFinancials.term || [])];
  const [lastCategory, ...reversedCategories] = [
    ...availableCategories,
  ].reverse();
  const categoriesWithoutLast = [...reversedCategories].reverse();
  const categories = !!lastCategory
    ? [...categoriesWithoutLast, 'LTM']
    : [...categoriesWithoutLast];

  const filteredData = [...data].filter((data) => !!data);

  const {filteredCategories, removedCategoryIndexes} = [...categories].reduce(
    (combined, category, index) => {
      const shouldIncludeCategory = filteredData.some(
        (data) => data.data[index] !== null
      );
      return shouldIncludeCategory
        ? {
            ...combined,
            filteredCategories: [...combined.filteredCategories, category],
          }
        : {
            ...combined,
            removedCategoryIndexes: [...combined.removedCategoryIndexes, index],
          };
    },
    {filteredCategories: [], removedCategoryIndexes: []}
  );

  const dataWithoutUnusedCategories = [...filteredData].map((data) => ({
    ...data,
    data: [...data.data].filter(
      (dataPoint, index) => !removedCategoryIndexes.includes(index)
    ),
  }));

  return {
    categories: filteredCategories,
    data: dataWithoutUnusedCategories,
  };
};

export default extractFinancialData;
