import styled from 'styled-components';

const Line = styled.div`
  width: ${({width}) => width};
  height: 3px;
  background: ${({color}) => color};
  border-radius: 2px;
  overflow: hidden;
`;

export default Line;
