// table components
import DynamicColumn from '../../../../table/components/DynamicColumn/DynamicColumn';

// table lib
import generateGeneralColumn from '../../../../table/lib/generateGeneralColumn.lib.table';

const generateColumns = () => [
  generateGeneralColumn({
    category: 'entry',
    Component: DynamicColumn,
    title: '',
  }),
  generateGeneralColumn({
    category: 'value',
    Component: DynamicColumn,
    title: '',
  }),
];

export default generateColumns;
