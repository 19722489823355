// aws constants
import AWS_PROJECT_REGION from '../constants/projectRegion.constant.aws';

const generateAwsConfig = (tenant) => ({
  aws_project_region: AWS_PROJECT_REGION,
  aws_cognito_region: tenant.region,
  aws_user_pools_id: tenant.userPoolId,
  aws_user_pools_web_client_id: tenant.appClientId,
});

export default generateAwsConfig;
