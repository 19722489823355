// ant components
import {message as messageApi} from 'antd';

// event HOCs
import subscriptionHOC from '../../../event/hoc/subscription.hoc.event';

// error lib
import parseError from '../../../error/lib/parse.lib.error';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// workflow components
import WorkflowEditorModal from '../../components/WorkflowEditorModal/WorkflowEditorModal';

// workflow events
import showWorkflowEditorModalEvent from '../../events/showEditorModal.event.workflow';

// workflow permissions
import canEditWorkflowPermission from '../../permissions/canEditWorkflow.permission.workflow';
import canSetupAlertsForOthersPermission from '../../permissions/canSetupAlertsForOthers.permission.workflow';

// workflow services
import updateWorkflowService from '../../services/update.service.workflow';

class EditWorkflowContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    actions: [],
    actionsPerCompany: '',
    comment: '',
    excludeCompanies: [],
    name: '',
    period: '',
    recipients: [],
    saving: false,
    scope: [],
    tenantWide: false,
    threshold: '',
    trigger: [],
    visible: false,
    workflow: null,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showWorkflowEditorModalEvent.subscribe(this.show));
  }

  show = ({workflow}) => {
    this.setState({
      actions: workflow?.settings?.actions || [],
      actionsPerCompany: workflow?.settings?.actionsPerCompany || '',
      comment: workflow?.settings?.comment || '',
      excludeCompanies: workflow?.settings?.excludeCompanies || [],
      name: workflow?.settings?.name || '',
      period: workflow?.settings?.period || '',
      recipients: workflow?.settings?.recipients || [],
      saving: workflow?.settings?.saving || false,
      scope: workflow?.settings?.scope || [],
      tenantWide: !(workflow?.settings?.recipients || []).length,
      threshold: workflow?.settings?.threshold || '',
      trigger: workflow?.settings?.trigger || [],
      visible: true,
      workflow,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  change = (key) => (e) => {
    const value = !!e?.target ? e.target.value : e;
    this.setState({[key]: value});
  };

  changeSelection = (key) => (value) => {
    const previousValues = [...this.state[key]];
    const updateValues = previousValues.includes(value)
      ? previousValues.filter((v) => v !== value)
      : [...previousValues, value];
    this.setState({[key]: updateValues});
  };

  clearSelection = (key) => () => {
    this.setState({[key]: []});
  };

  reset = () => {
    this.setState({...this.constructor.DEFAULT_STATE});
  };

  save = async () => {
    const {
      saving,
      visible,
      workflow: existingWorkflow,
      tenantWide,
      ...workflowAttributes
    } = this.state;

    if (saving) return;

    this.setState({saving: true});

    try {
      const workflow = {
        ...existingWorkflow,
        emails: workflowAttributes?.recipients || [],
        settings: {
          ...existingWorkflow.settings,
          ...workflowAttributes,
        },
      };
      await updateWorkflowService({workflow, tenantWide});
      this.setState({saving: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      messageApi.error(message);
      this.setState({saving: false});
    }
  };

  render() {
    const {workflow, ...inputs} = this.state;
    return (
      <WorkflowEditorModal
        {...inputs}
        canEditRecipients={canSetupAlertsForOthersPermission()}
        canEditWorkflow={canEditWorkflowPermission({workflow})}
        onChange={this.change}
        onChangeSelection={this.changeSelection}
        onClearSelection={this.clearSelection}
        onClose={this.hide}
        onReset={this.reset}
        onSave={this.save}
      />
    );
  }
}

export default subscriptionHOC(EditWorkflowContainer);
