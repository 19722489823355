// financial containers
import CompanyWeatherContainer from '../../../weather/containers/CompanyWeatherContainer/CompanyWeatherContainer';

// layout containers
import SubPage from '../../../layout/containers/SubPage/SubPage';

// local page
import page from './page';

// react
import React from 'react';

const WeatherPage = () => (
  <SubPage page={page}>
    <CompanyWeatherContainer />
  </SubPage>
);

export default WeatherPage;
