// dashboard components
import DashboardHighRiskSuppliersSection from '../DashboardHighRiskSuppliersSection/DashboardHighRiskSuppliersSection';
// import DashboardFinancialAndCyberHighRiskSuppliersSection from '../DashboardFinancialAndCyberHighRiskSuppliersSection/DashboardFinancialAndCyberHighRiskSuppliersSection';

// layout components
import {Column, Row} from '../../../layout/components/Grid/Grid';

// react
import React, {Fragment} from 'react';

const DashboardTop5Section = () => (
  <Fragment>
    <Row>
      <Column>
        <DashboardHighRiskSuppliersSection />
      </Column>
    </Row>
    {/* <Row>
      <Column>
        <DashboardFinancialAndCyberHighRiskSuppliersSection />
      </Column>
    </Row> */}
  </Fragment>
);

export default DashboardTop5Section;
