import React, {useCallback, useEffect, useMemo, Fragment} from 'react';
import {AutoComplete, Button, Input} from 'antd';
import {FiPlus} from 'react-icons/fi';
import {Link, useNavigate} from 'react-router-dom';

import {UnstyledLink} from './Links';
import {useSearchTerm} from '../hooks/useSearchTerm';

export const Search = ({
  className,
  style,
  placeholder,
  prefix,
  value,
  onChange,
  basicSearch = false,
}) => {
  const LOCAL = 'local';
  const HALO = 'halo';

  const buildNotFoundLink = ({id, instancetype}) => `/companies/add`;
  const buildGoToLink = ({id, instancetype}) => `/companies/${id}`;

  const NotFound = () => (
    <Fragment>
      <div className="ant-select-item ant-select-item-group">{`No companies found!`}</div>
      <Link to={`/companies/add`}>
        <Button
          block
          icon={<FiPlus />}
          size="small"
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
          type="primary"
        >
          {`ADD NEW ${'COMPANY'}`}
        </Button>
      </Link>
    </Fragment>
  );

  const LabelLink = ({id, name, instancetype}) => (
    <UnstyledLink to={buildGoToLink({id, instancetype})}>{name}</UnstyledLink>
  );

  const renderTitle = (instancetype) =>
    instancetype === LOCAL
      ? `Companies in your instance`
      : `Other known companies`;

  const navigate = useNavigate();
  const {searchTerm, searchResults, setSearchTerm} = useSearchTerm();
  const companySearchResults = useMemo(() => {
    function idNameToLabelValue(instancetype) {
      return ({item: {id, name}}) => ({
        label: basicSearch ? null : (
          <LabelLink id={id} name={name} instancetype={instancetype} />
        ),
        value: basicSearch ? `${name}` : `${name}`,
        instancetype,
        id,
      });
    }
    function eitherEmptyOrOptions(maybeOptions, instancetype) {
      return maybeOptions.length === 0
        ? []
        : [
            {
              label: renderTitle(instancetype),
              options: maybeOptions.map(idNameToLabelValue(instancetype)),
            },
          ];
    }
    function companiesSearchResultToRendarableOptions({
      companiesInHaloResults,
      companiesInLocalResults,
    }) {
      return companiesInHaloResults.length === 0 &&
        companiesInLocalResults.length === 0
        ? [
            {
              label: 'Companies',
              options: [
                {
                  value: `company-not-found`,
                  label: <NotFound />,
                },
              ],
            },
          ]
        : eitherEmptyOrOptions(companiesInLocalResults, LOCAL).concat(
            eitherEmptyOrOptions(companiesInHaloResults, HALO)
          );
    }
    return companiesSearchResultToRendarableOptions(searchResults);
  }, [searchResults, basicSearch]);

  const searchResultsOptions = useMemo(() => {
    return companySearchResults;
  }, [companySearchResults]);

  const handleOnSelect = useCallback((_lbl, itemData) => {
    if (onChange) onChange(searchTerm);
    if (basicSearch) return;
    const linkBuilder = itemData.value.includes('not-found')
      ? buildNotFoundLink
      : buildGoToLink;
    navigate(linkBuilder(itemData));
    setSearchTerm('');
    // eslint-disable-next-line
  }, []);

  const isNotFound =
    searchTerm.length === 0 ||
    (basicSearch &&
      searchResults.companiesInHaloResults.length === 0 &&
      searchResults.companiesInLocalResults.length === 0);
  const placeholderText = placeholder ? placeholder : 'Search Suppliers...';

  useEffect(() => {
    setSearchTerm(value || '');
    // eslint-disable-next-line
  }, [null]);

  useEffect(() => {
    if (onChange) onChange(searchTerm);
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <AutoComplete
      onChange={setSearchTerm}
      options={isNotFound ? [] : searchResultsOptions}
      value={searchTerm}
      onSelect={handleOnSelect}
    >
      <Input
        placeholder={placeholderText}
        style={{width: 320, ...style}}
        className={className}
        prefix={prefix}
      />
    </AutoComplete>
  );
};
