// financial components
import FinancialLegalFilingsSuitsAndJudgmentsCard from '../FinancialLegalFilingsSuitsAndJudgmentsCard/FinancialLegalFilingsSuitsAndJudgmentsCard';
import FinancialLegalFilingsUCCsCard from '../FinancialLegalFilingsUCCsCard/FinancialLegalFilingsUCCsCard';
import FinancialLegalFilingSummaryCard from '../FinancialLegalFilingSummaryCard/FinancialLegalFilingSummaryCard';

// layout components
import Tabs from '../../../layout/components/Tabs/Tabs';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialLegalFilings = ({printView}) => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const tabs = [
    {
      key: 'summary',
      label: 'Summary',
      children: (
        <FinancialLegalFilingSummaryCard
          currency={company.financials.currency}
          legal={company.financials.legal}
          printView={printView}
        />
      ),
    },
    {
      key: 'suitsAndJudgments',
      label: 'Suits & Judgments',
      children: (
        <FinancialLegalFilingsSuitsAndJudgmentsCard
          currency={company.financials.currency}
          suitsAndJudgments={company.financials.legal.suitsAndJudgments}
          printView={printView}
        />
      ),
    },
    {
      key: 'UCCs',
      label: 'UCCs',
      children: (
        <FinancialLegalFilingsUCCsCard
          currency={company.financials.currency}
          ucc={company.financials.legal.ucc}
          printView={printView}
        />
      ),
    },
  ].filter((tab) => !!tab);

  return <Tabs tabs={tabs} printView={printView} />;
};

FinancialLegalFilings.propTypes = {
  printView: PropTypes.bool,
};

export default FinancialLegalFilings;
