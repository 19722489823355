// antd
import {Table} from 'antd';

// layout components
import Card from '../../../layout/components/Card/Card';
import Column from '../../../layout/components/Grid/Column/Column';
import Row from '../../../layout/components/Grid/Row/Row';
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import extractTableData from './lib/extractTableData.lib';
import getColumns from './lib/getColumns.lib';

// react
import React, {Fragment} from 'react';

// redux
import {useSelector} from 'react-redux';

const PrivateCompanyFinancialsTable = () => {
  const {company} = useSelector((state) => state.company);

  const columns = getColumns();
  const data = extractTableData({company});

  return (
    <Fragment>
      <Row margin>
        <Column size={2 / 3} noPrintBreak>
          <StatementBlock title="Supplier Info">
            <Card noPadding>
              <Table
                columns={columns.info}
                dataSource={data}
                pagination={false}
                rowKey="section"
                size="small"
                locale={{emptyText: 'No financials data available'}}
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column size={1 / 3} noPrintBreak>
          <StatementBlock title="Funding">
            <Card noPadding>
              <Table
                columns={columns.funding}
                dataSource={data}
                pagination={false}
                rowKey="section"
                size="small"
                locale={{emptyText: 'No financials data available'}}
              />
            </Card>
          </StatementBlock>
        </Column>
      </Row>
      <Row margin>
        <Column noPrintBreak>
          <StatementBlock title="Revenue">
            <Card noPadding>
              <Table
                columns={columns.revenue}
                dataSource={data}
                pagination={false}
                rowKey="section"
                size="small"
                locale={{emptyText: 'No financials data available'}}
              />
            </Card>
          </StatementBlock>
        </Column>
      </Row>
      <Row>
        <Column size={2 / 3} noPrintBreak>
          <StatementBlock title="Valuation">
            <Card noPadding>
              <Table
                columns={columns.valuation}
                dataSource={data}
                pagination={false}
                rowKey="section"
                size="small"
                locale={{emptyText: 'No financials data available'}}
              />
            </Card>
          </StatementBlock>
        </Column>
        <Column size={1 / 3} noPrintBreak>
          <StatementBlock title="Innovation">
            <Card noPadding>
              <Table
                columns={columns.innovation}
                dataSource={data}
                pagination={false}
                rowKey="section"
                size="small"
                locale={{emptyText: 'No financials data available'}}
              />
            </Card>
          </StatementBlock>
        </Column>
      </Row>
    </Fragment>
  );
};

export default PrivateCompanyFinancialsTable;
