// currency
import getSymbolFromCurrency from 'currency-symbol-map';

// number lib
import shortenNumber from './shorten.lib.number';

const shortenCurrency = ({
  currency = null,
  currencySymbol = null,
  displayCurrency = false,
  displayCurrencySymbol = true,
  number,
  removeZeroDecimals = true,
  valueIfMissing = '-',
  withDecimals = true,
}) => {
  if (number === null) return valueIfMissing;
  const formattedCurrencySymbol =
    currencySymbol || getSymbolFromCurrency(currency);
  return [
    displayCurrencySymbol && formattedCurrencySymbol,
    shortenNumber({number, withDecimals, removeZeroDecimals}),
    displayCurrency && !!currency ? ` ${currency}` : null,
  ]
    .filter((value) => !!value)
    .join('')
    ?.toUpperCase?.();
};

export default shortenCurrency;
