// layout color
import brightGreen300Color from '../../layout/colors/brightGreen300.color.layout';
import brightRed300Color from '../../layout/colors/brightRed300.color.layout';
import brightRed500Color from '../../layout/colors/brightRed500.color.layout';
import brightYellow500Color from '../../layout/colors/brightYellow500.color.layout';
import green300Color from '../../layout/colors/green300.color.layout';

const FINANCIAL_RISK_SCORES = [
  {
    key: 'veryLowRisk',
    label: 'Very Low Risk',
    grade: 'A',
    min: 71,
    max: 100,
    color: brightGreen300Color,
  },
  {
    key: 'lowRisk',
    label: 'Low Risk',
    grade: 'B',
    min: 51,
    max: 70,
    color: green300Color,
  },
  {
    key: 'moderateRisk',
    label: 'Moderate Risk',
    grade: 'C',
    min: 30,
    max: 50,
    color: brightYellow500Color,
  },
  {
    key: 'highRisk',
    label: 'High Risk',
    grade: 'D',
    min: 21,
    max: 29,
    color: brightRed300Color,
  },
  {
    key: 'veryHighRisk',
    label: 'Very High Risk',
    grade: 'D',
    min: 1,
    max: 20,
    color: brightRed500Color,
  },
  {
    key: 'noScore',
    label: 'No Score / Recent Bankruptcy',
    grade: 'E',
    min: 0,
    max: 0,
    color: brightRed500Color,
  },
];

export default FINANCIAL_RISK_SCORES;
