import styled from 'styled-components';

const Indicator = styled.div`
  position: absolute;
  left: ${({score}) => `${score * 100}%`};
  ${({top}) => `${top ? `bottom` : `top`}: calc(100%)`};
  width: 1px;
  color: ${({color}) => color};
  font-size: 50px;
  line-height: 20px;
`;

export default Indicator;
