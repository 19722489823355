import styled from 'styled-components';

// layout colors
import green300Color from '../../../../layout/colors/green300.color.layout';
import whiteColor from '../../../../layout/colors/white.color.layout';

// constants
const SIZE = '26px';

const Send = styled.div`
  width: ${SIZE};
  min-width: ${SIZE};
  max-width: ${SIZE};
  height: ${SIZE};
  min-height: ${SIZE};
  max-height: ${SIZE};
  border-radius: 50%;
  background: ${green300Color};
  color: ${whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  font-size: 18px;
`;

export default Send;
