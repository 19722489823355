// ant components
import {Segmented} from 'antd';

// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ViewSelection = ({onSelect = () => {}, percentileView = false}) => (
  <Container>
    <Segmented
      block
      value={percentileView ? 'Percentile Ranking' : 'Rating'}
      options={['Rating', 'Percentile Ranking']}
      onChange={(value) => onSelect(value === 'Percentile Ranking')}
    />
  </Container>
);

ViewSelection.propTypes = {
  onSelect: PropTypes.func,
  percentileView: PropTypes.bool,
};

export default ViewSelection;
