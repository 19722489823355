// mock lib
import loadAndAttachData from './mock/lib/loadAndAttachData';

// supplier api
import getCreditReportPdfApi from '../../supplier/api/getCreditReportPdf.api.supplier';
import getSupplierBlackKiteComplianceFindingsApi from '../../supplier/api/getBlackKiteComplianceFindings.api.supplier';
import getSupplierDashboardApi from '../../supplier/api/getDashboard.api.supplier';

// tag services
import getCompanyTagsService from '../../tag/services/getCompanyTags.service.tag';

// tenant api
import getHaloScoresApi from '../../tenant/api/getHaloScores.api.tenant';

const getByIdApi = async ({companyId}) =>
  new Promise(async (resolve, reject) => {
    try {
      const [dashboardData, blackKiteComplianceFindings, supplierHaloScores] =
        await Promise.all([
          getSupplierDashboardApi(companyId),
          getSupplierBlackKiteComplianceFindingsApi(companyId),
          getHaloScoresApi(companyId),
        ]);

      const companyWithoutTags = {
        ...dashboardData,
        CompanyId: dashboardData.CompanyId,
        weightedHaloScores: supplierHaloScores,
        complianceFindings: blackKiteComplianceFindings,
      };

      const {data: tags} = await getCompanyTagsService({
        companyId: companyWithoutTags.CompanyId,
      });

      const company = {
        ...companyWithoutTags,
        tags,
      };

      const haloCompanyId = dashboardData.BlackKiteID || dashboardData.BlackKiteId || dashboardData.CompanyId;

      if (!company) return reject('Not found');
      const companyWithLoadedData = await loadAndAttachData({
        company,
        dataList: [
          {
            filePath: `/mock/corporateHierarchy/${haloCompanyId}.json`,
            dataKey: 'corporateHierarchy',
          },
          {
            filePath: `/mock/cyberSecurity/industryAverage/${haloCompanyId}.json`,
            dataKey: 'cyberSecurityIndustryAverage',
          },
          {
            filePath: `/mock/testFinancials/${haloCompanyId}.json`,
            dataKey: 'testFinancials',
          },
          {
            filePath: `/mock/customTags/${haloCompanyId}.json`,
            dataKey: 'customTags',
          },
          {
            filePath: `/mock/creditSafe/${haloCompanyId}.json`,
            dataKey: 'creditSafe',
          },
          {
            filePath: `/mock/financials/${haloCompanyId}.json`,
            dataKey: 'financials',
          },
          {
            filePath: `/mock/creditSafeAlerts/${haloCompanyId}.json`,
            dataKey: 'creditSafeAlerts',
          },
        ],
      });

      try {
        const financialsPdf = await getCreditReportPdfApi(haloCompanyId);
        if (!!financialsPdf)
          companyWithLoadedData.financialsPdf = financialsPdf;
      } catch (apiError) {
        // do nothing
      }

      const cbInsights = await loadAndAttachData({
        company: {},
        dataList: [
          {
            filePath: `/mock/cbInsights/businessRelationships/${haloCompanyId}.json`,
            dataKey: 'businessRelationships',
          },
          {
            filePath: `/mock/cbInsights/deals/${haloCompanyId}.json`,
            dataKey: 'deals',
          },
          {
            filePath: `/mock/cbInsights/organization/${haloCompanyId}.json`,
            dataKey: 'organization',
          },
          {
            filePath: `/mock/cbInsights/people/${haloCompanyId}.json`,
            dataKey: 'people',
          },
        ],
      });
      if (!!Object.keys(cbInsights).length)
        companyWithLoadedData.cbInsights = cbInsights;
      return resolve(companyWithLoadedData);
    } catch (error) {
      reject(error);
    }
  });

export default getByIdApi;
