// ant components
import {Button, Space, Tooltip} from 'antd';

// chart components
import MiniPrimaryScoreChart from '../../../../../chart/components/MiniPrimaryScoreChart/MiniPrimaryScoreChart';
import MiniPrimaryGradeChart from '../../../../../chart/components/MiniPrimaryGradeChart/MiniPrimaryGradeChart';

// haloGpt containers
import AnswerWithHaloGptContainer from '../../../../../haloGpt/containers/AnswerWithHaloGptContainer/AnswerWithHaloGptContainer';

// local components
import Container from './components/Container';
import Link from './components/Link';
import Tab from './components/Tab';
import Tabs from './components/Tabs';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const Content = ({
  children,
  contentStyle,
  haloGpt,
  modalContents,
  moreButton,
  primaryButton,
  primaryScore,
  secondaryGrade,
  secondaryScore,
  tabs = [],
  title,
  titleTooltip = null,
  withBorder,
}) => {
  return (
    <Container withBorder={withBorder} withTitle={!!title} style={contentStyle}>
      {modalContents}
      {!!title && (
        <Fragment>
          <Title>
            {!!titleTooltip ? (
              <span>
                <Tooltip title={titleTooltip}>
                  {title} <i className="mdi mdi-information" />
                </Tooltip>
              </span>
            ) : (
              title
            )}
            {!!primaryScore && <MiniPrimaryScoreChart score={primaryScore} />}
            {!!secondaryScore && (
              <MiniPrimaryGradeChart
                score={secondaryScore}
                grade={secondaryGrade}
              />
            )}
            {!!tabs.length && (
              <Tabs>
                {tabs.map((tab) => {
                  const tabDom = (
                    <Tab key={tab.url} onClick={tab.onClick}>
                      {tab.label}
                    </Tab>
                  );
                  return !!tab.url ? (
                    <Link
                      to={tab.url}
                      target={tab.openNewTab && '_blank'}
                      key={tab.key}
                    >
                      {tabDom}
                    </Link>
                  ) : (
                    tabDom
                  );
                })}
              </Tabs>
            )}
            {!!haloGpt && <AnswerWithHaloGptContainer {...haloGpt} />}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Space>
                {primaryButton && (
                  <Link to={primaryButton.url} key={primaryButton.key}>
                    <Button
                      size="medium"
                      type="primary"
                      shape="default"
                      style={{fontSize: '14px', fontWeight: '600'}}
                    >
                      {primaryButton.label}
                    </Button>
                  </Link>
                )}
                {moreButton}
              </Space>
            </div>
          </Title>
        </Fragment>
      )}
      {children}
    </Container>
  );
};

Content.propTypes = {
  children: PropTypes.node,
  primaryNumber: PropTypes.number,
  secondaryGrade: PropTypes.string,
  secondaryNumber: PropTypes.number,
  title: PropTypes.node,
  titleTooltip: PropTypes.node,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      label: PropTypes.string,
      onClick: PropTypes.func,
      url: PropTypes.string,
    })
  ),
  withBorder: PropTypes.bool,
  haloGpt: PropTypes.object,
};

export default Content;
