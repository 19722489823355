// charts
import Highcharts from 'highcharts';

// layout colors
import yellow200Color from '../../../layout/colors/yellow200.color.layout';
import yellow300Color from '../../../layout/colors/yellow300.color.layout';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// uuid
import {v4} from 'uuid';

const CyberIndustryAverageChart = ({
  categories = [],
  companyName,
  data = [],
  range = [],
  height = '350px',
}) => {
  const id = v4();

  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        height,
      },

      title: {
        text: `${companyName} vs. Industry Average`,
      },

      xAxis: {
        title: {
          text: null,
        },
        categories,
      },

      yAxis: {
        title: {
          text: 'Cyber Security Score (%)',
        },
        max: 100,
      },

      tooltip: {
        crosshairs: true,
        shared: true,
        valueSuffix: '%',
      },

      legend: {
        enabled: false,
      },

      series: [
        {
          name: `${companyName} score`,
          data: data,
          zIndex: 1,
          marker: {
            fillColor: 'white',
            lineWidth: 2,
            lineColor: yellow300Color,
          },
          lineColor: yellow300Color,
        },
        {
          name: 'Industry Average',
          data: range,
          type: 'arearange',
          lineWidth: 0,
          linkedTo: ':previous',
          color: yellow200Color,
          fillOpacity: 0.4,
          zIndex: 0,
          marker: {
            enabled: false,
          },
        },
      ],
    });
    // eslint-disable-next-line
  }, [categories, companyName, data, range, height]);
  return <div id={id} />;
};

CyberIndustryAverageChart.propTypes = {
  categories: PropTypes.array,
  companyName: PropTypes.string,
  data: PropTypes.array,
  height: PropTypes.string,
  range: PropTypes.array,
};

export default CyberIndustryAverageChart;
