// company components
import HighlightedScore from '../../../components/HighlightedScore/HighlightedScore';

// local lib
import generateColumn from '../../../../table/lib/generateColumn.lib.table';

export default generateColumn({
  title: 'Risk Quality Factor Rating',
  componentProps: ({company}) => ({
    score: Number(company?.GeopoliticalEconomics?.RiskQualityFactorRating),
  }),
  Component: HighlightedScore,
});
