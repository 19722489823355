// axios
import axios from 'axios';

const getWorldMapApi = async () => {
  try {
    const response = await axios.get(
      'https://code.highcharts.com/mapdata/custom/world-highres.topo.json'
    );
    return response?.data || null;
  } catch (error) {
    return null;
  }
};

export default getWorldMapApi;
