import styled from 'styled-components';

const Messages = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default Messages;
