// ant components
import {Button} from 'antd';

// ant icons
import {UploadOutlined} from '@ant-design/icons';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const UploadSuppliersButton = ({
  body = 'Please provide a file with a list of suppliers that you would like to upload.',
  children = 'Monitor New Suppliers',
  email = 'support@gotohalo.com',
  subject = 'New Suppliers',
  plain = false,
}) => (
  <a href={`mailto:${email}?subject=${subject}&body=${body}`}>
    {plain ? children : <Button icon={<UploadOutlined />}>{children}</Button>}
  </a>
);

UploadSuppliersButton.propTypes = {
  body: PropTypes.string,
  children: PropTypes.node,
  email: PropTypes.string,
  plain: PropTypes.bool,
  subject: PropTypes.string,
};

export default UploadSuppliersButton;
