// ant
import {Tooltip} from 'antd';

// local components
import Caret from './components/Caret';
import Container from './components/Container';
import Indicator from './components/Indicator';
import Label from './components/Label';
import LineContainer from './components/LineContainer';
import Score from './components/Score';
import Section from './components/Section';
import TooltipIcon from './components/TooltipIcon';

// local lib
import getScoreColor from './lib/getScoreColor.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ScoreLine = ({
  bottomScore = 0,
  bottomScoreLabel,
  bottomTooltip,
  maxScore = 1,
  topScore = 0,
  topScoreLabel,
  topTooltip,
}) => {
  const topScorePercent = topScore / maxScore;
  const topScoreColor = getScoreColor(topScorePercent);

  const bottomScorePercent = bottomScore / maxScore;
  const bottomScoreColor = getScoreColor(bottomScorePercent);

  return (
    <Container>
      {!!topScoreLabel && (
        <Label>
          {topScoreLabel}: <Score color={topScoreColor}>{topScore}</Score>{' '}
          {topTooltip && (
            <Tooltip title={topTooltip}>
              <TooltipIcon className="mdi mdi-information" />
            </Tooltip>
          )}
        </Label>
      )}
      <LineContainer>
        <Section background={getScoreColor(0.1)} />
        <Section background={getScoreColor(0.3)} />
        <Section background={getScoreColor(0.5)} />
        <Section background={getScoreColor(0.7)} />
        <Section background={getScoreColor(0.9)} />
        {!!topScoreLabel && (
          <Indicator score={topScorePercent} color={topScoreColor} top>
            <Caret className="mdi mdi-menu-down" />
          </Indicator>
        )}
        {!!bottomScoreLabel && (
          <Indicator score={bottomScorePercent} color={bottomScoreColor}>
            <Caret className="mdi mdi-menu-up" />
          </Indicator>
        )}
      </LineContainer>
      {!!bottomScoreLabel && (
        <Label>
          {bottomScoreLabel}:{' '}
          <Score color={bottomScoreColor}>{bottomScore}</Score>{' '}
          {bottomTooltip && (
            <Tooltip title={bottomTooltip}>
              <TooltipIcon className="mdi mdi-information" />
            </Tooltip>
          )}
        </Label>
      )}
    </Container>
  );
};

ScoreLine.propTypes = {
  bottomScore: PropTypes.number,
  bottomScoreLabel: PropTypes.node,
  bottomTooltip: PropTypes.node,
  maxScore: PropTypes.number,
  topScore: PropTypes.number,
  topTooltip: PropTypes.node,
};

export default ScoreLine;
