// ant components
import {Col as Column, Row, Table} from 'antd';

// financial components
import IndustryBreakdownChart from '../IndustryBreakdownChart/IndustryBreakdownChart';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local lib
import generateColumns from './lib/generateColumns.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const IndustryBreakdownCard = ({currency, valueCategories, entries}) => {
  const columns = generateColumns({currency, valueCategories});

  const chartCategories = [...entries]
    .filter(({industry}) => industry.length > 1)
    .map(({industry}) => industry);
  const statuses = [...entries].reduce(
    (combined, {status}) =>
      !combined.includes(status) ? [...combined, status] : combined,
    []
  );

  return (
    <StatementBlock title="Industry Breakdown">
      <Row gutter={[0, 10]}>
        <Column span={24}>
          <Table
            columns={columns.valueCategories}
            dataSource={entries}
            pagination={false}
            rowKey="industry"
            size="small"
          />
        </Column>
        <Column span={24}>
          <Row gutter={[10, 10]}>
            {statuses.map((status) => (
              <Column span={24 / statuses.length}>
                <IndustryBreakdownChart
                  categories={chartCategories}
                  entries={[
                    {
                      name: status,
                      data: [...entries]
                        .filter((entry) => entry.status === status)
                        .map(({total}) => total?.value),
                    },
                  ]}
                />
              </Column>
            ))}
          </Row>
        </Column>
      </Row>
    </StatementBlock>
  );
};

IndustryBreakdownCard.propTypes = {
  currency: PropTypes.string,
  valueCategories: PropTypes.array,
  entries: PropTypes.array,
};

export default IndustryBreakdownCard;
