// propTypes
import PropTypes from 'prop-types';

// react
// import React from 'react';

const BoardAssociationColumn = ({
  category,
  columnIndex,
  entry,
  entryIndex,
  organizationId,
}) => {
  const boardAssocations = entry[category];
  const boardAssocation = [...boardAssocations]
    .filter(({orgId}) => orgId === organizationId)
    .map(({title}) => title)
    .join(', ');
  return boardAssocation || '-';
};

BoardAssociationColumn.propTypes = {
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default BoardAssociationColumn;
