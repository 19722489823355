const extractIndustries = (companies) =>
  [...companies]
    .reduce((industries, company) => {
      const industry = company?.Firmographic?.PrimaryIndustry || null;
      return !industry || industries.includes(industry)
        ? industries
        : [...industries, industry];
    }, [])
    .map((industry) => ({value: industry, label: industry}));

export default extractIndustries;
