// financial components
import FinancialStatementsCard from '../FinancialStatementsCard/FinancialStatementsCard';
import FinancialStatementsKeyPerformanceIndicatorsCard from '../FinancialStatementsKeyPerformanceIndicatorsCard/FinancialStatementsKeyPerformanceIndicatorsCard';

// layout components
import Tabs from '../../../layout/components/Tabs/Tabs';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const FinancialStatements = ({printView}) => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));

  const tabs = [
    {
      key: 'incomeStatement',
      label: 'Income Statement',
    },
    {
      key: 'assets',
      label: 'Assets',
    },
    {
      key: 'liabilities',
      label: 'Liabilities',
    },
    {
      key: 'cashFlow',
      label: 'Cashflow',
    },
    {
      key: 'profitabilityRatios',
      label: 'Profitability Ratios',
    },
    {
      key: 'assetUtilization',
      label: 'Asset Utilization',
    },
    {
      key: 'liquidityRatios',
      label: 'Liquidity Ratios',
    },
    {
      key: 'capitalStructure',
      label: 'Capital Structure',
    },
    {
      key: 'strategicRiskItems',
      label: 'Strategic Risk Items',
    },
    {
      key: 'growthRates',
      label: 'Growth Rates',
    },
    {
      key: 'Key Performance Indicators',
      label: 'Key Performance Indicators',
      children: (
        <FinancialStatementsKeyPerformanceIndicatorsCard
          printView={printView}
        />
      ),
    },
  ]
    .filter((tab) => !!tab)
    .map((tab) => ({
      ...tab,
      children: tab.children || (
        <FinancialStatementsCard
          categories={company.financials.financials[tab.key].categories}
          currency={company.financials.currency}
          entries={company.financials.financials[tab.key].entries}
          title={tab.label}
        />
      ),
    }));

  return <Tabs tabs={tabs} printView={printView} />;
};

FinancialStatements.propTypes = {
  printView: PropTypes.bool,
};

export default FinancialStatements;
