// charts
import Highcharts from 'highcharts';

// layout components
import Card from '../../../layout/components/Card/Card';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// risk lib
import getRiskColor from '../../../risk/lib/getRiskColor.lib.risk';

// uuid
import {v4} from 'uuid';

const EsgRatingChartCard = ({
  height = '424px',
  info = '',
  score = 0,
  title = 'ESG Rating',
}) => {
  const id = v4();

  const {primary, secondary} = getRiskColor(score);

  useEffect(() => {
    Highcharts.chart(id, {
      title: {
        text: title,
      },
      chart: {
        type: 'solidgauge',
        height,
      },
      tooltip: {
        enabled: false,
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        size: '80%',
        background: [
          {
            outerRadius: '100%',
            innerRadius: '65%',
            backgroundColor: secondary,
            borderWidth: 0,
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: true,
            borderWidth: 0,
            padding: 0,
            y: -24,
            style: {
              fontSize: '40px',
            },
            format: '{point.y:.0f}',
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },
      series: [
        {
          name: 'Halo Intrinsic Score',
          data: [
            {
              color: primary,
              radius: '100%',
              innerRadius: '65%',
              y: score,
            },
          ],
        },
      ],
    });
    // eslint-disable-next-line
  }, [score, title, height]);

  return (
    <Card infoTooltip={info} noPadding>
      <div id={id} />
    </Card>
  );
};

EsgRatingChartCard.propTypes = {
  height: PropTypes.string,
  info: PropTypes.node,
  score: PropTypes.number,
  title: PropTypes.string,
};

export default EsgRatingChartCard;
