const removeEmptyTableColumns = ({categories, entries, ...props}) => {
  const filteredCategories = [...categories].filter(
    (category) =>
      [...entries].some(
        (entry) => ![null, undefined, 0, '', '-'].includes(entry[category?.key])
      ) || category?.key === 'section'
  );

  const filteredEntries = [...entries].map((entry) => {
    return Object.entries(entry).reduce((combined, [key, value]) => {
      return filteredCategories.includes(key)
        ? combined
        : {...combined, [key]: value};
    }, {});
  });

  return {categories: filteredCategories, entries: filteredEntries, ...props};
};

export default removeEmptyTableColumns;
