// ant components
import {Col as Column, Row, Table} from 'antd';

// financial constants
import FINANCIAL_RISK_SCORE_DEFINITION from '../../constants/riskScoreDefinition.constant.financial';
import FINANCIAL_RISK_SCORES from '../../constants/riskScores.constant.financial';

// financial lib
import getFinancialRiskScore from '../../lib/getRiskScore.lib.financial';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';
import Title from '../../../layout/components/Title/Title';

// local data
import columns from './data/columns.data';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// score components
import HighlightedScore from '../../../score/components/HighlightedScore/HighlightedScore';

const FinancialRiskScoreCard = ({internationalScore, riskScore}) => {
  const financialRiskScore = getFinancialRiskScore(riskScore);

  return (
    <StatementBlock
      padding
      title="Delinquency Risk Score"
      tooltip={FINANCIAL_RISK_SCORE_DEFINITION}
    >
      <Row gutter={[10, 10]}>
        <Column span={24}>
          <Row gutter={[10, 10]}>
            <Column span={12}>
              <Title heading="H4">Today's Delinquency Risk Score</Title>
              <HighlightedScore color={financialRiskScore.color}>
                {riskScore}
              </HighlightedScore>
            </Column>
            <Column span={12}>
              <Title heading="H4">
                Today's Delinquency International Risk Score
              </Title>
              <HighlightedScore color={financialRiskScore.color}>
                {internationalScore}
              </HighlightedScore>
            </Column>
          </Row>
        </Column>
        <Column span={24}>
          <Table
            columns={columns}
            dataSource={FINANCIAL_RISK_SCORES}
            pagination={false}
            rowKey="key"
            size="small"
          />
        </Column>
      </Row>
    </StatementBlock>
  );
};

FinancialRiskScoreCard.propTypes = {
  internationalScore: PropTypes.string,
  riskScore: PropTypes.number,
};

export default FinancialRiskScoreCard;
