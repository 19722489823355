// alert routes
// import alertSettingsRoute from '../../../alert/pages/AlertSettingsPage/route';

// ant components
import {Dropdown, Button} from 'antd';

// ant icons
import {UploadOutlined, WarningOutlined} from '@ant-design/icons';

// halo api
import getHaloData from '../../../api/lib/getHaloData.lib.api';

// local components
import Badge from './components/Badge';
import Container from './components/Container';
import IconButton from './components/IconButton';
import Image from './components/Image';
import LinkButton from './components/LinkButton';
import Logo from './components/Logo';
import Section from './components/Section';

// local handlers
// import onContactHandler from './handlers/onContact.handler';

// navigation components
import NavigationTabs from '../NavigationTabs/NavigationTabs';

// navigation containers
import NavigationSearchContainer from '../../containers/NavigationSearchContainer/NavigationSearchContainer';

// notification containers
import NotificationBadgeContainer from '../../../notification/containers/NotificationBadgeContainer/NotificationBadgeContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useState, useEffect} from 'react';

// router
import {Link} from 'react-router-dom';

// redux
import {useSelector} from 'react-redux';

// supplier components
import UploadSuppliersButton from '../../../supplier/components/UploadSuppliersButton/UploadSuppliersButton';

// workflow routes
import workflowsRoute from '../../../workflow/pages/WorkflowsPage/route';

const Navigation = ({onSignOut, pages, onLogoClick}) => {
  const {tenant} = useSelector((state) => state.auth);
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    async function fetchLogo() {
      try {
        const data = await getHaloData(`/tenant/${tenant.id}/logo.png`, tenant);
        setLogoUrl(data);
      } catch (error) {}
    }
    fetchLogo();
  }, [tenant]);

  return (
    <Container style={{height: '61px'}}>
      <Section>
        <Logo onClick={onLogoClick} />
        <NavigationSearchContainer />
        <NavigationTabs pages={pages} />
      </Section>
      <Section>
        <NotificationBadgeContainer>
          {({formattedNotificationCount, onClick}) => (
            <IconButton onClick={onClick}>
              <i className="mdi mdi-bell-outline" />
              {!!formattedNotificationCount && (
                <Badge>{formattedNotificationCount}</Badge>
              )}
            </IconButton>
          )}
        </NotificationBadgeContainer>
        <Dropdown
          menu={{
            items: [
              {
                icon: <WarningOutlined />,
                key: 'workflows',
                label: <Link to={workflowsRoute()}>Alert Workflows</Link>,
              },
              {
                icon: <UploadOutlined />,
                key: 'newSuppliers',
                label: <UploadSuppliersButton plain />,
              },
            ],
          }}
          trigger={['click']}
        >
          <Button type="primary">Actions</Button>
        </Dropdown>
        {/* <IconButton onClick={onContactHandler}>
          <i className="mdi mdi-email-outline" />
        </IconButton> */}
        <LinkButton onClick={onSignOut}>Sign out</LinkButton>
        {logoUrl && (
          <Image src={logoUrl} style={{height: '30px', marginRight: '5px'}} />
        )}
      </Section>
    </Container>
  );
};

Navigation.propTypes = {
  onLogoClick: PropTypes.func,
  onSearch: PropTypes.func,
  onSignOut: PropTypes.func,
  pages: PropTypes.array,
  search: PropTypes.string,
};

export default Navigation;
