// layout color
import brightGreen300Color from '../../layout/colors/brightGreen300.color.layout';
import brightRed300Color from '../../layout/colors/brightRed300.color.layout';
import brightRed400Color from '../../layout/colors/brightRed400.color.layout';
import brightRed500Color from '../../layout/colors/brightRed500.color.layout';
import brightYellow500Color from '../../layout/colors/brightYellow500.color.layout';
import green300Color from '../../layout/colors/green300.color.layout';

// helpers
const LEVELS = [
  [30, brightRed500Color],
  [45, brightRed400Color],
  [60, brightRed300Color],
  [75, brightYellow500Color],
  [85, green300Color],
  [101, brightGreen300Color],
];

const getScoreColor = (score) => {
  const minLevel = [...LEVELS][0];
  const maxLevel = [...LEVELS].slice(-1);
  return score > maxLevel[0]
    ? maxLevel[1]
    : [...LEVELS].find(([maxScore]) => score < maxScore)?.[1] || minLevel[1];
};

export default getScoreColor;
