// local components
import ActionsButton from './ActionsButton';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Column = ({dataKey, entry, entryIndex}) => {
  const value = entry?.[dataKey] || entry?.settings?.[dataKey] || null;
  return !!value ? (
    dataKey === 'period' ? (
      <div>
        {value} Day{value === 1 ? '' : 's'}
      </div>
    ) : dataKey === 'threshold' ? (
      <div>{value}%</div>
    ) : dataKey === 'actions' ? (
      <ActionsButton workflow={entry} />
    ) : (
      <div>{value || '-'}</div>
    )
  ) : (
    '-'
  );
};

Column.propTypes = {
  dataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnIndex: PropTypes.number,
  entry: PropTypes.object,
  entryIndex: PropTypes.number,
};

export default Column;
