// charts
import Highcharts from 'highcharts';

// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {useEffect} from 'react';

// risk lib
import convertScoreToGrade from '../../../risk/lib/convertScoreToGrade.lib.risk';
import getRiskColor from '../../../risk/lib/getRiskColor.lib.risk';
import getGradeColor from '../../../risk/lib/getGradeColor.lib.risk';

// uuid
import {v4} from 'uuid';

const MiniPrimaryGradeChart = ({
  grade = null,
  gradeColor = getGradeColor,
  riskColor = (score) => getRiskColor(score).secondary,
  score = 0,
  scoreToGrade = convertScoreToGrade,
}) => {
  const id = v4();

  const actualGrade = grade || scoreToGrade(score);
  const primary = gradeColor(actualGrade);
  const secondary = riskColor(score);

  useEffect(() => {
    Highcharts.chart(id, {
      title: {
        text: '',
      },
      exporting: {
        enabled: false,
      },
      chart: {
        type: 'solidgauge',
        height: '30px',
        spacing: [0, 0, 0, 0],
        // backgroundColor: 'transparent',
        style: {
          borderRadius: '50%',
        },
      },
      tooltip: {
        enabled: false,
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        size: '100%',
        background: [
          {
            outerRadius: '100%',
            innerRadius: '86%',
            backgroundColor: secondary,
            borderWidth: 0,
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: true,
            borderWidth: 0,
            padding: 0,
            shadow: false,
            y: -9,
            style: {
              fontSize: '16px',
              color: primary,
              border: 'none',
              textShadow: 'none',
              textOutline: 'none',
            },
            formatter: () => actualGrade,
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },
      series: [
        {
          name: 'Halo Intrinsic Score',
          data: [
            {
              color: primary,
              radius: '100%',
              innerRadius: '86%',
              y: score,
            },
          ],
        },
      ],
    });
    // eslint-disable-next-line
  }, [score, grade]);

  return (
    <Container>
      <div id={id} />
    </Container>
  );
};

MiniPrimaryGradeChart.propTypes = {
  grade: PropTypes.string,
  gradeColor: PropTypes.func,
  riskColor: PropTypes.func,
  score: PropTypes.number,
  scoreToGrade: PropTypes.func,
};

export default MiniPrimaryGradeChart;
