// ant components
import {Button} from 'antd';

// ant icons
import {PrinterOutlined} from '@ant-design/icons';

// company components
import CompanyKeySuppliersSection from '../../../company/components/CompanyKeySuppliersSection/CompanyKeySuppliersSection';

// company component lib
import renderKeySuppliersSection from '../../../company/components/CompanyKeySuppliersSection/lib/renderKeySuppliersSection.lib';

// company containers
import CompanySideMenuContainer from '../../../company/containers/CompanySideMenuContainer/CompanySideMenuContainer';

// company lib
import generateSubPages from '../../../company/lib/generateSubPages.lib.company';

// company routes
import suppliersDashboardRoute from '../../../company/pages/SuppliersDashboardPage/route';

// geopolitical constants
import GEOPOLITICAL_HALO_GPT_CONVERSATION from '../../constants/geopoliticalHaloGptConversation.constants.geopolitical';

// geopolitical lib
import prepareDataForHaloGptExplain from '../../lib/prepareDataForHaloGptExplain.lib.geopolitical';

// haloGpt containers
import PageHaloGptContainer from '../../../haloGpt/containers/PageHaloGptContainer/PageHaloGptContainer';

// layout components
import PageLayout from '../../../layout/components/PageLayout/PageLayout';

// print components
import Print from '../../../print/components/Print/Print';
import PrintLayout from '../../../print/components/PrintLayout/PrintLayout';
import PrintSection from '../../../print/components/PrintSection/PrintSection';

// react
import React from 'react';

// redux
import {useSelector} from 'react-redux';

const GeopoliticalLayout = () => {
  const {company, subPage, globalResilienceIndexes} = useSelector((state) => ({
    company: state.company.company,
    subPage: state.layout.subPage,
    globalResilienceIndexes: state.dashboard.globalResilienceIndexes,
  }));

  const haloGptData = prepareDataForHaloGptExplain({
    company,
    globalResilienceIndexes,
  });
  const questionFlow = GEOPOLITICAL_HALO_GPT_CONVERSATION({data: haloGptData});

  return (
    <Print
      title="Geopolitical Report"
      subtitle={company.CompanyName}
      trigger={({print}) => (
        <PageLayout
          alerts={company?.alerts || []}
          previousPageLabel="Back to Dashboard"
          previousPageUrl={suppliersDashboardRoute(company.CompanyId)}
          sideContent={({goToSection}) => (
            <CompanySideMenuContainer goToSection={goToSection} />
          )}
          subPage={subPage}
          subPages={generateSubPages(company)}
          sectionName="Geopolitical"
          sections={[
            renderKeySuppliersSection({company})
              ? {
                  section: 'Supply Chain',
                  component: <CompanyKeySuppliersSection />,
                  moreButton: (
                    <Button icon={<PrinterOutlined />} onClick={print}>
                      Print Report
                    </Button>
                  ),
                }
              : {
                  section: 'Supply Chain',
                  component: <div>No data found</div>,
                },
          ].filter((section) => !!section)}
          chat={
            <PageHaloGptContainer
              data={haloGptData}
              questionFlow={questionFlow}
            />
          }
        />
      )}
    >
      <PrintLayout>
        <PrintSection title="Linked Members">
          <CompanyKeySuppliersSection />
        </PrintSection>
      </PrintLayout>
    </Print>
  );
};

export default GeopoliticalLayout;
